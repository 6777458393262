import axios from 'axios';
import * as types from './actionTypes';
import {isEmpty} from '../../helperFunctions';

function fetchReceivedInProcess(bool) {
  return {
    type: types.FETCH_RECEIVED_IN_PROCESS,
    fetchReceivedInProcess: bool
  }
}

function fetchReceivedError(error) {
  return {
    type: types.FETCH_RECEIVED_ERROR,
    fetchReceivedError: error
  }
}

function updateEmailError(error) {
  return {
    type: types.UPDATE_EMAIL_ERROR,
    updateEmailError: error
  }
}

function deleteEmailError(error) {
  return {
    type: types.DELETE_EMAIL_ERROR,
    deleteEmailError: error
  }
}

function rejectEmailInProcess(bool) {
  return {
    type: types.REJECT_EMAIL_IN_PROCESS,
    rejectEmailInProcess: bool
  }
}

function rejectEmailSuccess(invite) {
  return {
    type: types.REJECT_EMAIL_SUCCESS,
    invite
  }
}

function rejectEmailError(error) {
  return {
    type: types.REJECT_EMAIL_ERROR,
    rejectEmailError: error
  }
}

function fetchEmailsSuccess(emails) {
  return {
    type: types.FETCH_EMAILS_SUCCESS,
    emails
  }
}

export function clearEmailsData() {
  return {
      type: types.CLEAR_EMAILS_DATA,
      cameraGroupEmails: [],
      sentEmails: []
  }
}

export function fetchUserEmails(values) {
  return (dispatch) => {
    let config = {headers: {Authorization: 'Bearer '+sessionStorage.getItem('jwt')}};
    let url = `${process.env.REACT_APP_API_URL}/emails?`;
    for (var property in values) {
      if (url.endsWith(`?`) && values[property] !== undefined){
        url += property + `=` + values[property];
      } else if (values[property] !== undefined){
        url += `&` + property + `=` + values[property];
      }
    }

    if (url !== `${process.env.REACT_APP_API_URL}/emails?`) {
      axios.get(url, config)
      .then(response => {
        if (!isEmpty(response.data)) {
          dispatch(fetchEmailsSuccess(response.data));
        } else {
          dispatch(fetchReceivedError('No records found.'));
        }
      })
      .catch((error) => {
        let errMessage = 'Error fetching emails';
        if (error.response  !== undefined) {
          errMessage = error.response;
          if (typeof error === 'object') {
            if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
              if (typeof error.response.data === 'object') {
                if ('Error' in error.response.data) {
                  errMessage = error.response.data['Error'];
                }
              } else {
                errMessage = error.response.data;
              }
            }
          }
        }
        dispatch(fetchReceivedError(errMessage));
      })
      .finally(() => {
        dispatch(fetchReceivedError(''));
        dispatch(fetchReceivedInProcess(false));
      });
    } else {
      dispatch(fetchReceivedError('Please fill in at least one field.'));
    }
  }
}

export function updateEmail(invitation) {
  return (dispatch) => {
    var data = JSON.parse(JSON.stringify(invitation));
    delete data.key;
    delete data.uuid;
    let config = {headers: {Authorization: 'Bearer '+sessionStorage.getItem('jwt')}};
    let url = `${process.env.REACT_APP_API_URL}/emails/${invitation.uuid}`;

    axios.patch(url, data, config)
      .catch((error) => {
        let errMessage = 'Error updating invitation';
        if (error.response  !== undefined) {
          errMessage = error.response;
          if (typeof error === 'object') {
            if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
              if (typeof error.response.data === 'object') {
                if ('Error' in error.response.data) {
                  errMessage = error.response.data['Error'];
                }
              } else {
                errMessage = error.response.data;
              }
            }
          }
        }
        dispatch(updateEmailError(errMessage));
      })
  }
}

export function deleteEmail(invitation_uuid) {
  return (dispatch) => {
    let config = {headers: {Authorization: 'Bearer '+sessionStorage.getItem('jwt')}};
    let url = `${process.env.REACT_APP_API_URL}/emails/${invitation_uuid}`;

    axios.delete(url, config)
    .catch((error) => {
      let errMessage = 'Error deleting invitation';
      if (error.response  !== undefined) {
        errMessage = error.response;
        if (typeof error === 'object') {
          if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
            if (typeof error.response.data === 'object') {
              if ('Error' in error.response.data) {
                errMessage = error.response.data['Error'];
              }
            } else {
              errMessage = error.response.data;
            }
          }
        }
      }
      dispatch(deleteEmailError(errMessage));
    })
  }
}

export function rejectEmail(invite) {
  return (dispatch) => {
    dispatch(rejectEmailError(''));
    dispatch(rejectEmailInProcess(true));

    let url = `${process.env.REACT_APP_API_URL}/emails/${invite.uuid}`;
    let config = {headers: {Authorization: 'Bearer '+sessionStorage.getItem('jwt')}};

    axios.delete(url, config)
    .then(response => {
      dispatch(rejectEmailSuccess(invite));
    })
    .catch((error) => {
      let errMessage = 'Error rejecting invitation';
      if (error.response  !== undefined) {
        errMessage = error.response;
        if (typeof error === 'object') {
          if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
            if (typeof error.response.data === 'object') {
              if ('Error' in error.response.data) {
                errMessage = error.response.data['Error'];
              }
            } else {
              errMessage = error.response.data;
            }
          }
        }
      }
      dispatch(rejectEmailError(errMessage));
    })
    .finally(() => {
      dispatch(rejectEmailError(''));
      dispatch(rejectEmailInProcess(false));
    });
  }
}
