const state = {
  sentEmails: [],
  emails: null,
  fetchReceivedInProcess: false,
  fetchReceivedError: "",
  fetchSentInProcess: false,
  fetchSentError: "",
  acceptEmailInProcess: false,
  acceptEmailError: "",
  rejectEmailInProcess: false,
  rejectEmailError: "",
  rescindEmailInProcess: false,
  rescindEmailError: "",
  updateEmailError: "",
  deleteEmailError: "",
};

export default state;
