import React, { useState, useEffect } from 'react';
import { List, Checkbox } from 'antd';
import InviteFriendsModal from '../modals/InviteFriends.js';

const FriendsList = ({ friends, handleFriendSelection }) => {
  const [selectedFriends, setSelectedFriends] = useState([]);

  useEffect(() => {
    if (friends && friends.length > 0) {
      // Initially select all friends
      const allFriendUuids = friends.map(friend => friend.uuid);
      setSelectedFriends(allFriendUuids);
      handleFriendSelection(allFriendUuids);
    }
  }, [friends, handleFriendSelection]);

  const onFriendSelect = (friendUuid, checked) => {
    const newSelection = checked
      ? [...selectedFriends, friendUuid]
      : selectedFriends.filter(id => id !== friendUuid);
    setSelectedFriends(newSelection);
    handleFriendSelection(newSelection);
  };

  return (
    <div className="friends-list">
      {friends && friends.length > 0 ? (
        <List
          dataSource={friends}
          renderItem={(friend) => {
            if (!friend || !friend.uuid) return null; // Skip rendering if friend or friend.uuid is undefined
            return (
              <List.Item>
                <Checkbox
                  checked={selectedFriends.includes(friend.uuid)}
                  onChange={(e) => onFriendSelect(friend.uuid, e.target.checked)}
                >
                  {`${friend.first_name} ${friend.last_name}`}
                </Checkbox>
              </List.Item>
            );
          }}
        />
      ) : (
        <p>No friends found. Invite some friends to get started!</p>
      )}
      <InviteFriendsModal />
    </div>
  );
};

export default FriendsList;
