import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Layout, Row, Col, Form, Button, Input, Checkbox, message } from 'antd';
import { LoadingOutlined, LockOutlined } from '@ant-design/icons';
import logoFull from '../../assets/images/logo.svg';
import { getEmail, register } from '../redux/authentication/actions'
import "../../assets/css/components/rows.css";

const { Header, Content } = Layout;

const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 14,
  },
};

class FriendRequestRegister extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmDirty: false
    }
  }

  UNSAFE_componentWillMount = () => {
    this.props.getEmail(this.props.match.params.token);
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (nextProps.registerSuccess && this.props.registerSuccess !== nextProps.registerSuccess) {
      message.success('Registration complete! Please log in.');
      this.props.history.push('/login');
    }

    if (nextProps.registerError && this.props.registerError !== nextProps.registerError) {
      message.error(nextProps.registerError, 10);
      if (nextProps.registerError === 'Invalid email') {
        this.props.history.push('/login');
      }
    }

    if (nextProps.getEmailError && this.props.getEmailError !== nextProps.getEmailError) {
      message.error(nextProps.getEmailError, 10);
      this.props.history.push('/login');
    }

    if (typeof nextProps.email !== 'undefined') {
      this.form.setFieldsValue({recipient: nextProps.email.recipient})
    }
  }

  handleSubmit = (e) => {
    this.form.validateFields().then(values => {
      this.props.register(values.recipient, values.firstName, values.lastName, values.password, values.confirmPassword, this.props.match.params.token, 'friend_request_register');
    });
  }

  onFinishFailed = ({ errorFields }) => {
    this.form.scrollToField(errorFields[0].name);
  };

  checkAgreement = async (rule, value) => {
    if (!value) {
      throw new Error('Please agree to our terms of use.');
    }
  }

  checkPasswordLength = async (rule, value) => {
    if (value && value.length < 8) {
      throw new Error('Password must be at least 8 characters long');
    }
  }

  checkConfirmPassword = async (rule, value) => {
    const form = this.form;
    if (value && value !== form.getFieldValue('password')) {
      throw new Error('The passwords must match');
    }
  }

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }

  formRef = (form) => {
    this.form = form;
  }

  render() {
    return (
      <Layout>
        <Header style={styles.header}>
          <img style={styles.headerLogo} src={logoFull} alt="Header Logo" height='50px'/>
        </Header>
        <Layout>
          <Content style={styles.content}>
            <Row classname='FriendRequestRow'>
              <Col xs={{span: 24}} style={styles.leftContainer}>
                <Row classname='FriendRequestRow2'>
                  <Col xs={{span: 22}} sm={{span: 18}} md={{span: 14}} lg={{span: 10}} style={styles.rightHeadTitle}>
                    <h2 style={styles.headTitleText}>Welcome to MeSearch</h2>
                  </Col>
                </Row>
                <Row classname='FriendRequestRow'>
                  <Col xs={{span: 22}} sm={{span: 18}} md={{span: 14}} lg={{span: 10}}>
                    <Form
                      onFinish={this.handleSubmit}
                      ref={this.formRef}
                      className='register-form'
                      style={styles.registerForm}
                    >
                      <Form.Item label='Email Address' name="recipient" hasFeedback {...layout}>
                        <Input onBlur={this.validateStatus} readOnly disabled />
                      </Form.Item>
                      <Form.Item label='First Name' name="firstName" rules={[{required: true, message: 'Please enter your first name'}]} hasFeedback {...layout}>
                        <Input onBlur={this.validateStatus} />
                      </Form.Item>
                      <Form.Item label='Last Name' name="lastName" rules={[{required: true, message: 'Please enter your last name'}]} hasFeedback {...layout}>
                        <Input onBlur={this.validateStatus} />
                      </Form.Item>
                      <Form.Item label='Password' name="password"
                        rules={[
                          {required: true, message: 'Please choose a password'},
                          {validator: this.checkPasswordLength}
                        ]}
                        hasFeedback
                         {...layout}
                      >
                        <Input.Password onBlur={this.validateStatus} />
                      </Form.Item>
                      <Form.Item label="Confirm Password" name="confirmPassword"
                        rules={[
                          {required: true, message: 'Please confirm your password'},
                          {validator: this.checkConfirmPassword}
                        ]}
                        hasFeedback
                         {...layout}
                      >
                        <Input.Password onBlur={this.handleConfirmBlur} />
                      </Form.Item>
                      <Form.Item name="termsOfUse" valuePropName="checked" rules={[{validator: this.checkAgreement}]} style={styles.checkBox} {...layout}>
                        <Checkbox>By clicking Sign Up, you acknowledge you have read and agree to the <a href='https://www.me-search.com/terms-of-use' target='_blank' rel="noopener noreferrer">Terms of Use</a>.</Checkbox>
                      </Form.Item>
                      <Form.Item style={{justifyContent: 'center'}} {...layout}>
                        <Button style={styles.signUpBtn} type='primary' htmlType='submit' disabled={this.props.registerInProcess}>
                          {this.props.registerInProcess ? <LoadingOutlined style={styles.font13} /> : <LockOutlined style={styles.font13} />}
                          &nbsp;Sign Up
                        </Button>
                      </Form.Item>
                    </Form>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Layout>
    )
  }
}

const styles = {
  header: {
    position: 'fixed',
    width: '100%',
    height: '65px',
    color: 'white',
    backgroundColor: 'black',
    zIndex: 1 // Required for Content to scroll under Header
  },
  headerLogo: {
    float: 'right',
    marginTop: 8
  },
  content: {
    marginTop: 65,
    zIndex: 0, // Required for Content to scroll under Header
  },
  leftContainer: {
    backgroundColor: '#fff',
    height: 'calc(100vh - 65px)'
  },
  signUpBtn: {
    backgroundColor: 'green'
  },
  registerFormHeadText: {
    marginTop: 30,
    marginBottom: 10
  },
  // phoneCaption: {
  //   marginTop: -20,
  // },
  signInLinkCaption: {
    fontSize: 16
  },
  rightContainer: {
    textAlign: 'center'
  },
  rightHeadTitle: {
    fontSize: 16,
    textAlign: 'center',
    float: 'left'
  },
  headTitleText: {
    width: 300,
    margin: '0 auto',
    paddingTop: 20,
    paddingBottom: 20
  },
  rightHeadCaption: {
    fontSize: 16
  },
  promotionText: {
    textAlign: 'left',
    fontSize: 16
  },
  termsOfServiceText: {
    marginBottom: 20
  },
  registerForm: {
    textAlign: 'center',
    float: 'left'
  },
  checkBox: {
    justifyContent: 'center'
  },
  timeZone: {
    width: '80%'
  },
}

const mapStateToProps = (state) => {
  return {
    email: state.auth.email,
    getEmailInProcess: state.auth.getEmailInProcess,
    getEmailError: state.auth.getEmailError,
    registerInProcess: state.auth.registerInProcess,
    registerError: state.auth.registerError,
    registerSuccess: state.auth.registerSuccess
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getEmail: (token) => dispatch(getEmail(token)),
    register: (recipient, firstName, lastName, password, confirmPassword, token, type) => dispatch(register(recipient, firstName, lastName, password, confirmPassword, token, type))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FriendRequestRegister));
