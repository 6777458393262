import React from "react";

import { List, Card } from "antd";

import ProductDetailsDrawer from "../productInformation/ProductDetailsDrawer.js";
import FavoriteProductButton from "./FavoriteProductButton.js";

const ProductItem = ({ product, categoryId, isOpen }) => {
  // console.log("ProductItem: ", product);
  return (
    <List.Item>
      <Card
        key={product.uuid}
        header={product.name}
        className="productCards"
        title={product.name}
        cover={
          <ProductDetailsDrawer
            key={product.uuid + "_note"}
            value={null}
            context="product"
            data={product}
            open={isOpen}
          />
        }
        actions={[
          <FavoriteProductButton categoryId={categoryId} productId={product.uuid} isFavorite={product.favorited} />,
        ]}
      ></Card>
    </List.Item>
  );
};

export default ProductItem;
