import * as types from './actionTypes';

export const setPhotoUploadModalVisible = (isVisible) => ({ type: types.SET_PHOTO_UPLOAD_MODAL_VISIBLE, payload: isVisible });
export const setTutorialStep = (step) => ({ type: types.SET_TUTORIAL_STEP, payload: step });
export const setEditableInputId = (id) => ({ type: types.SET_EDITABLE_INPUT_ID, payload: id });
export const setShowRemovedTasks = (show) => ({ type: types.SET_SHOW_REMOVED_TASKS, payload: show });
export const setIsLoadingSharedNotes = (isLoading) => ({ type: types.SET_IS_LOADING_SHARED_NOTES, payload: isLoading });
export const setCurrentTab = (tab) => ({ type: types.SET_CURRENT_TAB, payload: tab });
export const setIsAddMomentExpanded = (isExpanded) => ({ type: 'SET_IS_ADD_MOMENT_EXPANDED', payload: isExpanded });
export const setShowTutorial = (show) => ({ type: 'SET_SHOW_TUTORIAL', payload: show });
