import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Checklist from './Checklist';
import NewCategories from './NewCategories';
import "../../../assets/css/components/TabContent.css";
import { readAllCategoriesInAllTopics } from '../../redux/topics/actions';
import { readUserSelectedCategories } from '../../redux/categories/actions';
import { readCategoriesWithProducts } from '../../redux/products/actions';
import ErrorBoundary from './ErrorBoundary';

const TabContent = ({
  currentTab,
  groupedChecklist,
  location,
  user,
  friendUuid,
  searchLoading,
  onSearch,
  friends,
  friendSelected,
  showAddedCategories,
  toggleShowAddedCategories,
  handleViewFriendList,
  removedTasks,
  expandedCardId,
  handleMilestoneComplete,
  removeTask,
  restoreTask,
  toggleCardExpansion,
  handleNotesChange,
  updateDueDate,
  getFlexibilityExplanation,
  handlePrivacyChange,
  isLoadingSharedNotes,
  addedToTimelineText,
  scrollToCard,
  getTaskTypeInfo,
  canEdit,
  currentFriendUuid,
  checklistColor,
  isInviteExpanded,
  selectedUser,
}) => {
  const dispatch = useDispatch();

  const allCategoriesInTopics = useSelector(state => state.topics.allCategoriesInTopics);
  const userCategories = useSelector(state => state.categories.userSelectedCategories);
  const products = useSelector(state => state.products.products);
  const readProductsInCategoryInProgress = useSelector(state => state.products.readAllProductsInCategoryInProgress);
  const checkedCategories = useSelector(state => state.categories.checkedCategories);
  const removedCategories = useSelector(state => state.categories.removedCategories);

  useEffect(() => {
    dispatch(readAllCategoriesInAllTopics(null, currentFriendUuid));
    dispatch(readUserSelectedCategories(currentFriendUuid));
  }, [dispatch, currentFriendUuid]);

  useEffect(() => {
    if (currentTab === 'products') {
      dispatch(readCategoriesWithProducts(null, currentFriendUuid, null, 1, 10));
    }
  }, [currentTab, currentFriendUuid, dispatch]);

  const handleReadProductsInCategory = (categoryUuid) => {
    if (!products[categoryUuid]) {
      dispatch(readCategoriesWithProducts(null, currentFriendUuid, null, 1, 10, categoryUuid));
    }
  };

  const renderContent = useMemo(() => {
    if (currentTab === 'life') {
      return (
        <ErrorBoundary>
          <Checklist
            groupedChecklist={groupedChecklist}
            removedTasks={removedTasks}
            expandedCardId={expandedCardId}
            handleMilestoneComplete={handleMilestoneComplete}
            removeTask={removeTask}
            restoreTask={restoreTask}
            toggleCardExpansion={toggleCardExpansion}
            handleNotesChange={handleNotesChange}
            updateDueDate={updateDueDate}
            getFlexibilityExplanation={getFlexibilityExplanation}
            handlePrivacyChange={handlePrivacyChange}
            isLoadingSharedNotes={isLoadingSharedNotes}
            addedToTimelineText={addedToTimelineText}
            scrollToCard={scrollToCard}
            getTaskTypeInfo={getTaskTypeInfo}
            canEdit={canEdit}
            currentFriendUuid={currentFriendUuid}
            checklistColor={checklistColor}
            isInviteExpanded={isInviteExpanded}
            selectedUser={selectedUser}
          />
        </ErrorBoundary>
      );
    } else {
      return (
        <NewCategories
          location={location}
          user={user}
          allCategoriesInTopics={allCategoriesInTopics}
          userCategories={userCategories}
          products={products}
          readProductsInCategoryInProgress={readProductsInCategoryInProgress}
          checkedCategories={checkedCategories}
          removedCategories={removedCategories}
          friendUuid={friendUuid}
          searchLoading={searchLoading}
          onSearch={onSearch}
          friends={friends}
          friendSelected={friendSelected}
          showAddedCategories={showAddedCategories}
          toggleShowAddedCategories={toggleShowAddedCategories}
          handleViewFriendList={handleViewFriendList}
          canEdit={canEdit}
          currentFriendUuid={currentFriendUuid}
          readProductsInCategory={handleReadProductsInCategory}
        />
      );
    }
  }, [
    currentTab,
    groupedChecklist,
    removedTasks,
    expandedCardId,
    handleMilestoneComplete,
    removeTask,
    restoreTask,
    toggleCardExpansion,
    handleNotesChange,
    updateDueDate,
    getFlexibilityExplanation,
    handlePrivacyChange,
    isLoadingSharedNotes,
    addedToTimelineText,
    scrollToCard,
    getTaskTypeInfo,
    canEdit,
    currentFriendUuid,
    location,
    user,
    allCategoriesInTopics,
    userCategories,
    products,
    readProductsInCategoryInProgress,
    checkedCategories,
    removedCategories,
    friendUuid,
    searchLoading,
    onSearch,
    friends,
    friendSelected,
    showAddedCategories,
    toggleShowAddedCategories,
    handleViewFriendList,
    checklistColor,
    isInviteExpanded,
    selectedUser,
    handleReadProductsInCategory
  ]);

  console.log('Props passed to NewCategories:', {
    showAddedCategories,
    currentFriendUuid,
    allCategoriesInTopics,
    userCategories
  });

  return (
    <div className="tab-content">
      {renderContent}
    </div>
  );
};

export default React.memo(TabContent);
