import initialState from './initialState';
import * as types from './actionTypes';

const emails = (state = initialState, action) => {
  if (typeof action !== 'undefined') {
    switch (action.type) {
      case types.FETCH_RECEIVED_SUCCESS:
        return {
          ...state,
          cameraGroupEmails: action.cameraGroupEmails
        }

      case types.FETCH_RECEIVED_IN_PROCESS:
        return {
          ...state,
          fetchReceivedInProcess: action.bool
        }

      case types.FETCH_RECEIVED_ERROR:
        return {
          ...state,
          fetchReceivedError: action.fetchReceivedError
        }

      case types.UPDATE_EMAIL_ERROR:
        return {
          ...state,
          updateInvitationError: action.updateInvitationError
        }

      case types.DELETE_EMAIL_ERROR:
        return {
          ...state,
          deleteInvitationError: action.deleteInvitationdError
        }

      case types.FETCH_SENT_SUCCESS:
        return {
          ...state,
          sentEmails: action.sentEmails
        }

      case types.FETCH_SENT_IN_PROCESS:
        return {
          ...state,
          fetchSentInProcess: action.bool
        }

      case types.FETCH_SENT_ERROR:
        return {
          ...state,
          fetchSentError: action.fetchSentError
        }

      case types.ACCEPT_EMAIL_IN_PROCESS:
        return {
          ...state,
          acceptInviteInProcess: action.acceptInviteInProcess
        }

      case types.ACCEPT_EMAIL_SUCCESS:
        return {
          ...state,
          cameraGroupEmails: state.cameraGroupEmails.filter(invite => invite.uuid !== action.invite.uuid)
        }

      case types.ACCEPT_EMAIL_ERROR:
        return {
          ...state,
          acceptInviteError: action.acceptInviteError
        }

      case types.REJECT_EMAIL_IN_PROCESS:
        return {
          ...state,
          rejectInviteInProcess: action.rejectInviteInProcess
        }

      case types.REJECT_EMAIL_SUCCESS:
        return {
          ...state,
          cameraGroupEmails: state.cameraGroupEmails.filter(invite => invite.uuid !== action.invite.uuid)
        }

      case types.REJECT_EMAIL_ERROR:
        return {
          ...state,
          rejectInviteError: action.rejectInviteError
        }

      case types.RESCIND_EMAIL_IN_PROCESS:
        return {
          ...state,
          rescindInviteInProcess: action.rescindInviteInProcess
        }

      case types.RESCIND_EMAIL_ERROR:
        return {
          ...state,
          rescindInviteError: action.rescindInviteError
        }

      case types.CLEAR_EMAILS_DATA:
        return {
          ...state,
          cameraGroupEmails: action.cameraGroupEmails,
          sentEmails: action.sentEmails
        }

      case types.FETCH_EMAILS_SUCCESS:
        return {
          ...state,
          emails: action.emails
        }

      default:
        return state;
    }
  } else {
    return state
  }
}

export default emails;
