import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as noteFunctions from '../../redux/notes/actions';
import { Tag, Divider, Tooltip } from 'antd';

class NoteTags extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
      context: props.context,
      data: props.data,
      confirmLoading: false,
    };
  }

  onChange = ({ target: { value } }) => {
    this.setState({ value });
  };

  onFinish = (e) => {
    this.setState({confirmLoading: true});
    const form = this.form;
    form.validateFields().then(values => {
      if (this.props.value) {
        this.props.updateNote(this.props.user, values, this.state.context, this.state.data);
      } else {
        // TODO: need to figure out how to get the user to pick a permissions then put it into the this.state.permissions
        // for now, we are hard-coded to public permissions
        this.props.createNote(this.props.user, values, this.state.context, this.state.data, 1);
      }
    })
    .then(() => {
      form.resetFields();
      this.setState({confirmLoading: false});
    });
  }

  notesFormRef = (form) => {
    this.form = form
  };

  render() {
    return (
      <div>
        <Divider orientation="left">Products</Divider>
        <div style={styles.tagsContainer}>
        {/* turn this into a class } */}
          <Tooltip title="Stat or fact about bottles">
            <span>
              <Tag style={styles.tagContainer} color="magenta">Bottles</Tag>
            </span>
          </Tooltip>
          <Tooltip title="Stat or fact about diapers">
            <span>
              <Tag style={styles.tagContainer} color="red">Diapers</Tag>
            </span>
          </Tooltip><Tooltip title="Stat or fact about diapers">
            <span>
              <Tag style={styles.tagContainer} color="geekblue">Strollers</Tag>
            </span>
          </Tooltip><Tooltip title="There are 100+ products to research!">
            <span>
              <Tag style={styles.tagContainer} color="volcano">Other products</Tag>
            </span>
          </Tooltip>
        </div>
        <Divider orientation="left" >Services</Divider>
        <div style={styles.tagsContainer}>
        {/* turn this into a class } */}
          <Tooltip title="Stat or fact about babysitting">
            <span>
              <Tag style={styles.tagContainer} color="orange">Babysitting</Tag>
            </span>
          </Tooltip><Tooltip title="Stat or fact about Cleaners">
            <span>
              <Tag style={styles.tagContainer} color="gold">Cleaners</Tag>
            </span>
          </Tooltip><Tooltip title="Stat or fact about Servifes">
            <span>
              <Tag style={styles.tagContainer} color="lime">Other services</Tag>
            </span>
          </Tooltip><Tooltip title="Definition of a Community Highlight">
            <span>
              <Tag style={styles.tagContainer} color="green">Community highlight</Tag>
            </span>
          </Tooltip><Tooltip title="Definition of inspirations">
            <span>
              <Tag style={styles.tagContainer} color="cyan">Inspirations</Tag>
            </span>
          </Tooltip><Tooltip title="Stat or fact about Health">
            <span>
              <Tag style={styles.tagContainer} color="blue">Health</Tag>
            </span>
          </Tooltip>
        </div>
        <Divider orientation="left" >Privacy</Divider>
        <div style={styles.tagsContainer}>
        {/* turn this into a class } */}
          <Tooltip title="This is 100% private, unless you choose to share with a friend.">
            <span>
              <Tag style={styles.tagContainer} color="purple">Keep private</Tag>
            </span>
          </Tooltip>
          <Tooltip title="It's kind of you to support your friends.">
            <span>
              <Tag style={styles.tagContainer} color="blue">Share with friends</Tag>
            </span>
          </Tooltip>
        </div>
      </div>
    );
  }
}

const styles = {
  tagsContainer: {
    marginLeft: '10%',
    textAlign: 'right'
  },
  tagContainer: {
    marginTop: 5,
    borderRadius: 10
  },
  submitButton: {
    boxShadow: '0px 0px 10px 1px grey',
    backgroundColor: '#7E3FFF',
    border: 'none',
    color: 'white',
    borderRadius: 5
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createNote: (user, values, context, contextData) => dispatch(noteFunctions.createNote(user, values, context, contextData))
  }
}

export default connect(mapStateToProps, mapDispatchToProps) (NoteTags);
