import React from 'react';
import { Select } from 'antd';
import { LockOutlined, TeamOutlined, GlobalOutlined } from '@ant-design/icons';
import "../../../assets/css/components/toggles.css";

const { Option } = Select;

const PrivacyToggle = ({ value, onChange }) => {
  const getOptionColor = (optionValue) => {
    switch (optionValue) {
      case 'private':
        return '#fefabc'; // Light yellow for private notes
      case 'friends':
        return '#69c0ff'; // Light blue for friends' notes
      case 'community':
        return '#95de64'; // Light green for community notes
      default:
        return 'white';
    }
  };

  const handleChange = (newValue) => {
    onChange(newValue);
  };

  const handleDropdownClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="privacy-toggle" onClick={handleDropdownClick}>
      <Select
        value={value}
        onChange={handleChange}
        style={{ width: 180 }}
        onClick={handleDropdownClick}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <Option value="private" className="option-private">
          <LockOutlined /> Just me
        </Option>
        <Option value="friends" className="option-friends">
          <TeamOutlined /> My friends
        </Option>
        <Option value="community" className="option-community">
          <GlobalOutlined /> The community
        </Option>
      </Select>
      <style jsx>{`
        .privacy-toggle .ant-select-selector {
          background-color: ${getOptionColor(value)} !important;
        }
      `}</style>
    </div>
  );
};

export default PrivacyToggle;
