import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Form, Input, message, Button, Select } from 'antd';
import * as friendsFunctions from '../../redux/friends/actions';
import { isEmpty } from '../../helperFunctions';
import '../../../assets/css/components/InviteFriendsModal.css';
import '../../../assets/css/views/Login.css';
import '../../../assets/css/components/button.css';

const { Option } = Select;

const InviteUserForm = ({ onCancel, open, onCreate }) => {
  const [form] = Form.useForm();

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
  };

  return (
    <Modal
      title=""
      open={open}
      style={{ textAlign: 'center' }}
      onCancel={onCancel}
      onOk={() => {
        form.validateFields()
          .then(values => {
            form.resetFields();
            onCreate(values);
          })
          .catch(info => {
            console.log('Validate Failed:', info);
          });
      }}
      okText="Invite"
      cancelText="Cancel"
      closable={false}
      okButtonProps={{ className: 'ant-btn-primary invite-friends-button' }}
      cancelButtonProps={{ className: 'ant-btn-default' }}
    >
      <p style={{fontSize: 36, fontWeight: 'bold', marginBottom: -5}}>It takes a village</p>
      <p style={{fontSize: 24}}>Invite your friends to share their notes.</p>
      <Form form={form} {...formItemLayout}>
        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true, type: 'email', message: 'Please input a valid email!' }]}
        >
          <Input placeholder="Email" style={{textAlign: 'center'}} />
        </Form.Item>
        <Form.Item
          name="userType"
          label="User Type"
          rules={[{ required: true, message: 'Please select a user type!' }]}
        >
          <Select placeholder="Select user type">
            <Option value="pregnant">Pregnant/Trying</Option>
            <Option value="partner">Spouse/Partner</Option>
            <Option value="friend">Friend/Family</Option>
            <Option value="community">Community Expert</Option>
          </Select>
        </Form.Item>
      </Form>
      <p style={{fontSize: 12}}>Many of our community members would prefer to stay private so you may only add/invite friends by submitting their email address. You can check the status of your invites on the "My Friends" page.</p>
    </Modal>
  );
};

class InviteFriendsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    }
  }

  showModal = () => {
    this.setState({open: true});
  };

  handleCancel = () => {
    this.setState({open: false});
    this.props.setDropdownOpen(false);
  };

  handleCreate = (values) => {
    this.props.inviteFriends(values.email, values.userType);
    this.setState({ open: false });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let nextState = {...nextProps};
    message.config({maxCount: 1});
    if (!isEmpty(nextState.inviteFriendError)) {
      message.error(nextState.inviteFriendError);
      nextState.inviteFriendError = null;
    }
    if (!isEmpty(nextState.inviteFriendSuccess) && nextState.inviteFriendSuccess.hasOwnProperty("Success")) {
      message.success(nextState.inviteFriendSuccess["Success"]);
      nextState.inviteFriendSuccess = false;
    }
    return nextState
  }

  render() {
    return (
      <div>
        <Button
          onClick={this.showModal}
          className="invite-friends-button"
        >
          Invite Friends
        </Button>
        <InviteUserForm
          open={this.state.open}
          onCancel={this.handleCancel}
          onCreate={this.handleCreate}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    inviteFriendSuccess: state.friends.inviteFriendSuccess,
    inviteFriendError: state.friends.inviteFriendError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    inviteFriends: (email, userType) => dispatch(friendsFunctions.inviteFriends(email, userType)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InviteFriendsModal);
