const perksData = [
  {
    id: 1,
    itemId: 'item1',
    brand: "Dr. Brown's",
    discount: '15% off',
    description: 'Exclusive discount on Dr. Brown\'s Natural Flow Baby Bottles',
    explanation: "Dr. Brown's wants to earn your business. As someone who values a balanced approach to feeding and is health-conscious, these bottles are designed to reduce colic, spit-up, and gas. Perfect for parents planning to combine breastfeeding and bottle feeding."
  },
  {
    id: 2,
    itemId: 'item5',
    brand: 'Ergobaby',
    discount: '20% off',
    description: 'Special offer on Ergobaby Omni 360 Baby Carrier',
    explanation: "Ergobaby wants to earn your business. For active parents who enjoy the outdoors and value hands-free baby care, this versatile carrier is ideal for everything from hiking to household chores."
  },
  {
    id: 3,
    itemId: 'item10',
    brand: 'Pampers',
    discount: '$10 off your first box',
    description: 'Savings on Pampers Swaddlers Diapers',
    explanation: "Pampers wants to earn your business. As a parent focused on convenience and reliable products, Swaddlers offer superior leak protection and comfort for your newborn."
  },
  {
    id: 4,
    itemId: 'item15',
    brand: 'Graco',
    discount: '25% off',
    description: 'Discount on Graco 4Ever DLX 4-in-1 Car Seat',
    explanation: "Graco wants to earn your business. For parents planning multiple children or valuing long-term use products, this car seat grows with your child from infancy to booster seat stage."
  },
  {
    id: 5,
    itemId: 'item20',
    brand: 'Medela',
    discount: 'Free accessory kit',
    description: 'Complimentary accessory kit with purchase of Medela Pump in Style with MaxFlow',
    explanation: "Medela wants to earn your business. As a working parent planning to continue breastfeeding, this efficient pump with extra accessories will support your breastfeeding goals."
  },
  {
    id: 6,
    itemId: 'item25',
    brand: 'Halo',
    discount: '30% off',
    description: 'Savings on HALO BassiNest Swivel Sleeper',
    explanation: "HALO wants to earn your business. For parents prioritizing safe sleep and easy nighttime access to baby, this bedside sleeper is perfect for keeping baby close while following safe sleep guidelines."
  },
  {
    id: 7,
    itemId: 'item30',
    brand: 'Philips Avent',
    discount: 'Buy one get one 50% off',
    description: 'BOGO offer on Philips Avent Natural Baby Bottles',
    explanation: "Philips Avent wants to earn your business. As parents planning to combine breast and bottle feeding, these bottles are designed to mimic the shape and feel of the breast, making transitions easier."
  },
  {
    id: 8,
    itemId: 'item35',
    brand: 'UPPAbaby',
    discount: 'Free parent organizer',
    description: 'Complimentary parent organizer with purchase of UPPAbaby VISTA V2 Stroller',
    explanation: "UPPAbaby wants to earn your business. For urban parents who value style and versatility, this stroller system grows with your family and navigates city streets with ease."
  },
  {
    id: 9,
    itemId: 'item40',
    brand: 'Owlet',
    discount: '15% off',
    description: 'Discount on Owlet Smart Sock Baby Monitor',
    explanation: "Owlet wants to earn your business. As tech-savvy parents concerned about baby's well-being, this smart monitor tracks heart rate and oxygen levels, offering peace of mind during sleep."
  },
  {
    id: 10,
    itemId: 'item45',
    brand: 'Britax',
    discount: 'Free car seat base',
    description: 'Complimentary extra base with Britax B-Safe Gen2 Infant Car Seat',
    explanation: "Britax wants to earn your business. For families with multiple vehicles or caregivers, this extra base allows for easy transfers between cars without compromising safety."
  },
  {
    id: 11,
    itemId: 'item50',
    brand: 'Nanobebe',
    discount: '20% off',
    description: 'Savings on Nanobebe Breastmilk Bottle Starter Set',
    explanation: "Nanobebe wants to earn your business. For breastfeeding parents returning to work, these bottles are designed to protect breastmilk nutrients and allow for easy pumping, storing, and feeding."
  },
  {
    id: 12,
    itemId: 'item55',
    brand: 'Baby Jogger',
    discount: 'Free car seat adapter',
    description: 'Complimentary car seat adapter with Baby Jogger City Mini GT2 Stroller',
    explanation: "Baby Jogger wants to earn your business. For active parents who enjoy outdoor activities, this all-terrain stroller with a free car seat adapter offers versatility for your active lifestyle."
  },
  {
    id: 13,
    itemId: 'item60',
    brand: 'Nuna',
    discount: '10% off',
    description: 'Discount on Nuna PIPA Lite LX Infant Car Seat',
    explanation: "Nuna wants to earn your business. As safety-conscious parents who value lightweight gear, this car seat offers top-notch safety features in an ultra-light design."
  },
  {
    id: 14,
    itemId: 'item65',
    brand: 'Chicco',
    discount: 'Free extra KeyFit base',
    description: 'Complimentary extra base with Chicco KeyFit 30 Infant Car Seat',
    explanation: "Chicco wants to earn your business. For families juggling multiple caregivers or vehicles, this extra base ensures safe and easy car seat installation for everyone."
  },
  {
    id: 15,
    itemId: 'item70',
    brand: 'Spectra',
    discount: 'Free milk storage bags',
    description: 'Complimentary pack of milk storage bags with Spectra S1 Plus Electric Breast Pump',
    explanation: "Spectra wants to earn your business. For working parents committed to providing breastmilk, this efficient pump and storage solution supports your breastfeeding goals."
  },
  {
    id: 16,
    itemId: 'item75',
    brand: 'Happiest Baby',
    discount: '15% off',
    description: 'Savings on SNOO Smart Sleeper Bassinet',
    explanation: "Happiest Baby wants to earn your business. For parents prioritizing sleep and willing to invest in smart solutions, the SNOO provides responsive soothing to extend sleep for both baby and parents."
  },
  {
    id: 17,
    itemId: 'item80',
    brand: 'BOB Gear',
    discount: '20% off',
    description: 'Discount on BOB Gear Revolution Flex 3.0 Jogging Stroller',
    explanation: "BOB Gear wants to earn your business. For fitness-enthusiast parents planning to stay active, this jogging stroller provides a smooth ride for both runner and baby on various terrains."
  },
  {
    id: 18,
    itemId: 'item85',
    brand: 'Elvie',
    discount: 'Free carry bag',
    description: 'Complimentary carry bag with Elvie Pump Double Electric Breast Pump',
    explanation: "Elvie wants to earn your business. For discreet pumping at work or on-the-go, this wearable pump with a free carry bag offers ultimate flexibility for busy parents."
  },
  {
    id: 19,
    itemId: 'item90',
    brand: 'Baby Brezza',
    discount: '25% off',
    description: 'Savings on Baby Brezza Formula Pro Advanced Formula Dispenser',
    explanation: "Baby Brezza wants to earn your business. For parents planning to formula feed and valuing convenience, this smart dispenser prepares perfectly mixed bottles in seconds."
  },
  {
    id: 20,
    itemId: 'item95',
    brand: 'Willow',
    discount: 'Free pumping bra',
    description: 'Complimentary pumping bra with Willow Generation 3 Pump',
    explanation: "Willow wants to earn your business. For working parents seeking maximum pumping flexibility, this hands-free, cordless pump with a free pumping bra allows you to pump anywhere, anytime."
  },
  {
    id: 21,
    itemId: 'item100',
    brand: 'Newton Baby',
    discount: '15% off',
    description: 'Discount on Newton Baby Crib Mattress',
    explanation: "Newton Baby wants to earn your business. For parents prioritizing breathability and washability in sleep products, this innovative mattress offers peace of mind for safer sleep."
  },
  {
    id: 22,
    itemId: 'item105',
    brand: 'Bugaboo',
    discount: 'Free cup holder',
    description: 'Complimentary cup holder with Bugaboo Fox 3 Stroller',
    explanation: "Bugaboo wants to earn your business. For style-conscious parents who value high-end, adaptable products, this stroller with a free cup holder offers both form and function for city living."
  },
  {
    id: 23,
    itemId: 'item110',
    brand: 'Hanna Andersson',
    discount: '20% off',
    description: 'Savings on Hanna Andersson Organic Cotton Pajamas',
    explanation: "Hanna Andersson wants to earn your business. For eco-conscious parents who prioritize quality and comfort, these organic cotton pajamas offer durability and softness for your little one."
  },
  {
    id: 24,
    itemId: 'item115',
    brand: 'Cybex',
    discount: 'Free summer cover',
    description: 'Complimentary summer cover with CYBEX Sirona S Convertible Car Seat',
    explanation: "Cybex wants to earn your business. For parents seeking long-term use and comfort in all seasons, this convertible car seat with a free summer cover adapts to your growing child and changing weather."
  },
  {
    id: 25,
    itemId: 'item120',
    brand: 'Infantino',
    discount: '30% off',
    description: 'Discount on Infantino Flip 4-in-1 Convertible Carrier',
    explanation: "Infantino wants to earn your business. For budget-conscious parents who value versatility, this 4-in-1 carrier adapts to your growing baby from newborn to toddler stages."
  },
  {
    id: 26,
    itemId: 'item125',
    brand: 'Comotomo',
    discount: 'Buy two get one free',
    description: 'BOGO offer on Comotomo Natural Feel Baby Bottles',
    explanation: "Comotomo wants to earn your business. For parents transitioning between breast and bottle, these soft, squeezable bottles mimic natural breastfeeding to reduce bottle rejection."
  },
  {
    id: 27,
    itemId: 'item130',
    brand: 'Kyte Baby',
    discount: '15% off',
    description: 'Savings on Kyte Baby Bamboo Pajamas and Sleep Bags',
    explanation: "Kyte Baby wants to earn your business. For parents prioritizing temperature regulation and soft fabrics, these bamboo sleepwear products offer comfort and breathability for better sleep."
  },
  {
    id: 28,
    itemId: 'item135',
    brand: 'Noodle & Boo',
    discount: 'Free body wash',
    description: 'Complimentary body wash with Noodle & Boo Super Soft Lotion',
    explanation: "Noodle & Boo wants to earn your business. For parents with babies prone to sensitive skin, these gentle, dermatologist-tested products nurture and protect delicate skin."
  },
  {
    id: 29,
    itemId: 'item140',
    brand: 'Munchkin',
    discount: '25% off',
    description: 'Discount on Munchkin Step Diaper Pail',
    explanation: "Munchkin wants to earn your business. For parents wanting an odor-free nursery without sacrificing style, this sleek diaper pail uses a patented sealing system to lock away odors."
  },
  {
    id: 30,
    itemId: 'item145',
    brand: 'Fridababy',
    discount: 'Free postpartum recovery kit',
    description: 'Complimentary Mom Washer with purchase of Fridababy Postpartum Recovery Essentials Kit',
    explanation: "Fridababy wants to earn your business. For new mothers prioritizing postpartum recovery, this comprehensive kit with a free perineal washer provides essential tools for a comfortable recovery."
  }
];

export default perksData;
