export const READ_USER_NOTES_SUCCESS = 'READ_USER_NOTES_SUCCESS';
export const READ_FRIEND_NOTES_SUCCESS = 'READ_FRIEND_NOTES_SUCCESS';
export const READ_FRIEND_PRODUCT_NOTES_SUCCESS = 'READ_FRIEND_PRODUCT_NOTES_SUCCESS';
export const READ_PRODUCT_NOTES_SUCCESS = 'READ_PRODUCT_NOTES_SUCCESS';
export const READ_CATEGORY_NOTES_SUCCESS = 'READ_CATEGORY_NOTES_SUCCESS';
export const READ_LIST_NOTES_SUCCESS = 'READ_LIST_NOTES_SUCCESS';
export const READ_NOTES_ERROR = 'READ_NOTES_ERROR';
export const SAVE_NOTES_ERROR = 'SAVE_NOTES_ERROR';
export const SAVE_NOTES_SUCCESS = 'SAVE_NOTES_SUCCESS';
export const SAVE_NOTES_IN_PROGRESS = 'SAVE_NOTES_IN_PROGRESS';
export const READ_FRIEND_CATEGORY_NOTES_SUCCESS = 'READ_FRIEND_CATEGORY_NOTES_SUCCESS';
export const SET_NOTES_ENTERED = 'SET_NOTES_ENTERED';
export const SET_SAVED_MOMENTS = 'SET_SAVED_MOMENTS';
export const FETCH_FRIENDS_NOTES_SUCCESS = 'FETCH_FRIENDS_NOTES_SUCCESS';
export const FETCH_FRIENDS_NOTES_ERROR = 'FETCH_FRIENDS_NOTES_ERROR';
