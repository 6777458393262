import React, { useState } from "react";
import { Button } from "antd";
import { useMutation } from "@tanstack/react-query";

import { updateProductSetFavorite } from "../../redux/products/actions";

function getButtonText(isFavorite, isPending, isError) {
  if (isPending) {
    return "Saving...";
  }
  if (isError) {
    return "Error...";
  }
  return isFavorite ? "Remove" : "Add to list";
}

const FavoriteProductButton = ({ categoryId, productId, isFavorite }) => {
  const [ourIsFavorite, setOurIsFavorite] = useState(isFavorite);

  const mutation = useMutation({
    mutationFn: (isFavorite) => {
      return updateProductSetFavorite(productId, isFavorite);
    },
  });

  const handleFavoiteClick = () => {
    const newFavorite = !ourIsFavorite;
    mutation.mutate(newFavorite);
    setOurIsFavorite(newFavorite);
  };

  const buttonText = getButtonText(ourIsFavorite, mutation.isPending, mutation.isError);

  return (
    <Button className="addToListButton" onClick={handleFavoiteClick}>
      {buttonText}
    </Button>

    //   ];
    // } else {
    //   return [
    //     <Button
    //       key={this.props.product.uuid + "1"}
    //       className="addToListButton"
    //       onClick={() => {
    //         this.props.updateUserLinkProduct(
    //           this.props.product,
    //           this.props.categoriesWithProducts,
    //           true,
    //           true,
    //           false,
    //           this.props.product.product_rating,
    //           this.props.context,
    //           this.state.extension.split("=").pop()
    //         );
    //       }}
    //     >
    //       Add to list
    //     </Button>,
    //   ];
    // }
  );
};

export default FavoriteProductButton;
