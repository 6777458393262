export const SET_MOMENTS = 'SET_MOMENTS';
export const SET_SORTED_MILESTONES = 'SET_SORTED_MILESTONES';
export const SET_GROUPED_TIMELINE_ITEMS = 'SET_GROUPED_TIMELINE_ITEMS';
export const SET_ACTIVE_CARD_ID = 'SET_ACTIVE_CARD_ID';
export const SET_EDITING_MOMENT_ID = 'SET_EDITING_MOMENT_ID';
export const SET_CURRENT_LIFE_STAGE = 'SET_CURRENT_LIFE_STAGE';
export const SET_CURRENT_MILESTONE = 'SET_CURRENT_MILESTONE';
export const SET_NEWLY_COMPLETED_MILESTONE = 'SET_NEWLY_COMPLETED_MILESTONE';
export const SET_MOMENTS_WITH_INPUT = 'SET_MOMENTS_WITH_INPUT';
export const SET_ADDED_TO_TIMELINE_TEXT = 'SET_ADDED_TO_TIMELINE_TEXT';
