import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button } from 'antd';
import * as noteFunctions from '../../redux/notes/actions';
import { isEmpty } from '../../helperFunctions';
import '../../../assets/css/components/forms.css';
import '../../../assets/css/components/button.css';

const { TextArea } = Input;

class NoteForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value ? props.value : null,
      context: props.context ? props.context : null,
      contextData: props.contextData ? props.contextData : {},
      confirmLoading: false,
    };
  }

  onChange = ({ target: { value } }) => {
    this.setState({ value });
  };

  onFinish = (e) => {
    this.setState({confirmLoading: true});
    const form = this.form;
    form.validateFields().then(values => {
      if (this.props.value) {
        this.props.updateNote(this.props.user, values, this.state.context, this.state.contextData);
      } else {
        // TODO: need to figure out how to get the user to pick a permissions then put it into the this.state.permissions
        // for now, we are hard-coded to public permissions
        this.props.createNote(this.props.user, values, this.state.context, this.state.contextData, 1);
      }
    })
    .then(() => {
      form.resetFields();
      this.setState({confirmLoading: false});
      switch (this.state.context) {
        case 'product':
          this.props.readNotesForProduct(this.state.contextData.uuid);
          break;
        case 'category':
          this.props.readNotesInCategory(this.state.contextData.uuid);
          break;
        case 'user':
          this.props.readNotesForUser(this.state.contextData.uuid);
          break;
        case 'list':
          this.props.readNotesInList(this.state.contextData.uuid);
          break;
        case 'friend':
          this.props.readFriendsNotes(this.state.contextData.uuid);
          break;
        case 'friendProduct':
          this.props.readFriendsProductNotes(this.state.contextData.uuid);
          break;
        default:
          return null;
      }
    });
  }

  notesFormRef = (form) => {
    this.form = form;
  };

  render() {
    const { value, confirmLoading } = this.state;
    return (
      <div style={{width: '100%'}}>
        <Form
          onFinish={this.onFinish}
          style={{width: '100%'}}
          ref={this.notesFormRef}
        >
          <Form.Item
            name="notes"
            hasFeedback={true}
            required={true}
            style={{width: '100%'}}
            // style={isEmpty(this.props.textAreaStyle) ? "" : this.props.textAreaFormItemStyle}
          >
            <TextArea
              value={value}
              onChange={this.onChange}
              placeholder={isEmpty(this.props.placeholder) ? "Add note" : this.props.placeholder}
              // {*}style={isEmpty(this.props.textAreaStyle) ? "" : this.props.textAreaStyle}
              className='newPostForm'
              autoSize={{
                minRows: isEmpty(this.props.minRows) ? 10 : this.props.minRows,
                maxRows: isEmpty(this.props.maxRows) ? 20 : this.props.maxRows
              }}
            />
          </Form.Item>
          <Form.Item
            // style={isEmpty(this.props.submitButtonFormItemStyle) ? styles.submitButtonFormItem :   this.props.submitButtonFormItemStyle}
            style={{width:'100%'}}>
            <Button
              // style={isEmpty(this.props.submitButtonStyle) ? styles.submitButton : this.props.submitButtonStyle}
              className="standardButtonSaveNote"
              type="primary"
              htmlType="submit"
              loading={confirmLoading}
              block={isEmpty(this.props.block) ? true : this.props.block}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

// const styles = {
//   textAreaOverride: {
//     height: 10,
//     minHeight: 0,
//     maxHeight: 448,
//     overflowY: 'hidden',
//   },
//   submitButtonFormItem: {
//     width: '45%',
//   },
//   submitButton: {
//     backgroundColor: '#7E3FFF',
//     border: 'none',
//     color: 'white',
//     borderRadius: 5,
//     marginTop: 15
//   }
// }

const mapStateToProps = (state) => {
  return {
    user: state.auth.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createNote: (user, values, context, contextData, permissions) => dispatch(noteFunctions.createNote(user, values, context, contextData, permissions)),
    updateNote: (user, values, context, contextData) => dispatch(noteFunctions.updateNote(user, values, context, contextData)),
    readNotesForUser: () => dispatch(noteFunctions.readNotesForUser()),
    readNotesInList: (listUuid) => dispatch(noteFunctions.readNotesInList(listUuid)),
    readNotesInCategory: (categoryUuid) => dispatch(noteFunctions.readNotesInCategory(categoryUuid)),
    readNotesForProduct: (productUuid) => dispatch(noteFunctions.readNotesForProduct(productUuid)),
    readFriendsProductNotes: (productUuid) => dispatch(noteFunctions.readFriendsProductNotes(productUuid)),
    readFriendsNotes: () => dispatch(noteFunctions.readFriendsNotes()),

  }
}

export default connect(mapStateToProps, mapDispatchToProps) (NoteForm);
