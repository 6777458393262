const state = {
  updateCategoryInProgress: false,
  updateCategoryError: "",
  updateCategorySuccess: false,
  addCategoryError: "",
  addCategoryInProgress: false,
  readAllCategoriesError: false,
  readAllCategoriesSuccess: false,
  readAllCategoriesInProgress: false,
  readUserCategoriesError: false,
  readUserCategoriesSuccess: false,
  readUserCategoriesInProgress: false,
  updateUserSelectedCategoriesInProgress: false,
  updateUserSelectedCategoriesError: "",
  updateUserSelectedCategoriesSuccess: false,
  allCategories: [],
  userSelectedCategories: [],
  createUserLinkCategoryError: "",
  createUserLinkCategoryInProgress: false,
  createUserLinkCategorySuccess: false,
  updateUserLinkCategoryInProgress: false,
  updateUserLinkCategorySuccess: false,
  updateUserLinkCategoryError: "",
  deleteUserLinkCategoryInProgress: false,
  deleteUserLinkCategorySuccess: false,
  deleteUserLinkCategoryError: "",
  checkedCategories: {},
  userSelectedProducts: {},
  removedCategories: new Set(),
  expandedCategories: {},
  showSearch: false,
  showAddedCategories: true,
  searchLoading: false,
  searchResults: [],
  searchError: null,
};

export default state;
