import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Input, Row, Col, Form } from 'antd';

class PrimaryUserName extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stepCount: props.stepCount
    }
  }

  static getDerivedStateFromProps(nextProps, prevState){
    return {
      ...nextProps
    }
  }

  render() {
    return (
      <div styles= {{marginLeft: '%5', marginRight: '%5', }} hidden={this.state.stepCount !== 0}>
        <Row style={{ textAlign: 'center', marginTop: 100}}>
          <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 24}} style={{ marginBottom: 10}}>
            <span style={styles.headerText}> What is your name? </ span>
            </ Col>
        </ Row >
        <Input.Group>
          <Row style={{ textAlign: 'center', marginBottom: 100}}>
            <Col xs={{span: 24}} sm={{span: 24}} md={{span: 12}} lg={{span: 12}}>
              <Form.Item
                name='first_name'
                preserve={true}
                rules={[{required: true}]}
                hasFeedback
                hidden={this.state.stepCount !== 0}
                style={{ width: '40%', float: 'right', marginRight: '5%' }}
              >
                <Input placeholder="First Name" />
              </Form.Item>
            </Col>
            <Col xs={{span: 24}} sm={{span: 24}} md={{span: 12}} lg={{span: 12}}>
              <Form.Item
                name='last_name'
                preserve={true}
                rules={[{required: true}]}
                hasFeedback
                hidden={this.state.stepCount !== 0}
                style={{ width: '40%', float: 'left', marginLeft: '5%' }}
              >
                <Input placeholder="last Name" />
              </Form.Item>
            </ Col>
          </ Row >
        </Input.Group>
      </ div>
    );
  }
};

const styles = {
  headerText: {
    paddingTop: 40,
    paddingBottom: 0,
    fontWeight: 300,
    fontSize: '200%',
    marginBottom: 0,
  }
}


const mapStateToProps = (state) => {
  return {

  }
};

const mapDispatchToProps = (dispatch) => {
  return {

  }
};

export default connect(mapStateToProps, mapDispatchToProps)(PrimaryUserName);
