import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  message
} from 'antd';
import {
  HeartOutlined,
  MedicineBoxOutlined,
  ToolOutlined,
  BookOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

import "../../assets/css/components/header.css";
import "../../assets/css/views/moments.css";
import "../../assets/css/components/checklistItem.css";

import { setBabyBirthday, setDueDate } from '../redux/users/actions';
import { searchCategories } from '../redux/categories/actions';
import { setSavedMoments } from '../redux/notes/actions';
import { readAllCategoriesInAllTopics } from '../redux/topics/actions';
import { readProductsInCategory } from '../redux/products/actions';
import { fetchFriendLists } from '../redux/friends/actions';

import {
  setMoments,
  setSortedMilestones,
  setGroupedTimelineItems,
  setActiveCardId,
  setEditingMomentId,
  setNewlyCompletedMilestone,
  setMomentsWithInput,
  setAddedToTimelineText,
  setAchievedMoments,
  setUnachievedMoments,
  setAchievedMomentOrder,
  setExpandedCardId,
  setPreviousVisibleItemId,
  setRemovedTasks
} from '../redux/milestones/actions';
import {
  setIsLoadingSharedNotes,
  setCurrentTab,
  setIsAddMomentExpanded
} from '../redux/ui/actions';

import TabContent from '../components/checklist/TabContent';
import FilterContainer from '../components/checklist/FilterContainer';
import FriendsListCarousel from '../components/checklist/FriendsListCarousel';

import { sampleSharedNotes } from "../../assets/data/sampleSharedNotes";
import milestones from "../../assets/data/milestones";
import { userTypes } from '../routing/AppRouter';

dayjs.extend(isSameOrAfter);

const groupChecklistItems = (moments, confirmedPregnancyDate, dueDate, babysBirthday, inputToday = new Date()) => {
  if (!moments || moments.length === 0) {
    return {
      'This week': [],
      'Pre-pregnancy': [],
      'First trimester': [],
      'Second trimester': [],
      'Third trimester': [],
      'Labor': [],
      'Postpartum': []
    };
  }

  const today = new Date(inputToday);
  const completedMilestones = moments.filter(m => m.achieved).map(m => m.id);
  const relevantMoments = moments.filter(moment =>
    !moment.relevantUntil || !completedMilestones.includes(moment.relevantUntil)
  );

  const confirmedPregnancyMoment = moments.find(m => m.id === 6 && m.achieved);
  const foundOutDueDateMoment = moments.find(m => m.id === 8 && m.achieved);
  const babyBornMoment = moments.find(m => m.id === 51 && m.achieved);

  let referenceDate, confirmedDueDate, deliveryDate, estimatedDueDate;

  if (!confirmedPregnancyMoment) {
    referenceDate = today;
  } else if (!foundOutDueDateMoment) {
    referenceDate = new Date(confirmedPregnancyMoment.completedDate);
    estimatedDueDate = new Date(referenceDate.getTime() + 280 * 24 * 60 * 60 * 1000);
  } else if (!babyBornMoment) {
    confirmedDueDate = new Date(foundOutDueDateMoment.additionalInputs.confirmedDueDate);
    referenceDate = new Date(confirmedDueDate.getTime() - 280 * 24 * 60 * 60 * 1000);
  } else {
    deliveryDate = new Date(babyBornMoment.completedDate);
    referenceDate = new Date(deliveryDate.getTime() - 280 * 24 * 60 * 60 * 1000);
  }

  const oneWeekFromNow = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000);

  return moments.reduce((groups, moment) => {
    if (moment.completedDate) {
      groups['Completed'].push(moment);
      return groups;
    }

    let itemActualDueDate;
    if (moment.itemDueDateReference === 'conception') {
      itemActualDueDate = new Date(referenceDate.getTime() + moment.itemDueDateDays * 24 * 60 * 60 * 1000);
    } else if (moment.itemDueDateReference === 'birth') {
      if (!deliveryDate) {
        const estimatedDeliveryDate = confirmedDueDate || estimatedDueDate || new Date(today.getTime() + 280 * 24 * 60 * 60 * 1000);
        itemActualDueDate = new Date(estimatedDeliveryDate.getTime() + moment.itemDueDateDays * 24 * 60 * 60 * 1000);
      } else {
        itemActualDueDate = new Date(deliveryDate.getTime() + moment.itemDueDateDays * 24 * 60 * 60 * 1000);
      }
    }

    if (itemActualDueDate <= oneWeekFromNow && itemActualDueDate >= today) {
      groups['This week'].push(moment);
    } else {
      groups[moment.stage] = groups[moment.stage] || [];
      groups[moment.stage].push(moment);
    }

    return groups;
  }, {
    'This week': [],
    'Pre-pregnancy': [],
    'First trimester': [],
    'Second trimester': [],
    'Third trimester': [],
    'Labor': [],
    'Postpartum': []
  });
};

const Moments = ({ userType, parentScrollToTimeline, parentScrollToChecklist }) => {
  console.log('Moments component - userType:', userType);

  const dispatch = useDispatch();
  const location = useLocation();

  const timelineRef = useRef(null);
  const motivationalMessageRef = useRef(null);
  const cardsContainerRef = useRef(null);
  const isScrolling = useRef(false);
  const formRef = useRef(null);

  const babysBirthday = useSelector(state => state.users.babysBirthday);
  const confirmedPregnancyDate = useSelector(state => state.users.confirmedPregnancyDate);
  const dueDate = useSelector(state => state.users.dueDate);

  const savedMoments = useSelector(state => state.notes.savedMoments);

  const moments = useSelector(state => state.milestones.moments);
  const groupedTimelineItems = useSelector(state => state.milestones.groupedTimelineItems);
  const activeCardId = useSelector(state => state.milestones.activeCardId);
  const newlyCompletedMilestone = useSelector(state => state.milestones.newlyCompletedMilestone);
  const momentsWithInput = useSelector(state => state.milestones.momentsWithInput);
  const addedToTimelineText = useSelector(state => state.milestones.addedToTimelineText);
  const achievedMoments = useSelector(state => state.milestones.achievedMoments);
  const unachievedMoments = useSelector(state => state.milestones.unachievedMoments);
  const achievedMomentOrder = useSelector(state => state.milestones.achievedMomentOrder);
  const expandedCardId = useSelector(state => state.milestones.expandedCardId);
  const previousVisibleItemId = useSelector(state => state.milestones.previousVisibleItemId);
  const removedTasks = useSelector(state => state.milestones.removedTasks);

  const editableInputId = useSelector(state => state.ui.editableInputId);
  const showRemovedTasks = useSelector(state => state.ui.showRemovedTasks);
  const isLoadingSharedNotes = useSelector(state => state.ui.isLoadingSharedNotes);
  const currentTab = useSelector(state => state.ui.currentTab);
  const isAddMomentExpanded = useSelector(state => state.ui.isAddMomentExpanded);

  const user = useSelector(state => state.users.user);
  const allCategoriesInTopics = useSelector(state => state.topics.allCategoriesInTopics);
  const checkedCategories = useSelector(state => state.categories.checkedCategories);
  const removedCategories = useSelector(state => state.categories.removedCategories);
  const friends = useSelector(state => state.friends.users);
  const userCategories = useSelector(state => state.categories.userSelectedCategories);
  const products = useSelector(state => state.products.products);

  const [showAddedCategories, setShowAddedCategories] = useState(true);
  const [searchLoading, setSearchLoading] = useState(false);
  const [friendUuid, setFriendUuid] = useState(null);
  const [friendSelected, setFriendSelected] = useState("Shared");
  const [visibleItems, setVisibleItems] = useState([]);
  const [isInviteExpanded, setIsInviteExpanded] = useState(false);

  const [currentFriendUuid, setCurrentFriendUuid] = useState(null);
  const friendLists = useSelector(state => state.friends.friendLists);

  const [selectedUser, setSelectedUser] = useState({
  type: 'personal',
  name: 'My List',
  avatar: '🐵'
});

  const groupedChecklist = useMemo(() => {
  console.log('Calculating groupedChecklist');
  console.log('moments:', moments);
  console.log('confirmedPregnancyDate:', confirmedPregnancyDate);
  console.log('dueDate:', dueDate);
  console.log('babysBirthday:', babysBirthday);
  return groupChecklistItems(moments, confirmedPregnancyDate, dueDate, babysBirthday, new Date()) || {};
}, [moments, confirmedPregnancyDate, dueDate, babysBirthday]);

  const getTaskTypeInfo = (type) => {
    switch (type) {
      case 'life_event':
        return { icon: <HeartOutlined />, color: '#ff4d4f' };
      case 'medical':
        return { icon: <MedicineBoxOutlined />, color: '#1890ff' };
      case 'preparation':
        return { icon: <ToolOutlined />, color: '#52c41a' };
      case 'education':
        return { icon: <BookOutlined />, color: '#faad14' };
      default:
        return { icon: <CheckOutlined />, color: '#8c8c8c' };
    }
  };

  const fetchSharedNotes = (taskId) => {
    console.log('Fetching shared notes for task:', taskId);
    console.log('Full sampleSharedNotes:', sampleSharedNotes);

    dispatch(setIsLoadingSharedNotes({ ...isLoadingSharedNotes, [taskId]: true }));

    setTimeout(() => {
      const selectedNotes = sampleSharedNotes || [];

      console.log('Selected notes:', selectedNotes);

      dispatch(setMoments(moments.map(m =>
        m.id === taskId ? { ...m, sharedNotes: selectedNotes } : m
      )));

      dispatch(setIsLoadingSharedNotes({ ...isLoadingSharedNotes, [taskId]: false }));
    }, 1000);
  };

  const updateDueDate = (newDate, id) => {
    dispatch(setMoments(moments.map(moment =>
      moment.id === id ? { ...moment, dueDate: newDate ? newDate.format('YYYY-MM-DD') : null } : moment
    )));
  };

  const handlePrivacyChange = (id, privacy) => {
    dispatch(setMoments(moments.map(m =>
      m.id === id ? { ...m, notePrivacy: privacy } : m
    )));
  };

  const handleTabChange = (key) => {
    console.log('Tab changed to:', key);
    dispatch(setCurrentTab(key));
  };

  const toggleShowAddedCategories = (e) => {
      setShowAddedCategories(e.target.checked);
      dispatch(readAllCategoriesInAllTopics(e.target.checked, friendUuid, null));
    };

    const onSearch = (value) => {
      setSearchLoading(true);
      dispatch(searchCategories(value));
      setSearchLoading(false);
    };

    const removeTask = (taskId) => {
      const taskElement = document.getElementById(`card-${taskId}`);
      if (taskElement) {
        taskElement.style.transition = 'opacity 0.25s ease-out';
        taskElement.style.opacity = '0.5';
        setTimeout(() => {
          taskElement.style.opacity = '0';
          setTimeout(() => {
            const taskToRemove = moments.find(m => m.id === taskId);
            dispatch(setRemovedTasks([...removedTasks, taskToRemove]));
            dispatch(setMoments(moments.filter(m => m.id !== taskId)));
          }, 250);
        }, 250);
      }
    };

    const animalEmojis = useMemo(() => ['🐱', '🐶', '🐼', '🐨', '🐯', '🦁', '🐸', '🐘', '🦊', '🐰', '🐻', '🐧', '🦉', '🦄'], []);

    const getEmoji = useCallback((user) => {
      if (user.type === 'myList') return '🐵';
      if (!user.uuid) return '❓';

      const emojiIndex = user.uuid.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0) % animalEmojis.length;
      return animalEmojis[emojiIndex];
    }, [animalEmojis]);

    const handleUserSelect = useCallback((user) => {
      if (!user || user.type === 'myList') {
        setSelectedUser({
          type: 'personal',
          name: 'My List',
          avatar: '🐵'
        });
      } else {
        setSelectedUser({
          ...user,
          type: user.type === 'expert' ? 'expert' : 'friend',
          name: user.first_name && user.last_name ? `${user.first_name} ${user.last_name}` : user.name || 'Unknown User',
          avatar: user.avatar || getEmoji(user)
        });
      }
    }, [getEmoji]);

    const restoreTask = (taskId) => {
    const taskToRestore = removedTasks.find(t => t.id === taskId);
    dispatch(setMoments([...moments, taskToRestore]));
    dispatch(setRemovedTasks(removedTasks.filter(t => t.id !== taskId)));
  };

  useEffect(() => {
    if (currentTab === 'timeline') {
      if (typeof parentScrollToTimeline === 'function') {
        parentScrollToTimeline();
      } else {
        console.warn('parentScrollToTimeline is not a function');
      }
    } else if (currentTab === 'checklist') {
      if (typeof parentScrollToChecklist === 'function') {
        parentScrollToChecklist();
      } else {
        console.warn('parentScrollToChecklist is not a function');
      }
    }
  }, [currentTab, parentScrollToTimeline, parentScrollToChecklist]);

  useEffect(() => {
    console.log('Friends:', friends);
    console.log('Friend Lists:', friendLists);
  }, [friends, friendLists]);

  useEffect(() => {
    dispatch(fetchFriendLists());
  }, [dispatch]);

  useEffect(() => {
    const groupedItems = groupTimelineItemsByLifeStage();
    dispatch(setGroupedTimelineItems(groupedItems));
  }, [achievedMoments, achievedMomentOrder, confirmedPregnancyDate, dueDate, babysBirthday]);

  useEffect(() => {
    console.log('editableInputId changed:', editableInputId);
  }, [editableInputId]);

  useEffect(() => {
    console.log('\n=== State Change ===');
    console.log('expandedCardId:', expandedCardId);
    console.log('editableInputId:', editableInputId);
  }, [expandedCardId, editableInputId]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (expandedCardId && !event.target.closest('.moment-card')) {
        dispatch(setExpandedCardId(null));
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [expandedCardId]);

  useEffect(() => {
    console.log('=== Component Mount or Update ===');
    console.log('moments:', moments);
    console.log('expandedCardId:', expandedCardId);
    console.log('achievedMoments:', achievedMoments);
    console.log('UnachievedMoments:', unachievedMoments);
    console.log('==============================');
  }, [moments, expandedCardId, achievedMoments, unachievedMoments]);

  const getFlexibilityExplanation = (flexibility, reason) => {
    return `${flexibility === 'flexible' ? 'Flexible' : 'Fixed'} date: ${reason}`;
  };

  useEffect(() => {
    console.log('Filtering visibleItems');
    console.log('userType:', userType);
    console.log('moments:', moments);
    try {
      const filteredItems = moments.filter(moment => {
        if (!moment) {
          console.warn('Encountered null or undefined moment');
          return false;
        }
        switch(userType) {
          case 'personal':
            return true;
          case 'expert':
            return moment.visibleToExpert;
          default:
            console.warn(`Unknown userType: ${userType}`);
            return false;
        }
      });
      console.log('filteredItems:', filteredItems);
      setVisibleItems(filteredItems);
    } catch (error) {
      console.error('Error filtering visibleItems:', error);
    }
  }, [userType, moments]);

const userTypeColor = userTypes[userType]?.color || userTypes.personal.color;

const canEdit = userType === 'personal' || (userType === 'expert' && !selectedUser);

const handleSave = (moment) => {
  const updatedMoments = moments.map(m =>
    m.id === moment.id ? { ...m, achieved: true, notes: m.notes } : m
  );
  dispatch(setMoments(updatedMoments));
  dispatch(setAchievedMoments([...achievedMoments, moment]));
  dispatch(setUnachievedMoments(updatedMoments.filter(m => !m.achieved)));
  dispatch(setAchievedMomentOrder([...achievedMomentOrder, moment.id]));
  dispatch(setExpandedCardId(null));
  dispatch(setNewlyCompletedMilestone(null));
};

const handleNotesChange = (newNote, momentId) => {
  console.log('handleNotesChange called for moment', momentId);
  console.log('New note:', newNote);

  dispatch(setMoments(moments.map(moment => {
    if (moment.id === momentId) {
      const updatedNotes = Array.isArray(moment.notes) ? [...moment.notes] : [];
      updatedNotes.push(newNote);
      return {
        ...moment,
        notes: updatedNotes
      };
    }
    return moment;
  })));

  console.log('Moment updated with new note');
};

const handleDescriptionChange = (e, id) => {
  dispatch(setMomentsWithInput({
    ...momentsWithInput,
    [id]: {
      ...momentsWithInput[id],
      hasDescription: e.target.value.trim().length > 0
    }
  }));
};

const handlePhotoUpload = (info, id) => {
  dispatch(setMomentsWithInput({
    ...momentsWithInput,
    [id]: {
      ...momentsWithInput[id],
      hasPhoto: info.fileList.length > 0
    }
  }));
};

const scrollToCard = (id) => {
  console.log('Scrolling to card:', id);
  const cardElement = cardsContainerRef.current?.querySelector(`#card-${id}`);
  const timelineItemElement = id === 'add-moment'
    ? timelineRef.current?.querySelector('.new-moment-item')
    : timelineRef.current?.querySelector(`[data-moment-id="${id}"]`);

  console.log('Card element:', cardElement);
  console.log('Timeline item element:', timelineItemElement);

  if (cardElement && cardsContainerRef.current) {
    isScrolling.current = true;

    const cardTop = cardElement.offsetTop;
    const scrollTop = cardTop - 15;

    console.log('Scrolling cards container to:', scrollTop);

    cardsContainerRef.current.scrollTo({
      top: scrollTop,
      behavior: 'smooth'
    });

    if (timelineItemElement && timelineRef.current) {
      const timelineScrollTop = timelineItemElement.offsetTop - 15;
      console.log('Scrolling timeline to:', timelineScrollTop);
      timelineRef.current.scrollTo({
        top: timelineScrollTop,
        behavior: 'smooth'
      });
    } else if (id === 'add-moment') {
      console.log('Timeline item not found, scrolling to the bottom');
      timelineRef.current.scrollTo({
        top: timelineRef.current.scrollHeight,
        behavior: 'smooth'
      });
    }

    dispatch(setActiveCardId(id));

    setTimeout(() => {
      isScrolling.current = false;
    }, 1000);
  } else {
    console.log('Card element not found');
  }
};

const handleBackToChecklist = (moment) => {
  dispatch(setNewlyCompletedMilestone(null));
  if (previousVisibleItemId) {
    scrollToCard(previousVisibleItemId);
  } else {
    const firstUnachievedItem = unachievedMoments[0];
    if (firstUnachievedItem) {
      scrollToCard(firstUnachievedItem.id);
    }
  }
};

useEffect(() => {
  console.log('Moments state update:');
  console.log('expandedCardId:', expandedCardId);
  console.log('moments:', moments);
  console.log('groupedChecklist:', groupedChecklist);
}, [expandedCardId, moments, groupedChecklist]);

const toggleCardExpansion = (id, e) => {
  console.log(`\n=== toggleCardExpansion called ===`);
  console.log(`Expanding card ${id}:`, moments.find(m => m.id === id));
  console.log(`Current expandedCardId: ${expandedCardId}`);
  e.stopPropagation();
  const newExpandedCardId = expandedCardId === id ? null : id;
  console.log(`Setting new expandedCardId: ${newExpandedCardId}`);
  dispatch(setExpandedCardId(newExpandedCardId));
  if (newExpandedCardId !== null) {
    console.log(`Fetching shared notes for id: ${id}`);
    fetchSharedNotes(id);
  }
};

const handleNoThanks = (milestone) => {
  dispatch(setNewlyCompletedMilestone(null));
  if (previousVisibleItemId) {
    scrollToCard(previousVisibleItemId);
  } else {
    const firstUnachievedItem = unachievedMoments[0];
    if (firstUnachievedItem) {
      scrollToCard(firstUnachievedItem.id);
    }
  }
};

const scrollToExpandedCard = (id) => {
  console.log(`\n=== scrollToExpandedCard called ===`);
  console.log(`Scrolling to card: ${id}`);
  console.log(`scrollToExpandedCard called with id: ${id}`);

  const container = cardsContainerRef.current;
  const card = document.getElementById(`card-${id}`);

  if (container && card) {
    setTimeout(() => {
      const cardOffsetTop = card.offsetTop - container.offsetTop;
      const scrollTop = cardOffsetTop - 15;

      console.log('Calculated scrollTop:', scrollTop);

      container.scrollTo({
        top: scrollTop,
        behavior: 'smooth'
      });

      console.log('Scroll initiated to:', scrollTop);
    }, 50);
  } else {
    console.log('Container or card not found');
  }
};

useEffect(() => {
  console.log('Component mounted');
  const addNewMomentCard = cardsContainerRef.current?.querySelector('#card-add-moment');
  console.log('Add New Moment card found:', !!addNewMomentCard);
  if (addNewMomentCard) {
    console.log('Add New Moment card position:', addNewMomentCard.getBoundingClientRect());
  }
  console.log(`Confirmed Pregnancy Date: ${confirmedPregnancyDate}`);
  console.log(`Due Date (placeholder): ${dueDate}`);
  console.log(`Baby's Birthday (placeholder): ${babysBirthday}`);
}, []);

useEffect(() => {
  const handleClickOutside = (event) => {
    if (formRef.current && !formRef.current.contains(event.target)) {
      dispatch(setIsAddMomentExpanded(false));
    }
  };

  document.addEventListener('mousedown', handleClickOutside);
  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, []);

useEffect(() => {
  if (Array.isArray(milestones)) {
    dispatch(setMoments(milestones));
    dispatch(setSortedMilestones(milestones));
    const achieved = milestones.filter(m => m.achieved);
    dispatch(setAchievedMoments(achieved));
    dispatch(setUnachievedMoments(milestones.filter(m => !m.achieved)));
    dispatch(setAchievedMomentOrder(achieved.map(m => m.id)));

    const initialSavedMoments = milestones.reduce((acc, moment) => {
      if (moment.description && moment.description.trim().length > 0) {
        acc[moment.id] = true;
      }
      return acc;
    }, {});
    dispatch(setSavedMoments(initialSavedMoments));
  }
}, []);

useEffect(() => {
  console.log('moments updated:', moments);
}, [moments]);

const getLifeStageFromMoment = (moment) => {
  console.log(`\n--- Determining life stage for moment: "${moment.text}" (Date: ${moment.date}) ---`);
  let stage;

  const momentDate = dayjs(moment.date);
  const confirmedDate = dayjs(confirmedPregnancyDate);
  const dueDateObj = dayjs(dueDate || '2022-01-01');
  const babyBirthDateObj = dayjs(babysBirthday || '2022-09-01');

  console.log(`Moment date: ${momentDate.format('YYYY-MM-DD')}`);
  console.log(`Confirmed pregnancy date: ${confirmedDate.format('YYYY-MM-DD')}`);
  console.log(`Due date: ${dueDateObj.format('YYYY-MM-DD')} ${dueDate ? '(Set by user)' : '(Placeholder)'}`);
  console.log(`Baby's birthday: ${babyBirthDateObj.format('YYYY-MM-DD')} ${babysBirthday ? '(Set by user)' : '(Placeholder)'}`);

  if (moment.type === 'Confirmed Pregnancy') {
    stage = '1st Trimester';
    console.log(`Moment type is 'Confirmed Pregnancy', assigning to ${stage}`);
  } else if (moment.type === 'Baby\'s Birthday') {
    stage = 'Newborn';
    console.log(`Moment type is 'Baby's Birthday', assigning to ${stage}`);
  } else if (momentDate.isAfter(babyBirthDateObj) || momentDate.isSame(babyBirthDateObj)) {
    const ageInMonths = momentDate.diff(babyBirthDateObj, 'month');
    console.log(`Moment is on or after baby's birth. Age in months: ${ageInMonths}`);
    if (ageInMonths < 1) {
      stage = 'Newborn';
    } else if (ageInMonths < 12) {
      stage = 'Infant';
    } else if (ageInMonths < 36) {
      stage = 'Toddler';
    } else if (ageInMonths < 60) {
      stage = 'Preschooler';
    } else {
      stage = 'School Age';
    }
    console.log(`Assigned post-birth stage: ${stage}`);
  } else if (momentDate.isAfter(dueDateObj)) {
    stage = 'Overdue';
    console.log(`Moment is after due date but before birth, assigning to ${stage}`);
  } else {
    const weeksPassed = momentDate.diff(confirmedDate, 'week');
    console.log(`Weeks passed since confirmed pregnancy: ${weeksPassed}`);
    if (weeksPassed < 13) {
      stage = '1st Trimester';
    } else if (weeksPassed < 26) {
      stage = '2nd Trimester';
    } else {
      stage = '3rd Trimester';
    }
    console.log(`Assigned pregnancy stage: ${stage}`);
  }

  console.log(`Final life stage determined for "${moment.text}": ${stage}`);
  console.log('---');
  return stage;
};

const groupTimelineItemsByLifeStage = () => {
  console.log("groupTimelineItemsByLifeStage: Start");
  console.log("Current achievedMomentOrder:", achievedMomentOrder);
  console.log("Current achievedMoments:", achievedMoments);

  try {
    const achievedMoments = achievedMomentOrder
      .map(id => moments.find(m => m.id === id))
      .filter(Boolean);

    console.log('groupTimelineItemsByLifeStage: Start');

    if (achievedMoments.length === 0) {
      console.log('groupTimelineItemsByLifeStage: No achieved moments found, returning empty object');
      return {};
    }

    const lifeStageOrder = [
      '1st Trimester', '2nd Trimester', '3rd Trimester', 'Due Date', 'Overdue',
      'Newborn', 'Infant', 'Toddler', 'Preschooler', 'School Age', 'Adolescent'
    ];

    const groupedAchievedMoments = achievedMoments.reduce((acc, moment) => {
      const stage = getLifeStageFromMoment(moment);
      if (!acc[stage]) {
        acc[stage] = [];
      }
      acc[stage].push(moment);
      return acc;
    }, {});

    const groupedItems = lifeStageOrder.reduce((acc, stage) => {
      if (groupedAchievedMoments[stage] && groupedAchievedMoments[stage].length > 0) {
        acc[stage] = {
          header: getLifeStageGroupHeader(stage),
          moments: groupedAchievedMoments[stage]
        };
      }
      return acc;
    }, {});

    console.log('groupTimelineItemsByLifeStage: Final grouped items:', groupedItems);

    return groupedItems;
  } catch (error) {
    console.error('groupTimelineItemsByLifeStage: Error occurred:', error);
    return {};
  }
};

useEffect(() => {
  console.log("useEffect for groupTimelineItemsByLifeStage running");
  console.log("Current moments:", moments);
  console.log("Current achievedMoments:", achievedMoments);
  console.log("Current achievedMomentOrder:", achievedMomentOrder);
  const groupedItems = groupTimelineItemsByLifeStage();
  console.log("Grouped items:", groupedItems);
  dispatch(setGroupedTimelineItems(groupedItems));
}, [moments, confirmedPregnancyDate, dueDate, babysBirthday, achievedMoments, achievedMomentOrder]);

const getColorInfo = (index) => {
  const colors = [
    { class: 'color-1', bg: '#edfff3' },
    { class: 'color-2', bg: '#eee4ff' },
    { class: 'color-3', bg: '#ffebd7' },
    { class: 'color-4', bg: '#fdffd9' },
    { class: 'color-5', bg: '#fff0f8' }
  ];
  return colors[index % colors.length] || colors[0];
};

const handleAddMoment = (values, id) => {
  if (id === 3) {
    dispatch(setDueDate(values.dueDate.format('YYYY-MM-DD')));
  } else if (id === 42) {
    dispatch(setBabyBirthday(values.babysBirthday.format('YYYY-MM-DD')));
  }

  const updatedMoments = moments.map(moment =>
    moment.id === id ? {
      ...moment,
      achieved: true,
      date: values.date.format('YYYY-MM-DD'),
      description: values.description,
    } : moment
  );
  dispatch(setMoments(updatedMoments));
  dispatch(setSavedMoments({
    ...savedMoments,
    [id]: true
  }));
  dispatch(setAchievedMoments(updatedMoments.filter(m => m.achieved)));
  dispatch(setUnachievedMoments(updatedMoments.filter(m => !m.achieved)));
  dispatch(setIsAddMomentExpanded(false));
  dispatch(setEditingMomentId(null));
  dispatch(setNewlyCompletedMilestone(null));
  message.success('Moment added successfully!');
};

const groupMilestonesByLifeStage = (confirmedPregnancyDate, babysBirthday) => {
  console.log('groupMilestonesByLifeStage: Start');
  console.log(`Confirmed Pregnancy Date: ${confirmedPregnancyDate}`);
  console.log(`Baby's Birthday: ${babysBirthday}`);

  const unachieved = unachievedMoments;
  const sorted = unachieved.sort((a, b) => new Date(a.date) - new Date(b.date));

  const grouped = {
    '1st Trimester': [],
    '2nd Trimester': [],
    '3rd Trimester': [],
    'Due Date': [],
    'Overdue': [],
    'Newborn': [],
    'Infant': [],
    'Toddler': [],
    'Preschooler': [],
    'School Age': [],
    'Adolescent': []
  };

  sorted.forEach(moment => {
    const stage = getLifeStageFromMoment(moment);
    if (grouped[stage]) {
      grouped[stage].push(moment);
    } else {
      console.warn(`Unexpected life stage: ${stage} for moment: ${moment.text}`);
    }
  });

  Object.entries(grouped).forEach(([stage, moments]) => {
    moments.sort((a, b) => {
      if (new Date(a.date) < new Date(b.date)) return -1;
      if (new Date(a.date) > new Date(b.date)) return 1;
      return a.id - b.id;
    });
  });

  console.log('groupMilestonesByLifeStage: Grouped milestones', grouped);

  return grouped;
};

const handleMilestoneComplete = (milestone, e) => {
  console.log("\n=== handleMilestoneComplete called ===");
  console.log("Milestone:", milestone);
  console.log("Current achievedMoments:", achievedMoments);
  console.log("Current achievedMomentOrder:", achievedMomentOrder);

  if (e) {
    e.stopPropagation();
    e.preventDefault();
  }

  dispatch(setExpandedCardId(null));

  const newMoment = {
    ...milestone,
    achieved: true,
    date: dayjs().format('YYYY-MM-DD'),
  };

  dispatch(setAchievedMoments([...achievedMoments, newMoment]));
  dispatch(setMomentsWithInput({
    ...momentsWithInput,
    [newMoment.id]: { hasDescription: false, hasPhoto: false }
  }));

  const taskElement = document.getElementById(`card-${milestone.id}`);
  if (taskElement) {
    taskElement.style.transition = 'opacity 0.5s ease-out';
    taskElement.style.opacity = '0';

    setTimeout(() => {
      console.log("Removing task from moments");
      dispatch(setMoments(moments.filter(m => m.id !== milestone.id)));

      console.log("Adding new moment to achievedMoments");
      dispatch(setAchievedMoments([...achievedMoments, newMoment]));

      console.log("Updating achievedMomentOrder");
      dispatch(setAchievedMomentOrder([...achievedMomentOrder, newMoment.id]));

      dispatch(setAddedToTimelineText({
        ...addedToTimelineText,
        [milestone.id]: true
      }));

      setTimeout(() => {
        dispatch(setAddedToTimelineText({
          ...addedToTimelineText,
          [milestone.id]: false
        }));
      }, 20000);
    }, 500);
  }

  dispatch(setUnachievedMoments(unachievedMoments.filter(m => m.id !== milestone.id)));
  dispatch(setNewlyCompletedMilestone(newMoment));

  const currentIndex = unachievedMoments.findIndex(item => item.id === milestone.id);
  if (currentIndex > 0) {
    dispatch(setPreviousVisibleItemId(unachievedMoments[currentIndex - 1].id));
  } else {
    dispatch(setPreviousVisibleItemId(null));
  }

  console.log("=== End of handleMilestoneComplete ===\n");
};

useEffect(() => {
  console.log('\n=== Component Mount or Update ===');
  console.log('moments:', moments);
  console.log('expandedCardId:', expandedCardId);
  console.log('achievedMoments:', achievedMoments);
  console.log('UnachievedMoments:', unachievedMoments);
  console.log('==============================');
}, [moments, expandedCardId, achievedMoments, unachievedMoments]);

const scrollToNewlyCompletedMilestone = (id) => {
  const cardElement = cardsContainerRef.current?.querySelector(`#card-${id}`);
  if (cardElement && cardsContainerRef.current) {
    const containerRect = cardsContainerRef.current.getBoundingClientRect();
    const cardTop = cardElement.offsetTop;
    const scrollTop = cardTop - containerRect.top - 25;

    cardsContainerRef.current.scrollTo({
      top: scrollTop,
      behavior: 'smooth'
    });
  }
};

const getUnachievedMoments = () => {
  return moments.filter(moment => !moment.achieved);
};

const getSuggestedMoments = () => {
  const unachieved = getUnachievedMoments();
  return unachieved.slice(0, 5);
};

const getLifeStageGroupHeader = (stage) => {
  switch (stage) {
    case '1st Trimester':
    case '2nd Trimester':
    case '3rd Trimester':
    case 'Due Date':
    case 'Newborn':
    case 'Infant':
    case 'Toddler':
    case 'Preschooler':
    case 'School Age':
    case 'Adolescent':
      return stage;
    default:
      return 'Other';
  }
};

const handleInviteToggle = useCallback((expanded) => {
  setIsInviteExpanded(expanded);
}, []);

const handleBackgroundClick = useCallback((e) => {
  console.log('handleBackgroundClick called', e.target);
  if (e.target.classList.contains('gray-overlay')) {
    console.log('Attempting to close invite expanded');
    setIsInviteExpanded(false);
  }
}, []);

useEffect(() => {
  console.log('friendLists updated:', friendLists);
}, [friendLists]);

const getListColor = useCallback(() => {
  if (selectedUser && selectedUser.type === 'friend') {
    return '#d9ecff';
  } else if (userType === 'expert') {
    return '#e1f7e1';
  } else {
    return '#fffded';
  }
}, [selectedUser, userType]);

useEffect(() => {
  console.log('Current tab:', currentTab);
}, [currentTab]);

useEffect(() => {
  if (currentTab === 'products') {
    allCategoriesInTopics.forEach(topic => {
      topic.categories.forEach(category => {
        dispatch(readProductsInCategory(category.uuid));
      });
    });
  }
}, [currentTab, allCategoriesInTopics, dispatch]);

useEffect(() => {
  if (currentTab === 'products' && products) {
    allCategoriesInTopics.forEach(topic => {
      topic.categories.forEach(category => {
        if (!products[category.uuid]) {
          dispatch(readProductsInCategory(category.uuid));
        }
      });
    });
  }
}, [currentTab, allCategoriesInTopics, products, dispatch]);

useEffect(() => {
  if (currentTab === 'products' && products && allCategoriesInTopics) {
    allCategoriesInTopics.forEach(topic => {
      if (topic.categories) {
        topic.categories.forEach(category => {
          if (category && category.uuid && !products[category.uuid]) {
            dispatch(readProductsInCategory(category.uuid));
          }
        });
      }
    });
  }
}, [currentTab, allCategoriesInTopics, products, dispatch]);

console.log('Rendering Moments component');
console.log('groupedChecklist:', groupedChecklist);
console.log('visibleItems:', visibleItems);
console.log('Rendering Moments, isInviteExpanded:', isInviteExpanded);

return (
  <div className="moments-container">
    <FriendsListCarousel
      friends={friendLists}
      onUserSelect={handleUserSelect}
      currentUserUuid={selectedUser ? selectedUser.uuid : null}
      onInviteToggle={handleInviteToggle}
      isInviteExpanded={isInviteExpanded}
    />
    <div className={`content-container ${isInviteExpanded ? 'blurred' : ''}`}>
      {isInviteExpanded && (
        <div
          className="gray-overlay"
          onClick={handleBackgroundClick}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1000,
            cursor: 'pointer'
          }}
        />
      )}
      <FilterContainer
        className={`checklist-container ${
          !selectedUser || selectedUser.type === 'personal'
            ? 'personal'
            : selectedUser.type === 'expert'
            ? 'expert'
            : 'friend'
        }`}
        selectedUser={selectedUser}
        userType={userType}
        friendUuid={selectedUser ? selectedUser.uuid : null}
        searchLoading={searchLoading}
        onSearch={onSearch}
        friends={friends}
        showAddedCategories={showAddedCategories}
        toggleShowAddedCategories={toggleShowAddedCategories}
        currentTab={currentTab}
        setCurrentTab={(tab) => {
          dispatch(setCurrentTab(tab));
          handleTabChange(tab);
        }}
        disabledItems={selectedUser && selectedUser.type === 'friend' ? ['customize', 'friends', 'myPlan', 'myPerks'] : []}
      />
      <TabContent
        currentTab={currentTab}
        groupedChecklist={groupedChecklist}
        location={location}
        user={user}
        allCategoriesInTopics={allCategoriesInTopics}
        checkedCategories={checkedCategories}
        removedCategories={removedCategories}
        removedTasks={removedTasks}
        expandedCardId={expandedCardId}
        handleMilestoneComplete={handleMilestoneComplete}
        removeTask={removeTask}
        restoreTask={restoreTask}
        toggleCardExpansion={toggleCardExpansion}
        handleNotesChange={handleNotesChange}
        updateDueDate={updateDueDate}
        getFlexibilityExplanation={getFlexibilityExplanation}
        handlePrivacyChange={handlePrivacyChange}
        isLoadingSharedNotes={isLoadingSharedNotes}
        addedToTimelineText={addedToTimelineText}
        scrollToCard={scrollToCard}
        getTaskTypeInfo={getTaskTypeInfo}
        groupedTimelineItems={groupedTimelineItems}
        activeCardId={activeCardId}
        getColorInfo={getColorInfo}
        achievedMoments={achievedMoments}
        newlyCompletedMilestone={newlyCompletedMilestone}
        handleAddMoment={handleAddMoment}
        handlePhotoUpload={handlePhotoUpload}
        handleDescriptionChange={handleDescriptionChange}
        momentsWithInput={momentsWithInput}
        savedMoments={savedMoments}
        handleBackToChecklist={handleBackToChecklist}
        isAddMomentExpanded={isAddMomentExpanded}
        setIsAddMomentExpanded={setIsAddMomentExpanded}
        getSuggestedMoments={getSuggestedMoments}
        groupMilestonesByLifeStage={groupMilestonesByLifeStage}
        confirmedPregnancyDate={confirmedPregnancyDate}
        babysBirthday={babysBirthday}
        moments={moments}
        showRemovedTasks={showRemovedTasks}
        userCategories={userCategories}
        products={products}
        visibleItems={visibleItems}
        userType={userType}
        userTypeColor={userTypeColor}
        canEdit={canEdit}
        currentFriendUuid={selectedUser && selectedUser.type === 'friend' ? selectedUser.uuid : null}
        checklistColor={getListColor()}
        isInviteExpanded={isInviteExpanded}
        getListColor={getListColor}
        selectedUser={selectedUser}
      />
    </div>
  </div>
);
};

export default Moments;
