import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, List, Drawer, Button } from "antd";
//import '../../../assets/css/components/productInfoBox.css';
import InviteFriendsModal from "../modals/InviteFriends";
import NoteForm from "../inputs/NoteForm.js";
import * as noteFunctions from '../../redux/notes/actions';
import "../../../assets/css/views/Products.css";

class ProductDetailsDrawer extends Component {
  constructor(props, context) {
    super(props, context);
    let windowWidth = window.innerWidth;

    this.state = {
      key: props.data.uuid + "notes",
      productUuid: props.data.uuid,
      addNoteTextBoxVisible: false,
      inviteFriendsModalVisible: false,
      open: false,
      windowWidth: windowWidth,
    };
  }

  makeAddNoteTextBoxVisible() {
    this.setState({
      addNoteTextBoxVisible: true,
    });
  }

  makeInviteFriendsModalVisible() {
    this.setState({
      inviteFriendsModalVisible: true,
    });
  }

  showDrawer = () => {
    this.props.readNotesForProduct(this.props.data.uuid);
    this.setState({
      open: true,
    });
  };

  onClose = () => {
    this.setState({
      open: false,
    });
  };

  onTabChange = (key, product) => {
    this.setState({ key: key });
  };

  static getDerivedStateFromProps = (nextProps, prevState) => {
    let windowWidth = window.innerWidth;
    let productNotes = [];
    nextProps.productNotes.forEach((productNote, index) => {
      if (productNote.context_data.uuid === nextProps.data.uuid) {
        productNotes.push(productNote);
      }
    });

    let friendProductNotes = [];
    nextProps.friendProductNotes.forEach((friendProductNote, index) => {
      if (friendProductNote.context_data.uuid === nextProps.data.uuid) {
        friendProductNotes.push(friendProductNote);
      }
    });

    return {
      key: nextProps.data.uuid + "notes",
      productUuid: nextProps.data.uuid,
      productNotes: productNotes,
      friendProductNotes: friendProductNotes,
      windowWidth: windowWidth,
    };
  };

  render() {
    return (
      <div style={styles.ButtonContainer}>
        <Button
          onClick={this.showDrawer}
          className="productCardDrawerButton"
        >
          <img
            loading="lazy"
            alt="Product not available"
            src={this.props.data.photo_url}
            className="productCardDrawerButtonImage"
          />
        </ Button>
        <Drawer
          width={this.state.windowWidth < 600 ? "100%" : "25%"}
          height={this.state.windowWidth < 600 ? "90%" : "550px"}
          placement={this.state.windowWidth < 600 ? "bottom" : "right"}
          closable={this.state.windowWidth < 600 ? true : false}
          onClose={this.onClose}
          open={this.state.open}
          destroyOnClose={true}
          headerStyle={styles.headerStyle}
          drawerStyle={styles.drawerStyle}
          bodyStyle={styles.bodyStyle}
          footerStyle={styles.footerStyle}
          maskStyle={styles.maskStyle}
          onCancel={this.props.handleCancel}
        >
          <div style={{textAlign: 'center'}}>
            <p style={styles.headerText}>{this.props.data.name}</p>
            <p style={styles.subheaderText2}>What should your friends know about {this.props.data.name}?{" "}</p>
            {this.state.key.includes(this.props.data.uuid + "notes") && (
              <div>
                <Row style={styles.infoCardBodyRow}>
                  <Row style={{justifyContent: 'center', textAlign: 'center', width: '100%'}}>
                    <NoteForm
                      value={this.props.data.value}
                      contextData={this.props.data}
                      context="product"
                    />
                  </Row>
                  <List
                    itemLayout="horizontal"
                    dataSource={this.state.productNotes}
                    style={styles.stickyNoteContainer}
                    grid={{
                      gutter: 16,
                      xs: 1,
                      sm: 1,
                      md: 1,
                      lg: 2,
                      xl: 3,
                      xxl: 6,
                    }}
                    renderItem={(item) => (
                      <List.Item className="notesList">
                        <List.Item.Meta
                          className="notesListMeta"
                          title={<p>{item.name}</p>}
                          description={item.note}
                        />
                      </List.Item>
                    )}
                  />
                    <Row style={styles.inviteFriendsContainer}>
                      <p style={styles.inviteFriendsHeaderStyle}>
                        Want to see what others have to say?{" "}
                      </p>
                    </Row>
                    <Row style={styles.inviteFriendsContainer}>
                      <InviteFriendsModal />
                    </Row>
                  </Row>
              </div>
            )}
          </div>
        </Drawer>
      </div>
    );
  }
}

const styles = {
  dislayProductInfo: {},
  myNotesHeader: {
    marginTop: 25,
    fontSize: 12,
    fontWeight: "bold",
  },
  friendsNotesHeader: {
    marginTop: 25,
    fontSize: 12,
    fontWeight: "bold",
  },
  infoCardHeaderRow: {
    width: "100%",
    height: 50,
    backgroundColor: "#f8f8f8",
    borderRadius: 10,
  },
  infoCardleftHeaderCol: {
    width: "50%",
    height: 50,
    padding: 10,
    backgroundColor: "none",
  },
  infoCardrightHeaderCol: {
    width: "50%",
    height: 50,
    padding: 10,
    backgroundColor: "none",
  },
  infoCardBodyRow: {
    width: "100%",
    height: 600,
    backgroundColor: "#white",
    borderRadius: 10,
  },
  infoCardmidBodyCol: {
    width: "2%",
    backgroundColor: "none",
  },
  infoCardrightBodyCol: {
    width: "100%",
    height: 600,
    backgroundColor: "none",
    padding: 25,
  },
  formColumn: {
    marginTop: 15,
    width: "50%",
    marginRight: "50%",
    float: "left",
  },
  notesHeader: {
    textAlign: "center",
    fontSize: 18,
  },
  verticalDivider: {
    height: 500,
    marginTop: 20,
  },
  newStickyNoteButton: {
    marginTop: 0,
    width: "100%",
    height: 150,
    backgroundColor: "#fffe99",
    borderRadius: 10,
    padding: 0,
    boxShadow: "0px 0px 4px 1px #D3D3D3",
    textOverflow: "wrap",
  },
  stickyNoteContainer: {
    paddingTop: 10,
    height: 600,
    width: "100%",
  },
  yellowStickyNote: {
    marginLeft: 10,
    marginRight: 20,
    marginBottom: 50,
    marginTop: 0,
    width: "90%",
    height: 150,
    backgroundColor: "#fffe99",
    borderRadius: 10,
    padding: 10,
    boxShadow: "0px 0px 4px 1px #D3D3D3",
    textOverflow: "wrap",
  },
  inviteFriendsContainer: {
    width: '100%',
    textAlign: "center",
    justifyContent: "center",
    marginBottom: 15,
  },
  takenoteContainer: {
    justifyContent: "center",
    textAlign: 'center',
    marginBottom: 15,
    width: '100%'

  },
  inviteFriendsButtonStyle: {
    backgroundColor: "none",
    border: "none",
    color: "#7E3FFF",
    margin: 0,
    marginLeft: 2,
    padding: 0,
    height: 22,
  },
  inviteFriendsHeaderStyle: {
    backgroundColor: "none",
    border: "none",
    color: "black",
    margin: 0,
    padding: 0,
    width: '100%',
  },
  headerText: {
    paddingBottom: 0,
    fontWeight: 300,
    fontSize: 36,
    marginLeft: 0,
    marginBottom: 0,
  },
  subheaderText2: {
    paddingBottom: 0,
    fontWeight: 300,
    fontSize: 20,
    marginLeft: 0,
    color: "grey",
  },
};

const mapStateToProps = (state) => {
  return {
    friendProductNotes: state.notes.friendProductNotes,
    productNotes: state.notes.productNotes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    readNotesForProduct: (productUuid) => dispatch(noteFunctions.readNotesForProduct(productUuid)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailsDrawer);
