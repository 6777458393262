import { combineReducers } from 'redux';
import auth from './authentication/reducer';
import emails from './emails/reducer';
import users from './users/reducer';
import categories from './categories/reducer';
import products from './products/reducer';
import notes from './notes/reducer';
import friends from './friends/reducer';
import topics from './topics/reducer';
import milestones from './milestones/reducer';
import ui from './ui/reducer';
import categoriesReducer from './categories/reducer';

export default combineReducers({
  auth,
  emails,
  users,
  categories,
  products,
  notes,
  friends,
  topics,
  milestones,
  ui,
  categories: categoriesReducer,
});
