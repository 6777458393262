const initialState = {
  photoUploadModalVisible: false,
  tutorialStep: 1,
  editableInputId: null,
  showRemovedTasks: false,
  isLoadingSharedNotes: {},
  currentTab: 'life',
  showTutorial: true,
  isAddMomentExpanded: false
};

export default initialState;
