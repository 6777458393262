import React, { useState, useRef, useEffect } from 'react';
import { List, Avatar, Carousel, Button } from 'antd';
import { UserOutlined, GlobalOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import "../../../assets/css/components/SharedNotes.css";
import InviteCard from './InviteCard';
import dayjs from 'dayjs';

const CarouselArrow = ({ type, onClick, disabled }) => {
  console.log('Rendering CarouselArrow:', { type, onClick, disabled });
  return (
    <Button
      className={`carousel-arrow ${type} ${disabled ? 'disabled' : ''}`}
      onClick={onClick}
      icon={type === 'prev' ? <LeftOutlined /> : <RightOutlined />}
      disabled={disabled}
    />
  );
};

const NoteItem = ({ note, isFriend }) => {
  console.log('Rendering NoteItem:', { note, isFriend });
  return (
    <List.Item className={isFriend ? 'friend-note' : 'community-note'}>
      <div className="note-container">
        <div className="note-header">
          <Avatar icon={isFriend ? <UserOutlined /> : <GlobalOutlined />} />
          <div className="note-author-info">
            <span className="note-author">{note.author}</span>
            {!isFriend && <span className="note-relevance">{note.relevance}</span>}
          </div>
        </div>
        <div className="note-content">{note.content}</div>
        <div className="note-date">{dayjs(note.date).format('MMMM D, YYYY')}</div>
      </div>
    </List.Item>
  );
};

const SharedNotes = ({ notes = [], onInvite = () => {} }) => {
  console.log('SharedNotes render start. Props:', { notes, onInvite });

  const carouselRef = useRef(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log('SharedNotes useEffect triggered. notes:', notes);
    if (!Array.isArray(notes)) {
      console.error('notes is not an array:', notes);
      setError('Invalid notes data');
    } else {
      setError(null);
    }
  }, [notes]);

  if (error) {
    console.log('Rendering error state:', error);
    return <div className="shared-notes-error">{error}</div>;
  }

  console.log('Processing notes:', notes);
  const myNotes = notes.filter(note => note.type === 'mine');
  const friendNotes = notes.filter(note => note.type === 'friends').map(note => ({ ...note, type: 'friends' }));
  const expertNotes = notes.filter(note => note.type === 'expert').map(note => ({ ...note, type: 'expert' }));

  console.log('Processed notes:', { myNotes, friendNotes, expertNotes });

  const carouselItems = [
    ...myNotes.map(note => ({ type: 'myNote', data: note })),
    { type: 'invitePartner' },
    ...friendNotes.map(note => ({ type: 'friendNote', data: note })),
    { type: 'inviteFriends' },
    ...expertNotes.map(note => ({ type: 'expertNote', data: note })),
    { type: 'inviteExpert' }
  ];

  console.log('Carousel items:', carouselItems);

  const handleSlideChange = (current) => {
    console.log('Slide changed:', current);
  };

  if (carouselItems.length === 0) {
    console.log('No carousel items to render');
    return <div className="no-notes">No notes available</div>;
  }

  return (
    <div className="shared-notes">
      <Carousel
        ref={carouselRef}
        arrows
        prevArrow={<CarouselArrow type="prev" />}
        nextArrow={<CarouselArrow type="next" />}
        afterChange={handleSlideChange}
      >
      {carouselItems.map((item, index) => {
        console.log(`Rendering carousel item ${index}:`, item);
        return (
          <div key={index}>
            {item.type === 'myNote' && <NoteItem note={item.data} isFriend={false} />}
            {item.type === 'friendNote' && <NoteItem note={item.data} isFriend={true} />}
            {item.type === 'expertNote' && <NoteItem note={item.data} isFriend={false} />}
            {item.type === 'invitePartner' && <InviteCard type="partner" onInvite={onInvite} />}
            {item.type === 'inviteFriends' && <InviteCard type="friends" onInvite={onInvite} />}
            {item.type === 'inviteExpert' && <InviteCard type="expert" onInvite={onInvite} />}
          </div>
        );
      })}
    </Carousel>
  </div>
);
};

export default SharedNotes;
