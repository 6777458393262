const quizQuestions = [
  {
    type: "Life",
    question: "How would you describe your work situation after the baby arrives?",
    explanation: "Your work plans significantly impact your preparation and childcare needs.",
    options: ["Staying at home full-time", "Returning to work soon", "Working from home"],
    impact: {
      "Staying at home full-time": {
        message: "We've tailored your checklist to focus on full-time baby care at home.",
        addedItems: [
          { id: 64, importance: "High", type: "task", title: "Prepared for full-time stay-at-home parenting", description: "Plan your daily routine as a stay-at-home parent. Research local parent groups and activities. Set up a support network and plan for personal time and self-care." },
          { id: 93, importance: "High", type: "task", title: "Created balanced meal and snack plan", description: "Develop a balanced meal and snack plan that includes a mix of homemade and convenient options. Focus on nutrients important for postpartum recovery and breastfeeding if applicable." }
        ],
        removedItems: [
          { id: 61, reason: "Not needed for full-time at-home care", type: "task", title: "Researched and chose daycare options" },
          { id: 65, reason: "Not applicable for stay-at-home parent", type: "task", title: "Planned return to work" }
        ]
      },
      "Returning to work soon": {
        message: "We've added tasks related to balancing work and baby care to your list.",
        addedItems: [
          { id: 61, importance: "High", type: "task", title: "Researched and chose daycare options", description: "Research local daycare options, considering factors such as location, cost, staff qualifications, and available schedules. Visit potential daycares and ask about their policies, curriculum, and safety measures." },
          { id: 65, importance: "High", type: "task", title: "Planned return to work", description: "Plan your return to work after parental leave. Discuss arrangements with your employer, plan childcare, and prepare for pumping at work if breastfeeding." },
          { id: 107, importance: "High", type: "task", title: "Developed work-life balance strategies", description: "Develop strategies for balancing work and family life after the baby arrives. Consider time management techniques, boundary-setting at work, and ways to maximize quality time with your baby." }
        ],
        removedItems: [
          { id: 64, reason: "Less relevant for working parents", type: "task", title: "Prepared for full-time stay-at-home parenting" }
        ]
      },
      "Working from home": {
        message: "We've included tasks to help you manage working from home with a baby.",
        addedItems: [
          { id: 66, importance: "High", type: "task", title: "Set up home office for remote work", description: "Prepare a dedicated workspace at home for remote work. Ensure you have necessary equipment and a quiet area for video calls. Plan your work schedule around baby's routine." },
          { id: 107, importance: "High", type: "task", title: "Developed work-life balance strategies", description: "Develop strategies for balancing work and family life after the baby arrives. Consider time management techniques, boundary-setting at work, and ways to maximize quality time with your baby." },
          { id: 67, importance: "Medium", type: "task", title: "Established flexible work arrangements", description: "Negotiate flexible work arrangements with your employer, such as adjusted hours, part-time work, or a combination of remote and in-office work." }
        ],
        removedItems: []
      }
    }
  },
  {
    type: "Life",
    question: "How would you describe your support system?",
    explanation: "Your support network influences the resources and strategies you'll need.",
    options: ["Strong local support", "Limited local support", "Mostly long-distance support"],
    impact: {
      "Strong local support": {
        message: "We've added tasks to help you coordinate local support in your baby care routine.",
        addedItems: [
          { id: 68, importance: "High", type: "task", title: "Organized local support network", description: "Identify and organize your local support network, including family, friends, and neighbors. Create a contact list and discuss how each person can help after the baby arrives." },
          { id: 29, importance: "High", type: "task", title: "Arranged for postpartum support", description: "Postpartum support can include family members, friends, postpartum doulas, or professional services. Discuss expectations clearly. Support might involve help with household chores, meal preparation, childcare for older children, or simply providing company." }
        ],
        removedItems: [
          { id: 69, reason: "Not necessary with strong local support", type: "task", title: "Set up virtual support system" }
        ]
      },
      "Limited local support": {
        message: "We've included tasks to help you build a local support network and find resources.",
        addedItems: [
          { id: 68, importance: "High", type: "task", title: "Organized local support network", description: "Identify and organize your local support network, including family, friends, and neighbors. Create a contact list and discuss how each person can help after the baby arrives." },
          { id: 70, importance: "High", type: "task", title: "Researched local parenting resources", description: "Research and compile a list of local parenting resources, including support groups, parenting classes, lactation consultants, and pediatricians." }
        ],
        removedItems: []
      },
      "Mostly long-distance support": {
        message: "We've added tasks to help you manage long-distance support and local resources.",
        addedItems: [
          { id: 69, importance: "High", type: "task", title: "Set up virtual support system", description: "Establish a virtual support system for long-distance family and friends. Set up video call schedules, join online parenting groups, and explore virtual postpartum support services." },
          { id: 70, importance: "High", type: "task", title: "Researched local parenting resources", description: "Research and compile a list of local parenting resources, including support groups, parenting classes, lactation consultants, and pediatricians." },
          { id: 81, importance: "High", type: "task", title: "Joined a parenting support group", description: "Find and join a local or online parenting support group. This could be for expectant parents or new parents, depending on your preference and stage of pregnancy." }
        ],
        removedItems: [
          { id: 68, reason: "Less relevant with primarily long-distance support", type: "task", title: "Organized local support network" }
        ]
      }
    }
  },
  {
    type: "Life",
    question: "How would you describe your approach to health and wellness?",
    explanation: "Your health approach affects your prenatal and postpartum care plans.",
    options: ["Very health-conscious", "Balanced approach", "Relaxed attitude"],
    impact: {
      "Very health-conscious": {
        message: "We've added detailed health and wellness tasks for both you and your baby.",
        addedItems: [
          { id: 72, importance: "High", type: "task", title: "Established prenatal fitness routine", description: "Consult with your healthcare provider and establish a safe prenatal fitness routine. This may include prenatal yoga, swimming, or walking." },
          { id: 73, importance: "High", type: "task", title: "Created postpartum nutrition plan", description: "Develop a nutritious postpartum meal plan, considering dietary needs for recovery and breastfeeding if applicable. Consider meal prep strategies and easy, nutritious snacks." },
          { id: 75, importance: "Medium", type: "task", title: "Established self-care routine", description: "Create and maintain a regular self-care routine, including activities that help you relax and recharge. This could include meditation, prenatal massage, or hobby time." }
        ],
        removedItems: []
      },
      "Balanced approach": {
        message: "We've included a mix of essential health tasks and general wellness activities.",
        addedItems: [
          { id: 74, importance: "High", type: "task", title: "Scheduled regular prenatal check-ups", description: "Schedule and attend regular prenatal check-ups as recommended by your healthcare provider. Keep track of questions to ask during these appointments." },
          { id: 75, importance: "Medium", type: "task", title: "Established self-care routine", description: "Create and maintain a regular self-care routine, including activities that help you relax and recharge. This could include meditation, prenatal massage, or hobby time." }
        ],
        removedItems: []
      },
      "Relaxed attitude": {
        message: "We've focused on key health check-ups and simple wellness tasks.",
        addedItems: [
          { id: 74, importance: "High", type: "task", title: "Scheduled regular prenatal check-ups", description: "Schedule and attend regular prenatal check-ups as recommended by your healthcare provider. Keep track of questions to ask during these appointments." },
          { id: 82, importance: "Medium", type: "task", title: "Established stress management techniques", description: "Learn and practice stress management techniques such as deep breathing, progressive muscle relaxation, or mindfulness meditation. Find methods that work best for you." }
        ],
        removedItems: [
          { id: 72, reason: "Less aligned with a relaxed approach to health", type: "task", title: "Established prenatal fitness routine" }
        ]
      }
    }
  },
  {
    type: "Life",
    question: "How do you feel about asking for help?",
    explanation: "Your comfort level with seeking help influences support strategies.",
    options: ["Very comfortable", "Somewhat hesitant", "Prefer to manage on my own"],
    impact: {
      "Very comfortable": {
        message: "We've added tasks to help you coordinate and maximize support from others.",
        addedItems: [
          { id: 29, importance: "High", type: "task", title: "Arranged for postpartum support", description: "Postpartum support can include family members, friends, postpartum doulas, or professional services. Discuss expectations clearly. Support might involve help with household chores, meal preparation, childcare for older children, or simply providing company." },
          { id: 76, importance: "High", type: "task", title: "Created a help request list", description: "Make a list of specific tasks or types of help you might need after the baby arrives. This can include meal preparation, house cleaning, or childcare for older siblings." }
        ],
        removedItems: [
          { id: 90, reason: "Less necessary with high comfort in asking for help", type: "task", title: "Developed solo parenting strategies" }
        ]
      },
      "Somewhat hesitant": {
        message: "We've included a mix of self-reliant tasks and ways to accept help comfortably.",
        addedItems: [
          { id: 76, importance: "High", type: "task", title: "Created a help request list", description: "Make a list of specific tasks or types of help you might need after the baby arrives. This can include meal preparation, house cleaning, or childcare for older siblings." },
          { id: 75, importance: "Medium", type: "task", title: "Established self-care routine", description: "Create and maintain a regular self-care routine, including activities that help you relax and recharge. This could include meditation, prenatal massage, or hobby time." }
        ],
        removedItems: []
      },
      "Prefer to manage on my own": {
        message: "We've focused on self-reliant parenting strategies and personal organization.",
        addedItems: [
          { id: 90, importance: "High", type: "task", title: "Developed solo parenting strategies", description: "If you'll be parenting solo, develop strategies for managing daily tasks, self-care, and building a support network. Consider both practical and emotional aspects of solo parenting." },
          { id: 75, importance: "High", type: "task", title: "Established self-care routine", description: "Create and maintain a regular self-care routine, including activities that help you relax and recharge. This could include meditation, prenatal massage, or hobby time." }
        ],
        removedItems: [
          { id: 29, reason: "Less aligned with preference for self-management", type: "task", title: "Arranged for postpartum support" }
        ]
      }
    }
  },
  {
    type: "Life",
    question: "How do you approach learning new skills?",
    explanation: "Your learning style impacts how we present parenting information and resources.",
    options: ["Hands-on learner", "Prefer reading and research", "Learn best from others"],
    impact: {
      "Hands-on learner": {
        message: "We've added practical, hands-on learning tasks for baby care skills.",
        addedItems: [
          { id: 79, importance: "High", type: "task", title: "Attended hands-on parenting class", description: "Participate in a hands-on parenting class that covers essential skills like diapering, bathing, and feeding. Practice with dolls or demo equipment." },
          { id: 30, importance: "High", type: "task", title: "Completed newborn care class", description: "Newborn care classes cover essential topics like diapering, bathing, feeding, safe sleep practices, and recognizing common newborn health issues. They often provide hands-on practice with dolls." }
        ],
        removedItems: [
          { id: 80, reason: "Less suited to hands-on learning style", type: "task", title: "Created parenting book reading list" }
        ]
      },
      "Prefer reading and research": {
        message: "We've included research-based tasks and reading materials on parenting and baby care.",
        addedItems: [
          { id: 80, importance: "High", type: "task", title: "Created parenting book reading list", description: "Compile a list of recommended parenting books and create a reading schedule. Include books on various topics such as baby care, child development, and parenting philosophies." },
          { id: 103, importance: "High", type: "task", title: "Researched sleep training methods", description: "Research various sleep training methods and their pros and cons. Consider which approach aligns best with your parenting philosophy and baby's temperament." }
        ],
        removedItems: [
          { id: 79, reason: "Less aligned with preference for reading and research", type: "task", title: "Attended hands-on parenting class" }
        ]
      },
      "Learn best from others": {
        message: "We've added tasks involving parenting groups, classes, and mentorship opportunities.",
        addedItems: [
          { id: 81, importance: "High", type: "task", title: "Joined a parenting support group", description: "Find and join a local or online parenting support group. This could be for expectant parents or new parents, depending on your preference and stage of pregnancy." },
          { id: 79, importance: "High", type: "task", title: "Attended hands-on parenting class", description: "Participate in a hands-on parenting class that covers essential skills like diapering, bathing, and feeding. Practice with dolls or demo equipment." }
        ],
        removedItems: [
          { id: 80, reason: "Less effective for those who learn best from others", type: "task", title: "Created parenting book reading list" }
        ]
      }
    }
  },
  {
    type: "Life",
    question: "How do you handle stress and emotional challenges?",
    explanation: "Your stress management approach informs self-care and support strategies.",
    options: ["Proactive self-care", "Seek support when needed", "Tend to push through on my own"],
    impact: {
      "Proactive self-care": {
        message: "We've added regular self-care and stress management tasks to your list.",
        addedItems: [
          { id: 75, importance: "High", type: "task", title: "Established self-care routine", description: "Create and maintain a regular self-care routine, including activities that help you relax and recharge. This could include meditation, prenatal massage, or hobby time." },
          { id: 82, importance: "High", type: "task", title: "Established stress management techniques", description: "Learn and practice stress management techniques such as deep breathing, progressive muscle relaxation, or mindfulness meditation. Find methods that work best for you." }
        ],
        removedItems: []
      },
      "Seek support when needed": {
        message: "We've included a mix of self-care tasks and support-seeking strategies.",
        addedItems: [
          { id: 75, importance: "Medium", type: "task", title: "Established self-care routine", description: "Create and maintain a regular self-care routine, including activities that help you relax and recharge. This could include meditation, prenatal massage, or hobby time." },
          { id: 81, importance: "High", type: "task", title: "Joined a parenting support group", description: "Find and join a local or online parenting support group. This could be for expectant parents or new parents, depending on your preference and stage of pregnancy." }
        ],
        removedItems: []
      },
      "Tend to push through on my own": {
        message: "We've added tasks to help you recognize stress signals and simple coping strategies.",
        addedItems: [
          { id: 82, importance: "High", type: "task", title: "Established stress management techniques", description: "Learn and practice stress management techniques such as deep breathing, progressive muscle relaxation, or mindfulness meditation. Find methods that work best for you." },
          { id: 84, importance: "High", type: "task", title: "Learned quick coping strategies", description: "Learn and practice quick coping strategies for stressful moments, such as grounding techniques, positive affirmations, or brief mindfulness exercises." }
        ],
        removedItems: [
          { id: 81, reason: "Less aligned with tendency to manage stress independently", type: "task", title: "Joined a parenting support group" }
        ]
      }
    }
  },
  {
    type: "Life",
    question: "How do you approach financial planning?",
    explanation: "Your financial planning style affects budgeting and expense management tasks.",
    options: ["Detailed budgeter", "General planner", "Take it as it comes"],
    impact: {
      "Detailed budgeter": {
        message: "We've added comprehensive financial planning tasks for your growing family.",
        addedItems: [
          { id: 85, importance: "High", type: "task", title: "Created detailed baby budget", description: "Develop a comprehensive budget for baby-related expenses, including one-time purchases, recurring costs, and potential changes to income. Consider both immediate and long-term expenses." },
          { id: 87, importance: "High", type: "task", title: "Started tracking baby-related expenses", description: "Begin tracking all baby-related purchases and expenses. This will help you understand your spending patterns and adjust your budget as needed." }
        ],
        removedItems: []
      },
      "General planner": {
        message: "We've included basic financial planning tasks and general budgeting guidelines.",
        addedItems: [
          { id: 86, importance: "High", type: "task", title: "Set up basic family budget", description: "Create a basic family budget that includes essential baby expenses and adjusts for changes in income. Focus on key categories like diapers, formula (if applicable), and childcare." },
          { id: 87, importance: "Medium", type: "task", title: "Started tracking baby-related expenses", description: "Begin tracking all baby-related purchases and expenses. This will help you understand your spending patterns and adjust your budget as needed." }
        ],
        removedItems: [
          { id: 85, reason: "Less necessary for general planners", type: "task", title: "Created detailed baby budget" }
        ]
      },
      "Take it as it comes": {
        message: "We've added simple financial tracking tasks and basic expense management.",
        addedItems: [
          { id: 87, importance: "Medium", type: "task", title: "Started tracking baby-related expenses", description: "Begin tracking all baby-related purchases and expenses. This will help you understand your spending patterns and adjust your budget as needed." },
          { id: 86, importance: "Medium", type: "task", title: "Set up basic family budget", description: "Create a basic family budget that includes essential baby expenses and adjusts for changes in income. Focus on key categories like diapers, formula (if applicable), and childcare." }
        ],
        removedItems: [
          { id: 85, reason: "Less aligned with a flexible financial approach", type: "task", title: "Created detailed baby budget" }
        ]
      }
    }
  },
  {
    type: "Life",
    question: "How do you envision dividing parenting responsibilities?",
    explanation: "Your parenting division plans influence coordination and support strategies.",
    options: ["Equal partnership", "Primary caregiver with support", "Mostly sole responsibility"],
    impact: {
      "Equal partnership": {
        message: "We've added tasks to help you and your partner coordinate shared responsibilities.",
        addedItems: [
          { id: 88, importance: "High", type: "task", title: "Created shared parenting responsibility plan", description: "Work with your partner to create a plan for sharing parenting responsibilities. Discuss expectations for tasks like night feedings, diaper changes, and household chores." },
          { id: 112, importance: "High", type: "task", title: "Created couple and family time balance plan", description: "Develop a plan to balance couple time, family time, and individual time after the baby arrives. Consider strategies for incorporating the baby into couple activities and ensuring each partner gets personal time." }
        ],
        removedItems: [
          { id: 90, reason: "Less relevant for equal parenting partnerships", type: "task", title: "Developed solo parenting strategies" }
        ]
      },
      "Primary caregiver with support": {
        message: "We've included tasks for managing primary care with supportive involvement.",
        addedItems: [
          { id: 89, importance: "High", type: "task", title: "Arranged partner support for primary caregiver", description: "If one partner will be the primary caregiver, discuss and plan how the other partner can provide support. This may include taking on specific tasks or providing regular breaks." },
          { id: 112, importance: "High", type: "task", title: "Created couple and family time balance plan", description: "Develop a plan to balance couple time, family time, and individual time after the baby arrives. Consider strategies for incorporating the baby into couple activities and ensuring each partner gets personal time." }
        ],
        removedItems: []
      },
      "Mostly sole responsibility": {
        message: "We've focused on self-reliant parenting strategies and personal time management.",
        addedItems: [
          { id: 90, importance: "High", type: "task", title: "Developed solo parenting strategies", description: "If you'll be parenting solo, develop strategies for managing daily tasks, self-care, and building a support network. Consider both practical and emotional aspects of solo parenting." },
          { id: 75, importance: "High", type: "task", title: "Established self-care routine", description: "Create and maintain a regular self-care routine, including activities that help you relax and recharge. This could include meditation, prenatal massage, or hobby time." }
        ],
        removedItems: [
          { id: 88, reason: "Less applicable for sole responsibility parenting", type: "task", title: "Created shared parenting responsibility plan" }
        ]
      }
    }
  },
  {
    type: "Life",
    question: "How do you approach meal planning and nutrition?",
    explanation: "Your meal planning style impacts food preparation and nutrition strategies.",
    options: ["Enjoy cooking from scratch", "Mix of homemade and convenient options", "Prefer quick and easy meals"],
    impact: {
      "Enjoy cooking from scratch": {
        message: "We've added tasks for homemade baby food preparation and family meal planning.",
        addedItems: [
          { id: 91, importance: "High", type: "task", title: "Researched homemade baby food options", description: "Research recipes, techniques, and equipment for making homemade baby food. Consider nutritional needs at different stages and safe food storage practices." },
          { id: 93, importance: "High", type: "task", title: "Created balanced meal and snack plan", description: "Develop a balanced meal and snack plan that includes a mix of homemade and convenient options. Focus on nutrients important for postpartum recovery and breastfeeding if applicable." }
        ],
        removedItems: [
          { id: 92, reason: "Less relevant for those who enjoy cooking from scratch", type: "task", title: "Planned quick and nutritious meal options" }
        ]
      },
      "Mix of homemade and convenient options": {
        message: "We've included a balance of home cooking tasks and convenient meal planning.",
        addedItems: [
          { id: 93, importance: "High", type: "task", title: "Created balanced meal and snack plan", description: "Develop a balanced meal and snack plan that includes a mix of homemade and convenient options. Focus on nutrients important for postpartum recovery and breastfeeding if applicable." },
          { id: 92, importance: "Medium", type: "task", title: "Planned quick and nutritious meal options", description: "Compile a list of quick, easy, and nutritious meal options for busy days with a newborn. Consider meals that can be prepared in advance or with minimal effort." }
        ],
        removedItems: []
      },
      "Prefer quick and easy meals": {
        message: "We've focused on quick, nutritious meal options and time-saving food prep strategies.",
        addedItems: [
          { id: 92, importance: "High", type: "task", title: "Planned quick and nutritious meal options", description: "Compile a list of quick, easy, and nutritious meal options for busy days with a newborn. Consider meals that can be prepared in advance or with minimal effort." },
          { id: 40, importance: "High", type: "task", title: "Arranged for meal train or food delivery service", description: "Meal trains can be organized through websites or apps where friends and family sign up to provide meals. If using a food delivery service, stock up on gift cards or set up a recurring order." }
        ],
        removedItems: [
          { id: 91, reason: "Less suited to preference for quick and easy meals", type: "task", title: "Researched homemade baby food options" }
        ]
      }
    }
  },
  {
    type: "Life",
    question: "How do you plan to document your baby's milestones and memories?",
    explanation: "Your documentation preferences guide memory-keeping task recommendations.",
    options: ["Detailed baby book or scrapbook", "Digital photo albums and social media", "Minimal documentation"],
    impact: {
      "Detailed baby book or scrapbook": {
        message: "We've added tasks for creating and maintaining a comprehensive baby book.",
        addedItems: [
          { id: 94, importance: "High", type: "task", title: "Started baby book or scrapbook", description: "Begin a baby book or scrapbook to document your pregnancy and baby's milestones. Gather supplies and start with pregnancy memories if desired." },
          { id: 33, importance: "High", type: "task", title: "Had maternity/newborn photoshoot", description: "Schedule a maternity photoshoot between 28-36 weeks when your bump is pronounced but you're still comfortable. For newborn shoots, aim for 5-14 days after birth." }
        ],
        removedItems: [
          { id: 96, reason: "Less aligned with detailed documentation preference", type: "task", title: "Planned simple memory-keeping strategy" }
        ]
      },
      "Digital photo albums and social media": {
        message: "We've included tasks for digital photo organization and online memory sharing.",
        addedItems: [
          { id: 95, importance: "High", type: "task", title: "Set up digital photo organization system", description: "Establish a system for organizing and backing up digital photos of your baby. Choose a cloud storage service or local backup method and create a folder structure." },
          { id: 33, importance: "Medium", type: "task", title: "Had maternity/newborn photoshoot", description: "Schedule a maternity photoshoot between 28-36 weeks when your bump is pronounced but you're still comfortable. For newborn shoots, aim for 5-14 days after birth." }
        ],
        removedItems: [
          { id: 94, reason: "Less relevant for digital documentation preference", type: "task", title: "Started baby book or scrapbook" }
        ]
      },
      "Minimal documentation": {
        message: "We've added simple tasks for capturing key moments without extensive record-keeping.",
        addedItems: [
          { id: 96, importance: "Medium", type: "task", title: "Planned simple memory-keeping strategy", description: "Develop a simple, low-effort strategy for capturing and preserving key memories and milestones. This could include a monthly photo tradition, a one-line-a-day journal, or milestone cards." },
          { id: 95, importance: "Low", type: "task", title: "Set up digital photo organization system", description: "Establish a system for organizing and backing up digital photos of your baby. Choose a cloud storage service or local backup method and create a folder structure." }
        ],
        removedItems: [
          { id: 94, reason: "Not aligned with minimal documentation preference", type: "task", title: "Started baby book or scrapbook" },
          { id: 33, reason: "Less necessary for minimal documentation approach", type: "task", title: "Had maternity/newborn photoshoot" }
        ]
      }
    }
  },
  {
    type: "Life",
    question: "How do you approach maintaining your social life after the baby arrives?",
    explanation: "Your social preferences influence recommendations for balancing social life and parenting.",
    options: ["Plan to stay very socially active", "Maintain key friendships", "Expect to focus mostly on family"],
    impact: {
      "Plan to stay very socially active": {
        message: "We've added tasks to help you balance an active social life with new parenthood.",
        addedItems: [
          { id: 97, importance: "High", type: "task", title: "Researched baby-friendly social activities", description: "Research local baby-friendly activities and venues, such as mom and baby yoga classes, library story times, or parent-child playgroups. Make a list of options to explore after the baby arrives." },
          { id: 99, importance: "High", type: "task", title: "Identified key friendships to maintain", description: "Identify the key friendships you want to prioritize maintaining after the baby arrives. Consider friends who are supportive, understanding of your new role, and those who may also be parents." }
        ],
        removedItems: [
          { id: 98, reason: "Less relevant for very socially active lifestyle", type: "task", title: "Planned family bonding activities" }
        ]
      },
      "Maintain key friendships": {
        message: "We've included tasks for nurturing important relationships while adapting to parenthood.",
        addedItems: [
          { id: 99, importance: "Medium", type: "task", title: "Identified key friendships to maintain", description: "Identify the key friendships you want to prioritize maintaining after the baby arrives. Consider friends who are supportive, understanding of your new role, and those who may also be parents." },
          { id: 97, importance: "Medium", type: "task", title: "Researched baby-friendly social activities", description: "Research local baby-friendly activities and venues, such as mom and baby yoga classes, library story times, or parent-child playgroups. Make a list of options to explore after the baby arrives." }
        ],
        removedItems: []
      },
      "Expect to focus mostly on family": {
        message: "We've focused on family bonding activities and occasional social connections.",
        addedItems: [
          { id: 98, importance: "High", type: "task", title: "Planned family bonding activities", description: "Plan simple activities to help your family bond with the new baby, such as reading time, gentle play sessions, or family walks. Include activities for siblings if applicable." },
          { id: 99, importance: "Low", type: "task", title: "Identified key friendships to maintain", description: "Identify the key friendships you want to prioritize maintaining after the baby arrives. Consider friends who are supportive, understanding of your new role, and those who may also be parents." }
        ],
        removedItems: [
          { id: 97, reason: "Less aligned with family-focused preference", type: "task", title: "Researched baby-friendly social activities" }
        ]
      }
    }
  },
  {
    type: "Life",
    question: "How do you plan to approach your personal hobbies and interests after the baby arrives?",
    explanation: "Your plans for personal interests guide recommendations for maintaining work-life balance.",
    options: ["Maintain most of my current activities", "Scale back but keep some key interests", "Expect to put most on hold for now"],
    impact: {
      "Maintain most of my current activities": {
        message: "We've added tasks to help you integrate your hobbies with new parenting responsibilities.",
        addedItems: [
          { id: 100, importance: "High", type: "task", title: "Planned strategies to continue hobbies", description: "Develop strategies for continuing your hobbies or interests after the baby arrives. This might include modifying how you engage in the hobby, finding baby-friendly versions, or scheduling dedicated time." },
          { id: 112, importance: "High", type: "task", title: "Created couple and family time balance plan", description: "Develop a plan to balance couple time, family time, and individual time after the baby arrives. Consider strategies for incorporating the baby into couple activities and ensuring each partner gets personal time." }
        ],
        removedItems: [
          { id: 101, reason: "Less relevant for maintaining current activities", type: "task", title: "Created future hobby reintroduction plan" }
        ]
      },
      "Scale back but keep some key interests": {
        message: "We've included tasks for balancing key personal interests with new parent duties.",
        addedItems: [
          { id: 102, importance: "Medium", type: "task", title: "Identified key interests to maintain", description: "Identify the most important personal interests or activities you want to maintain after the baby arrives. Prioritize those that are most fulfilling or essential to your well-being." },
          { id: 100, importance: "Medium", type: "task", title: "Planned strategies to continue hobbies", description: "Develop strategies for continuing your hobbies or interests after the baby arrives. This might include modifying how you engage in the hobby, finding baby-friendly versions, or scheduling dedicated time." }
        ],
        removedItems: []
      },
      "Expect to put most on hold for now": {
        message: "We've focused on essential self-care and future planning for returning to hobbies.",
        addedItems: [
          { id: 75, importance: "High", type: "task", title: "Established self-care routine", description: "Create and maintain a regular self-care routine, including activities that help you relax and recharge. This could include meditation, prenatal massage, or hobby time." },
          { id: 101, importance: "Low", type: "task", title: "Created future hobby reintroduction plan", description: "If you expect to pause some hobbies, create a plan for gradually reintroducing them in the future. Set realistic timelines and consider how your interests might evolve with parenthood." }
        ],
        removedItems: [
          { id: 100, reason: "Less aligned with putting hobbies on hold", type: "task", title: "Planned strategies to continue hobbies" }
        ]
      }
    }
  },
  {
    type: "Life",
    question: "How do you feel about sleep training?",
    explanation: "Your approach to sleep training affects recommendations for sleep routines and strategies.",
    options: ["Plan to sleep train early", "Will consider it if needed", "Prefer not to sleep train"],
    impact: {
      "Plan to sleep train early": {
        message: "We've added tasks related to early sleep training methods and schedules.",
        addedItems: [
          { id: 103, importance: "High", type: "task", title: "Researched sleep training methods", description: "Research various sleep training methods and their pros and cons. Consider which approach aligns best with your parenting philosophy and baby's temperament." },
          { id: 52, importance: "High", type: "task", title: "Baby slept for 4-hour stretch", description: "Babies typically start sleeping for longer stretches between 3-6 months old. A 4-hour sleep stretch is a positive sign of developing sleep patterns. However, sleep patterns can vary greatly between babies and can change frequently." }
        ],
        removedItems: [
          { id: 104, reason: "Less relevant for early sleep training approach", type: "task", title: "Learned about responsive nighttime parenting" }
        ]
      },
      "Will consider it if needed": {
        message: "We've included a mix of gentle sleep strategies and optional sleep training info.",
        addedItems: [
          { id: 105, importance: "Medium", type: "task", title: "Created gradual sleep development plan", description: "Develop a plan for gradually supporting your baby's natural sleep development. Include strategies for establishing bedtime routines, creating a sleep-friendly environment, and gently encouraging independent sleep over time." },
          { id: 103, importance: "Low", type: "task", title: "Researched sleep training methods", description: "Research various sleep training methods and their pros and cons. Consider which approach aligns best with your parenting philosophy and baby's temperament." }
        ],
        removedItems: []
      },
      "Prefer not to sleep train": {
        message: "We've focused on responsive nighttime parenting and gradual sleep development.",
        addedItems: [
          { id: 104, importance: "High", type: "task", title: "Learned about responsive nighttime parenting", description: "Research responsive nighttime parenting techniques, including safe co-sleeping practices if interested. Learn about normal infant sleep patterns and strategies for supporting your baby's sleep development." },
          { id: 105, importance: "High", type: "task", title: "Created gradual sleep development plan", description: "Develop a plan for gradually supporting your baby's natural sleep development. Include strategies for establishing bedtime routines, creating a sleep-friendly environment, and gently encouraging independent sleep over time." }
        ],
        removedItems: [
          { id: 103, reason: "Not aligned with preference against sleep training", type: "task", title: "Researched sleep training methods" }
        ]
      }
    }
  },
  {
    type: "Life",
    question: "How do you plan to handle childcare in the long term?",
    explanation: "Your childcare plans influence preparation tasks and resource recommendations.",
    options: ["Plan to use daycare", "Considering a nanny or au pair", "Family member will provide care"],
    impact: {
      "Plan to use daycare": {
        message: "We've added tasks related to researching and preparing for daycare.",
        addedItems: [
          { id: 61, importance: "High", type: "task", title: "Researched and chose daycare options", description: "Research local daycare options, considering factors such as location, cost, staff qualifications, and available schedules. Visit potential daycares and ask about their policies, curriculum, and safety measures." },
          { id: 16, importance: "High", type: "task", title: "Completed hospital pre-registration", description: "Pre-registration allows you to provide necessary information to the hospital before labor begins. This usually includes insurance details, medical history, and your birth plan preferences." }
        ],
        removedItems: [
          { id: 62, reason: "Not relevant for daycare plan", type: "task", title: "Hired a nanny or au pair" },
          { id: 63, reason: "Less applicable for daycare plan", type: "task", title: "Coordinated childcare with family member" }
        ]
      },
      "Considering a nanny or au pair": {
        message: "We've included tasks for the nanny hiring process and home preparation.",
        addedItems: [
          { id: 62, importance: "High", type: "task", title: "Hired a nanny or au pair", description: "Research nanny or au pair options, conduct interviews, check references, and complete necessary paperwork. Discuss expectations, schedules, and responsibilities. Prepare your home for the caregiver's arrival if applicable." },
          { id: 37, importance: "High", type: "task", title: "Installed baby gates", description: "Install baby gates at the top and bottom of stairs and to section off any unsafe areas. Ensure gates are securely attached and meet current safety standards." }
        ],
        removedItems: [
          { id: 61, reason: "Not relevant for nanny/au pair plan", type: "task", title: "Researched and chose daycare options" }
        ]
      },
      "Family member will provide care": {
        message: "We've focused on tasks for coordinating care with family members.",
        addedItems: [
          { id: 63, importance: "High", type: "task", title: "Coordinated childcare with family member", description: "Discuss childcare arrangements with the family member who will be providing care. Agree on schedules, responsibilities, and any compensation. Prepare your home and necessary supplies for childcare." },
          { id: 98, importance: "Medium", type: "task", title: "Planned family bonding activities", description: "Plan simple activities to help your family bond with the new baby, such as reading time, gentle play sessions, or family walks. Include activities for siblings if applicable." }
        ],
        removedItems: [
          { id: 61, reason: "Not relevant for family care plan", type: "task", title: "Researched and chose daycare options" },
          { id: 62, reason: "Not applicable for family care plan", type: "task", title: "Hired a nanny or au pair" }
        ]
      }
    }
  },
  {
    type: "Life",
    question: "How do you plan to approach your career development after the baby arrives?",
    explanation: "Your career plans affect recommendations for work-life balance and professional development.",
    options: ["Continue on current career path", "Planning a career change", "Taking an extended break"],
    impact: {
      "Continue on current career path": {
        message: "We've added tasks to help you balance your current career with new parenthood.",
        addedItems: [
          { id: 107, importance: "High", type: "task", title: "Developed work-life balance strategies", description: "Develop strategies for balancing work and family life after the baby arrives. Consider time management techniques, boundary-setting at work, and ways to maximize quality time with your baby." },
          { id: 65, importance: "Medium", type: "task", title: "Planned return to work", description: "Plan your return to work after parental leave. Discuss arrangements with your employer, plan childcare, and prepare for pumping at work if breastfeeding." }
        ],
        removedItems: [
          { id: 108, reason: "Not relevant for continuing current career", type: "task", title: "Planned career transition strategy" }
        ]
      },
      "Planning a career change": {
        message: "We've included tasks to support your career transition while adapting to parenthood.",
        addedItems: [
          { id: 108, importance: "High", type: "task", title: "Planned career transition strategy", description: "If planning a career change, develop a strategy for your transition. Consider timing, necessary skills or education, and how to balance the change with new parenthood." },
          { id: 107, importance: "High", type: "task", title: "Developed work-life balance strategies", description: "Develop strategies for balancing work and family life after the baby arrives. Consider time management techniques, boundary-setting at work, and ways to maximize quality time with your baby." }
        ],
        removedItems: []
      },
      "Taking an extended break": {
        message: "We've focused on tasks for managing an extended career break and future planning.",
        addedItems: [
          { id: 109, importance: "High", type: "task", title: "Created plan for extended career break", description: "If planning an extended career break, create a comprehensive plan. Consider financial implications, strategies for staying current in your field, and a tentative timeline for returning to work if desired." },
          { id: 64, importance: "Medium", type: "task", title: "Prepared for full-time stay-at-home parenting", description: "Plan your daily routine as a stay-at-home parent. Research local parent groups and activities. Set up a support network and plan for personal time and self-care." }
        ],
        removedItems: [
          { id: 65, reason: "Less relevant during extended career break", type: "task", title: "Planned return to work" }
        ]
      }
    }
  },
  {
    type: "Life",
    question: "How do you plan to approach your relationship with your partner after the baby arrives?",
    explanation: "Your relationship approach guides recommendations for maintaining connection with your partner.",
    options: ["Prioritize regular date nights", "Focus mainly on parenting together", "Balance between couple time and family time"],
    impact: {
      "Prioritize regular date nights": {
        message: "We've added tasks to help you maintain regular couple time and connection.",
        addedItems: [
          { id: 110, importance: "High", type: "task", title: "Planned regular date nights", description: "Plan strategies for maintaining regular date nights after the baby arrives. Consider both at-home and out-of-home options, and discuss childcare arrangements." },
          { id: 112, importance: "High", type: "task", title: "Created couple and family time balance plan", description: "Develop a plan to balance couple time, family time, and individual time after the baby arrives. Consider strategies for incorporating the baby into couple activities and ensuring each partner gets personal time." }
        ],
        removedItems: []
      },
      "Focus mainly on parenting together": {
        message: "We've included tasks centered on co-parenting and family bonding.",
        addedItems: [
          { id: 111, importance: "High", type: "task", title: "Developed co-parenting communication plan", description: "Create a plan for effective communication and decision-making as co-parents. Include strategies for discussing parenting choices, sharing information about the baby, and resolving disagreements." },
          { id: 98, importance: "High", type: "task", title: "Planned family bonding activities", description: "Plan simple activities to help your family bond with the new baby, such as reading time, gentle play sessions, or family walks. Include activities for siblings if applicable." }
        ],
        removedItems: [
          { id: 110, reason: "Less prioritized in co-parenting focus", type: "task", title: "Planned regular date nights" }
        ]
      },
      "Balance between couple time and family time": {
        message: "We've added tasks to help you balance couple connection with family activities.",
        addedItems: [
          { id: 112, importance: "High", type: "task", title: "Created couple and family time balance plan", description: "Develop a plan to balance couple time, family time, and individual time after the baby arrives. Consider strategies for incorporating the baby into couple activities and ensuring each partner gets personal time." },
          { id: 111, importance: "High", type: "task", title: "Developed co-parenting communication plan", description: "Create a plan for effective communication and decision-making as co-parents. Include strategies for discussing parenting choices, sharing information about the baby, and resolving disagreements." }
        ],
        removedItems: []
      }
    }
  }
];

export default quizQuestions;
