import React, { useState, useRef, useEffect } from 'react';
import { Input, DatePicker, Button, Menu, Divider, message } from 'antd';
import { UpOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import PrivacyToggle from "./PrivacyToggle";
import TaskDetails from "./TaskDetails";
import SavedNote from './SavedNote';
import CarouselContainer from './CarouselContainer';
import InviteCard from './InviteCard';

const getFlexibilityColor = (flexibility) => {
  switch (flexibility) {
    case 'flexible': return 'green';
    case 'moderate': return 'orange';
    case 'strict': return 'red';
    default: return 'inherit';
  }
}

const ExpandedChecklistItem = ({
  task,
  onNotesChange,
  onDateChange,
  onCollapse,
  onPrivacyChange,
  isLoadingSharedNotes,
  sharedNotes = [],
  canEdit,
  currentFriendUuid,
  friendLists = []
}) => {
  console.log('ExpandedChecklistItem props:', { task, isLoadingSharedNotes, sharedNotes, canEdit, currentFriendUuid, friendLists });

  const [currentNote, setCurrentNote] = useState('');
  const [currentSection, setCurrentSection] = useState('mine');
  const carouselRef = useRef(null);
  const [myNotes, setMyNotes] = useState([]);

  useEffect(() => {
    console.log('ExpandedChecklistItem useEffect. sharedNotes:', sharedNotes);

    if (!Array.isArray(sharedNotes)) {
      console.error('sharedNotes is not an array:', sharedNotes);
      return;
    }

    const existingMyNotes = sharedNotes.filter(note => !note.isFromFriend && note.author === 'You') || [];
    console.log('Processed myNotes:', existingMyNotes);
    setMyNotes(existingMyNotes);
  }, [sharedNotes]);

  if (isLoadingSharedNotes) {
    console.log('Shared notes are still loading');
    return <div>Loading shared notes...</div>;
  }

  console.log('Processing sharedNotes:', sharedNotes);
  if (!Array.isArray(sharedNotes)) {
    console.error('sharedNotes is not an array:', sharedNotes);
    return <div>Error: Unable to load shared notes.</div>;
  }

  const friendNotes = sharedNotes.filter(note => note.isFromFriend).map(note => ({ ...note, type: 'friends' })) || [];
  console.log('Processed friendNotes:', friendNotes);

  const communityNotes = sharedNotes.filter(note => !note.isFromFriend && note.author !== 'You').map(note => ({ ...note, type: 'community' })) || [];
  console.log('Processed communityNotes:', communityNotes);

  const allNotes = [
    { type: 'input' },
    ...myNotes.map(note => ({ ...note, type: 'mine' })),
    ...friendNotes,
    ...(canEdit && !currentFriendUuid ? [{ type: 'inviteFriends' }] : []),
    ...communityNotes
  ];
  console.log('Final allNotes:', allNotes);

  const handleSave = (e) => {
    console.log('handleSave called');
    e.stopPropagation();
    if (currentNote.trim()) {
      const newNote = {
        content: currentNote,
        date: new Date(),
        privacy: currentFriendUuid ? 'friends' : task.notePrivacy,
        author: 'You',
        type: 'mine'
      };

      console.log('New note created:', newNote);

      const updatedMyNotes = [...myNotes, newNote];
      setMyNotes(updatedMyNotes);
      onNotesChange(newNote, task.id);
      setCurrentNote('');
      message.success('Note saved!');

      setTimeout(() => {
        const newNoteIndex = 1 + updatedMyNotes.length - 1;
        if (carouselRef.current) {
          console.log('Scrolling to new note at index:', newNoteIndex);
          carouselRef.current.goTo(newNoteIndex);
        }
      }, 100);
    } else {
      console.log('Attempted to save empty note');
      message.warning('Please enter a note before saving.');
    }
  };

  console.log('About to render notes. allNotes:', allNotes);

  const renderNote = (note, index) => {
    console.log(`Rendering note of type ${note.type} at index ${index}:`, note);
    switch (note.type) {
      case 'input':
        return (
          <div className="note-input-container" onClick={e => e.stopPropagation()}>
            <div className="note-input-header">
              {currentFriendUuid ? (
                <span>Share a note with {friendLists.find(f => f.uuid === currentFriendUuid)?.first_name || 'Friend'}?</span>
              ) : (
                <span>Create a note for </span>
              )}
              <PrivacyToggle
                value={currentFriendUuid ? 'friends' : task.notePrivacy}
                onChange={onPrivacyChange}
                disabled={!!currentFriendUuid}
              />
            </div>
            <Input.TextArea
              placeholder="Add your notes here..."
              className="sticky-note-input"
              value={currentNote}
              onChange={(e) => setCurrentNote(e.target.value)}
            />
            <div className="notes-actions">
              <Button
                onClick={handleSave}
                className="new-moment-button"
              >
                Save
              </Button>
            </div>
          </div>
        );
      case 'mine':
      case 'friends':
      case 'community':
        return (
          <SavedNote
            key={`${note.type}-note-${index}`}
            note={note}
            noteType={note.type}
            notes={allNotes}
          />
        );
      case 'inviteFriends':
        return <InviteCard key="invite-friends" type="friends" onInvite={(values) => {console.log('Invite friend:', values);}} />;
      default:
        console.warn('Unknown note type:', note.type);
        return null;
    }
  };

  const handleTabChange = (key) => {
    console.log('Tab changed to:', key);
    setCurrentSection(key);
    const index = allNotes.findIndex(note => note.type === key);
    if (index !== -1 && carouselRef.current) {
      console.log('Scrolling to note at index:', index);
      carouselRef.current.goTo(index);
    }
  };

  return (
    <div className="card-expanded-content" onClick={e => e.stopPropagation()}>
      {!currentFriendUuid && <TaskDetails details={task.details} />}
      <div className="shared-notes-carousel">
        <h3 className="notes-header">Notes</h3>
        <Divider className="notes-header-divider"/>
        {!currentFriendUuid && (
          <Menu
            onClick={(e) => handleTabChange(e.key)}
            selectedKeys={[currentSection]}
            mode="horizontal"
            className="notes-menu"
          >
            <Menu.Item key="input" className="menu-item-mine">My Notes</Menu.Item>
            <Menu.Item key="friends" className="menu-item-friends">Friends</Menu.Item>
            <Menu.Item key="community" className="menu-item-others">Community</Menu.Item>
          </Menu>
        )}
        <CarouselContainer
          ref={carouselRef}
          items={currentFriendUuid ? allNotes.filter(note => note.type === 'input' || note.author === currentFriendUuid) : allNotes}
          renderItem={(note, index) => renderNote(note, index)}
          onAfterChange={(current) => {
            const currentNote = allNotes[current];
            console.log('Carousel changed to note:', currentNote);
            setCurrentSection(currentNote.type);
          }}
          className="notes-carousel"
        />
      </div>
      {!currentFriendUuid && (
        <div className="task-details">
          <div className="task-metadata">
            <DatePicker
              value={task.dueDate ? dayjs(task.dueDate) : null}
              onChange={(date, dateString) => {
                if (canEdit) {
                  console.log('Date changed:', dateString);
                  onDateChange(date, task.id);
                }
              }}
              disabled={!canEdit}
              onClick={e => e.stopPropagation()}
            />
          </div>
          <div className="task-metadata">
            <div className="flexibility-status">
              <span>The timing for this task is </span>
              <span style={{ fontWeight: 'bold', color: getFlexibilityColor(task.flexibility) }}>
                {task.flexibility}
              </span>
            </div>
            <div className="flexibility-reason">
              {task.flexibilityReason}
            </div>
          </div>
        </div>
      )}
      <Button
        type="text"
        icon={<UpOutlined />}
        onClick={(e) => {
          e.stopPropagation();
          console.log('Collapse button clicked');
          onCollapse(task.id);
        }}
        className="collapse-button"
      >
        Collapse
      </Button>
    </div>
  );
};

export default ExpandedChecklistItem;
