import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ClickableCard from './ClickableCard';
import * as actions from '../../redux/users/actions';
import '../../../assets/css/components/ClickableCard.css';

const UserTypeSelection = ({ onSelect }) => {
  const dispatch = useDispatch();
  const selectedType = useSelector(state => state.users.userType);

  const userTypes = [
    {
      type: 'pregnant',
      description: "I am pregnant or trying to get pregnant"
    },
    {
      type: 'partner',
      description: "My partner is pregnant or we're trying to get pregnant"
    },
    {
      type: 'friend',
      description: "I'm a friend or family member of someone who is pregnant"
    },
    {
      type: 'community',
      description: "I'm here to contribute my expertise to the community"
    }
  ];

  const handleSelect = (type) => {
    dispatch(actions.setUserType(type));
    onSelect(type);
  };

  return (
    <div className="user-type-selection">
      <h2>Tell Us About Yourself</h2>
      <div className="clickable-card-container">
        {userTypes.map((userType, index) => (
          <ClickableCard
            key={userType.type}
            selected={selectedType === userType.type}
            onClick={() => handleSelect(userType.type)}
            className={`card-${(index % 6) + 1}`}
          >
            {userType.description}
          </ClickableCard>
        ))}
      </div>
    </div>
  );
};

export default UserTypeSelection;
