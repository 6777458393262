import axios from 'axios';
import * as types from './actionTypes';
import { isEmpty } from '../../../app/helperFunctions';
import { updateUserAttributes } from '../users/actions';

function readUserNoteSuccess(userNotes) {
  return {
    type: types.READ_USER_NOTES_SUCCESS,
    userNotes
  }
}

function readFriendNoteSuccess(friendNotes) {
  return {
    type: types.READ_FRIEND_NOTES_SUCCESS,
    friendNotes
  }
}

function readFriendProductNoteSuccess(friendProductNotes) {
  return {
    type: types.READ_FRIEND_PRODUCT_NOTES_SUCCESS,
    friendProductNotes
  }
}

function readFriendCategoryNoteSuccess(friendCategoryNotes) {
  return {
    type: types.READ_FRIEND_CATEGORY_NOTES_SUCCESS,
    friendCategoryNotes
  }
}

function readProductNoteSuccess(productNotes) {
  return {
    type: types.READ_PRODUCT_NOTES_SUCCESS,
    productNotes
  }
}

function readCategoryNoteSuccess(categoryNotes) {
  return {
    type: types.READ_CATEGORY_NOTES_SUCCESS,
    categoryNotes
  }
}

function readListNoteSuccess(listNotes) {
  return {
    type: types.READ_LIST_NOTES_SUCCESS,
    listNotes
  }
}

function readNoteError(error) {
  return {
    type: types.READ_NOTES_ERROR,
    readNotesError: error
  }
}

function saveNoteSuccess(bool) {
  return {
    type: types.SAVE_NOTES_SUCCESS,
    bool
  }
}

function saveNoteInProgress(bool) {
  return {
    type: types.SAVE_NOTES_IN_PROGRESS,
    bool
  }
}

function saveNoteError(errMessage) {
  return {
    type: types.SAVE_NOTES_ERROR,
    errMessage
  }
}

export function createNote(user, values, context, contextData, permissions) {
  return (dispatch) => {
    if (contextData.hasOwnProperty('notes')) {
      delete contextData['notes'];
    }
    let url = `${process.env.REACT_APP_API_URL}/notes`;
    let config = {headers: {Authorization: 'Bearer '+sessionStorage.getItem('jwt')}};
    let data = {
      note: values.notes,
      name: user.first_name + ' ' + user.last_name,
      context: context,
      contextData: contextData,
      permissions: permissions
    }
    dispatch(saveNoteInProgress(true));
    axios.post(url, data, config)
      .then((response) => {
        if (!isEmpty(response.data)) {
          if (context === 'milestone') {
            dispatch(updateUserAttributes(response.data));
          } else if (context === 'category') {
            dispatch(readNotesInCategory(contextData["uuid"]));
          } else if (context === 'product') {
            dispatch(readNotesForProduct(contextData['uuid']));
          } else {
            dispatch(saveNoteSuccess(true));
          }
        } else {
          dispatch(saveNoteError('No Response from API: ' + response.data));
        }
      })
      .catch((error) => {
        let errMessage = 'Error saving notes data. Please try again later.';
        if (error.response !== undefined) {
          errMessage = error.response;
          if (typeof error === 'object') {
            if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
              if (typeof error.response.data === 'object') {
                if ('Error' in error.response.data) {
                  errMessage = error.response.data['Error'];
                }
              } else {
                errMessage = error.response.data;
              }
            }
          }
        }
        dispatch(saveNoteError(errMessage));
      })
      .finally(() => {
        dispatch(saveNoteError(null));
        dispatch(saveNoteInProgress(false));
        dispatch(saveNoteSuccess(false));
      })
  }
}

export function readNotesForUser() {
  return (dispatch) => {
    let url = `${process.env.REACT_APP_API_URL}/users/notes`;
    let config = {headers: {Authorization: 'Bearer '+sessionStorage.getItem('jwt')}};
    dispatch(readUserNoteSuccess([]));
    axios.get(url, config)
    .then((response) => {
      if (!isEmpty(response.data)) {
        dispatch(readUserNoteSuccess(response.data));
      } else {
        dispatch(readNoteError('No Response from API: ' + response.data));
      }
    })
    .catch((error) => {
      let errMessage = 'Error reading notes data. Please try again later.';
      if (error.response !== undefined) {
        errMessage = error.response;
        if (typeof error === 'object') {
          if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
            if (typeof error.response.data === 'object') {
              if ('Error' in error.response.data) {
                errMessage = error.response.data['Error'];
              }
            } else {
              errMessage = error.response.data;
            }
          }
        }
      }
      console.log(errMessage);
    })
    .finally(() => {
      dispatch(readNoteError(''));
    })
  }
}

export function readNotesInList(listUuid) {
  return (dispatch) => {
    console.log(listUuid);
    let url = `${process.env.REACT_APP_API_URL}/lists/${listUuid}/notes`;
    let config = {headers: {Authorization: 'Bearer '+sessionStorage.getItem('jwt')}};
    dispatch(readListNoteSuccess([]));
    axios.get(url, config)
    .then((response) => {
      if (!isEmpty(response.data)) {
        dispatch(readListNoteSuccess(response.data));
      } else {
        dispatch(readNoteError('No Response from API: ' + response.data));
      }
    })
    .catch((error) => {
      console.log(error);
      let errMessage = 'Error reading notes data. Please try again later.';
      if (error.response !== undefined) {
        errMessage = error.response;
        if (typeof error === 'object') {
          if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
            if (typeof error.response.data === 'object') {
              if ('Error' in error.response.data) {
                errMessage = error.response.data['Error'];
              }
            } else {
              errMessage = error.response.data;
            }
          }
        }
      }
      console.log(errMessage);
    })
    .finally(() => {
      dispatch(readNoteError(''));
    })
  }
}

export function readNotesInCategory(categoryUuid) {
  return (dispatch) => {
    let url = `${process.env.REACT_APP_API_URL}/categories/${categoryUuid}/notes`;
    let config = {headers: {Authorization: 'Bearer '+sessionStorage.getItem('jwt')}};
    dispatch(readCategoryNoteSuccess([]));
    axios.get(url, config)
    .then((response) => {
      if (!isEmpty(response.data)) {
        dispatch(readCategoryNoteSuccess(response.data));
      } else {
        dispatch(readNoteError('No Response from API: ' + response.data));
      }
    })
    .catch((error) => {
      console.log(error);
      let errMessage = 'Error reading notes data. Please try again later.';
      if (error.response !== undefined) {
        errMessage = error.response;
        if (typeof error === 'object') {
          if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
            if (typeof error.response.data === 'object') {
              if ('Error' in error.response.data) {
                errMessage = error.response.data['Error'];
              }
            } else {
              errMessage = error.response.data;
            }
          }
        }
      }
      console.log(errMessage);
    })
    .finally(() => {
      dispatch(readNoteError(''));
    })
  }
}

export function readNotesForProduct(productUuid) {
  return (dispatch) => {
    let url = `${process.env.REACT_APP_API_URL}/products/${productUuid}/notes`;
    let config = {headers: {Authorization: 'Bearer '+sessionStorage.getItem('jwt')}};
    dispatch(readProductNoteSuccess([]));
    axios.get(url, config)
    .then((response) => {
      if (!isEmpty(response.data)) {
        dispatch(readProductNoteSuccess(response.data));
      } else {
        dispatch(readNoteError('No Response from API: ' + response.data));
      }
    })
    .catch((error) => {
      let errMessage = 'Error reading notes data. Please try again later.';
      if (error.response !== undefined) {
        errMessage = error.response;
        if (typeof error === 'object') {
          if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
            if (typeof error.response.data === 'object') {
              if ('Error' in error.response.data) {
                errMessage = error.response.data['Error'];
              }
            } else {
              errMessage = error.response.data;
            }
          }
        }
      }
      dispatch(readNoteError(errMessage));
    })
    .finally(() => {
      dispatch(readNoteError(''));
    })
  }
}

export function readFriendsNotes() {
  return (dispatch) => {
    let url = `${process.env.REACT_APP_API_URL}/users/notes/friends`;
    let config = {headers: {Authorization: 'Bearer '+sessionStorage.getItem('jwt')}};
    axios.get(url, config)
    .then((response) => {
      if (!isEmpty(response.data)) {
        dispatch(readFriendNoteSuccess(response.data));
      }
    })
    .catch((error) => {
      let errMessage = 'Error reading notes data. Please try again later.';
      if (error.response !== undefined) {
        errMessage = error.response;
        if (typeof error === 'object') {
          if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
            if (typeof error.response.data === 'object') {
              if ('Error' in error.response.data) {
                errMessage = error.response.data['Error'];
              }
            } else {
              errMessage = error.response.data;
            }
          }
        }
      }
      dispatch(readNoteError(errMessage));
    })
    .finally(() => {
      dispatch(readNoteError(''));
    })
  }
}

export function readFriendsProductNotes(productUuid) {
  return (dispatch) => {
    let url = `${process.env.REACT_APP_API_URL}/products/${productUuid}/notes/friends`;
    let config = {headers: {Authorization: 'Bearer '+sessionStorage.getItem('jwt')}};
    axios.get(url, config)
    .then((response) => {
      if (!isEmpty(response.data)) {
        dispatch(readFriendProductNoteSuccess(response.data));
      }
    })
    .catch((error) => {
      let errMessage = 'Error reading notes data. Please try again later.';
      if (error.response !== undefined) {
        errMessage = error.response;
        if (typeof error === 'object') {
          if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
            if (typeof error.response.data === 'object') {
              if ('Error' in error.response.data) {
                errMessage = error.response.data['Error'];
              }
            } else {
              errMessage = error.response.data;
            }
          }
        }
      }
      dispatch(readNoteError(errMessage));
    })
    .finally(() => {
      dispatch(readNoteError(''));
    })
  }
}

export function readFriendsCategoryNotes(categoryUuid) {
  return (dispatch) => {
    let url = `${process.env.REACT_APP_API_URL}/categories/${categoryUuid}/notes/friends`;
    let config = {headers: {Authorization: 'Bearer '+sessionStorage.getItem('jwt')}};
    axios.get(url, config)
    .then((response) => {
      if (!isEmpty(response.data)) {
        dispatch(readFriendCategoryNoteSuccess(response.data));
      }
    })
    .catch((error) => {
      let errMessage = 'Error reading notes data. Please try again later.';
      if (error.response !== undefined) {
        errMessage = error.response;
        if (typeof error === 'object') {
          if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
            if (typeof error.response.data === 'object') {
              if ('Error' in error.response.data) {
                errMessage = error.response.data['Error'];
              }
            } else {
              errMessage = error.response.data;
            }
          }
        }
      }
      dispatch(readNoteError(errMessage));
    })
    .finally(() => {
      dispatch(readNoteError(''));
    })
  }
}

export function updateNote(user, values, context, contextData) {
  return (dispatch) => {
    dispatch(saveNoteInProgress(true));
    let url = `${process.env.REACT_APP_API_URL}/notes/${values.uuid}`;
    let config = {headers: {Authorization: 'Bearer '+sessionStorage.getItem('jwt')}};
    let data = {
      note: values.notes,
      name: user.first_name + ' ' + user.last_name,
      context: context,
      contextData: contextData
    }
    axios.patch(url, config, data)
    .then((response) => {
      if (!isEmpty(response.data)) {
        dispatch(saveNoteSuccess(true));
      }
    })
    .catch((error) => {
      let errMessage = 'Error saving notes data. Please try again later.';
      if (error.response !== undefined) {
        errMessage = error.response;
        if (typeof error === 'object') {
          if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
            if (typeof error.response.data === 'object') {
              if ('Error' in error.response.data) {
                errMessage = error.response.data['Error'];
              }
            } else {
              errMessage = error.response.data;
            }
          }
        }
      }
      dispatch(saveNoteError(errMessage));
    })
    .finally(() => {
      dispatch(saveNoteError(''));
      dispatch(saveNoteInProgress(false));
    })
  }
}

export const setNotesEntered = (notes) => ({
  type: types.SET_NOTES_ENTERED,
  payload: notes
});

export const setSavedMoments = (moments) => ({
  type: types.SET_SAVED_MOMENTS,
  payload: moments
});
