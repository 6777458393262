import React, { useState } from 'react';
import { Checkbox } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

const CheckboxWithSound = ({ onChange, soundUrl, ...props }) => {
  const [isChecked, setIsChecked] = useState(props.checked || false);

  const handleChange = (e) => {
    setIsChecked(e.target.checked);
    if (e.target.checked && soundUrl) {
      try {
        const audio = new Audio(soundUrl);
        audio.play().catch(error => {
          console.warn("Audio playback failed:", error);
        });
      } catch (error) {
        console.warn("Error creating Audio object:", error);
      }
    }
    onChange(e);
  };

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <Checkbox onChange={handleChange} checked={isChecked} {...props} />
      {isChecked && (
        <CheckOutlined
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
            fontSize: '12px',
            pointerEvents: 'none'
          }}
        />
      )}
    </div>
  );
};

export default CheckboxWithSound;
