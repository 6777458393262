import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { isEmpty } from "../../helperFunctions";
import { readAllUsersFriends } from "../../redux/products/actions";
import { Button, Divider, Dropdown, Space } from "antd";
import {
  StarFilled,
  StarOutlined,
  FolderOpenFilled,
  FolderOpenOutlined,
  ContactsFilled,
  ContactsOutlined,
} from "@ant-design/icons";

import FreeFormSearchBox from "../inputs/search";
import InviteFriendsModal from "../modals/InviteFriends.js";
import ProductsCategoriesToggle from "../navigation/productsCategoriesToggle.js";

const NewProductsHeader = (props) => {
  const [friendSelected, setFriendSelected] = useState("My Checklist");
  const [friendLists, setFriendLists] = useState([]);

  const { data } = useQuery({
    queryKey: ["usersFriends", props.friendUuid, props.favorited],
    queryFn: () => readAllUsersFriends(),
  });
  const friends = data || [];

  useEffect(() => {
    populateFriendsLists(friends, props.friendUuid);
  }, [friends, props.friendUuid]);

  const populateFriendsLists = (friends, friendUuid) => {
    let output = [];
    output.push(
      {
        key: "1",
        label: "My Checklist",
        onClick: () => handleViewFriendList("1", props.favorited)
      }
    )
    friends.forEach((friend, i) => {
      let label = isEmpty(friend["first_name"]) || isEmpty(friend["last_name"]) ? friend["email"] + "'s List" : friend["first_name"] + " " + friend["last_name"];
      let friendDropdownData = {
        key: friend["uuid"],
        label: label,
        onClick: () => handleViewFriendList(friend["uuid"], props.favorited)
      };
      output.push(friendDropdownData);
      if (friend["uuid"] === friendUuid) {
        setFriendSelected(friendDropdownData["label"]);
      } else if (!friendUuid) {
        setFriendSelected("My Checklist");
      }
    });
    setFriendLists(output);
  };

  const handleViewFriendList = (key, favorited) => {
    if (!isEmpty(key) && key !== "1") {
      props.history.push({
        pathname: "/products",
        search: "?friendUuid=" + key + "&favorited=" + true,
      });
    } else {
      props.history.push({
        pathname: "/products",
        search: "?favorited=" + favorited,
      });
    }
  };

  return (
    <div>
      <p className="headerTitleText">
        {friendSelected === "My Checklist" ? "My Checklist" : friendSelected + "'s Checklist"}
      </p>
      <div className="footerLayout">
        <div>
          <ProductsCategoriesToggle location={props.location} history={props.history} />
        </div>
        <div style={{ height: 150, background: "none", width: "33%", padding: "none" }}>
          <Button
            onClick={() => {
              props.setIsOpen(!props.isOpen);
            }}
          >
            <Dropdown
              menu={{"items": friendLists}}
              open={props.isOpen}
              trigger="click"
              placement={"bottom"}
              dropdownRender={(menu) => (
                <div
                  style={{
                    marginBottom: 40,
                    backgroundColor: "white",
                    borderRadius: 10,
                    boxShadow: "2px 5px 10px 1px #e2e2e2",
                  }}
                >
                  {menu}
                  <Divider
                    style={{
                      margin: 10,
                    }}
                  />
                  <Space
                    style={{
                      padding: 8,
                    }}
                  >
                    Want to compare lists?
                  </Space>
                  <Divider
                    style={{
                      margin: 0,
                      color: "none",
                      borderColor: "none",
                    }}
                  />
                  <Space
                    style={{
                      padding: 8,
                    }}
                  >
                    <InviteFriendsModal setDropdownOpen={props.isOpen} />
                  </Space>
                </div>
              )}
            >
              <Space className="mobileIconFooterMenu">
                {props.favorited === null ? <ContactsFilled /> : <ContactsOutlined />}
              </Space>
            </Dropdown>
          </Button>
        </div>
        <div style={{ height: 150, background: "none", width: "33%", padding: "none" }}>
          <Button className="mobileIconFooterMenu" onClick={() => props.handleFavoriteToggle(true)}>
            {props.favorited === true ? <StarFilled /> : <StarOutlined />}
          </Button>
        </div>
        <div style={{ height: 150, background: "none", width: "33%" }}>
          <Button className="mobileIconFooterMenu" onClick={() => props.handleFavoriteToggle(null)}>
            {props.favorited === false || props.favorited === null ? <FolderOpenFilled /> : <FolderOpenOutlined />}
          </Button>
        </div>
      </div>
      <div className="searchContainer" style={{ position: "inherit" }}>
        <FreeFormSearchBox
          favorited={props.favorited}
          friendUuid={props.friendUuid}
          context="products"
          loading={props.isPending}
          onSearch={props.onSearch}
        />
      </div>
    </div>
  );
};

export default NewProductsHeader;
