import axios from 'axios';

import { clearEmailsData } from '../emails/actions';
import { setSuperuser } from '../users/actions';

import * as types from './actionTypes';

function registerInProcess(bool) {
  return {
    type: types.REGISTER_IN_PROCESS,
    registerInProcess: bool
  }
}

function registerError(error) {
  return {
    type: types.REGISTER_ERROR,
    registerError: error
  }
}

function registerSuccess() {
  return {
    type: types.REGISTER_SUCCESS
  }
}

function resetPasswordInProcess(bool) {
  return {
    type: types.RESET_PASSWORD_IN_PROCESS,
    resetPasswordInProcess: bool
  }
}

function resetPasswordError(error) {
  return {
    type: types.RESET_PASSWORD_ERROR,
    resetPasswordError: error
  }
}

function resetPasswordSuccess() {
  return {
    type: types.RESET_PASSWORD_SUCCESS
  }
}

export function loginInProcess(bool) {
  return {
    type: types.LOGIN_IN_PROCESS,
    loginInProcess: bool
  }
}

export function loginError(error) {
  return {
    type: types.LOGIN_ERROR,
    loginError: error
  }
}

export function loginSuccess(user) {
  return {
    type: types.LOGIN_SUCCESS,
    user
  }
}

function sendRegistrationInProcess(bool) {
  return {
    type: types.SEND_REGISTRATION_IN_PROCESS,
    sendRegistrationInProcess: bool
  }
}

function sendRegistrationSuccess(bool) {
  return {
    type: types.SEND_REGISTRATION_SUCCESS,
    sendRegistrationSuccess: bool
  }
}

function sendRegistrationError(error) {
  return {
    type: types.SEND_REGISTRATION_ERROR,
    sendRegistrationError: error
  }
}

function getEmailInProcess(bool) {
  return {
    type: types.GET_EMAIL_IN_PROCESS,
    getEmailInProcess: bool
  }
}

function getEmailSuccess(email) {
  return {
    type: types.GET_EMAIL_SUCCESS,
    email
  }
}

function getEmailError(error) {
  return {
    type: types.GET_EMAIL_ERROR,
    getEmailError: error
  }
}

function sendPasswordResetRequestInProcess(bool) {
  return {
    type: types.SEND_PASSWORD_RESET_REQUEST_IN_PROCESS,
    sendPasswordResetRequestInProcess: bool
  }
}

function sendPasswordResetRequestSuccess(bool) {
  return {
    type: types.SEND_PASSWORD_RESET_REQUEST_SUCCESS,
    sendPasswordResetRequestSuccess: bool
  }
}

function sendPasswordResetRequestError(error) {
  return {
    type: types.SEND_PASSWORD_RESET_REQUEST_ERROR,
    sendPasswordResetRequestError: error
  }
}

function getPasswordResetRequestInProcess(bool) {
  return {
    type: types.GET_PASSWORD_RESET_REQUEST_IN_PROCESS,
    getPasswordResetRequestInProcess: bool
  }
}

function getPasswordResetRequestSuccess(request) {
  return {
    type: types.GET_PASSWORD_RESET_REQUEST_SUCCESS,
    request
  }
}

function getPasswordResetRequestError(error) {
  return {
    type: types.GET_PASSWORD_RESET_REQUEST_ERROR,
    getPasswordResetRequestError: error
  }
}

export function loginMissing() {
  return {
    type: types.LOGIN_MISSING,
    user: null
  }
}

export function logoutSuccess() {
  return {
    type: types.LOGOUT_SUCCESS,
    user: null
  }
}

export function resetRegisterSuccess() {
  return {
    type: types.RESET_REGISTER_SUCCESS
  }
}

export function resetResetPasswordSuccess() {
  return {
    type: types.RESET_RESET_PASSWORD_SUCCESS
  }
}

export function checkLogin() {
  return (dispatch) => {
    const jwt = sessionStorage.getItem('jwt');
    if (jwt) {
      let url = `${process.env.REACT_APP_API_URL}/users`;
      axios.get(url, {headers: {Authorization: 'Bearer '+jwt}})
        .then(resp => {
          const user = {
            ...resp.data,
            jwt: jwt
          };

          if (window.jwtTokenRefresh === null) {
            dispatch(loginSuccess(user));
            dispatch(login(sessionStorage.getItem('email'), sessionStorage.getItem('password')));
          } else {
            dispatch(loginSuccess(user));
          }
        })
        .catch(error => {
          sessionStorage.removeItem('jwt');
          sessionStorage.removeItem('email');
          sessionStorage.removeItem('password');
          if(window.jwtTokenRefresh !== null){
            window.clearInterval(window.jwtTokenRefresh);
            window.jwtTokenRefresh = null;
          };
          dispatch(loginMissing());
        });
    } else {
      dispatch(loginMissing());
    }
  }
}

export function register(email, firstName, lastName, password, confirmPassword, token, type) {
  return (dispatch) => {
    dispatch(registerError(''));
    dispatch(registerInProcess(true));
    const url = `${process.env.REACT_APP_API_URL}/users`;
    const data = {
      auth_token: token,
      email: email,
      first_name: firstName,
      last_name: lastName,
      type: type,
      password: password,
      confirm_password: confirmPassword
    };

    axios.post(url, data)
      .then((response) => {
        dispatch(registerSuccess());
      })
      .catch((error) => {
        let errMessage = 'Error registering. Please try again later';
        if (error.response !== undefined) {
          errMessage = error.response;
          if (typeof error === 'object') {
            if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
              if (typeof error.response.data === 'object') {
                if ('Error' in error.response.data) {
                  errMessage = error.response.data['Error'];
                }
              } else {
                errMessage = error.response.data;
              }
            }
          }
        }
        dispatch(registerError(errMessage));
      })
      .finally(() => {
        dispatch(registerError(''));
        dispatch(registerInProcess(false));
      });
  }
}

export function resetPassword(new_password, confirmPassword, token) {
  return (dispatch) => {
    dispatch(resetPasswordError(''));
    dispatch(resetPasswordInProcess(true));

    const url = `${process.env.REACT_APP_API_URL}/reset-password/${token}`;
    const data = {new_password};

    axios.patch(url, data)
      .then((response) => {
        dispatch(resetPasswordSuccess());
      })
      .catch((error) => {
        let errMessage = 'Error resetting your password. Please try again later';
        if (error.response !== undefined) {
          errMessage = error.response;
          if (typeof error === 'object') {
            if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
              if (typeof error.response.data === 'object') {
                if ('Error' in error.response.data) {
                  errMessage = error.response.data['Error'];
                }
              } else {
                errMessage = error.response.data;
              }
            }
          }
        }
        dispatch(resetPasswordError(errMessage));
      })
      .finally(() => {
        dispatch(resetPasswordError(''));
        dispatch(resetPasswordInProcess(false));
      });
  }
}

window.jwtTokenRefresh = null;

export function login(email, password) {
  return (dispatch) => {
    dispatch(loginInProcess(true));
    dispatch(loginError(''));

    const cleanEmail = email.trim().toLowerCase();
    const cleanPassword = password.trim();

    if (!cleanEmail) {
      dispatch(loginInProcess(false));
      dispatch(loginError('Please enter an email'));
    }
    else if (!cleanPassword) {
      dispatch(loginInProcess(false));
      dispatch(loginError('Please enter a password'));
    } else {
      let url = `${process.env.REACT_APP_API_URL}/authenticate`;
      axios.post(url, {email: cleanEmail, password: cleanPassword})
        .then((response) => {
          if (response.data && response.data.jwt) {
            const user = {
              ...response.data.user,
              jwt: response.data.jwt
            };

            sessionStorage.setItem('jwt', response.data.jwt);
            sessionStorage.setItem('email', cleanEmail);
            sessionStorage.setItem('password', cleanPassword);

            dispatch(setSuperuser(cleanEmail === 'james@frankel.tv'));
            dispatch(loginSuccess(user));

            if (window.jwtTokenRefresh === null) {
              window.jwtTokenRefresh = window.setInterval(
                function(){
                  dispatch(login(cleanEmail, cleanPassword));
                }, (10 * 60 * 1000), [cleanEmail, cleanPassword]
              );
            }
          } else {
            dispatch(loginError('Invalid response from server'));
          }
        })
        .catch((error) => {
          let errMessage = 'Error logging in';
          if (error.response !== undefined) {
            errMessage = error.response;
            if (typeof error === 'object') {
              if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
                if (typeof error.response.data === 'object') {
                  if ('Error' in error.response.data) {
                    errMessage = error.response.data['Error'];
                  }
                } else {
                  errMessage = error.response.data;
                }
              }
            }
          }
          dispatch(loginError(errMessage));
        })
        .finally(() => {
          dispatch(loginInProcess(false));
        });
    }
  }
}

export function logout(user) {
  return (dispatch) => {
    sessionStorage.removeItem('jwt');
    sessionStorage.removeItem('email');
    sessionStorage.removeItem('password');
    window.clearInterval(window.jwtTokenRefresh);
    window.jwtTokenRefresh = null;
    dispatch(clearAssociatedData());
    dispatch(logoutSuccess());
  }
}

export function clearAssociatedData() {
  return (dispatch) => {
    dispatch(clearEmailsData());
  }
}

export function sendRegistrationEmail(email) {
  return (dispatch) => {
    dispatch(sendRegistrationError(''));
    dispatch(sendRegistrationInProcess(true));

    const invitationEmail = email.trim();
    let url = `${process.env.REACT_APP_API_URL}/emails/register`;
    let data = {email: invitationEmail};

    axios.post(url, data)
      .then(resp => {
        dispatch(sendRegistrationSuccess(true));
      })
      .catch(error => {
        let errMessage = 'Error sending invitation. Please try again later.';
        if (error.response !== undefined) {
          errMessage = error.response;
          if (typeof error === 'object') {
            if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
              if (typeof error.response.data === 'object') {
                if ('Error' in error.response.data) {
                  errMessage = error.response.data['Error'];
                }
              } else {
                errMessage = error.response.data;
              }
            }
          }
        }
        dispatch(sendRegistrationError(errMessage));
      })
      .finally(() => {
        dispatch(sendRegistrationError(''));
        dispatch(sendRegistrationInProcess(false));
        dispatch(sendRegistrationSuccess(false));
      })
  }
}

export function getEmail(token) {
  return (dispatch) => {
    dispatch(getEmailError(''));
    dispatch(getEmailInProcess(true));

    let url = `${process.env.REACT_APP_API_URL}/emails/${token}`;
    axios.get(url)
      .then(resp => {
        dispatch(getEmailSuccess(resp.data));
      })
      .catch(error => {
        let errMessage = 'Error getting invitation. Please try again later.';
        if (error.response !== undefined) {
          errMessage = error.response;
          if (typeof error === 'object') {
            if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
              if (typeof error.response.data === 'object') {
                if ('Error' in error.response.data) {
                  errMessage = error.response.data['Error'];
                }
              } else {
                errMessage = error.response.data;
              }
            }
          }
        }
        dispatch(getEmailError(errMessage));
      })
      .finally(() => {
        dispatch(getEmailError(''));
        dispatch(getEmailInProcess(false));
      });
  }
}

export function sendPasswordResetRequestEmail(email) {
  return (dispatch) => {
    dispatch(sendPasswordResetRequestError(''));
    dispatch(sendPasswordResetRequestInProcess(true));

    const passwordResetRequestEmail = email.trim()
    let url = `${process.env.REACT_APP_API_URL}/forgot-password`;
    let data = {email: passwordResetRequestEmail};

    axios.post(url, data)
      .then(resp => {
        dispatch(sendPasswordResetRequestSuccess(true));
        dispatch(sendPasswordResetRequestSuccess(false));
      })
      .catch(error => {
        let errMessage = 'Sorry, we can\'t find that email.';
        if (typeof error.response !== 'undefined') {
          if (typeof error === 'object') {
            if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
              if (typeof error.response.data === 'object') {
                if ('Error' in error.response.data) {
                  errMessage = error.response.data['Error'];
                }
              } else {
                errMessage = error.response.data;
              }
            }
          }
        }
        dispatch(sendPasswordResetRequestError(errMessage));
      })
      .finally(() => {
        dispatch(sendPasswordResetRequestError(''));
        dispatch(sendPasswordResetRequestInProcess(false));
      })
  }
}

export function getPasswordResetRequest(token) {
  return (dispatch) => {
    dispatch(getPasswordResetRequestError(''));
    dispatch(getPasswordResetRequestInProcess(true));

    let url = `${process.env.REACT_APP_API_URL}/emails/${token}`;
    axios.get(url)
      .then(response => {
        dispatch(getPasswordResetRequestSuccess(response.data));
      })
      .catch(error => {
        let errMessage = 'Error getting Valid Password Reset Request.';
        if (typeof error.response !== 'undefined') {
          if (typeof error === 'object') {
            if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
              if (typeof error.response.data === 'object') {
                if ('Error' in error.response.data) {
                  errMessage = error.response.data['Error'];
                }
              } else {
                errMessage = error.response.data;
              }
            }
        }
      }
      dispatch(getPasswordResetRequestError(errMessage));
    })
    .finally(() => {
      dispatch(getPasswordResetRequestError(''));
      dispatch(getPasswordResetRequestInProcess(false));
    })
}
}
