import initialState from './initialState';
import * as types from './actionTypes';

const users = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_USER_ERROR:
      return {
        ...state,
        addUserError: action.addUserError
      }
    case types.ADD_USER_IN_PROCESS:
      return {
        ...state,
        addUserInProcess: action.addUserInProcess
      }
    case types.UPDATE_USER_IN_PROGRESS:
      return {
        ...state,
        updateUserInProgress: action.updateUserInProgress
      }
    case types.UPDATE_USER_SUCCESS:
      return {
        ...state,
        updateUserSuccess: action.updateUserSuccess
      }
    case types.UPDATE_USER_ERROR:
      return {
        ...state,
        updateUserError: action.updateUserError
      }
    case types.UPDATE_USER_DATA:
      return {
        ...state,
        userData: action.userData
      }
    case types.UPDATE_USER_ATTRIBUTES:
      return {
        ...state,
        userAttributes: action.attributes
      }
    case types.REGISTER_REQUEST:
      return {
        ...state,
        registerInProcess: true,
        registerError: null,
        registerSuccess: false
      }
    case types.REGISTER_SUCCESS:
      return {
        ...state,
        registerInProcess: false,
        registerSuccess: true
      }
    case types.REGISTER_FAILURE:
      return {
        ...state,
        registerInProcess: false,
        registerError: action.payload
      }
    case types.SET_BABY_BIRTHDAY:
      return {
        ...state,
        babysBirthday: action.payload
      };
    case types.SET_CONFIRMED_PREGNANCY_DATE:
      return {
        ...state,
        confirmedPregnancyDate: action.payload
      };
    case types.SET_DUE_DATE:
      return {
        ...state,
        dueDate: action.payload
      };
    case types.UPDATE_USER_CHECKLIST:
      const { addedItems, removedItems } = action.payload;
      return {
        ...state,
        checklist: [
          ...state.checklist.filter(item => !removedItems.includes(item)),
          ...addedItems.filter(item => !state.checklist.includes(item))
        ],
        productRecommendations: [
          ...state.productRecommendations.filter(item => !removedItems.includes(item)),
          ...addedItems.filter(item => item.type === 'product' && !state.productRecommendations.includes(item))
        ]
      };
    case types.SET_USER_TYPE:
      console.log('Reducer: Setting user type to', action.payload);
      return {
        ...state,
        currentUserType: action.payload,
        userType: action.payload // Update both fields for consistency
      };
    case types.SET_PREGNANCY_STATUS:
      return { ...state, pregnancyStatus: action.payload };
    case types.SET_BABY_NAME:
      return { ...state, babyName: action.payload };
    case types.SET_PREGNANCY_PHOTO:
      return { ...state, pregnancyPhoto: action.payload };
    case types.SET_PRIVACY_LEVEL:
      return { ...state, privacyLevel: action.payload };
    case types.SET_CHILDREN_COUNT:
      return { ...state, childrenCount: action.payload };
    case types.SET_PARTNER_STATUS:
      return { ...state, partnerStatus: action.payload };
    case types.SET_PARTNER_EMAIL:
      return { ...state, partnerEmail: action.payload };
    case types.SET_INVITE_FRIENDS:
      return { ...state, inviteFriends: action.payload };
    case types.COMPLETE_ONBOARDING_REQUEST:
      return { ...state, onboardingLoading: true };
    case types.COMPLETE_ONBOARDING_SUCCESS:
      return { ...state, onboardingLoading: false, onboardingCompleted: true };
    case types.COMPLETE_ONBOARDING_FAILURE:
      return { ...state, onboardingLoading: false, onboardingError: action.payload };
    case types.SET_SUPERUSER:
      return { ...state, isSuperuser: action.payload };
    case types.FETCH_USER_ANALYTICS_REQUEST:
      return { ...state, fetchingUserAnalytics: true, userAnalyticsError: null };
    case types.FETCH_USER_ANALYTICS_SUCCESS:
      return { ...state, fetchingUserAnalytics: false, userAnalytics: action.payload };
    case types.FETCH_USER_ANALYTICS_FAILURE:
      return { ...state, fetchingUserAnalytics: false, userAnalyticsError: action.payload };
    case types.SET_USER_EXPERTISE:
      return { ...state, userExpertise: action.payload };
    default:
      return state;
  }
};

export default users;
