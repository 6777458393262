import initialState from './initialState';
import * as types from './actionTypes';

const initialProductsState = {
  ...initialState,
  products: {}
};

const products = (state = initialState, action) => {
  if (typeof action !== 'undefined') {
    switch (action.type) {
      case types.READ_PRODUCTS_IN_CATEGORY_SUCCESS:
        if (JSON.stringify(state.products[action.categoryUuid]) !== JSON.stringify(action.products)) {
          return {
            ...state,
            products: {
              ...state.products,
              [action.categoryUuid]: action.products
            }
          };
        }
        return state;

      case types.READ_PRODUCTS_IN_CATEGORY_IN_PROGRESS:
        if (state.readAllProductsInCategoryInProgress !== action.readAllProductsInCategoryInProgress) {
          return {
            ...state,
            readAllProductsInCategoryInProgress: action.readAllProductsInCategoryInProgress
          };
        }
        return state;

      case types.READ_PRODUCTS_IN_CATEGORY_ERROR:
        if (state.readAllProductsInCategoryError !== action.readAllProductsInCategoryError) {
          return {
            ...state,
            readAllProductsInCategoryError: action.readAllProductsInCategoryError
          };
        }
        return state;

      case types.CREATE_USER_LINK_PRODUCT_ERROR:
        if (state.createUserLinkProductError !== action.createUserLinkProductError) {
          return {
            ...state,
            createUserLinkProductError: action.createUserLinkProductError
          };
        }
        return state;

      case types.UPDATE_USER_LINK_PRODUCT_ERROR:
        if (state.updateUserLinkProductError !== action.updateUserLinkProductError) {
          return {
            ...state,
            updateUserLinkProductError: action.updateUserLinkProductError
          };
        }
        return state;

      case types.DELETE_USER_LINK_PRODUCT_ERROR:
        if (state.deleteUserLinkProductError !== action.deleteUserLinkProductError) {
          return {
            ...state,
            deleteUserLinkProductError: action.deleteUserLinkProductError
          };
        }
        return state;

      case types.READ_CATEGORIES_WITH_PRODUCTS_IN_PROGRESS:
        if (state.readCategoriesWithProductsInProgress !== action.readCategoriesWithProductsInProgress) {
          return {
            ...state,
            readCategoriesWithProductsInProgress: action.readCategoriesWithProductsInProgress
          };
        }
        return state;

      case types.READ_CATEGORIES_WITH_PRODUCTS_SUCCESS:
        if (JSON.stringify(state.categoriesWithProducts) !== JSON.stringify(action.categoriesWithProducts)) {
          return {
            ...state,
            readCategoriesWithProductsSuccess: action.readCategoriesWithProductsSuccess,
            categoriesWithProducts: action.categoriesWithProducts
          };
        }
        return state;

      case types.READ_CATEGORIES_WITH_PRODUCTS_ERROR:
        if (state.readCategoriesWithProductsError !== action.readCategoriesWithProductsError) {
          return {
            ...state,
            readCategoriesWithProductsError: action.readCategoriesWithProductsError
          };
        }
        return state;

      default:
        return state;
    }
  } else {
    return state;
  }
};

export default products;
