import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Form, Input, message } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { sendRegistrationEmail } from '../../redux/authentication/actions';
import '../../../assets/css/components/Modal.css';
import '../../../assets/css/components/Inputs.css';
import '../../../assets/css/components/button.css';
import Ultrasound2 from '../../../assets/images/Ultrasound2.png';


const{Search}=Input;

const FormItem = Form.Item;

const RegistrationForm = ({open, onCancel, onCreate, form, sendRegistrationInProcess}) => {
  return (
    <Modal
      open={open}
      okText='Send'
      onCancel={onCancel}
      footer={[null, null]}
      closable={false}
      centered
    >
      <p className="registerModelHeaderText"> Welcome! </p>
      <img src={Ultrasound2} alt='Woman seeing ultrasound' className="registerModelHeaderImage" />
      <p className="registerModelHeaderSubtext"> It's a beautiful journey. </p>
      <Form ref={form} >
        <FormItem
          name="email"
          rules={[{
            required: true,
            pattern: new RegExp("^.+@[^.].*.[a-z]{2,}$"),
            message: "Please enter a valid email address."
          }]}
          hasFeedback
          className='enterEmailInput'
        >
          <Search
            prefix={<MailOutlined />}
            placeholder='Enter email address'
            enterButton='Submit'
            onSearch={onCreate}
            size='large'
            allowClear
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

class RegisterModal extends Component {
constructor(props){
  super(props);
  this.state = {
    type: props.type
  }
}

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.sendRegistrationSuccess && this.props.sendRegistrationSuccess !== nextProps.sendRegistrationSuccess) {
      message.success('Registration sent. Please check your email.');
      this.form.resetFields();
      this.props.toggleRegistrationModalVisibility();
    }

    if (nextProps.sendRegistrationError && this.props.sendRegistrationError !== nextProps.sendRegistrationError) {
      message.error(nextProps.sendRegistrationError, 10);
    }
  }

  handleCancel = () => {
    this.form.resetFields();
    this.props.toggleRegistrationModalVisibility();
  };

  handleCreate = () => {
    const form = this.form;
    form.validateFields().then(values => {
      this.props.sendRegistrationEmail(values.email.toLowerCase());
    });
  };

  saveFormRef = (form) => {
    this.form = form
  };

  render() {
    return (
      <div>
        <RegistrationForm
          form={this.saveFormRef}
          open={this.props.open}
          onCancel={this.handleCancel}
          onCreate={this.handleCreate}
          sendRegistrationInProcess={this.props.sendRegistrationInProcess}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    sendRegistrationInProcess: state.auth.sendRegistrationInProcess,
    sendRegistrationError: state.auth.sendRegistrationError,
    sendRegistrationSuccess: state.auth.sendRegistrationSuccess
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    sendRegistrationEmail: (email) => dispatch(sendRegistrationEmail(email))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterModal);
