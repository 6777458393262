import * as types from './actionTypes';
import initialState from './initialState';

const milestonesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_MOMENTS:
      return { ...state, moments: action.payload };
    case types.SET_SORTED_MILESTONES:
      return { ...state, sortedMilestones: action.payload };
    case types.SET_GROUPED_TIMELINE_ITEMS:
      return { ...state, groupedTimelineItems: action.payload };
    case types.SET_ACTIVE_CARD_ID:
      return { ...state, activeCardId: action.payload };
    case types.SET_EDITING_MOMENT_ID:
      return { ...state, editingMomentId: action.payload };
    case types.SET_CURRENT_LIFE_STAGE:
      return { ...state, currentLifeStage: action.payload };
    case types.SET_CURRENT_MILESTONE:
      return { ...state, currentMilestone: action.payload };
    case types.SET_NEWLY_COMPLETED_MILESTONE:
      return { ...state, newlyCompletedMilestone: action.payload };
    case types.SET_MOMENTS_WITH_INPUT:
      return { ...state, momentsWithInput: action.payload };
    case types.SET_ADDED_TO_TIMELINE_TEXT:
      return { ...state, addedToTimelineText: action.payload };
    case 'SET_EXPANDED_CARD_ID':
      return {
        ...state,
        expandedCardId: action.payload
    };
    default:
      return state;
  }
};

export default milestonesReducer;
