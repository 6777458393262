import React, { useState } from 'react';
import { Select, Button, Typography } from 'antd';

const { Option } = Select;
const { Title } = Typography;

const expertiseOptions = [
  "Pediatric Physical Therapist",
  "Reproductive Psychiatrist",
  "Prenatal Care Center Nurse",
  "Sleep Expert",
  "Pediatrician",
  "Obstetrician",
  "Midwife",
  "Lactation Consultant",
  "Child Psychologist",
  "Nutritionist",
  "Doula",
  "Neonatal Nurse",
  "Pediatric Occupational Therapist",
  "Family Therapist",
  "Genetic Counselor",
  "Perinatal Mental Health Specialist",
  "Pediatric Dentist",
  "Child Development Specialist",
  "Breastfeeding Specialist",
  "Postpartum Doula",
  "Pediatric Speech Therapist",
  "Prenatal Yoga Instructor",
  "Infant Massage Therapist",
  "Pediatric Dietitian",
  "Child Life Specialist",
  "Pediatric Nurse Practitioner",
  "Maternal-Fetal Medicine Specialist",
  "Early Childhood Educator",
  "Pediatric Behavioral Therapist",
  "Parenting Coach",
  "Other"
];

const ExpertiseSelection = ({ onSelect }) => {
  const [expertise, setExpertise] = useState(null);

  const handleSubmit = () => {
    if (expertise) {
      onSelect(expertise);
    }
  };

  // Custom filter function for the searchable dropdown
  const filterOption = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  return (
    <div className="expertise-selection">
      <Title level={2}>What is your area of expertise?</Title>
      <Select
        showSearch
        style={{ width: '100%', maxWidth: 400 }}
        placeholder="Search for your expertise"
        optionFilterProp="children"
        filterOption={filterOption}
        onChange={(value) => setExpertise(value)}
      >
        {expertiseOptions.map((option, index) => (
          <Option key={index} value={option.toLowerCase().replace(/\s+/g, '_')}>
            {option}
          </Option>
        ))}
      </Select>
      <Button onClick={handleSubmit} style={{ marginTop: 20 }}>Submit</Button>
    </div>
  );
};

export default ExpertiseSelection;
