import axios from 'axios';
import * as types from './actionTypes';
import {loginInProcess, loginError, loginSuccess} from '../authentication/actions';
import { message } from 'antd';
import {isEmpty} from '../../helperFunctions';

export const registerRequest = () => ({ type: types.REGISTER_REQUEST });
export const registerSuccess = () => ({ type: types.REGISTER_SUCCESS });
export const registerFailure = (error) => ({ type: types.REGISTER_FAILURE, payload: error });

export const register = (userData) => async (dispatch) => {
  dispatch(registerRequest());
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/register`, userData);
    dispatch(registerSuccess());
    return response.data;
  } catch (error) {
    dispatch(registerFailure(error.response.data));
    throw error;
  }
};

export const setBabyBirthday = (date) => ({
  type: types.SET_BABY_BIRTHDAY,
  payload: date
});

export const setConfirmedPregnancyDate = (date) => ({
  type: types.SET_CONFIRMED_PREGNANCY_DATE,
  payload: date
});

export const setDueDate = (date) => ({
  type: types.SET_DUE_DATE,
  payload: date
});

export function addUserError(error) {
  return {
    type: types.ADD_USER_ERROR,
    addUserError: error
  }
}

export function addUserInProcess(bool) {
  return {
    type: types.ADD_USER_IN_PROCESS,
    addUserInProcess: bool
  }
}

export function updateUserData(userData) {
  return{
    type: types.UPDATE_USER_DATA,
    userData: userData
  }
}

export function updateUserAttributes(attributes) {
  return {
    type: types.UPDATE_USER_ATTRIBUTES,
    attributes
  }
}

function updateUserError(error) {
  return {
    type: types.UPDATE_USER_ERROR,
    updateUserError: error
  }
}

function updateUserInProgress(bool) {
  return {
    type: types.UPDATE_USER_IN_PROGRESS,
    updateUserInProgress: bool
  }
}

function updateUserSuccess(bool, user) {
  return {
    type: types.UPDATE_USER_SUCCESS,
    updateUserSuccess: bool
  }
}

export const setSuperuser = (isSuperuser) => ({
  type: types.SET_SUPERUSER,
  payload: isSuperuser,
});

export const fetchUserAnalyticsRequest = () => ({
  type: types.FETCH_USER_ANALYTICS_REQUEST
});

export const fetchUserAnalyticsSuccess = (data) => ({
  type: types.FETCH_USER_ANALYTICS_SUCCESS,
  payload: data
});

export const fetchUserAnalyticsFailure = (error) => ({
  type: types.FETCH_USER_ANALYTICS_FAILURE,
  payload: error
});

export const login = (email, password) => async (dispatch) => {
  console.log('Login action started');
  dispatch(loginInProcess(true));
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/authenticate`, { email, password });
    console.log('Authentication response received:', response);

    if (response.data && response.data.jwt) {
      const user = {
        ...response.data.user,
        jwt: response.data.jwt
      };

      sessionStorage.setItem('jwt', response.data.jwt);
      sessionStorage.setItem('email', email);

      dispatch(setSuperuser(email === 'james@frankel.tv'));
      dispatch(loginSuccess(user));

      if (user.time_zone === 'UTC') {
        message.warning("MeSearch works best when you define your default time zone. Default is UTC.", 10);
      }
    } else {
      console.error('No JWT token in response');
      dispatch(loginError('Invalid response from server'));
    }
  } catch (error) {
    console.error('Login error:', error);
    dispatch(loginError(error.response ? error.response.data : 'An error occurred during login'));
  } finally {
    dispatch(loginInProcess(false));
  }
};

export function updateUser(user, values) {
  return (dispatch) => {
    dispatch(updateUserError(''));
    dispatch(updateUserSuccess(false));
    dispatch(updateUserInProgress(true));
    let config = {headers: {Authorization: 'Bearer '+sessionStorage.getItem('jwt')}};
    let url = `${process.env.REACT_APP_API_URL}/users/${user.uuid}`;
    const data = {
      first_name: values.firstName,
      last_name: values.lastName,
      time_zone: values.time_zone
    };

    axios.patch(url, data, config)
      .then((response) => {
        dispatch(updateUserData(response.data.user));
        dispatch(updateUserSuccess(true));
        dispatch(updateUserInProgress(false));
      })
      .catch(error => {
        let errMessage = 'Error updating user';
        if (error.response !== undefined) {
          errMessage = error.response;
          if (typeof error === 'object') {
            if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
              if (typeof error.response.data === 'object') {
                if ('Error' in error.response.data) {
                  errMessage = error.response.data['Error'];
                }
              } else {
                errMessage = error.response.data;
              }
            }
          }
        }
        dispatch(updateUserError(errMessage));
        dispatch(updateUserInProgress(false));
      });
  }
}

export function createMultipleUserAttributes(user, attributes) {
  return (dispatch) => {
    let config = {headers: {Authorization: 'Bearer '+sessionStorage.getItem('jwt')}};
    let url = `${process.env.REACT_APP_API_URL}/users/attributes`;
    let data = {
      attributes: attributes
    }

    axios.post(url, data, config)
      .then((response) => {
        if (!isEmpty(response.data)) {
          dispatch(updateUserAttributes(response.data));
        }
      })
      .catch(error => {
        let errMessage = 'Error updating user';
        if (error.response !== undefined) {
          errMessage = error.response;
          if (typeof error === 'object') {
            if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
              if (typeof error.response.data === 'object') {
                if ('Error' in error.response.data) {
                  errMessage = error.response.data['Error'];
                }
              } else {
                errMessage = error.response.data;
              }
            }
          }
        }
        dispatch(updateUserError(errMessage));
      });
  }
}

export const updateUserChecklist = (addedItems, removedItems) => ({
  type: types.UPDATE_USER_CHECKLIST,
  payload: { addedItems, removedItems }
});

export const setUserType = (userType) => ({
  type: types.SET_USER_TYPE,
  payload: userType,
});

export const setPregnancyStatus = (status) => ({
  type: types.SET_PREGNANCY_STATUS,
  payload: status,
});

export const setBabyName = (name) => ({
  type: types.SET_BABY_NAME,
  payload: name,
});

export const setPregnancyPhoto = (photo) => ({
  type: types.SET_PREGNANCY_PHOTO,
  payload: photo,
});

export const setPrivacyLevel = (level) => ({
  type: types.SET_PRIVACY_LEVEL,
  payload: level,
});

export const setChildrenCount = (count) => ({
  type: types.SET_CHILDREN_COUNT,
  payload: count,
});

export const setPartnerStatus = (status) => ({
  type: types.SET_PARTNER_STATUS,
  payload: status,
});

export const setPartnerEmail = (email) => ({
  type: types.SET_PARTNER_EMAIL,
  payload: email,
});

export const setInviteFriends = (invite) => ({
  type: types.SET_INVITE_FRIENDS,
  payload: invite,
});

export const completeOnboardingRequest = () => ({
  type: types.COMPLETE_ONBOARDING_REQUEST,
});

export const completeOnboardingSuccess = () => ({
  type: types.COMPLETE_ONBOARDING_SUCCESS,
});

export const completeOnboardingFailure = (error) => ({
  type: types.COMPLETE_ONBOARDING_FAILURE,
  payload: error,
});

export const completeOnboardingAction = (userData) => async (dispatch) => {
  dispatch(completeOnboardingRequest());
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/users/onboarding`, userData);
    dispatch(completeOnboardingSuccess());
    return response.data;
  } catch (error) {
    dispatch(completeOnboardingFailure(error.message));
    throw error;
  }
};

export const sendInvitesAction = (invites) => async (dispatch) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/invites`, invites);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchUserAnalytics = () => async (dispatch) => {
  dispatch({ type: types.FETCH_USER_ANALYTICS_REQUEST });
  try {
    const token = sessionStorage.getItem('jwt');
    if (!token) {
      throw new Error('No JWT token found in sessionStorage');
    }
    console.log('API URL:', `${process.env.REACT_APP_API_URL}/users/analytics`);
    console.log('JWT Token:', token);

    const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/analytics`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });

    console.log('User analytics response:', response.data);
    dispatch({ type: types.FETCH_USER_ANALYTICS_SUCCESS, payload: response.data });
  } catch (error) {
    console.error('Error fetching user analytics:', error);
    let errorMessage = 'An unknown error occurred';
    if (error.response) {
      console.error('Error response:', error.response.data);
      console.error('Error status:', error.response.status);
      console.error('Error headers:', error.response.headers);
      errorMessage = `Server responded with ${error.response.status}: ${error.response.data.message || error.response.statusText}`;
    } else if (error.request) {
      console.error('Error request:', error.request);
      errorMessage = 'No response received from server';
    } else {
      console.error('Error message:', error.message);
      errorMessage = error.message;
    }
    dispatch({ type: types.FETCH_USER_ANALYTICS_FAILURE, payload: errorMessage });
  }
};

export const setUserExpertise = (expertise) => ({
  type: types.SET_USER_EXPERTISE,
  payload: expertise,
});
