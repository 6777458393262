import React, { useState, useEffect, useCallback, useMemo } from "react";
import { connect } from "react-redux";
import { createSelector } from 'reselect';
import {
  HashRouter as Router,
  Route,
  Redirect,
  Switch,
  NavLink,
  Link
} from "react-router-dom";
import { Layout, Switch as AntSwitch, Button } from "antd";
import { MenuOutlined, ShareAltOutlined, TeamOutlined } from '@ant-design/icons';
import SideMenu from "../components/navigation/SideMenu";
import DueDate from "../views/Onboarding";
import NewCategories from "../components/checklist/NewCategories";
import NewProducts from "../views/NewProducts";
import ChecklistsView from "../views/ChecklistsView";
import Friends from "../views/Friends";
import Notepad from "../views/NotePad";
import Dashboard from "../views/Dashboard";
import Home from "../views/Home";
import Moments from "../views/Moments";
import AnalyticsView from "../views/AnalyticsView";
import { setUserType } from "../redux/users/actions";
import "../../assets/css/components/button.css";
import "../../assets/css/components/header.css";
import Logo from "../../assets/images/logo";

const { Header, Sider, Content } = Layout;
const ACTIVE_COLOR = '#8A2BE2';

const activeStyle = {
  fontWeight: 'bold',
  color: ACTIVE_COLOR,
  position: 'relative',
  textDecoration: 'none',
};

export const userTypes = {
  personal: { name: "Personal", color: "#FFB3BA" },
  expert: { name: "Expert", color: "#FFFFBA" }
};

const CribIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 30H35M5 30V20H35V30M5 30L10 35M35 30L30 35M10 20V10H30V20M15 10V20M25 10V20" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const AppRouter = (props) => {
  const [collapsed, setCollapsed] = useState(true);
  const [activeTab, setActiveTab] = useState('timeline');
  // const [isExpertMode, setIsExpertMode] = useState(false);
  // const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleCollapsed = useCallback(() => {
    setCollapsed(prev => !prev);
  }, []);

  const scrollToTimeline = useCallback(() => {
  }, []);

  const scrollToChecklist = useCallback(() => {
  }, []);

  // const handleUserTypeChange = useCallback((checked) => {
  //   setIsExpertMode(checked);
  //   props.setUserType(checked ? 'expert' : 'personal');
  // }, [props]);

  // const toggleMenu = useCallback(() => {
  //   setIsMenuOpen(prev => !prev);
  // }, []);

  // useEffect(() => {
  //   if (props.currentUserType && userTypes[props.currentUserType]) {
  //     document.documentElement.style.setProperty('--user-type-color', userTypes[props.currentUserType].color);
  //   } else {
  //     document.documentElement.style.setProperty('--user-type-color', userTypes.personal.color);
  //   }
  // }, [props.currentUserType]);

  const renderMoments = useCallback((routeProps) => {
    return (
      <Moments
        {...routeProps}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        scrollToTimeline={scrollToTimeline}
        scrollToChecklist={scrollToChecklist}
        userType={props.currentUserType || 'personal'}
      />
    );
  }, [activeTab, setActiveTab, scrollToTimeline, scrollToChecklist, props.currentUserType]);

  const navLinks = useMemo(() => [
    { to: "/Home", label: "Home" },
    // { to: "/Moments", label: "Checklist" },
    // { to: "/products?favorited=false", label: "Products", isActive: (match, location) => location.pathname.startsWith('/products') },
    { to: "/friends", label: "Share" },
    // { to: "/analytics", label: "Analytics" }
  ], []);

  return (
    <Router>
      <Layout>
        <Header className="headerContainer">
          <div className="headerContent" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', padding: 10 }}>
            {/*<CribIcon />*/}
            <Link className="headerLogo" to="/home" style={{ marginLeft: '10px' }}>
              <Logo/>
            </Link>
            <Link to="/friends" style={{ position: 'absolute', right: '10px' }}>
              <TeamOutlined className="team-icon" style={{color: 'gray', fontSize: 24}}/>
            </Link>
            <div className="header-right">
              {/* <Button icon={<MenuOutlined />} onClick={toggleMenu} className="menu-toggle" /> */}
            </div>
          </div>
          {/* {isMenuOpen && (
            <div className="menu-overlay">
              <div className="user-type-toggle">
                <span>Personal</span>
                <AntSwitch
                  checked={isExpertMode}
                  onChange={handleUserTypeChange}
                />
                <span>Expert</span>
              </div>
              {!isExpertMode && (
                <nav className="headerMenu">
                  {navLinks.map(({ to, label, isActive }) => (
                    <NavLink
                      key={to}
                      className="menuItem"
                      to={to}
                      activeStyle={activeStyle}
                      isActive={isActive}
                    >
                      {label}
                    </NavLink>
                  ))}
                </nav>
              )}
            </div>
          )} */}
        </Header>
        <Layout>
          {/* {!isExpertMode && ( */}
            <Content className="mainContent">
              <Switch>
                <Route exact path="/">
                  {props.user ? <Redirect to="/home" /> : <Redirect to="/login" />}
                </Route>
                <Route exact path="/login">
                  {props.user ? <Redirect to="/home" /> : <div>Login component here</div>}
                </Route>
                {/* {props.isSuperuser && (
                  <Route exact path="/analytics" component={AnalyticsView} />
                )} */}
                {/* <Route exact path="/analytics" component={AnalyticsView} /> */}
                <Route exact path="/dashboard" component={Dashboard} />
                <Route exact path="/Notepad" component={Notepad} />
                <Route exact path="/onboarding" component={DueDate} />
                <Route exact path="/categories" component={NewCategories} />
                {/* <Route exact path="/products" component={NewProducts} /> */}
                <Route exact path="/friends" component={Friends} />
                <Route exact path="/home" component={Home} />
                {/* <Route exact path="/moments" render={renderMoments} /> */}
                {/* <Route exact path="/checklists" component={ChecklistsView} />
                <Route exact path="/checklists/:topicId" component={ChecklistsView} /> */}
                <Route path="*" component={() => <div>Page not found</div>} />
              </Switch>
            </Content>
          {/* )} */}
          {/* {isExpertMode && (
            <div className="expert-mode-content">
              <h1>Expert Mode</h1>
              <p>Welcome to Expert Mode. This area is under development.</p>
            </div>
          )} */}
          <Sider
            collapsed={collapsed}
            collapsedWidth={0}
            className="sider"
          >
            <SideMenu user={props.user} />
          </Sider>
        </Layout>
      </Layout>
      <style jsx>{`
        .team-icon {
          transition: color 0.3s ease;
        }
        .team-icon:hover {
          color: #7E3FFF !important;
        }
      `}</style>
    </Router>
  );
};

const getUserState = state => state.auth.user;
const getIsSuperuserState = state => state.users.isSuperuser;
const getCurrentUserTypeState = state => state.users.currentUserType;

const getAppRouterProps = createSelector(
  [getUserState, getIsSuperuserState, getCurrentUserTypeState],
  (user, isSuperuser, currentUserType) => ({
    user,
    isSuperuser,
    currentUserType
  })
);

const mapStateToProps = (state) => {
  const props = getAppRouterProps(state);
  return props;
};

const mapDispatchToProps = {
  setUserType,
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(AppRouter));
