const state = {
  friends: null,
  requestsReceived: null,
  requestsSent: null,
  addFriendError: null,
  addFriendSuccess: false,
  addFriendInProgress: false,
  removeFriendError: null,
  removeFriendSuccess: false,
  removeFriendInProgress: false,
  readAllFriendsError: null,
  readReceivedFriendRequestsError: null,
  readSentFriendRequestsError: null,
  inviteFriendError: null,
  inviteFriendSuccess: null,
  rejectEmailInProcess: false,
  rejectEmailError: "",
  selectedFriends: [],
  friendsNotes: {},
  friendsNotesError: null,
  friendLists: [],
  loading: false,
  error: null,
  selectedFriendUuid: null,
};

export default state;
