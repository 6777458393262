import React, { useRef, useEffect } from 'react';
import { Card, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import CheckboxWithSound from './CheckboxWithSound';
import ExpandedChecklistItem from './ExpandedChecklistItem';
import ErrorBoundary from './ErrorBoundary';
import "../../../assets/css/components/checklistItem.css";

const Checklist = ({
  groupedChecklist = {},
  removedTasks,
  expandedCardId,
  handleMilestoneComplete,
  removeTask,
  restoreTask,
  toggleCardExpansion,
  handleNotesChange,
  updateDueDate,
  getFlexibilityExplanation,
  handlePrivacyChange,
  isLoadingSharedNotes,
  addedToTimelineText,
  scrollToCard,
  getTaskTypeInfo,
  canEdit,
  currentFriendUuid,
  selectedUser,
  isInviteExpanded,
}) => {
  const textAreaRefs = useRef({});

  useEffect(() => {
    console.log('Checklist: selectedUser changed', selectedUser);
  }, [selectedUser]);

  const textAreaRef = (el, taskId) => {
    if (el) {
      textAreaRefs.current[taskId] = el;
    }
  };

  const getColorClass = () => {
    console.log('getColorClass called, selectedUser:', selectedUser);
    if (!selectedUser || selectedUser.type === 'personal' || selectedUser.type === 'myList') {
      console.log('Returning personal class');
      return 'personal';
    } else if (selectedUser.type === 'friend') {
      console.log('Returning friend class');
      return 'friend';
    } else if (selectedUser.type === 'expert') {
      console.log('Returning expert class');
      return 'expert';
    }
    console.log('Returning default personal class');
    return 'personal'; // default case
  };

  const colorClass = getColorClass();
  console.log('Rendering Checklist with colorClass:', colorClass);

  const renderCard = (task) => {
    // const { icon, color } = getTaskTypeInfo(task.type);
    getTaskTypeInfo(task.type);
    return (
      <Card
        key={task.id}
        id={`card-${task.id}`}
        className={`analyticsCard moment-card task task-${task.type} ${expandedCardId === task.id ? 'expanded' : ''} ${colorClass}`}
        onClick={(e) => toggleCardExpansion(task.id, e)}
        title={
          <div className="card-title-container">
            <CheckboxWithSound
              checked={task.achieved}
              onChange={(e) => {
                e.stopPropagation();
                if (canEdit) handleMilestoneComplete(task, e);
              }}
              onClick={(e) => e.stopPropagation()}
              className="checklist-checkbox"
              soundUrl="../../assets/sounds/successSound.mp3"
              aria-label={`Mark task "${task.text}" as complete`}
              disabled={!canEdit || !!currentFriendUuid}
            />
            <div className="checklist-item-title">{task.text}</div>
            {canEdit && !currentFriendUuid && (
              <Button
                type="text"
                icon={<CloseOutlined />}
                onClick={(e) => {
                  e.stopPropagation();
                  removeTask(task.id);
                }}
                className="remove-task-button"
                aria-label={`Remove task "${task.text}"`}
              />
            )}
          </div>
        }
      >
      {expandedCardId === task.id && (
          <ExpandedChecklistItem
            task={task}
            onNotesChange={(newNote) => handleNotesChange(newNote, task.id)}
            onDateChange={(date) => updateDueDate(date, task.id)}
            onCollapse={() => toggleCardExpansion(task.id)}
            onPrivacyChange={(value) => handlePrivacyChange(task.id, value)}
            isLoadingSharedNotes={isLoadingSharedNotes[task.id]}
            sharedNotes={task.sharedNotes}
            getFlexibilityExplanation={getFlexibilityExplanation}
            canEdit={canEdit}
            currentFriendUuid={currentFriendUuid}
            textAreaRef={(el) => textAreaRef(el, task.id)}
          />
        )}
      </Card>
    );
  };

  return (
    <ErrorBoundary>
      <div className={`checklist-container ${isInviteExpanded ? 'blurred' : ''}`}>
        {Object.entries(groupedChecklist).map(([group, tasks]) => (
          <div key={group}>
            <div className="sticky-header">{group}</div>
            {group === 'This week' && tasks.length === 0 ? (
              <div className="no-tasks-message">
                No tasks due this week. Keep up the great work!
              </div>
            ) : (
              tasks.map(task => (
                <React.Fragment key={task.id}>
                  {renderCard(task)}
                  {addedToTimelineText[task.id] && (
                    <div className="added-to-timeline">
                      Added to <span onClick={() => scrollToCard(task.id)} className="timeline-link">Timeline!</span>
                    </div>
                  )}
                </React.Fragment>
              ))
            )}
          </div>
        ))}
        {removedTasks.length > 0 && !currentFriendUuid && (
          <div className="removed-tasks">
            <h3>Removed Tasks</h3>
            {removedTasks.map(task => (
              <div key={task.id} className="removed-task">
                {task.text}
                <Button onClick={() => restoreTask(task.id)}>Restore</Button>
              </div>
            ))}
          </div>
        )}
      </div>
    </ErrorBoundary>
  );
};

export default React.memo(Checklist);
