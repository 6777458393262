import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Space } from 'antd';

class ProductsCategoriesToggle extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      checked: props.location.pathname === "/products" ? true : false
    }
  }

  onChange = (checked) => {
    if (checked) {
      this.props.history.push("/products" + this.props.location.search);
    } else {
      this.props.history.push("/categories" + this.props.location.search);
    }
    this.setState({ checked: checked });
  };

  render() {
    return (
      <Space direction="vertical">
        <Switch checkedChildren="Products" unCheckedChildren="Categories" onChange={this.onChange} defaultChecked={this.state.checked} checked={this.state.checked} />
      </Space>
    );
  }
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps) (ProductsCategoriesToggle);
