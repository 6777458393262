import initialState from './initialState';
import * as types from './actionTypes';

const notes = (state = initialState, action) => {
  if (typeof action === 'undefined') {
    return state;
  }

  switch (action.type) {
    case types.READ_USER_NOTES_SUCCESS:
      return {
        ...state,
        userNotes: action.userNotes
      };
    case types.READ_FRIEND_NOTES_SUCCESS:
      return {
        ...state,
        friendNotes: action.friendNotes
      };
    case types.READ_FRIEND_PRODUCT_NOTES_SUCCESS:
      return {
        ...state,
        friendProductNotes: action.friendProductNotes
      };
    case types.READ_FRIEND_CATEGORY_NOTES_SUCCESS:
      return {
        ...state,
        friendCategoryNotes: action.friendCategoryNotes
      };
    case types.READ_PRODUCT_NOTES_SUCCESS:
      return {
        ...state,
        productNotes: action.productNotes
      };
    case types.READ_CATEGORY_NOTES_SUCCESS:
      return {
        ...state,
        categoryNotes: action.categoryNotes
      };
    case types.READ_LIST_NOTES_SUCCESS:
      return {
        ...state,
        listNotes: action.listNotes
      };
    case types.READ_NOTES_ERROR:
      return {
        ...state,
        readNotesError: action.readNotesError
      };
    case types.SAVE_NOTES_ERROR:
      return {
        ...state,
        saveNoteError: action.errMessage
      };
    case types.SAVE_NOTES_SUCCESS:
      return {
        ...state,
        saveNoteSuccess: action.bool
      };
    case types.SAVE_NOTES_IN_PROGRESS:
      return {
        ...state,
        saveNoteInProgress: action.bool
      };
    case types.SET_NOTES_ENTERED:
      return {
        ...state,
        notesEntered: action.payload
      };
    case types.SET_SAVED_MOMENTS:
      return {
        ...state,
        savedMoments: action.payload
      };
    case types.FETCH_FRIENDS_NOTES_SUCCESS:
      return {
        ...state,
        friendsNotes: action.notes,
        friendsNotesError: null
      };

    case types.FETCH_FRIENDS_NOTES_ERROR:
      return {
        ...state,
        friendsNotesError: action.error
      };

    default:
      return state;
  }
};

export default notes;
