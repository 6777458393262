export const UPDATE_USER_IN_PROGRESS = 'UPDATE_USER_IN_PROGRESS';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const ADD_USER_ERROR = 'ADD_USER_ERROR';
export const ADD_USER_IN_PROCESS = 'ADD_USER_IN_PROCESS';
export const UPDATE_USER_CAMERA_LICENSE_DATA = 'UPDATE_USER_CAMERA_LICENSE_DATA';
export const UPDATE_USER_ATTRIBUTES = 'UPDATE_USER_ATTRIBUTES';
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';
export const SET_BABY_BIRTHDAY = 'SET_BABY_BIRTHDAY';
export const SET_CONFIRMED_PREGNANCY_DATE = 'SET_CONFIRMED_PREGNANCY_DATE';
export const SET_DUE_DATE = 'SET_DUE_DATE';
export const UPDATE_USER_CHECKLIST = 'UPDATE_USER_CHECKLIST';
export const SET_USER_TYPE = 'SET_USER_TYPE';
export const SET_PREGNANCY_STATUS = 'SET_PREGNANCY_STATUS';
export const SET_BABY_NAME = 'SET_BABY_NAME';
export const SET_PREGNANCY_PHOTO = 'SET_PREGNANCY_PHOTO';
export const SET_PRIVACY_LEVEL = 'SET_PRIVACY_LEVEL';
export const SET_CHILDREN_COUNT = 'SET_CHILDREN_COUNT';
export const SET_PARTNER_STATUS = 'SET_PARTNER_STATUS';
export const SET_PARTNER_EMAIL = 'SET_PARTNER_EMAIL';
export const SET_INVITE_FRIENDS = 'SET_INVITE_FRIENDS';
export const COMPLETE_ONBOARDING_REQUEST = 'COMPLETE_ONBOARDING_REQUEST';
export const COMPLETE_ONBOARDING_SUCCESS = 'COMPLETE_ONBOARDING_SUCCESS';
export const COMPLETE_ONBOARDING_FAILURE = 'COMPLETE_ONBOARDING_FAILURE';
export const SET_SUPERUSER = 'SET_SUPERUSER';
export const FETCH_USER_ANALYTICS_REQUEST = 'FETCH_USER_ANALYTICS_REQUEST';
export const FETCH_USER_ANALYTICS_SUCCESS = 'FETCH_USER_ANALYTICS_SUCCESS';
export const FETCH_USER_ANALYTICS_FAILURE = 'FETCH_USER_ANALYTICS_FAILURE';
export const SET_USER_EXPERTISE = 'SET_USER_EXPERTISE';
