import React from 'react';
import { Card } from 'antd';
import '../../../assets/css/components/ClickableCard.css';

const ClickableCard = ({ children, selected, onClick, className }) => (
  <Card
    className={`clickable-card ${selected ? 'selected' : ''} ${className}`}
    onClick={onClick}
  >
    <div className="clickable-card-content">
      {children}
    </div>
  </Card>
);

export default ClickableCard;
