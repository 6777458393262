import React, { useEffect, useCallback, useMemo, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card } from 'antd';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { readAllCategoriesInAllTopics, readAllTopics } from '../redux/topics/actions';
import {
  readUserSelectedCategories,
  toggleCategoryCheck,
  loadSavedCategoryStates,
  saveCategoryState,
  saveProductState
} from '../redux/categories/actions';
import ChecklistItemNew from './ChecklistItemNew';
import CustomCheckmark from './CustomCheckmark';

const isDev = process.env.NODE_ENV === 'development';
const log = (...args) => isDev && console.log(...args);

const ChecklistsView = ({
  topicId,
  isEmbedded,
  onNavigate,
  updateContainerHeight,
  onCategoryExpand,
  expandedCategoryId
}) => {
  log('ChecklistsView rendering with topicId:', topicId, 'isEmbedded:', isEmbedded);

  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const cardRef = useRef(null);
  const cardBodyRef = useRef(null);

  const allTopics = useSelector(state => state.topics.allCategoriesInTopics || []);
  const checkedCategories = useSelector(state => state.categories.checkedCategories || {});
  const selectedProducts = useSelector(state => state.categories.selectedProducts || {});

  const [reorderedCategories, setReorderedCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedProductNames, setSelectedProductNames] = useState({});
  const [itemPositions, setItemPositions] = useState({});

  const currentTopic = useMemo(() => {
    log('Calculating currentTopic for topicId:', topicId);
    const topic = allTopics.find(topic => topic.id === parseInt(topicId)) || null;
    log('currentTopic:', topic);
    return topic;
  }, [allTopics, topicId]);

  useEffect(() => {
    log('ChecklistsView initial useEffect');
    setIsLoading(true);
    Promise.all([
      dispatch(readAllTopics()),
      dispatch(readAllCategoriesInAllTopics()),
      dispatch(readUserSelectedCategories()),
      dispatch(loadSavedCategoryStates())
    ]).then(() => {
      log('All data loaded');
      setIsLoading(false);
    });
  }, [dispatch]);

  useEffect(() => {
    log('ChecklistsView useEffect for currentTopic', currentTopic);
    if (currentTopic && currentTopic.categories) {
      log('Setting reorderedCategories:', currentTopic.categories);
      setReorderedCategories(currentTopic.categories);
      setItemPositions({});
    }
  }, [currentTopic]);

  useEffect(() => {
    if (containerRef.current) {
      updateContainerHeight(containerRef.current.scrollHeight);
    }
  }, [reorderedCategories, expandedCategoryId, updateContainerHeight]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target === containerRef.current) {
          updateContainerHeight(entry.contentRect.height);
        }
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, [updateContainerHeight]);

  const updateItemPosition = useCallback((categoryId) => {
    requestAnimationFrame(() => {
      const element = document.getElementById(`category-content-${categoryId}`);
      if (element) {
        const rect = element.getBoundingClientRect();
        const cardBody = cardBodyRef.current;
        const relativeTop = cardBody ? rect.top - cardBody.getBoundingClientRect().top : rect.top;
        setItemPositions(prev => {
          const newPositions = {...prev, [categoryId]: relativeTop};
          log(`Updated position for ${categoryId}:`, newPositions[categoryId]);
          return newPositions;
        });
      }
    });
  }, []);

  const handleCategoryToggle = useCallback((categoryId) => {
    log('Toggling category:', categoryId);
    const newCheckedState = !checkedCategories[categoryId];
    dispatch(toggleCategoryCheck(categoryId));
    dispatch(saveCategoryState(categoryId, newCheckedState));

    setReorderedCategories(prev => {
      log('Reordering categories');
      const newOrder = [...prev];
      const index = newOrder.findIndex(cat => cat.uuid === categoryId);
      if (index !== -1) {
        const [item] = newOrder.splice(index, 1);
        if (newCheckedState) {
          const originalIndex = currentTopic.categories.findIndex(cat => cat.uuid === categoryId);
          newOrder.splice(originalIndex, 0, item);
        } else {
          newOrder.push(item);
        }
      }
      log('New order:', newOrder.map(cat => cat.uuid));
      return newOrder;
    });

    if (newCheckedState && !selectedProducts[categoryId]) {
      log('Expanding category:', categoryId);
      onCategoryExpand(categoryId);
    } else {
      onCategoryExpand(null);
    }

    updateItemPosition(categoryId);

    if (!newCheckedState) {
      log('Clearing product state for category:', categoryId);
      dispatch(saveProductState(categoryId, null));
      setSelectedProductNames(prev => {
        const newState = { ...prev };
        delete newState[categoryId];
        return newState;
      });
    }
  }, [dispatch, checkedCategories, currentTopic, selectedProducts, updateItemPosition, onCategoryExpand]);

  const handleProductSelect = useCallback((categoryId, productId, productName) => {
    log('Selecting product:', { categoryId, productId, productName });
    dispatch(saveProductState(categoryId, productId));
    dispatch(toggleCategoryCheck(categoryId, true));
    dispatch(saveCategoryState(categoryId, true));

    setSelectedProductNames(prev => ({...prev, [categoryId]: productName}));
    setReorderedCategories(prev => {
      const newOrder = [...prev];
      const index = newOrder.findIndex(cat => cat.uuid === categoryId);
      if (index !== -1) {
        const [item] = newOrder.splice(index, 1);
        newOrder.push(item);
      }
      return newOrder;
    });

    onCategoryExpand(null);
    updateItemPosition(categoryId);
  }, [dispatch, updateItemPosition, onCategoryExpand]);

  const handleNoThanks = useCallback((categoryId) => {
    log('No thanks for category:', categoryId);
    dispatch(saveProductState(categoryId, 'none'));
    dispatch(toggleCategoryCheck(categoryId, true));
    dispatch(saveCategoryState(categoryId, true));

    setSelectedProductNames(prev => ({...prev, [categoryId]: 'Select a product'}));
    setReorderedCategories(prev => {
      const newOrder = [...prev];
      const index = newOrder.findIndex(cat => cat.uuid === categoryId);
      if (index !== -1) {
        const [item] = newOrder.splice(index, 1);
        newOrder.push(item);
      }
      return newOrder;
    });

    onCategoryExpand(null);
    updateItemPosition(categoryId);
  }, [dispatch, updateItemPosition, onCategoryExpand]);

  const renderCategory = useCallback((category, index) => {
    log(`Rendering category ${category.name}, index: ${index}`);
    const isChecked = checkedCategories[category.uuid] || false;
    const isExpanded = expandedCategoryId === category.uuid;
    const selectedProductId = selectedProducts[category.uuid];
    const selectedProductName = selectedProductNames[category.uuid] || '';

    return (
      <div
        key={category.uuid}
        id={`category-content-${category.uuid}`}
        style={{
          transition: 'all 0.5s ease',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '0.5rem 25px',
            border: '1px solid #e5e7eb',
            cursor: 'pointer',
            backgroundColor: 'white',
          }}
          onClick={() => onCategoryExpand(category.uuid)}
        >
          <div style={{ opacity: isChecked ? 0.6 : 1, display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <CustomCheckmark
              key={`checkmark-${category.uuid}`}
              id={category.uuid}
              checked={isChecked}
              onChange={(e) => {
                e.stopPropagation();
                handleCategoryToggle(category.uuid);
              }}
            />
            <span style={{
              marginLeft: '0.5rem',
              flexGrow: 1,
              textAlign: 'left',
              color: isChecked ? '#9CA3AF' : 'inherit',
              transition: 'all 0.3s ease',
            }}>
              {category.name}
            </span>
          </div>
          {selectedProductName && (
            <div style={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '0.875rem',
              color: selectedProductName === 'Select a product' ? 'blue' : '#6B7280',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '200px',
              transition: 'color 0.3s ease',
            }}>
              <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{selectedProductName}</span>
            </div>
          )}
        </div>

        {isExpanded && (
          <div style={{
            padding: '0.5rem 25px',
            backgroundColor: 'white',
            border: '1px solid #e5e7eb',
            borderTop: 'none',
            borderBottomLeftRadius: '4px',
            borderBottomRightRadius: '4px',
          }}>
            <ChecklistItemNew
              categoryId={category.uuid}
              categoryName={category.name}
              isExpanded={true}
              onToggle={() => {}}
              checked={isChecked}
              onProductSelect={handleProductSelect}
              onNoThanks={handleNoThanks}
              selectedProduct={selectedProductId}
              updateContainerHeight={updateContainerHeight}
            />
          </div>
        )}
      </div>
    );
  }, [checkedCategories, expandedCategoryId, selectedProducts, selectedProductNames, onCategoryExpand, handleCategoryToggle, handleProductSelect, handleNoThanks, updateContainerHeight]);

  if (isLoading) {
    log('Rendering loading state');
    return <div>Loading...</div>;
  }

  if (!currentTopic) {
    log('No current topic');
    return <div>No topic selected</div>;
  }

  log('Rendering checklist for topic:', currentTopic.name);
  return (
    <div ref={containerRef} style={{
      backgroundColor: 'white',
      color: '#4b5563',
      height: 'auto',
      fontFamily: 'sans-serif',
      fontWeight: 300,
      letterSpacing: '0.025em',
      marginTop: isEmbedded ? 0 : 125,
      borderRadius: 20
    }}>
      <main style={{
        maxWidth: '1200px',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        gap: '1.5rem',
        borderRadius: 20
      }}>
        <Card
          ref={cardRef}
          bodyStyle={{ padding: '1rem', overflow: 'visible' }}
        >
          <div ref={cardBodyRef}>
            {reorderedCategories.map(renderCategory)}
          </div>
        </Card>
      </main>
    </div>
  );
};

export default ChecklistsView;
