import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Button, Carousel } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const CarouselContainer = forwardRef(({ items, renderItem, onAfterChange, className }, ref) => {
  console.log('CarouselContainer render start. Props:', { items, className });

  const carouselRef = useRef(null);

  useImperativeHandle(ref, () => ({
    goTo: (index) => {
      console.log('CarouselContainer goTo called with index:', index);
      carouselRef.current.goTo(index);
    }
  }));

  const handlePrev = (e) => {
    console.log('CarouselContainer handlePrev called');
    e.stopPropagation();
    carouselRef.current.prev();
  };

  const handleNext = (e) => {
    console.log('CarouselContainer handleNext called');
    e.stopPropagation();
    carouselRef.current.next();
  };

  console.log('CarouselContainer rendering items:', items);

  return (
    <div className={`carousel-container ${className}`}>
      <Button
        icon={<LeftOutlined />}
        onClick={handlePrev}
        className="carousel-arrow prev"
      />
      <Carousel
        ref={carouselRef}
        dots={false}
        afterChange={(current) => {
          console.log('Carousel afterChange:', current);
          if (onAfterChange) onAfterChange(current);
        }}
      >
        {items.map((item, index) => {
          console.log(`Rendering carousel item ${index}:`, item);
          return (
            <div key={index} className="carousel-slide">
              {renderItem(item, index)}
            </div>
          );
        })}
      </Carousel>
      <Button
        icon={<RightOutlined />}
        onClick={handleNext}
        className="carousel-arrow next"
      />
    </div>
  );
});

export default CarouselContainer;
