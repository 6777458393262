import React, { useState, useEffect, useRef } from 'react';
import { Button, Radio, Typography, Carousel, message, Row, Col } from 'antd';
import { PlusCircleFilled, MinusCircleFilled, CheckOutlined, LeftOutlined, RightOutlined, FileTextOutlined } from '@ant-design/icons';
import quizQuestions from "../../../assets/data/quizQuestions";
import perksData from "../../../assets/data/perksData";
import "../../../assets/css/components/CustomizeQuiz.css";

const { Paragraph } = Typography;

const RecommendationItem = ({ rec, onToggle, isInList, isCompleted, hasPerk }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    if (rec.item.images && rec.item.images.length > 1) {
      const interval = setInterval(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % rec.item.images.length);
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [rec.item.images]);

  const renderImage = () => {
    const images = rec.item.images;
    return (rec.item.category === 'product' && images && images.length > 0)
      ? <img src={images[currentImageIndex]} alt={rec.item.name} />
      : null;
  };

  return (
    <div className={`recommendation-card ${isInList ? 'in-list' : 'removed'} ${isCompleted ? 'completed' : ''}`}>
      {isInList && (
        <div className="giant-checkmark">
          <CheckOutlined />
        </div>
      )}
      {renderImage()}
      {hasPerk && <div className="perk-tag">Custom discount!</div>}
      <h3>{rec.item.name || rec.item.text}</h3>
      <div className="checklist-item-name">{rec.item.title}</div>
      <p>{rec.item.details || rec.item.description}</p>
      {isCompleted ? (
        <CheckOutlined className="completed-icon" />
      ) : (
        <Button
          icon={isInList ? <MinusCircleFilled style={{ color: 'red' }} /> : <PlusCircleFilled style={{ color: 'green' }} />}
          type="text"
          className={isInList ? 'remove-button' : 'add-button'}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onToggle(rec);
          }}
        >
          {isInList ? 'Remove from list' : 'Add to list'}
        </Button>
      )}
    </div>
  );
};

const CustomizeQuiz = ({ currentTab, onComplete, initialChecklist, updateChecklist, showMyPerks }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [currentAnswer, setCurrentAnswer] = useState(null);
  const [recommendations, setRecommendations] = useState([]);
  const [showRecommendations, setShowRecommendations] = useState(false);
  const [localChecklist, setLocalChecklist] = useState([]);
  const [quizSummary, setQuizSummary] = useState([]);
  const [showSummary, setShowSummary] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const carouselRef = useRef(null);

  useEffect(() => {
    if (Array.isArray(initialChecklist)) {
      setLocalChecklist(initialChecklist);
    } else {
      console.warn('initialChecklist is not an array:', initialChecklist);
      setLocalChecklist([]);
    }
  }, [initialChecklist]);

  useEffect(() => {
    const filtered = quizQuestions.filter(q => q.type.toLowerCase() === currentTab.toLowerCase() || q.type.toLowerCase() === 'both');
    setFilteredQuestions(filtered);
    setCurrentQuestionIndex(0);
    setShowRecommendations(false);
    setCurrentAnswer(null);
    setRecommendations([]);
  }, [currentTab]);

  const currentQuestion = filteredQuestions[currentQuestionIndex];

  const handleAnswer = (e) => {
    setCurrentAnswer(e.target.value);
  };

  const generateRecommendations = () => {
    if (!currentAnswer || !currentQuestion) {
      console.log('Cannot generate recommendations: currentAnswer or currentQuestion is missing');
      return;
    }

    const impact = currentQuestion.impact[currentAnswer];

    let newRecommendations = [];
    if (impact.addedItems) {
      newRecommendations = newRecommendations.concat(impact.addedItems.map(item => ({ type: 'add', item, question: currentQuestion.question, answer: currentAnswer })));
    }
    if (impact.removedItems) {
      newRecommendations = newRecommendations.concat(impact.removedItems.map(item => ({ type: 'remove', item, question: currentQuestion.question, answer: currentAnswer })));
    }

    setRecommendations(newRecommendations);
    setCurrentSlide(0);
    setShowRecommendations(true);
  };

  const handleRecommendationToggle = (rec) => {
    const itemId = rec.item.id;
    const isInList = localChecklist.some(item => item.id === itemId);

    let newChecklist, action, reason;

    if (isInList) {
      newChecklist = localChecklist.filter(item => item.id !== itemId);
      action = 'removed';
      reason = rec.item.removeReason || '';
    } else {
      const newItem = { ...rec.item, category: currentTab.toLowerCase() };
      newChecklist = [...localChecklist, newItem];
      action = 'added';
      reason = rec.item.addReason || '';
    }

    setLocalChecklist(newChecklist);
    setQuizSummary(prevSummary => [
      ...prevSummary,
      {
        id: itemId,
        title: rec.item.title,
        action,
        reason,
        description: rec.item.description || rec.item.details,
        question: rec.question,
        answer: rec.answer
      }
    ]);

    if (typeof updateChecklist === 'function') {
      try {
        updateChecklist(newChecklist);
      } catch (error) {
        console.error('Error in updateChecklist:', error);
        message.error('An error occurred while updating the checklist. Your changes may not be saved.');
      }
    } else {
      console.warn('updateChecklist is not a function. Changes will only be reflected locally.');
      message.info('Changes saved locally. They may not persist after leaving this page.');
    }

    setRecommendations(prevRecs =>
      prevRecs.map(r =>
        r.item.id === itemId
          ? { ...r, isInList: !isInList }
          : r
      )
    );
  };

  const handleCarouselChange = (current) => {
    setCurrentSlide(current);
  };

  const handlePrevRecommendation = () => {
    carouselRef.current.prev();
  };

  const handleNextRecommendation = () => {
    carouselRef.current.next();
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < filteredQuestions.length - 1) {
      setCurrentQuestionIndex(prevIndex => prevIndex + 1);
      setCurrentAnswer(null);
      setRecommendations([]);
      setShowRecommendations(false);
    } else {
      setShowSummary(true);
    }
  };

  const handleQuizComplete = () => {
    onComplete(localChecklist, quizSummary);
  };

  const handleViewSummary = () => {
    setShowSummary(true);
  };

  const renderRecommendations = () => {
    if (recommendations.length === 0) {
      return <p>No recommendations available.</p>;
    }

    return (
      <div className="recommendations-container">
        <Carousel
          ref={carouselRef}
          dots={false}
          slidesToShow={1}
          slidesToScroll={1}
          afterChange={handleCarouselChange}
        >
          {recommendations.map((rec, index) => {
            const isInList = localChecklist.some(item => item.id === rec.item.id);
            const isCompleted = localChecklist.some(item => item.id === rec.item.id && item.completed);
            const hasPerk = Array.isArray(perksData) && perksData.some(perk => perk.itemId === rec.item.id);

            return (
              <div key={index}>
                <RecommendationItem
                  rec={rec}
                  onToggle={handleRecommendationToggle}
                  isInList={isInList}
                  isCompleted={isCompleted}
                  hasPerk={hasPerk}
                />
              </div>
            );
          })}
        </Carousel>
        <div className="recommendation-navigation">
          <Button
            icon={<LeftOutlined />}
            onClick={handlePrevRecommendation}
            disabled={currentSlide === 0}
            className="nav-button"
          />
          <span>{currentSlide + 1} of {recommendations.length}</span>
          <Button
            icon={<RightOutlined />}
            onClick={handleNextRecommendation}
            disabled={currentSlide === recommendations.length - 1}
            className="nav-button"
          />
        </div>
      </div>
    );
  };

  const renderQuizContent = () => {
    if (showSummary) {
      return (
        <div className="quiz-summary">
          <h2>Quiz Summary</h2>
          <Row gutter={[16, 16]}>
            {quizSummary.map((item, index) => (
              <Col xs={24} sm={12} md={8} lg={6} key={index}>
                <div className={`summary-card ${item.action}`}>
                  {item.action === 'added' && (
                    <div className="giant-checkmark">
                      <CheckOutlined />
                    </div>
                  )}
                  <h3 className="checklist-item-name">{item.title}</h3>
                  <p>{item.description}</p>
                  <p><strong>We recommended this item because you answered "{item.answer}" to the question:</strong></p>
                  <p>"{item.question}"</p>
                  {item.reason && <p><strong>Reason:</strong> {item.reason}</p>}
                  <div className="card-action">
                    {item.action === 'added' ? (
                      <PlusCircleFilled style={{ color: 'green' }} />
                    ) : (
                      <MinusCircleFilled style={{ color: 'red' }} />
                    )}
                    <span>{item.action === 'added' ? 'Added to your list' : 'Removed from your list'}</span>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          <Button onClick={handleQuizComplete} type="primary" style={{ marginTop: '20px' }}>
            Finish Quiz
          </Button>
          {currentQuestionIndex < filteredQuestions.length - 1 && (
            <Button onClick={() => setShowSummary(false)} style={{ marginTop: '20px', marginLeft: '10px' }}>
              Continue Quiz
            </Button>
          )}
        </div>
      );
    }

    if (!showRecommendations) {
      return (
        <div className="question-container">
          <h3>Question {currentQuestionIndex + 1} of {filteredQuestions.length}</h3>
          <h4>{currentQuestion.question}</h4>
          <Paragraph type="secondary">
            {currentQuestion.explanation}
          </Paragraph>
          <Radio.Group onChange={handleAnswer} value={currentAnswer}>
            {currentQuestion.options.map((option, index) => (
              <Radio key={index} value={option}>
                {option}
              </Radio>
            ))}
          </Radio.Group>
          <Button onClick={generateRecommendations} disabled={!currentAnswer}>
            See Recommendations
          </Button>
        </div>
      );
    }

    return (
      <div className="recommendations-container">
        {renderRecommendations()}
        <div className="button-container">
          <Button
            onClick={handleViewSummary}
            type="primary"
            icon={<FileTextOutlined />}
            className="view-summary-button"
          >
            View Summary
          </Button>
          <Button
            onClick={handleNextQuestion}
            type="primary"
            style={{ marginLeft: '10px' }}
          >
            {currentQuestionIndex < filteredQuestions.length - 1 ? 'Next Question' : 'Finish Quiz'}
          </Button>
        </div>
      </div>
    );
  };

  if (!currentQuestion) {
    return <div>Loading questions...</div>;
  }

  return (
    <div className="customize-quiz">
      {renderQuizContent()}
    </div>
  );
};

export default CustomizeQuiz;
