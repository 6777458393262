import React, { useEffect } from 'react';
import { Button, Form, Input, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { inviteFriends } from '../../redux/friends/actions';

const InviteCard = ({ type, onClose, onInvite }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { inviteFriendSuccess, inviteFriendError } = useSelector(state => state.friends);

  const getCardContent = () => {
    switch (type) {
      case 'partner':
        return {
          text: "Allow a partner to view, edit, and share your list and notes",
          buttonColor: "#fefabc",
          buttonText: "Invite Partner"
        };
      case 'friends':
        return {
          text: "Share your checklist and notes with friends or family - and see theirs too!",
          buttonColor: "#b3d9ff",
          buttonText: "Invite Family or Friends"
        };
      case 'expert':
        return {
          text: "Add an expert to our community",
          buttonColor: "#c2f0c2",
          buttonText: "Invite an Expert"
        };
      default:
        return {};
    }
  };

  const { text, buttonColor, buttonText } = getCardContent();

  const handleSubmit = (values) => {
    dispatch(inviteFriends(values.email, type));
    form.resetFields();
    onInvite();
  };

  useEffect(() => {
    if (inviteFriendSuccess) {
      message.success('Invitation Sent.');
    }
    if (inviteFriendError) {
      message.error(inviteFriendError);
    }
  }, [inviteFriendSuccess, inviteFriendError]);

  return (
    <div className="invite-card-expanded" style={{padding: 15}}>
      <h3>{buttonText}</h3>
      <p>{text}</p>
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item
          name="email"
          rules={[{ required: true, type: 'email', message: 'Please input a valid email!' }]}
        >
          <Input placeholder="Enter email" />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ backgroundColor: buttonColor, borderColor: buttonColor }}
          >
            Send Invite
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default InviteCard;
