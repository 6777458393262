const state = {
  updateUserInProgress: false,
  updateUserError: "",
  updateUserSuccess: false,
  addUserError: "",
  addUserInProcess: false,
  userData: {},
  cameraLicenseData: {},
  userAttributes: {},
  babysBirthday: null,
  confirmedPregnancyDate: null,
  dueDate: null,
  checklist: [],
  productRecommendations: [],
  registerInProcess: false,
  registerError: null,
  registerSuccess: false,
  userType: null,
  pregnancyStatus: null,
  babyName: null,
  pregnancyPhoto: null,
  privacyLevel: 'normal',
  childrenCount: 0,
  partnerStatus: null,
  partnerEmail: null,
  inviteFriends: false,
  onboardingLoading: false,
  onboardingCompleted: false,
  onboardingError: null,
  isSuperuser: false,
  userAnalytics: [],
  fetchingUserAnalytics: false,
  userAnalyticsError: null,
  userExpertise: null,
  currentUserType: 'pregnant'
};

export default state;
