export const FETCH_RECEIVED_IN_PROCESS = 'FETCH_RECEIVED_IN_PROCESS';
export const FETCH_RECEIVED_SUCCESS = 'FETCH_RECEIVED_SUCCESS';
export const FETCH_RECEIVED_ERROR = 'FETCH_RECEIVED_ERROR';
export const FETCH_SENT_IN_PROCESS = 'FETCH_SENT_IN_PROCESS';
export const FETCH_SENT_SUCCESS = 'FETCH_SENT_SUCCESS';
export const FETCH_SENT_ERROR = 'FETCH_SENT_ERROR';
export const ACCEPT_EMAIL_IN_PROCESS = 'ACCEPT_EMAIL_IN_PROCCES';
export const ACCEPT_EMAIL_SUCCESS = 'ACCEPT_EMAIL_SUCCESS';
export const ACCEPT_EMAIL_ERROR = 'ACCEPT_EMAIL_ERROR';
export const REJECT_EMAIL_IN_PROCESS = 'REJECT_EMAIL_IN_PROCESS';
export const REJECT_EMAIL_SUCCESS = 'REJECT_EMAIL_SUCCESS';
export const REJECT_EMAIL_ERROR = 'REJECT_EMAIL_ERROR';
export const RESCIND_EMAIL_IN_PROCESS = 'RESCIND_EMAIL_IN_PROCESS';
export const RESCIND_EMAIL_ERROR = 'RESCIND_EMAIL_ERROR';
export const CLEAR_EMAILS_DATA = 'CLEAR_EMAILS_DATA';
export const FETCH_EMAILS_SUCCESS = 'FETCH_EMAILS_SUCCESS';
export const UPDATE_EMAIL_ERROR = 'UPDATE_EMAIL_ERROR';
export const DELETE_EMAIL_ERROR = 'DELETE_EMAIL_ERROR';
