import React from 'react';
import { Menu } from 'antd';
import LogoutItem from './LogoutItem';
import Collaborator from '../modals/Collaborator';
import InviteFriendsModal from '../modals/InviteFriends';
import MyData from '../modals/MyData';
import '../../../assets/css/components/Menu.css';

const SideMenu = (props) => (
  <Menu>
    <Menu.Item key='1'>
        <Collaborator />
    </Menu.Item>
    <Menu.Item key='2'>
        <InviteFriendsModal />
    </Menu.Item>
    <Menu.Item key='/3'>
      <MyData />
    </Menu.Item>
    <Menu.Item key='/4'>
      <LogoutItem/>
    </Menu.Item>
  </Menu>
);

export default SideMenu;
