import React from 'react';
import { List, Card, Spin, Empty } from 'antd';

const ProductList = ({ products, loading }) => {
  if (loading) {
    return <Spin size="large" />;
  }

  if (!products || products.length === 0) {
    return <Empty description="No products found" />;
  }

  return (
    <List
      grid={{ gutter: 16, column: 4 }}
      dataSource={products}
      renderItem={product => (
        <List.Item>
          <Card
            hoverable
            cover={<img alt={product.name} src={product.image_url} />}
          >
            <Card.Meta title={product.name} description={`$${product.price}`} />
          </Card>
        </List.Item>
      )}
    />
  );
};

export default ProductList;
