import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout, Card, Row, Col, Form, Button, Input, Divider } from "antd";
import { login } from "../redux/authentication/actions";
import RegisterModal from "../components/modals/RegisterModal";
import RequestPasswordResetModal from "../components/modals/RequestPasswordResetModal";
import SelectCategoryPhoto from "../../assets/images/SelectCategoryPhoto.png";
import Categories_CarSeat from "../../assets/images/Categories_CarSeat.png";
import Categories_Baby_Monitor from "../../assets/images/Categories_Baby_Monitor.png";
import Categories_Baby_Swing from "../../assets/images/Categories_Baby_Swing.png";
import Categories_Diaper_Rash_Cream from "../../assets/images/Categories_Diaper_Rash_Cream.png";
import Categories_Bouncy_Seat from "../../assets/images/Categories_Bouncy_Seat.png";
import LoginImageCheckbox from "../../assets/images/LoginImageCheckbox.png";
import LoginImagePlus from "../../assets/images/LoginImagePlus.png";
import LoginImageChecklistIcon from "../../assets/images/LoginImageChecklistIcon.png";
import LoginImageStickyNotes from "../../assets/images/LoginImageStickyNotes.png";
import LoginImageMomsCollaborating from "../../assets/images/LoginImageMomsCollaborating.png";
import Logo from "../../assets/images/logo";

import "../../assets/css/views/Login.css";
import "../../assets/css/components/button.css";
import "../../assets/css/components/header.css";

const { Header, Content } = Layout;

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      registerModalVisible: false,
      requestPasswordResetModal: false,
    };
    this.form = React.createRef();
  }

  handleSubmit = (values) => {
    this.props.login(values.email, values.password);
  };

  toggleRegistrationModalVisibility = () => {
    this.setState({ registerModalVisible: !this.state.registerModalVisible });
  };

  toggleRequestPasswordResetModalVisibility = () => {
    this.setState({
      requestPasswordResetModal: !this.state.requestPasswordResetModal,
    });
  };

  goToAnchor = (h) => {
    var element_to_scroll_to = document.getElementById(h);
    element_to_scroll_to.scrollIntoView({ behavior: "smooth", block: "center" });
  }

  render() {
    return (
      <Layout>
        <Header className="headerContainer">
          <a  className="headerLogo" href='#/MyList'>
              <Logo />
          </a>
          <div className="headerButtonsContainer">
            <Button
                onClick={this.toggleRegistrationModalVisibility}
                className="standardButton"
            >
                Sign up
            </Button>
            <RegisterModal
                open={this.state.registerModalVisible}
                toggleRegistrationModalVisibility={this.toggleRegistrationModalVisibility.bind(this)}
            />
            <Button
                onClick={() => this.goToAnchor("signInForm")}
                className="signInButtonHeader"
            >
                Sign in
            </Button>
          </div>
        </Header>
        <Layout style={{backgroundColor: 'white'}}>
          <Content>
            <Row>
              <Col style={styles.content}>
                <Row
                  type="flex"
                  justify="center"
                  align="middle"
                  className="heroContainer"
                >
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                  >
                    <div className="heroTextContainer">
                      <div className="heroText">
                        Expecting a baby? <br/><b> Own your journey.</b>
                      </div>
                      <p className="heroSubText2">
                        <Button
                          onClick={this.toggleRegistrationModalVisibility}
                          className="joinUsButton1"
                        >
                          Take back control
                        </Button>
                        with an app that makes planning simple.
                      </p>
                      <img
                        src={SelectCategoryPhoto}
                        alt={"Expectant mom on computer"}
                        className="heroPhoto"
                      />
                    </div>
                  </Col>
                  <Col
                    id="signInForm"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}
                  >
                    <Card className="loginCard">
                      <Form
                        ref={this.form}
                        onFinish={this.handleSubmit}
                        initialValues={{ remember: true }}
                      >
                        <p style={{fontSize: 24, marginTop: 10, marginBottom: 10, textAlign: 'center' }}>Sign in</p>
                        <Form.Item
                          name="email"
                          hasFeedback
                          rules={[
                            {
                              required: true,
                              pattern: new RegExp("^.+@[^.].*.[a-z]{2,}$"),
                              message: "Please enter a valid email address.",
                            },
                          ]}
                        >
                          <Input className="loginForm" />
                        </Form.Item>
                        <Form.Item
                          name="password"
                          rules={[
                            { required: true, message: "Please enter your password" },
                          ]}
                          hasFeedback
                        >
                          <Input.Password className="loginForm" />
                        </Form.Item>
                        <Form.Item style={{ height: "30px", textAlign: "center" }}>
                          <Button
                            type="secondary"
                            className="forgotPasswordButton"
                            onClick={this.toggleRequestPasswordResetModalVisibility}
                          >
                            Forgot password?
                          </Button>
                        </Form.Item>
                        <Form.Item style={{ marginLeft: "none",marginBottom: "none", textAlign: "center" }}>
                          <Button
                            className="signInButtonBody"
                            type="primary"
                            htmlType="submit"
                            disabled={this.props.loginInProcess}
                          >
                            {this.props.loginInProcess}&nbsp;Sign In
                          </Button>
                        </Form.Item>
                        <Form.Item style={{width: "100%", textAlign: "center"}}>
                          <Divider style={{width: "100%"}}>or</Divider>
                          <Button
                            onClick={this.toggleRegistrationModalVisibility}
                            className="standardButtonLogIn"
                          >
                            Sign up
                          </Button>
                        </Form.Item>
                      </Form>
                    </Card>
                    <RequestPasswordResetModal
                      open={this.state.requestPasswordResetModal}
                      toggleRequestPasswordResetModalVisibility={this.toggleRequestPasswordResetModalVisibility.bind(
                        this
                      )}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Content>
          <Row className="bannerContainer"> </Row>
        </Layout>
        <Content className="bodyContainerA">
          <Row className="bodyRowContainer1">
            <div className="stickyProductTextContainer">
              <Row className="stickyHeaderContainerBlank" />
              <Row className="stickyHeaderContainerA">
                <b>Create a checklist</b>
              </Row>
              <img
                src={Categories_CarSeat}
                alt={"Car Seat"}
                className="categoryImage"
              />
              <Row className="stickyProductTextContainer2">
                <img
                  src={LoginImageCheckbox}
                  alt={"Checkbox"}
                  style={{width: 25, marginRight: 5}}
                />
                Car Seats
              </Row>
              <img
                src={Categories_Baby_Monitor}
                alt={"Baby Monitor"}
                className="categoryImage"
              />
              <Row className="stickyProductTextContainer3">
                <img
                  src={LoginImageCheckbox}
                  alt={"Checkbox"}
                  style={{width: 25, marginRight: 5}}
                />
                Baby Monitors
              </Row>
              <img
                src={Categories_Baby_Swing}
                alt={"Baby Swing"}
                className="categoryImage"
              />
              <Row className="stickyProductTextContainer4">
                <img
                  src={LoginImageCheckbox}
                  alt={"Checkbox"}
                  style={{width: 25, marginRight: 5}}
                />
                Baby Swings
              </Row>
              <img
                src={Categories_Diaper_Rash_Cream}
                alt={"Diaper Rash Cream"}
                className="categoryImage"
              />
              <Row className="stickyProductTextContainer5">
                <img
                  src={LoginImageCheckbox}
                  alt={"Checkbox"}
                  style={{width: 25, marginRight: 5}}
                />
                Rash Creams
              </Row>
              <img
                src={Categories_Bouncy_Seat}
                alt={"Bouncy Seat"}
                className="categoryImage"
              />
              <Row className="stickyProductTextContainer6">
                <img
                  src={LoginImageCheckbox}
                  alt={"Checkbox"}
                  style={{width: 25, marginRight: 5}}
                />
                Bouncers
              </Row>
            </div>
          </Row>
        </Content>
        <div style={{textAlign: "center"}}>
          <Row style={{marginTop: 1000}}/>
          <Row className="stickyProductSubTextContainer1a">Save time</Row>
          <Row className="stickyProductSubTextContainer2a">Get Organized</Row>
          <Row className="stickyProductSubTextContainer3a">Be confident</Row>
          <Row style={{marginTop: 500}}/>
        </div>
          <div style={{textAlign: "center"}}>
            <Row className="stickyProductSubTextContainer1b">
              <img
                  src={LoginImagePlus}
                  alt={"Plus Icon"}
                  style={{ width: 75, marginRight: 5 }}
              />
              <div className="designedForCollaborationSubText">Add friends</div>
            </Row>
            <Row className="stickyProductSubTextContainer2b">
              <img
                  src={LoginImageChecklistIcon}
                  alt={"Checklist Icon"}
                  style={{ width: 75, marginRight: 5 }}
              />
              <div className="designedForCollaborationSubText">Share checklists</div>
            </Row>
            <Row className="stickyProductSubTextContainer3b">
              <img
                  src={LoginImageStickyNotes}
                  alt={"Sticky Notes Icon"}
                  style={{ width: 75, marginRight: 5 }}
              />
              <div className="designedForCollaborationSubText">Compare notes</div></Row>
            <Row style={{marginTop: 500}}/>
          </div>
          <Row className="newWayToResearchClosingSection">
            The new way to research.
          </Row>
        <Content style={{textAlign: "center", top: 500}}>
          <div style={{height:200}}/>
          <Row className="stickyProductSubTextContainer9d">
            <b>me</b>search.
          </Row>
          <Button
            onClick={this.toggleRegistrationModalVisibility}
            className="standardButtonFooter"
          >
            Sign up
          </Button>
          <img
              src={LoginImageMomsCollaborating}
              alt={"Mom in cafe on computer"}
              className="designedForCollaborationImage"
          />
        </Content>
      </Layout>
    );
  }
}

const styles = {
  content: {
    marginTop: 65,
    width: "100%",
    zIndex: 0,
    objectFit: "stretch",
  },
};

const mapStateToProps = (state) => {
  return {
    loginInProcess: state.auth.loginInProcess,
    loginError: state.auth.loginError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (email, password) => dispatch(login(email, password)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
