import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import "../../assets/css/App.css";
import { useSelector, useDispatch } from 'react-redux';
import { Card, Spin } from 'antd';
import { ChevronRight, ChevronLeft, ChevronUp } from 'lucide-react';
import { readAllTopics, readAllCategoriesInAllTopics } from '../redux/topics/actions';
import {
  readUserSelectedCategories,
  toggleCategoryCheck,
  loadSavedCategoryStates,
  saveCategoryState,
  saveProductState
} from '../redux/categories/actions';
import ChecklistsView from './ChecklistsView';
import InviteCard from '../components/checklist/InviteCard';

const isDev = process.env.NODE_ENV === 'development';
const log = (...args) => isDev && console.log(...args);

const hexToRgb = (colorVar) => {
  // Get the actual color value from the CSS variable
  const color = getComputedStyle(document.documentElement).getPropertyValue(colorVar.replace('var(', '').replace(')', '')).trim();
  // Handle both hex and rgb formats
  if (color.startsWith('#')) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
    return result ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}` : null;
  } else if (color.startsWith('rgb')) {
    return color.replace('rgb(', '').replace(')', '').trim();
  }
  return null;
};

const debounce = (func, wait) => {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

const ProgressBar = ({ value, max, color }) => (
  <div style={{ width: '100%', backgroundColor: '#edf2f7', borderRadius: '9999px', height: '0.625rem' }}>
    <div style={{
      width: `${(value / max) * 100}%`,
      backgroundColor: color,
      height: '0.625rem',
      borderRadius: '9999px'
    }}></div>
  </div>
);

const InstagramShareIcon = ({ onClick }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="instagram-share-icon"
    onClick={onClick}
  >
    <line x1="22" y1="2" x2="11" y2="13"></line>
    <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
  </svg>
);

const Home = () => {
  const dispatch = useDispatch();
  const allTopics = useSelector(state => state.topics.allCategoriesInTopics || []);
  const checkedCategories = useSelector(state => state.categories.checkedCategories);
  const selectedProducts = useSelector(state => state.categories.selectedProducts || {});

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTopicIndex, setSelectedTopicIndex] = useState(null);
  const [expandedTopicIndex, setExpandedTopicIndex] = useState(null);
  const [navigationDirection, setNavigationDirection] = useState(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const [animationStage, setAnimationStage] = useState(0);
  const [deselectAnimationStage, setDeselectAnimationStage] = useState(0);
  const [isInviteCardVisible, setIsInviteCardVisible] = useState(false);
  const [inviteCardType, setInviteCardType] = useState('friends');
  const [expandedCategoryId, setExpandedCategoryId] = useState(null);
  const [selectedProductNames, setSelectedProductNames] = useState({});
  const [isNavigationMode, setIsNavigationMode] = useState(false);
  const [isDeselecting, setIsDeselecting] = useState(false);
  const [deselectedItemRepositioned, setDeselectedItemRepositioned] = useState(false);
  const [repositioningIndex, setRepositioningIndex] = useState(null);
  const [headerAnimationState, setHeaderAnimationState] = useState('normal'); // 'normal', 'exiting-up', 'entering-bottom'
  const [isShareViewActive, setIsShareViewActive] = useState(false);

  const containerRef = useRef(null);
  const cardBodyRef = useRef(null);
  const expandedContentRef = useRef(null);
  const TITLE_CENTER_DURATION = 600;
  const TITLE_LEFT_POSITION = '0.5rem';

  const updateContainerHeight = useCallback(
    debounce((height) => {
      if (containerRef.current) {
        const topicsHeight = allTopics.length * 50;
        const newHeight = `${Math.max(height, topicsHeight)}px`;
        if (containerRef.current.style.height !== newHeight) {
          containerRef.current.style.height = newHeight;
        }
      }
    }, 100),
    [allTopics.length]
  );

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        await Promise.all([
          dispatch(readAllTopics()),
          dispatch(readAllCategoriesInAllTopics()),
          dispatch(readUserSelectedCategories()),
          dispatch(loadSavedCategoryStates())
        ]);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to fetch data. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    let resizeObserver;
    try {
      resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          if (entry.target === cardBodyRef.current) {
            updateContainerHeight(entry.contentRect.height);
          }
        }
      });

      if (cardBodyRef.current) {
        resizeObserver.observe(cardBodyRef.current);
      }
    } catch (error) {
      console.error('ResizeObserver error:', error);
    }

    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
  }, [updateContainerHeight]);

  useEffect(() => {
    if (expandedContentRef.current) {
      updateContainerHeight(expandedContentRef.current.scrollHeight);
    }
  }, [expandedTopicIndex, expandedCategoryId, updateContainerHeight]);

  const calculateProgress = useCallback((topic) => {
    if (!topic.categories || topic.categories.length === 0) return 0;
    const completedCount = topic.categories.filter(category => checkedCategories[category.uuid]).length;
    return (completedCount / topic.categories.length) * 100;
  }, [checkedCategories]);

  const getProgressColor = (index) => {
    const colors = [
      'var(--progress-color-1)',
      'var(--progress-color-2)',
      'var(--progress-color-3)',
      'var(--progress-color-4)',
      'var(--progress-color-5)',
      'var(--progress-color-6)',
      'var(--progress-color-7)',
      'var(--progress-color-8)',
      'var(--progress-color-9)',
      'var(--progress-color-10)',
      'var(--progress-color-11)',
      'var(--progress-color-12)'
    ];
    return colors[index % colors.length];
  };

  const handleNavigateToChecklist = useCallback((topicIndex) => {
    if (isAnimating) return;
    setIsAnimating(true);
    setSelectedTopicIndex(topicIndex);
    setAnimationStage(1);
    setIsNavigationMode(false);
    setIsDeselecting(false);
    setDeselectAnimationStage(0);

    console.log(`Navigation to checklist started: topicIndex = ${topicIndex}`);

    const animationSteps = [
      { stage: 2, delay: 0 },
      { stage: 3, delay: TITLE_CENTER_DURATION },
      { stage: 4, delay: 300 },
      { stage: 5, delay: 300 },
    ];

    animationSteps.forEach(({ stage, delay }, index) => {
      setTimeout(() => {
        setAnimationStage(stage);
        console.log(`Expansion animation stage: ${stage}`);
        if (stage === 2) console.log('Title centering started');
        if (stage === 3) console.log('Title centered, font size increasing');
        if (stage === 4) console.log('Background color fading in');
        if (stage === 5) {
          setExpandedTopicIndex(topicIndex);
          setNavigationDirection(null);
          setIsAnimating(false);
          console.log('Navigation to checklist completed, content expanded');
        }
      }, animationSteps.slice(0, index + 1).reduce((sum, step) => sum + step.delay, 0));
    });
  }, [isAnimating, TITLE_CENTER_DURATION]);

  const handleNavigateTopic = useCallback((direction, e) => {
    e.stopPropagation();
    if (allTopics.length <= 1) return;

    const currentIndex = selectedTopicIndex !== null ? selectedTopicIndex : 0;
    const newIndex = direction === 'next'
      ? (currentIndex + 1) % allTopics.length
      : (currentIndex - 1 + allTopics.length) % allTopics.length;

    setSelectedTopicIndex(newIndex);
    setExpandedTopicIndex(newIndex);
    setNavigationDirection(direction);
    setIsNavigationMode(true);
    setIsDeselecting(false);

    setIsAnimating(false);
    setAnimationStage(5);

    console.log(`Navigation completed: direction = ${direction}, newIndex = ${newIndex}`);
  }, [allTopics.length, selectedTopicIndex]);

  const handleDeselectAllTopics = useCallback(() => {
    if (selectedTopicIndex !== null) {
      setIsDeselecting(true);
      setIsAnimating(true);
      setDeselectAnimationStage(1);
      setExpandedTopicIndex(null);

      const animationSteps = [
        { stage: 1, delay: 0 },      // Move off screen left
        { stage: 2, delay: 300 },    // Update styles while hidden
        { stage: 3, delay: 50 },     // Reposition while hidden
        { stage: 4, delay: 50 },     // Return from left
        { stage: 5, delay: 300 },    // Cleanup
      ];

      let totalDelay = 0;
      animationSteps.forEach(({ stage, delay }) => {
        totalDelay += delay;
        setTimeout(() => {
          setDeselectAnimationStage(stage);
          if (stage === 5) {
            setSelectedTopicIndex(null);
            setIsAnimating(false);
            setIsDeselecting(false);
            setDeselectAnimationStage(0);
            updateContainerHeight(allTopics.length * 50);
          }
        }, totalDelay);
      });
    }
  }, [selectedTopicIndex, allTopics.length, updateContainerHeight]);

  const getItemStyle = useCallback((index) => {
    const isSelected = index === selectedTopicIndex;
    const wasOnRight = selectedTopicIndex !== null && index > selectedTopicIndex;

    const baseStyle = {
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '0.375rem',
      cursor: expandedTopicIndex !== null ? 'default' : isAnimating ? 'default' : 'pointer', // Updated cursor logic
      position: 'absolute',
      left: 0,
      right: 0,
      marginTop: 10,
      height: '40px',
      maxHeight: '40px',
      opacity: 1,
      zIndex: isSelected ? 2 : 1,
      overflow: 'visible',
      transition: 'background-color 0.2s ease-in-out',
    };

    // Handle deselection animation sequence
    if (isDeselecting) {
      return {
        ...baseStyle,
        top: (() => {
          if (deselectAnimationStage <= 2) {
            return isSelected ? 0 : `${index * 50}px`;
          }
          return `${index * 50}px`;
        })(),
        transform: (() => {
          if (deselectAnimationStage === 1) {
            if (wasOnRight) {
              return 'translateX(-100%)';
            }
            return 'translateX(-100%)';
          }
          switch (deselectAnimationStage) {
            case 2:
            case 3:
              return 'translateX(-100%)';
            case 4:
              return 'translateX(0)';
            default:
              return 'translateX(0)';
          }
        })(),
        transition: (() => {
          if (deselectAnimationStage === 1 && wasOnRight) {
            return 'none';
          }
          switch (deselectAnimationStage) {
            case 1:
              return 'transform 0.3s ease-in-out, background-color 0.2s ease-in-out';
            case 2:
            case 3:
              return 'none';
            case 4:
              return 'transform 0.3s ease-in-out, background-color 0.2s ease-in-out';
            default:
              return 'transform 0.3s ease-in-out, background-color 0.2s ease-in-out';
          }
        })(),
      };
    }

    if (selectedTopicIndex === null) {
      return {
        ...baseStyle,
        top: `${index * 50}px`,
        transform: 'translateX(0)',
        transition: 'transform 0.3s ease-in-out, background-color 0.2s ease-in-out',
      };
    }

    if (isSelected) {
      return {
        ...baseStyle,
        top: animationStage >= 3 ? '0' : `${index * 50}px`,
        transform: 'translateX(0)',
        height: animationStage >= 4 ? 'auto' : '40px',
        maxHeight: animationStage >= 4 ? 'none' : '40px',
        transition: 'transform 0.3s ease-in-out, top 0.3s ease-in-out, background-color 0.2s ease-in-out',
      };
    }

    const direction = index < selectedTopicIndex ? -1 : 1;
    return {
      ...baseStyle,
      top: `${index * 50}px`,
      transform: `translateX(${(animationStage >= 1 && animationStage <= 6) ? direction * 110 : 0}%)`,
      transition: 'transform 0.3s ease-in-out, background-color 0.2s ease-in-out',
    };
  }, [selectedTopicIndex, expandedTopicIndex, animationStage, isDeselecting, deselectAnimationStage, isAnimating]);

  const getInnerContainerStyle = useCallback((index, progressColor) => {
    const isSelected = index === selectedTopicIndex;

    if (isDeselecting) {
      // During deselection, no transitions on background-color
      return {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        height: '40px',
        position: 'relative',
        backgroundColor: isSelected && deselectAnimationStage < 2
          ? `rgba(${hexToRgb(progressColor)}, 1)`
          : 'transparent',
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        // No transition property at all for deselection
        transition: 'none',
      };
    }

    // During normal selection
    return {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      height: '40px',
      position: 'relative',
      backgroundColor: isSelected
        ? `rgba(${hexToRgb(progressColor)}, ${(animationStage - 1) / 4})`
        : 'transparent',
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      // During selection, we can animate everything
      transition: 'all 0.3s ease-in-out',
    };
  }, [selectedTopicIndex, animationStage, isDeselecting, deselectAnimationStage]);

  const handleTitleClick = useCallback(() => {
    if (isInviteCardVisible) {
      setIsInviteCardVisible(false);
    } else {
      handleDeselectAllTopics();
    }
  }, [isInviteCardVisible, handleDeselectAllTopics]);

  const handleShareIconClick = useCallback(() => {
    setIsShareViewActive(true);
    setIsInviteCardVisible(true);
    setInviteCardType('friends');
  }, []);

  const handleBackFromShare = useCallback(() => {
    setIsShareViewActive(false);
    setIsInviteCardVisible(false);
  }, []);

  const getHeaderTitle = useCallback(() => {
      const isItemSelected = selectedTopicIndex !== null;
      const showBackState = isItemSelected && animationStage >= 3 && !isDeselecting;
      const selectedTopicName = isItemSelected ? allTopics[selectedTopicIndex]?.name : '';

      return (
        <div className="card-title">
          <div style={{ width: '24px', display: showBackState ? 'none' : 'block' }} />
          <div
            style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              transition: 'justify-content 0.3s ease-in-out',
              marginLeft: showBackState ? '-24px' : '0',
              overflow: 'hidden',
              position: 'relative',
              height: '32px'
            }}
          >
            {/* "Your Checklists" text */}
            <span
              style={{
                position: 'absolute',
                left: '50%',
                transform: `translateX(${
                  isInviteCardVisible ? '-150%' :
                  showBackState ? '-150%' :
                  '-50%'
                })`,
                opacity: !showBackState && !isInviteCardVisible ? 1 : 0,
                transition: 'all 0.3s ease-in-out',
                fontSize: '18px',
                fontWeight: '500',
                cursor: 'default',
                whiteSpace: 'nowrap',
                padding: '0.5rem',
                pointerEvents: !showBackState && !isInviteCardVisible ? 'auto' : 'none',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              Your Checklists
            </span>

            {/* "Back to checklists" button */}
            <div
              className="back-button"
              onClick={handleTitleClick}
              style={{
                position: 'absolute',
                left: 0,
                transform: `translateX(${
                  isInviteCardVisible ? '-150%' :
                  showBackState ? '0' :
                  '-150%'
                })`,
                opacity: !isInviteCardVisible && showBackState ? 1 : 0,
                transition: 'all 0.3s ease-in-out',
                fontSize: '14px',
                fontWeight: '400',
                cursor: 'pointer',
                whiteSpace: 'nowrap',
                padding: '0.5rem 0.75rem',
                pointerEvents: !isInviteCardVisible && showBackState ? 'auto' : 'none',
                display: 'flex',
                alignItems: 'center',
                gap: '0.5rem',
                borderRadius: '6px',
              }}
            >
              <ChevronLeft size={18} />
              Back to checklists
            </div>

            {/* "Back to [topic/checklists]" button for share view */}
            <div
              className="back-button"
              onClick={handleTitleClick}
              style={{
                position: 'absolute',
                left: '50%',
                transform: `translateX(-50%) translateX(${isInviteCardVisible ? '0' : '150%'})`,
                opacity: isInviteCardVisible ? 1 : 0,
                transition: 'all 0.3s ease-in-out',
                fontSize: '14px',
                fontWeight: '400',
                cursor: 'pointer',
                whiteSpace: 'nowrap',
                padding: '0.5rem 0.75rem',
                pointerEvents: isInviteCardVisible ? 'auto' : 'none',
                display: 'flex',
                alignItems: 'center',
                gap: '0.5rem',
                borderRadius: '6px',
              }}
            >
              <ChevronUp size={18} />
              Back to {isItemSelected ? selectedTopicName : 'your checklists'}
            </div>
          </div>
          <div style={{ width: '24px', marginRight: '10px' }}>
            <InstagramShareIcon onClick={handleShareIconClick}/>
          </div>
        </div>
      );
    }, [
      selectedTopicIndex,
      animationStage,
      isDeselecting,
      handleTitleClick,
      handleShareIconClick,
      isInviteCardVisible,
      allTopics
    ]);

  const getHeaderStyle = useCallback(() => {
    const isItemSelected = selectedTopicIndex !== null;
    const baseStyle = {
      backgroundColor: 'var(--color-cream)',
      color: 'var(--text-primary)',
      transition: 'background-color 0.3s ease-in-out'
    };

    if (isDeselecting) {
      // During deselection animation, gradually return to cream
      return {
        ...baseStyle,
        backgroundColor: deselectAnimationStage >= 2 ? 'var(--color-cream)' : 'var(--color-faded)'
      };
    }

    // During normal selection/deselection
    if (isItemSelected && animationStage >= 3) {
      return {
        ...baseStyle,
        backgroundColor: 'var(--color-faded)'  // Using the new theme variable
      };
    }

    return baseStyle;
  }, [selectedTopicIndex, isDeselecting, deselectAnimationStage, animationStage]);

  const getTitleStyle = useCallback((index) => {
    const isSelected = index === selectedTopicIndex;

    const baseStyle = {
      position: 'absolute',
      top: '50%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      zIndex: 10,
    };

    // Normal state style (not selected)
    const normalStyle = {
      ...baseStyle,
      left: TITLE_LEFT_POSITION,
      width: 'calc(100% - 1rem)',
      transform: 'translateY(-50%)',
      fontSize: '1rem',
      fontWeight: 'normal',
      color: 'inherit',
      textAlign: 'left',
      // Only transition position properties, not colors or fonts
      transition: isDeselecting ? 'none' : 'transform 0.3s ease-in-out, left 0.3s ease-in-out',
    };

    // Selected state style
    const selectedStyle = {
      ...baseStyle,
      left: '50%',
      width: 'auto',
      transform: 'translate(-50%, -50%)',
      fontSize: '1.25rem',
      fontWeight: 'bold',
      color: 'white',
      textAlign: 'center',
      transition: 'transform 0.3s ease-in-out, left 0.3s ease-in-out',
    };

    // During deselection
    if (isDeselecting) {
      // Switch to normal style immediately while off screen
      if (deselectAnimationStage >= 2) {
        return normalStyle;
      }
      return selectedStyle;
    }

    return isSelected ? selectedStyle : normalStyle;

  }, [selectedTopicIndex, isDeselecting, deselectAnimationStage, TITLE_LEFT_POSITION]);

  const getProgressStyle = useCallback((index) => {
    const isSelected = index === selectedTopicIndex;
    return {
      position: 'absolute',
      right: '2rem',
      top: '50%',
      transform: 'translateY(-50%)',
      display: 'flex',
      alignItems: 'center',
      opacity: (isSelected && !isDeselecting) ||
        (isDeselecting && deselectAnimationStage < 5) ? 0 : 1,
      transition: 'opacity 0.3s ease-in-out',
    };
  }, [selectedTopicIndex, isDeselecting, deselectAnimationStage]);

  const handleCategoryExpand = useCallback((categoryId) => {
    setExpandedCategoryId(prevId => {
      const newId = prevId === categoryId ? null : categoryId;
      console.log(`Category ${categoryId} ${newId ? 'expanded' : 'collapsed'}`);
      return newId;
    });
  }, []);

const handleCategoryToggle = useCallback((categoryId) => {
  const newCheckedState = !checkedCategories[categoryId];
  dispatch(toggleCategoryCheck(categoryId));
  dispatch(saveCategoryState(categoryId, newCheckedState));

  if (newCheckedState && !selectedProducts[categoryId]) {
    handleCategoryExpand(categoryId);
  } else {
    handleCategoryExpand(null);
  }

  if (!newCheckedState) {
    dispatch(saveProductState(categoryId, null));
    setSelectedProductNames(prev => {
      const newState = { ...prev };
      delete newState[categoryId];
      return newState;
    });
  }
}, [dispatch, checkedCategories, selectedProducts, handleCategoryExpand]);

const handleProductSelect = useCallback((categoryId, productId, productName) => {
  dispatch(saveProductState(categoryId, productId));
  dispatch(toggleCategoryCheck(categoryId, true));
  dispatch(saveCategoryState(categoryId, true));
  setSelectedProductNames(prev => ({...prev, [categoryId]: productName}));
  handleCategoryExpand(null);
}, [dispatch, handleCategoryExpand]);

const handleNoThanks = useCallback((categoryId) => {
  dispatch(saveProductState(categoryId, 'none'));
  dispatch(toggleCategoryCheck(categoryId, true));
  dispatch(saveCategoryState(categoryId, true));
  setSelectedProductNames(prev => ({...prev, [categoryId]: 'Select a product'}));
  handleCategoryExpand(null);
}, [dispatch, handleCategoryExpand]);

  const handleCloseInviteCard = () => {
    setIsInviteCardVisible(false);
  };

  const handleInviteSent = () => {
    setIsInviteCardVisible(false);
  };

  const memoizedChecklistsView = useMemo(() => (
    <ChecklistsView
      topicId={expandedTopicIndex !== null ? allTopics[expandedTopicIndex].id : null}
      isEmbedded={true}
      onNavigate={() => {}}
      updateContainerHeight={updateContainerHeight}
      onCategoryExpand={handleCategoryExpand}
      expandedCategoryId={expandedCategoryId}
    />
  ), [expandedTopicIndex, allTopics, updateContainerHeight, handleCategoryExpand, expandedCategoryId]);

  if (isLoading) {
    return (
      <div className="loading-container">
        <Spin size="large" />
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }


  console.log('Render called with states:', {
    selectedTopicIndex,
    isDeselecting,
    deselectAnimationStage,
    animationStage
  });

  return (
    <div className="home-container">
      <Card
        title={getHeaderTitle()}
        headStyle={getHeaderStyle()}
        bodyStyle={{
          padding: '1rem',
          height: 'auto',
          transition: 'height 0.3s ease-in-out',
          overflow: 'hidden',
        }}
      >
        <div ref={cardBodyRef} style={{ height: '100%', overflow: 'hidden', position: 'relative' }}>
          <div
            style={{
              transform: `translateY(${isInviteCardVisible ? '-100%' : '0'})`,
              transition: 'transform 0.5s ease-in-out',
              height: 'auto',
            }}
          >
            <div ref={containerRef} style={{
              position: 'relative',
              transition: 'height 0.5s ease-in-out',
              overflow: 'visible'
            }}>
              {allTopics.length > 0 ? (
                <ul className="checklist-list" style={{ listStyle: 'none', padding: 0, margin: 0, position: 'relative' }}>
                  {allTopics.map((topic, index) => {
                    const progress = calculateProgress(topic);
                    const isSelected = index === selectedTopicIndex;
                    const isExpanded = index === expandedTopicIndex;
                    const progressColor = getProgressColor(index);

                    return (
                      <li
                      key={topic.id}
                      id={`topic-${topic.id}`}
                      onClick={() => {
                      if (!isAnimating && expandedTopicIndex === null) {  // Only allow clicks when no topic is expanded
                        handleNavigateToChecklist(index);
                      }
                      }}
                      className="checklist-item"
                      style={getItemStyle(index)}
                      >
                        <div style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          width: '100%',
                          height: '40px',
                          position: 'relative',
                          backgroundColor: isSelected
                            ? isDeselecting
                              ? (deselectAnimationStage < 2 ? `rgba(${hexToRgb(progressColor)}, 1)` : 'transparent')
                              : `rgba(${hexToRgb(progressColor)}, ${(animationStage - 1) / 4})`
                            : 'transparent',
                          transition: isDeselecting ? 'none' : 'all 0.3s ease-in-out',
                          borderTopLeftRadius: 10,
                          borderTopRightRadius: 10
                        }}>
                          {isExpanded && (
                            <ChevronLeft
                              size={20}
                              onClick={(e) => handleNavigateTopic('prev', e)}
                              style={{
                                cursor: 'pointer',
                                position: 'absolute',
                                left: '10px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                opacity: isExpanded ? 1 : 0,
                                transition: 'opacity 0.5s ease-in-out',
                                transitionDelay: '0.5s',
                                color: 'white',
                                pointerEvents: 'auto',
                                zIndex: 10,
                              }}
                            />
                          )}
                          <div className="title" style={getTitleStyle(index)}>
                            {topic.name}
                          </div>
                          {isExpanded && (
                            <ChevronRight
                              size={20}
                              onClick={(e) => handleNavigateTopic('next', e)}
                              style={{
                                cursor: 'pointer',
                                position: 'absolute',
                                right: '10px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                opacity: isExpanded ? 1 : 0,
                                transition: 'opacity 0.5s ease-in-out',
                                transitionDelay: '0.5s',
                                color: 'white',
                                pointerEvents: 'auto',
                                zIndex: 10,
                              }}
                            />
                          )}
                          <div style={getProgressStyle(index)}>
                            <div style={{
                              width: '100px',
                              marginRight: '0.5rem',
                              position: 'absolute',
                              right: '2.5rem',
                            }}>
                              <div className="progress-bar">
                                <div
                                  className="progress-bar-fill"
                                  style={{
                                    width: `${progress}%`,
                                    backgroundColor: progressColor,
                                    height: '0.625rem',
                                    borderRadius: '9999px'
                                  }}
                                />
                              </div>
                            </div>
                            <span className="progress-text" style={{ fontSize: '0.875rem', color: '#718096' }}>
                              {`${Math.round(progress)}%`}
                            </span>
                          </div>
                        </div>
                        <div
                          ref={isExpanded ? expandedContentRef : null}
                          style={{
                            width: '100%',
                            opacity: isExpanded ? 1 : 0,
                            maxHeight: isExpanded ? 'none' : '0',
                            overflow: 'visible',
                            transition: 'opacity 0.4s ease-in-out, max-height 0.4s ease-in-out',
                            transitionDelay: '0.6s',
                            pointerEvents: isExpanded ? 'auto' : 'none',
                            paddingBottom: isExpanded ? '25px' : '0',
                            backgroundColor: 'white',
                          }}
                          className="checklist-view"
                          onClick={(e) => e.stopPropagation()}
                        >
                          {isExpanded && memoizedChecklistsView}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '200px',
                }}>
                  <Spin size="large" />
                </div>
              )}
            </div>
          </div>
          <div style={{
            position: 'absolute',
            top: '100%',
            left: 0,
            right: 0,
            height: '100%',
            transform: `translateY(${isInviteCardVisible ? '-100%' : '0'})`,
            transition: 'transform 0.5s ease-in-out',
            backgroundColor: 'white',
          }}>
            <InviteCard
              type={inviteCardType}
              onClose={handleCloseInviteCard}
              onInvite={handleInviteSent}
            />
          </div>
        </div>
      </Card>
      <style jsx>{`
        .home-container {
          background-color: var(--background-primary);
          color: var(--text-secondary);
          min-height: 100vh;
          font-family: sans-serif;
          font-weight: 300;
          letter-spacing: 0.025em;
          margin-top: 25px;
          overflow: hidden;
          position: relative;
          padding: 25px;
          padding-top: 75px;
        }

        .loading-container {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100vh;
          width: 100vw;
          position: fixed;
          top: 0;
          left: 0;
          background-color: var(--background-secondary);
        }

        .card-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
        }

        .checklist-title {
          cursor: pointer;
          flex: 0 auto;
          font-size: 18px;
          transition: all 0.3s ease;
        }

        .checklist-title:hover {
          color: var(--color-deeper-blue-dark);
        }

        .instagram-share-icon {
          cursor: pointer;
          transition: stroke 0.3s ease;
        }

        .instagram-share-icon:hover {
          stroke: var(--color-deeper-blue-dark);
        }

        /* Only apply list hover effects when not expanded */
        .checklist-list:hover .checklist-item {
          color: ${expandedTopicIndex === null ? '#718096' : 'inherit'};
        }

        .checklist-item:hover {
          background-color: rgba(181, 212, 255, 0.1);
        }

        /* Title hover effects only when not expanded */
        .checklist-list:hover .checklist-item .title {
          color: ${expandedTopicIndex === null ? '#718096' : 'white'};
          font-weight: ${expandedTopicIndex === null ? 'normal' : 'bold'};
        }

        .checklist-item:hover .title {
          color: ${expandedTopicIndex === null ? '#000000' : 'white'} !important;
          font-weight: ${expandedTopicIndex === null ? '600' : 'bold'} !important;
        }

        .checklist-item:hover .progress-text {
          color: #000000;
          font-weight: 500;
        }

        .checklist-item:hover .progress-bar-fill {
          filter: brightness(0.9);
        }

        .checklist-item .title,
        .checklist-item .progress-text {
          transition: color 0.2s ease-in-out, font-weight 0.2s ease-in-out;
        }

        :global(.back-button) {
          color: var(--text-secondary) !important;
          transition: all 0.2s ease-in-out !important;
        }

        :global(.card-title .back-button:hover) {
          color: var(--text-primary) !important;
          background-color: rgba(0, 0, 0, 0.05) !important;
        }
      `}</style>
    </div>
  );
};

export default Home;
