import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Button, Carousel } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const GenericCarousel = forwardRef(({
  items,
  renderItem,
  onAfterChange,
  className,
  prevArrowProps = {},
  nextArrowProps = {},
  carouselProps = {}
}, ref) => {
  const carouselRef = useRef(null);

  useImperativeHandle(ref, () => ({
    goTo: (index) => {
      carouselRef.current.goTo(index);
    }
  }));

  const handlePrev = (e) => {
    e.stopPropagation();
    carouselRef.current.prev();
  };

  const handleNext = (e) => {
    e.stopPropagation();
    carouselRef.current.next();
  };

  return (
    <div className={`generic-carousel-container ${className}`}>
      <Button
        icon={<LeftOutlined />}
        onClick={handlePrev}
        className="carousel-arrow prev"
        {...prevArrowProps}
      />
      <Carousel
        ref={carouselRef}
        dots={false}
        afterChange={onAfterChange}
        {...carouselProps}
      >
        {items.map((item, index) => (
          <div key={index} className="carousel-slide">
            {renderItem(item, index)}
          </div>
        ))}
      </Carousel>
      <Button
        icon={<RightOutlined />}
        onClick={handleNext}
        className="carousel-arrow next"
        {...nextArrowProps}
      />
    </div>
  );
});

export default GenericCarousel;
