import React from "react";
import { Divider } from "antd";
import { useInView } from "react-intersection-observer";

import ProductList from "./ProductList.js";

const ProductCategory = ({ category, friendUuid, favorited, isOpen }) => {
  const { ref, inView } = useInView({
    threshold: 0,
  });

  return (
    <>
      <Divider className="categoryTitleText">
        <div ref={ref}>{category.name}</div>
      </Divider>

      <ProductList categoryId={category.uuid} friendUuid={friendUuid} favorited={favorited} isOpen={isOpen} show={inView} />
    </>
  );
};

export default ProductCategory;
