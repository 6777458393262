import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Button, Divider } from 'antd';
import { CloseOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';
import GenericCarousel from './GenericCarousel';
import InviteCard from './InviteCard';
import zoeAvatarImage from '../../../assets/images/Zoe.jpg';
import '../../../assets/css/components/FriendsListCarousel.css';

const FriendsListCarousel = ({ friends = [], onUserSelect, currentUserUuid, onInviteToggle, isInviteExpanded }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [expandedInvite, setExpandedInvite] = useState(null);
  const [partnerInvited, setPartnerInvited] = useState(false);
  const [showPartnerInvite, setShowPartnerInvite] = useState(true);
  const carouselRef = useRef(null);
  const emojiMapRef = useRef({});

  useEffect(() => {
    if (!isInviteExpanded) {
      setExpandedInvite(null);
    }
  }, [isInviteExpanded]);

  const dummyExperts = useMemo(() => [
    { uuid: 'expert1', first_name: 'Emma', last_name: 'Smith', type: 'expert' },
    { uuid: 'expert2', first_name: 'Michael', last_name: 'Johnson', type: 'expert' },
    { uuid: 'expert3', first_name: 'Sophia', last_name: 'Brown', type: 'expert' },
  ], []);

  const animalEmojis = useMemo(() => ['🐱', '🐶', '🐼', '🐨', '🐯', '🦁', '🐸', '🐘', '🦊', '🐰', '🐻', '🐧', '🦉', '🦄'], []);

  const getEmoji = useCallback((item) => {
    if (item.type === 'myList') return '🐵';
    if (!item.uuid) return '❓';
    const emojiIndex = item.uuid.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0) % animalEmojis.length;
    return animalEmojis[emojiIndex];
  }, [animalEmojis]);

  const generateEmojis = useCallback(() => {
    emojiMapRef.current = {
      myList: '🐵',
      ...friends.reduce((acc, friend) => {
        acc[friend.uuid] = getEmoji(friend);
        return acc;
      }, {}),
      ...dummyExperts.reduce((acc, expert) => {
        acc[expert.uuid] = getEmoji(expert);
        return acc;
      }, {})
    };
  }, [friends, dummyExperts, getEmoji]);

  useEffect(() => {
    const partnerInvitedState = localStorage.getItem('partnerInvited');
    const showPartnerInviteState = localStorage.getItem('showPartnerInvite');
    if (partnerInvitedState) setPartnerInvited(JSON.parse(partnerInvitedState));
    if (showPartnerInviteState) setShowPartnerInvite(JSON.parse(showPartnerInviteState));

    if (Object.keys(emojiMapRef.current).length === 0) {
      generateEmojis();
    }
  }, [generateEmojis]);

  const getButtonClassName = (type) => {
    switch (type) {
      case 'myList':
      case 'partner':
        return 'friend-tab partner-color';
      case 'friend':
        return 'friend-tab friend-color';
      case 'expert':
        return 'friend-tab expert-color';
      default:
        return 'friend-tab';
    }
  };

  const renderAvatar = (item) => {
    if (item.first_name === 'Zoe' && item.last_name === 'Frankel') {
      return (
        <div
          className="friend-avatar"
          data-user="friend"
          style={{ backgroundImage: `url(${zoeAvatarImage})`, backgroundSize: 'cover' }}
        />
      );
    }

    if (item.avatar) {
      return (
        <div
          className="friend-avatar"
          data-user={item.type}
          style={{ backgroundImage: `url(${item.avatar})`, backgroundSize: 'cover' }}
        />
      );
    }

    const emoji = getEmoji(item);
    return (
      <div
        className="friend-avatar"
        data-user={item.type}
      >
        {emoji}
      </div>
    );
  };

  const handleFriendClick = (friend) => {
    onUserSelect(friend);
  };

  const renderButton = (item) => {
    let buttonText, onClick, userType, avatarItem;

    switch (item.type) {
      case 'myList':
        buttonText = 'My List';
        onClick = () => onUserSelect({ type: 'myList', name: 'My List', uuid: 'myList' });
        userType = 'myList';
        avatarItem = { type: 'myList', uuid: 'myList' };
        break;
      case 'friend':
      case 'expert':
        buttonText = `${item.first_name} ${item.last_name}`;
        onClick = () => handleFriendClick(item);
        userType = item.type;
        avatarItem = item;
        break;
      case 'invitePartner':
      case 'inviteFriends':
      case 'inviteExpert':
        return renderInviteCard(item.type, `${item.type}-color`);
      default:
        return null;
    }

    return (
      <Button
        key={item.uuid || item.type}
        className={`${getButtonClassName(userType)} ${currentUserUuid === item.uuid ? 'active' : ''}`}
        onClick={onClick}
      >
        {renderAvatar(avatarItem)}
        {buttonText}
      </Button>
    );
  };

  const handleInvite = (type) => {
    setExpandedInvite(type);
    onInviteToggle(true);
  };

  const handleCloseInvite = () => {
    setExpandedInvite(null);
    onInviteToggle(false);
  };

  const handleInviteSent = (type) => {
    if (type === 'partner') {
      setPartnerInvited(true);
      localStorage.setItem('partnerInvited', JSON.stringify(true));
    }
    handleCloseInvite();
  };

  const handleClosePartnerInvite = () => {
    setShowPartnerInvite(false);
    localStorage.setItem('showPartnerInvite', JSON.stringify(false));
  };

  const renderInviteCard = (type, color) => (
    <div className={`invite-card ${color}`}>
      {type === 'partner' && partnerInvited ? (
        <span>Partner invited</span>
      ) : (
        <>
          <Button onClick={() => handleInvite(type)}>
            Invite {type === 'partner' ? 'Partner' : type === 'friends' ? 'Friends' : 'Expert'}
          </Button>
          {type === 'partner' && (
            <Button
              icon={<CloseOutlined />}
              className="close-button"
              onClick={handleClosePartnerInvite}
            />
          )}
        </>
      )}
    </div>
  );

  const carouselItems = [
    { type: 'myList', name: 'My List', uuid: null },
    ...(showPartnerInvite ? [{ type: 'invitePartner' }] : []),
    ...(Array.isArray(friends) && friends.length > 0 ? friends.map(friend => ({ ...friend, type: 'friend' })) : []),
    { type: 'inviteFriends' },
    ...dummyExperts,
    { type: 'inviteExpert' }
  ];

  const handleNext = () => {
    if (currentIndex < carouselItems.length - 3) {
      setCurrentIndex(prevIndex => prevIndex + 1);
      carouselRef.current.goTo(currentIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(prevIndex => prevIndex - 1);
      carouselRef.current.goTo(currentIndex - 1);
    }
  };

  const handleInviteCollapse = () => {
    onInviteToggle(!isInviteExpanded);
  };

  return (
    <div className="friends-list-carousel-container">
      <h2>Lean on the community</h2>
      <div className="divider"></div>
      <div className="generic-carousel-container">
        <GenericCarousel
          ref={carouselRef}
          className="friends-list"
          items={carouselItems}
          renderItem={renderButton}
          prevArrowProps={{
            onClick: handlePrev,
            disabled: currentIndex === 0,
          }}
          nextArrowProps={{
            onClick: handleNext,
            disabled: currentIndex >= carouselItems.length - 3,
          }}
          carouselProps={{
            dots: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: false,
          }}
        />
      </div>
      <Divider className="invite-divider" />
      <div className={`invite-section ${isInviteExpanded ? 'expanded' : 'collapsed'}`}>
        <div className="invite-container">
          {isInviteExpanded && (
            <div className="invite-overlay">
              <div className="invite-expanded-container">
                <InviteCard
                  type={expandedInvite}
                  onToggle={onInviteToggle}
                  isExpanded={isInviteExpanded}
                  onClose={handleCloseInvite}
                  onInvite={() => handleInviteSent(expandedInvite)}
                />
              </div>
            </div>
          )}
          <Button
            type="text"
            icon={isInviteExpanded ? <UpOutlined /> : <DownOutlined />}
            onClick={handleInviteCollapse}
            className="collapse-button"
          >
            {isInviteExpanded ? 'Collapse' : 'Expand'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FriendsListCarousel;
