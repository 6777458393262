import React from 'react';
import "../css/App.css";

const Logo = ({ size = '1.5rem' }) => {
  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  };

  const mainLetterStyle = (colorVar, glowVar) => ({
    fontFamily: '"Comic Sans MS", "Chalkboard SE", "Comic Neue", "Marker Felt", sans-serif',
    display: 'inline-block',
    margin: '0 0.02em',
    fontWeight: 900,
    color: `var(${colorVar})`,
    textShadow: `0 0 10px var(${glowVar})`,
    transform: 'scale(1.05)',
    fontSize: '1em',
    transition: 'color 0.3s ease, text-shadow 0.3s ease'
  });

  const secondaryLetterStyle = (colorVar, glowVar) => ({
    fontFamily: '"Comic Sans MS", "Chalkboard SE", "Comic Neue", "Marker Felt", sans-serif',
    display: 'inline-block',
    margin: '0 0.02em',
    fontWeight: 500,
    color: `var(${colorVar})`,
    textShadow: `0 0 10px var(${glowVar})`,
    fontSize: '0.8em',
    transition: 'color 0.3s ease, text-shadow 0.3s ease'
  });

  const logoContainerStyle = {
    fontSize: size,
    position: 'relative',
    padding: '4px'
  };

  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <div
      style={containerStyle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div style={logoContainerStyle}>
        <span style={mainLetterStyle(
          isHovered ? '--color-deeper-blue-dark' : '--color-deeper-blue',
          '--glow-blue'
        )}>m</span>
        <span style={mainLetterStyle(
          isHovered ? '--color-deeper-pink-dark' : '--color-deeper-pink',
          '--glow-pink'
        )}>e</span>
        <span style={secondaryLetterStyle(
          isHovered ? '--color-deeper-sage-dark' : '--color-deeper-sage',
          '--glow-sage'
        )}>s</span>
        <span style={secondaryLetterStyle(
          isHovered ? '--color-deeper-blue-dark' : '--color-deeper-blue',
          '--glow-blue'
        )}>e</span>
        <span style={secondaryLetterStyle(
          isHovered ? '--color-deeper-pink-dark' : '--color-deeper-pink',
          '--glow-pink'
        )}>a</span>
        <span style={secondaryLetterStyle(
          isHovered ? '--color-deeper-sage-dark' : '--color-deeper-sage',
          '--glow-sage'
        )}>r</span>
        <span style={secondaryLetterStyle(
          isHovered ? '--color-deeper-blue-dark' : '--color-deeper-blue',
          '--glow-blue'
        )}>c</span>
        <span style={secondaryLetterStyle(
          isHovered ? '--color-deeper-blue-dark' : '--color-deeper-blue',
          '--glow-blue'
        )}>h</span>
      </div>
    </div>
  );
};

export default Logo;
