import React, { useState, useEffect } from 'react';

const CustomCheckmark = React.memo(({ checked, onChange, id }) => {
  const [isChecked, setIsChecked] = useState(checked);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleChange = (e) => {
    setIsChecked(e.target.checked);
    onChange(e);
  };

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <label
      className="custom-checkmark"
      style={{
        position: 'relative',
        display: 'inline-block',
        width: '24px',
        height: '24px',
        cursor: 'pointer'
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <input
        type="checkbox"
        style={{ position: 'absolute', opacity: 0, width: 0, height: 0 }}
        checked={isChecked}
        onChange={handleChange}
      />
      <div style={{
        width: '22px',
        height: '22px',
        backgroundColor: isHovered ? '#e5e7eb' : '#f3f4f6', // Darker grey on hover
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        transition: 'background-color 0.2s ease-in-out' // Smooth transition for hover effect
      }}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 36 36"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <defs>
            <linearGradient id="checkmarkGradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" stopColor="#7C3AED" />
              <stop offset="100%" stopColor="#A78BFA" />
            </linearGradient>
          </defs>
          <path
            className="checkmark-path"
            d="M8 19L15 26L28 13"
            stroke="url(#checkmarkGradient)"
            strokeWidth="3"
            style={{
              strokeDasharray: 40,
              strokeDashoffset: isChecked ? 0 : 40,
              transition: 'stroke-dashoffset 0.6s ease-out, opacity 0.2s',
              opacity: isChecked ? 1 : 0,
            }}
          />
        </svg>
      </div>
    </label>
  );
});

export default CustomCheckmark;
