import React from "react";
import { List } from "antd";
import { useQuery } from "@tanstack/react-query";

import { getAllCategoryProductData } from "../../redux/products/actions.js";

import ProductItem from "./ProductItem.js";

const ProductList = ({ categoryId, friendUuid, favorited, isOpen, show }) => {
  const { isPending, error, data } = useQuery({
    queryKey: ["categoryAllProductList", categoryId, favorited, friendUuid],
    queryFn: () => getAllCategoryProductData(categoryId, friendUuid, favorited),
    enabled: show,
  });

  const products = data || [];

  if (isPending) {
    return (
      <div
        style={{
          margin: 0,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          fontSize: 36,
        }}
      >
        Loading
      </div>
    );
  }

  if (error) return "An error has occurred: " + error.message;

  return (
    <List
      grid={{
        gutter: 16,
        xs: 2,
        sm: 2,
        md: 2,
        lg: 3,
        xl: 4,
        xxl: 4,
      }}
      className="categoriesContainer"
      dataSource={products}
      renderItem={(product) => <ProductItem product={product} categoryId={categoryId} isOpen={isOpen} />}
    />
  );
};

export default ProductList;
