import React from 'react';
import { connect } from 'react-redux';
import * as topicFunctions from "../../redux/topics/actions";
import * as productFunctions from "../../redux/products/actions";
import { Input } from 'antd';
import "../../../assets/css/components/search.css";

const { Search } = Input;

const FreeFormSearchBox = ({ context, loading, onSearch }) => {
  const placeholder = context === 'life' ? 'Search for tasks' : 'Find a category';

  return (
    <Search
      placeholder={placeholder}
      allowClear
      enterButton
      size="medium"
      onSearch={onSearch}
      loading={loading}
      className="categorySearch"
    />
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    readAllCategoriesInAllTopics: (favorited, friendUuid, categorySearch) =>
      dispatch(topicFunctions.readAllCategoriesInAllTopics(favorited, friendUuid, categorySearch)),
    readCategoriesWithProducts: (favorited, friendUuid, productSearch) =>
      dispatch(productFunctions.readCategoriesWithProducts(favorited, friendUuid, productSearch)),
  }
}

export default connect(null, mapDispatchToProps)(FreeFormSearchBox);
