import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { message, Form, Input, Button } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import OnboardingCarousel from '../components/onboarding/OnboardingCarousel';
import PrivacyStatement from '../components/onboarding/PrivacyStatement';
import UserTypeSelection from '../components/onboarding/UserTypeSelection';
import PregnancyQuestion from '../components/onboarding/OnboardingQuestions/PregnancyQuestion';
import DueDateQuestion from '../components/onboarding/OnboardingQuestions/DueDateQuestion';
import InvitePartner from '../components/onboarding/OnboardingQuestions/InvitePartner';
import ExpertiseSelection from '../components/onboarding/OnboardingQuestions/ExpertiseSelection';
import ConfirmationMessage from '../components/onboarding/ConfirmationMessage';
import {
  setUserType,
  setPregnancyStatus,
  setDueDate,
  setPartnerEmail,
  setUserExpertise,
} from '../redux/users/actions';
import { getEmail, register } from '../redux/authentication/actions';
import "../../assets/css/views/onboarding.css";

const Register = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { token } = useParams();
  const [form] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(-1);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');

  const emailObject = useSelector(state => state.auth.email);
  const getEmailError = useSelector(state => state.auth.getEmailError);
  const registerError = useSelector(state => state.auth.registerError);
  const registerSuccess = useSelector(state => state.auth.registerSuccess);
  const isInvitedPartner = useSelector(state => state.users.isInvitedPartner);
  const invitedByPregnantUser = useSelector(state => state.users.invitedByPregnantUser);
  const invitedByName = useSelector(state => state.users.invitedByName);

  useEffect(() => {
    console.log('Email object:', emailObject);
  }, [emailObject]);

  useEffect(() => {
    dispatch(getEmail(token));
  }, [dispatch, token]);

  useEffect(() => {
    if (emailObject && emailObject.recipient) {
      form.setFieldsValue({ recipient: emailObject.recipient });
    }
  }, [emailObject, form]);

  useEffect(() => {
    if (getEmailError) {
      message.error(getEmailError, 10);
      history.push('/login');
    }
  }, [getEmailError, history]);

  useEffect(() => {
    if (registerError) {
      message.error(registerError, 10);
    }
  }, [registerError]);

  useEffect(() => {
    if (isInvitedPartner && invitedByPregnantUser) {
      showConfirmationMessage(`You have been invited by ${invitedByName} to add notes. Enjoy!`, () => {
        history.push('/moments');
      });
    }
  }, [isInvitedPartner, invitedByPregnantUser, invitedByName, history]);

  const showConfirmationMessage = (message, callback, duration = 3000) => {
    setConfirmationMessage(message);
    setShowConfirmation(true);
    if (callback) {
      setTimeout(() => {
        setShowConfirmation(false);
        callback();
      }, duration);
    }
  };

  const handleEmailConfirmation = (values) => {
    dispatch(register(
      values.recipient,
      values.firstName,
      values.lastName,
      values.password,
      values.confirmPassword,
      token,
      'register'
    ));
    setCurrentStep(0);
  };

  const handlePrivacyAccept = () => {
    setCurrentStep(1);
  };

  const handleUserTypeSelect = (type) => {
    dispatch(setUserType(type));
    if (isInvitedPartner) {
      if (invitedByPregnantUser) {
        showConfirmationMessage("You have been invited by your partner and have full access to the existing checklist and notes.", () => {
          history.push('/moments');
        });
      } else {
        showConfirmationMessage("Welcome! Just a couple of questions to get you set up…", () => {
          setCurrentStep(3);
        });
      }
    } else if (type === 'pregnant') {
      setCurrentStep(2);
    } else if (type === 'partner') {
      setCurrentStep(4);
    } else if (type === 'friend') {
      showConfirmationMessage("All members of our community are kept private. Please ask your friend / family member to send you an invite to get access.");
    } else if (type === 'community') {
      setCurrentStep(5);
    }
  };

  const handlePregnancyStatusSelect = (status) => {
    dispatch(setPregnancyStatus(status));
    if (status === 'Yes') {
      showConfirmationMessage("Congratulations! Only one more question...", () => {
        setCurrentStep(3);
      });
    } else {
      showConfirmationMessage("Ok, your checklist will include tasks that support the earlier stages of family planning.", () => {
        setCurrentStep(4);
      });
    }
  };

  const handleDueDateSelect = (knowsDueDate, date) => {
    if (knowsDueDate) {
      dispatch(setDueDate(date));
      const today = new Date();
      const dueDate = new Date(date);
      const weeksDiff = Math.floor((dueDate - today) / (7 * 24 * 60 * 60 * 1000));
      let trimester;
      if (weeksDiff > 26) {
        trimester = 'first';
      } else if (weeksDiff > 13) {
        trimester = 'second';
      } else {
        trimester = 'third';
      }
      showConfirmationMessage(`Ok, so you're in your ${trimester} trimester...`, () => {
        setCurrentStep(4);
      });
    } else {
      showConfirmationMessage("No problem. We'll provide general information for all stages of pregnancy.", () => {
        setCurrentStep(4);
      });
    }
  };

  const handlePartnerInvite = (email) => {
    if (email) {
      dispatch(setPartnerEmail(email));
    }
    history.push('/moments');
  };

  const handleExpertiseSelect = (expertise) => {
    dispatch(setUserExpertise(expertise));
    showConfirmationMessage("Great! We're happy to have you be a part of the community…", () => {
      history.push('/moments');
    });
  };

  const renderStep = () => {
    if (showConfirmation) {
      return <ConfirmationMessage message={confirmationMessage} />;
    }

    switch (currentStep) {
      case -1:
        return (
          <Form form={form} onFinish={handleEmailConfirmation}>
            <Form.Item name="recipient" label="Email">
              <Input readOnly />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: 'Please choose a password' },
                { min: 8, message: 'Password must be at least 8 characters long' }
              ]}
            >
              <Input.Password prefix={<LockOutlined />} placeholder="Password" />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              dependencies={['password']}
              rules={[
                { required: true, message: 'Please confirm your password' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The passwords do not match'));
                  },
                }),
              ]}
            >
              <Input.Password prefix={<LockOutlined />} placeholder="Confirm Password" />
            </Form.Item>
            <Form.Item
              name="firstName"
              rules={[{ required: true, message: 'Please enter your first name' }]}
            >
              <Input prefix={<UserOutlined />} placeholder="First Name" />
            </Form.Item>
            <Form.Item
              name="lastName"
              rules={[{ required: true, message: 'Please enter your last name' }]}
            >
              <Input prefix={<UserOutlined />} placeholder="Last Name" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Confirm and Continue
              </Button>
            </Form.Item>
          </Form>
        );
      case 0:
        return <PrivacyStatement onAccept={handlePrivacyAccept} firstName={form.getFieldValue('firstName')} />;
      case 1:
        return <UserTypeSelection onSelect={handleUserTypeSelect} />;
      case 2:
        return <PregnancyQuestion onSelect={handlePregnancyStatusSelect} />;
      case 3:
        return <DueDateQuestion onSelect={handleDueDateSelect} />;
      case 4:
        return <InvitePartner onInvite={handlePartnerInvite} onSkip={() => handlePartnerInvite(null)} />;
      case 5:
        return <ExpertiseSelection onSelect={handleExpertiseSelect} />;
      default:
        return null;
    }
  };

  return (
    <div className="register-container">
      <OnboardingCarousel>
        {renderStep()}
      </OnboardingCarousel>
    </div>
  );
};

export default Register;
