import initialState from './initialState';
import * as types from './actionTypes';

const users = (state = initialState, action) => {
  if (typeof action !== 'undefined') {
    switch (action.type) {
      case types.ADD_TOPIC_ERROR:
        return {
          ...state,
          addTopicError: action.addTopicError
        }
      case types.ADD_TOPIC_IN_PROGRESS:
        return {
          ...state,
          addTopicInProgress: action.addTopicInProgress
        }
      case types.UPDATE_TOPIC_IN_PROGRESS:
        return {
          ...state,
          updateTopicInProgress: action.updateTopicInProgress
        }
      case types.UPDATE_TOPIC_SUCCESS:
        return {
          ...state,
          updateTopicSuccess: action.updateTopicSuccess
        }
      case types.UPDATE_TOPIC_ERROR:
        return {
          ...state,
          updateTopicError: action.updateTopicError
        }
      case types.UPDATE_TOPIC_DATA:
        return {
          ...state,
          allCategoriesInTopics: state.allCategoriesInTopics.map(topic =>
            topic.uuid === action.topic.uuid ? {...topic, ...action.topic} : topic
          )
        }
      case types.READ_ALL_TOPICS_IN_PROGRESS:
        return {
          ...state,
          readAllTopicsInProgress: action.readAllTopicsInProgress
        }

      case types.READ_ALL_TOPICS_SUCCESS:
        return {
          ...state,
          readAllTopicsSuccess: action.readAllTopicsSuccess,
          allTopics: action.allTopics
        }

      case types.READ_ALL_TOPICS_ERROR:
        return {
          ...state,
          readAllTopicsError: action.readAllTopicsError
        }

      case types.READ_ALL_CATEGORIES_IN_ALL_TOPICS_IN_PROGRESS:
        return {
          ...state,
          readAllCategoriesInAllTopicsInProgress: action.readAllCategoriesInAllTopicsInProgress
        }

      case types.READ_ALL_CATEGORIES_IN_ALL_TOPICS_SUCCESS:
        if (JSON.stringify(state.allCategoriesInTopics) !== JSON.stringify(action.allCategoriesInTopics)) {
          return {
            ...state,
            readAllCategoriesInAllTopicsSuccess: action.readAllCategoriesInAllTopicsSuccess,
            allCategoriesInTopics: action.allCategoriesInTopics
          };
        }
      case types.READ_ALL_CATEGORIES_IN_ALL_TOPICS_ERROR:
        return {
          ...state,
          readAllCategoriesInAllTopicsError: action.readAllCategoriesInAllTopicsError
        }

      case types.READ_USER_TOPICS_IN_PROGRESS:
        return {
          ...state,
          readUserTopicsInProgress: action.readUserTopicsInProgress
        }

      case types.READ_USER_TOPICS_SUCCESS:
        return {
          ...state,
          readUserTopicsSuccess: action.readUserTopicsSuccess,
          userSelectedTopics: action.userSelectedTopics
        }

      case types.READ_USER_TOPICS_ERROR:
        return {
          ...state,
          readUserTopicsError: action.readUserTopicsError
        }

      case types.SELECT_USER_TOPIC_ERROR:
        return {
          ...state,
          updateUserSelectedTopicsError: action.updateUserSelectedTopicsError
        }

      case types.SELECT_USER_TOPIC_SUCCESS:
        return {
          ...state,
          updateUserSelectedTopicsSuccess: action.updateUserSelectedTopicsSuccess
        }

      case types.SELECT_USER_TOPIC_IN_PROGRESS:
        return {
          ...state,
          updateUserSelectedTopicsInProgress: action.updateUserSelectedTopicsInProgress
        }

      case types.READ_CATEGORIES_IN_TOPIC_SUCCESS:
        return {
          ...state,
          categories: action.categories
        }

      case types.READ_CATEGORIES_IN_TOPIC_IN_PROGRESS:
        return {
          ...state,
          readAllCategoriesInTopicInProgress: action.readAllCategoriesInTopicInProgress
        }

      case types.READ_CATEGORIES_IN_TOPIC_ERROR:
        return {
          ...state,
          readAllCategoriesInTopicError: action.readAllCategoriesInTopicError
        }

      case types.UPDATE_TOPIC_PROGRESS:
        return {
          ...state,
          allCategoriesInTopics: state.allCategoriesInTopics.map(topic =>
            topic.id === action.payload.topicId
              ? { ...topic, completedCategories: action.payload.completedCategories }
              : topic
          )
        };
        
      default:
        return state;
    }
  } else {
    return state
  }
}

export default users;
