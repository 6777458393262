import React, { useState } from "react";
import { connect } from "react-redux";
import { Divider } from "antd";
import { useQuery } from "@tanstack/react-query";

import * as friendsFunctions from "../redux/friends/actions";
import NewProductsHeader from "../components/newProducts/NewProductsHeader.js";
import ProductCategory from "../components/newProducts/ProductCategory.js";
import { getCategoryListData } from "../redux/products/actions";

import "../../assets/css/components/button.css";
import "../../assets/css/components/cards.css";
import "../../assets/css/components/search.css";
import "../../assets/css/components/dropdown.css";
import "../../assets/css/components/header.css";
import "../../assets/css/views/categories.css";

const NewProducts = (props) => {
  let favoritedParam = new URLSearchParams(props.location.search).get("favorited") || true;
  let friendUuid = new URLSearchParams(props.location.search).get("friendUuid");
  const [favorited, setFavorited] = useState(JSON.parse(favoritedParam));
  const [isOpen, setIsOpen] = useState(false);

  const { isPending, error, data } = useQuery({
    queryKey: ["categoryList", friendUuid, favorited],
    queryFn: () => getCategoryListData(),
  });
  const categoryList = data || [];

  const handleFavoriteToggle = (favorited) => {
    props.history.push({
      pathname: "/products",
      search: "?favorited=" + favorited,
    });
    setFavorited(favorited);
  }

  const onSearch = (value) => {
    // Implementation for search functionality
  };

  if (isPending) {
    return (
      <div
        style={{
          margin: 0,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          fontSize: 36,
        }}
      >
        Loading
      </div>
    );
  }

  if (error) return "An error has occurred: " + error.message;

  return (
    <div>
      <Divider />
      <div>
        <NewProductsHeader
          friends={props.friends}
          friendUuid={friendUuid}
          handleFavoriteToggle={handleFavoriteToggle}
          favorited={favorited}
          isPending={isPending}
          onSearch={onSearch}
          location={props.location}
          history={props.history}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
        />
        <Divider />
        <div
          id="scrollableDiv"
          style={{
            height: "83vh",
            width: "100vw",
            overflow: "auto",
            padding: "0 16px",
            border: "1px solid rgba(140, 140, 140, 0.35)",
          }}
        >
          {categoryList.map((category, i) => (
            <div key={i} style={{ width: "100%" }}>
              <ProductCategory category={category} friendUuid={friendUuid} favorited={favorited} setIsOpen={setIsOpen} isOpen={isOpen} />
            </div>
          ))}
        </div>
        <div style={{ height: 60 }} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    friends: state.friends.users,
    products: state.products.products,
    readCategoriesWithProductsInProgress: state.products.readCategoriesWithProductsInProgress,
    readCategoriesWithProductsSuccess: state.products.readCategoriesWithProductsSuccess,
    readCategoriesWithProductsError: state.products.readCategoriesWithProductsError,
    createUserLinkProductError: state.products.createUserLinkProductError,
    createUserLinkProductInProgress: state.products.createUserLinkProductInProgress,
    createUserLinkProductSuccess: state.products.createUserLinkProductSuccess,
    updateUserLinkProductInProgress: state.products.updateUserLinkProductInProgress,
    updateUserLinkProductSuccess: state.products.updateUserLinkProductSuccess,
    updateUserLinkProductError: state.products.updateUserLinkProductError,
    deleteUserLinkProductInProgress: state.products.deleteUserLinkProductInProgress,
    deleteUserLinkProductSuccess: state.products.deleteUserLinkProductSuccess,
    deleteUserLinkProductError: state.products.deleteUserLinkProductError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    readAllUsersFriends: () => dispatch(friendsFunctions.readAllUsersFriends()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewProducts);
