import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { SearchOutlined, TeamOutlined, SettingOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import FreeFormSearchBox from "../../components/inputs/search";
import FriendsList from './FriendsList';
import CustomizeQuiz from './CustomizeQuiz';
import zoeAvatarImage from '../../../assets/images/Zoe.jpg';
import { updateUserChecklist } from '../../redux/users/actions';
import { inviteFriends } from '../../redux/friends/actions';
import "../../../assets/css/components/FilterContainer.css";

const FilterContainer = ({
  friendUuid,
  searchLoading,
  onSearch,
  friends,
  showAddedCategories,
  toggleShowAddedCategories,
  handleFriendSelection,
  currentTab,
  setCurrentTab,
  checklist,
  onQuizComplete,
  selectedFriend,
  userType,
  selectedUser,
  onClose,
}) => {
  const dispatch = useDispatch();
  const [activeButton, setActiveButton] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isQuizStarted, setIsQuizStarted] = useState(false);

  const getBackgroundColor = () => {
     console.log('selectedUser in FilterContainer:', selectedUser);
     const bgColor = !selectedUser || selectedUser.type === 'myList' || selectedUser.type === 'personal'
       ? '#fefabc'
       : selectedUser.type === 'friend'
       ? '#b3d9ff'
       : selectedUser.type === 'expert'
       ? '#c2f0c2'
       : '#fefabc';
     console.log('Calculated background color:', bgColor);
     return bgColor;
   };

  useEffect(() => {
    document.body.style.overflow = isExpanded ? 'hidden' : 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isExpanded]);

  const toggleButton = (buttonName) => {
    if (activeButton === buttonName) {
      setActiveButton(null);
      setIsExpanded(false);
      setIsQuizStarted(false);
    } else {
      setActiveButton(buttonName);
      setIsExpanded(true);
      setIsQuizStarted(false);
    }
  };

  const handleQuizComplete = (updatedChecklist, quizSummary) => {
    console.log("Quiz completed in FilterContainer. Updated checklist:", updatedChecklist);
    console.log("Quiz summary:", quizSummary);
    onQuizComplete(updatedChecklist, quizSummary);
    setIsQuizStarted(false);
  };

  const handleInviteFriend = (email, userType) => {
    dispatch(inviteFriends(email, userType));
  };

  const renderContent = () => {
    switch (activeButton) {
      case 'search':
        return (
          <FreeFormSearchBox
            friendUuid={friendUuid}
            context={currentTab}
            loading={searchLoading}
            onSearch={onSearch}
          />
        );
      case 'friends':
        return <FriendsList friends={friends} handleFriendSelection={handleFriendSelection} onInvite={handleInviteFriend} />;
      case 'customize':
        return isQuizStarted ? (
          <CustomizeQuiz
            currentTab={currentTab.toLowerCase()}
            onComplete={handleQuizComplete}
            initialChecklist={Array.isArray(checklist) ? checklist : []}
            updateChecklist={(addedItems, removedItems) => {
              dispatch(updateUserChecklist(addedItems, removedItems));
            }}
          />
        ) : (
          <div className="quiz-intro">
            <p>Take our personalization quiz to tailor your checklist and product recommendations to your unique needs and preferences.</p>
            <Button onClick={() => setIsQuizStarted(true)} type="primary">Start Customization Quiz</Button>
          </div>
        );
      default:
        return null;
    }
  };

  const handleContainerClick = (e) => {
    if (e.target === e.currentTarget) {
      setIsExpanded(false);
      setActiveButton(null);
      setIsQuizStarted(false);
      onClose();
    }
  };

  const renderAvatar = () => {
  if (!selectedUser || selectedUser.type === 'myList') {
    return <div className="friend-avatar" data-user="myList">🐵</div>; // Monkey emoji for My List
  }

  if (selectedUser.first_name === 'Zoe' && selectedUser.last_name === 'Frankel') {
    return <img src={zoeAvatarImage} alt="Zoe Frankel" className="friend-avatar" data-user="friend" />;
  }

  if (selectedUser.avatar) {
    if (typeof selectedUser.avatar === 'string' && (selectedUser.avatar.startsWith('http') || selectedUser.avatar.startsWith('data:'))) {
      return <img src={selectedUser.avatar} alt={selectedUser.name || 'User avatar'} className="friend-avatar" data-user={selectedUser.type} />;
    } else {
      return <div className="friend-avatar" data-user={selectedUser.type}>{selectedUser.avatar}</div>;
    }
  }

  return <div className="avatar-placeholder" data-user={selectedUser.type}>{selectedUser.name ? selectedUser.name[0] : '?'}</div>;
};


  return (
  <div
    className={`filter-container ${isExpanded ? 'expanded' : ''}`}
    onClick={handleContainerClick}
    style={{ backgroundColor: getBackgroundColor() }}
  >
    <div className="filter-content">
      <div className="filter-left">
        <div className="friend-tab">
          {renderAvatar()}
          <span>{selectedUser ? selectedUser.name : 'No user selected'}</span>
        </div>
      </div>
        <div className="filter-right">
          <div className="filter-row">
            <Button
              className={`tab-button ${currentTab === 'life' ? 'active' : ''}`}
              onClick={() => setCurrentTab('life')}
            >
              <div className="button-content">
                <span className="button-icon" role="img" aria-label="calendar">📅</span>
                <span className="button-text">Calendar</span>
              </div>
            </Button>
            <Button
              className={`tab-button ${currentTab === 'products' ? 'active' : ''}`}
              onClick={() => setCurrentTab('products')}
            >
              <div className="button-content">
                <span className="button-icon" role="img" aria-label="products">🍼</span>
                <span className="button-text">Products</span>
              </div>
            </Button>
            <Button
              className={`tab-button ${currentTab === 'notes' ? 'active' : ''}`}
              onClick={() => setCurrentTab('notes')}
            >
              <div className="button-content">
                <span className="button-icon" role="img" aria-label="clipboard">📝</span>
                <span className="button-text">Notes</span>
              </div>
            </Button>
          </div>
          <div className="filter-row">
            <Button
              icon={<SearchOutlined />}
              className={activeButton === 'search' ? 'active' : ''}
              onClick={() => toggleButton('search')}
            />
            <Button
              icon={<TeamOutlined />}
              className={activeButton === 'friends' ? 'active' : ''}
              onClick={() => toggleButton('friends')}
            />
            <Button
              icon={<SettingOutlined />}
              className={activeButton === 'customize' ? 'active' : ''}
              onClick={() => toggleButton('customize')}
            />
          </div>
        </div>
      </div>
      {isExpanded && (
        <div className="filter-content-container" onClick={(e) => e.stopPropagation()}>
          {renderContent()}
        </div>
      )}
    </div>
  );
};

export default FilterContainer;
