import axios from 'axios';
import * as types from './actionTypes';
import { isEmpty } from '../../../app/helperFunctions';

export function addTopicError(error) {
  return {
    type: types.ADD_TOPIC_ERROR,
    addTopicError: error
  }
}

export function addTopicInProgress(bool) {
  return {
    type: types.ADD_TOPIC_IN_PROGRESS,
    addTopicInProgress: bool
  }
}

function readAllTopicsInProgress(bool) {
  return {
    type: types.READ_ALL_TOPICS_IN_PROGRESS,
    readAllTopicsInProgress: bool
  }
}

function readAllTopicsError(errMessage) {
  return {
    type: types.READ_ALL_TOPICS_ERROR,
    readAllTopicsError: errMessage
  };
}

function readAllTopicsSuccess(bool, topics) {
  return {
    type: types.READ_ALL_TOPICS_SUCCESS,
    readAllTopicsSuccess: bool,
    allTopics: topics
  };
}

function readAllCategoriesInAllTopicsInProgress(bool) {
  return {
    type: types.READ_ALL_CATEGORIES_IN_ALL_TOPICS_IN_PROGRESS,
    readAllCategoriesInAllTopicsInProgress: bool
  }
}

function readAllCategoriesInAllTopicsError(errMessage) {
  return {
    type: types.READ_ALL_CATEGORIES_IN_ALL_TOPICS_ERROR,
    readAllCategoriesInAllTopicsError: errMessage
  };
}

function readAllCategoriesInAllTopicsSuccess(bool, categoriesInTopics) {
  return {
    type: types.READ_ALL_CATEGORIES_IN_ALL_TOPICS_SUCCESS,
    readAllCategoriesInAllTopicsSuccess: bool,
    allCategoriesInTopics: categoriesInTopics
  };
}

function readUserTopicsInProgress(bool) {
  return {
    type: types.READ_USER_TOPICS_IN_PROGRESS,
    readUserTopicsInProgress: bool
  }
}

function readUserTopicsError(errMessage) {
  return {
    type: types.READ_USER_TOPICS_ERROR,
    readUserTopicsError: errMessage
  };
}

function readUserTopicsSuccess(bool, topics) {
  return {
    type: types.READ_USER_TOPICS_SUCCESS,
    readUserTopicsSuccess: bool,
    userSelectedTopics: topics
  };
}

function updateUserSelectedTopicsInProgress(bool) {
  return {
    type: types.SELECT_USER_TOPIC_IN_PROGRESS,
    updateUserSelectedTopicsInProgress: bool
  }
}

function updateUserSelectedTopicsSuccess(bool) {
  return {
    type: types.SELECT_USER_TOPIC_SUCCESS,
    updateUserSelectedTopicsSuccess: bool
  }
}

function updateUserSelectedTopicsError(message) {
  return {
    type: types.SELECT_USER_TOPIC_ERROR,
    updateUserSelectedTopicsError: message
  }
}

export function updateTopicData(allTopics) {
  return{
    type: types.UPDATE_TOPIC_DATA,
    allTopics: allTopics
  }
}

function updateTopicError(error) {
  return {
    type: types.UPDATE_TOPIC_ERROR,
    updateTopicError: error
  }
}

function updateTopicInProgress(bool) {
  return {
    type: types.UPDATE_TOPIC_IN_PROGRESS,
    updateTopicInProgress: bool
  }
}

function updateTopicSuccess(bool, topic) {
  return {
    type: types.UPDATE_TOPIC_SUCCESS,
    updateTopicSuccess: bool
  }
}

function readAllCategoriesInTopicInProgress(bool) {
  return {
    type: types.READ_CATEGORIES_IN_TOPIC_IN_PROGRESS,
    readAllCategoriesInTopicInProgress: bool
  }
}

function readAllCategoriesInTopicSuccess(categories) {
  return {
    type: types.READ_CATEGORIES_IN_TOPIC_SUCCESS,
    categories
  }
}

function readAllCategoriesInTopicError(errMessage) {
  return {
    type: types.READ_CATEGORIES_IN_TOPIC_ERROR,
    readAllCategoriesInTopicError: errMessage
  }
}

export function readAllTopics() {
  return(dispatch) => {
    let url = `${process.env.REACT_APP_API_URL}/topics`;
    let config = {headers: {Authorization: 'Bearer '+sessionStorage.getItem('jwt')}};
    dispatch(readAllTopicsInProgress(true));
    axios.get(url, config)
      .then((response) => {
        if (!isEmpty(response)) {
          dispatch(readAllTopicsSuccess(true, response.data));
        } else {
          dispatch(readAllTopicsSuccess(false, null));
        }
      })
      .catch(error => {
        let errMessage = 'Error fetching topic data. Please try again later.';
        if (error.response !== undefined) {
          errMessage = error.response;
          if (typeof error === 'object') {
            if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
              if (typeof error.response.data === 'object') {
                if ('Error' in error.response.data) {
                  errMessage = error.response.data['Error'];
                }
              } else {
                errMessage = error.response.data;
              }
            }
          }
        }
        dispatch(readAllTopicsError(errMessage));
      })
      .finally(() => {
        dispatch(readAllTopicsInProgress(false));
        dispatch(readAllTopicsError(''));
      });
  }
}

export function readAllCategoriesInAllTopics(favorited=null, friendUuid=null, categorySearch=null) {
  return (dispatch) => {
    let url = `${process.env.REACT_APP_API_URL}/topics/categories`;
    let config = {headers: {Authorization: 'Bearer '+sessionStorage.getItem('jwt')}};
    let data = {};
    if (favorited != null) {
      data.favorited = favorited
    }
    if (friendUuid != null) {
      data.friendUuid = friendUuid
    }
    if (categorySearch != null) {
      data.categorySearch = categorySearch
    }
    dispatch(readAllCategoriesInAllTopicsInProgress(true));
    axios.post(url, data, config)
      .then((response) => {
        if (!isEmpty(response)) {
          dispatch(readAllCategoriesInAllTopicsSuccess(true, response.data));
        } else {
          dispatch(readAllCategoriesInAllTopicsSuccess(false, null));
        }
      })
      .catch(error => {
        let errMessage = 'Error fetching topic/category data. Please try again later.';
        if (error.response !== undefined) {
          errMessage = error.response;
          if (typeof error === 'object') {
            if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
              if (typeof error.response.data === 'object') {
                if ('Error' in error.response.data) {
                  errMessage = error.response.data['Error'];
                }
              } else {
                errMessage = error.response.data;
              }
            }
          }
        }
        dispatch(readAllCategoriesInAllTopicsError(errMessage));
      })
      .finally(() => {
        dispatch(readAllCategoriesInAllTopicsInProgress(false));
        dispatch(readAllCategoriesInAllTopicsError(''));
      });
  }
}

export function readUserSelectedTopics() {
  return(dispatch) => {
    let url = `${process.env.REACT_APP_API_URL}/users/topics`;
    let config = {headers: {Authorization: 'Bearer '+sessionStorage.getItem('jwt')}};
    dispatch(readUserTopicsInProgress(true));
    axios.get(url, config)
      .then((response) => {
        if (!isEmpty(response)) {
          dispatch(readUserTopicsSuccess(true, response.data));
        } else {
          dispatch(readUserTopicsSuccess(true, []));
        }
      })
      .catch(error => {
        let errMessage = 'Error fetching topic data. Please try again later.';
        if (error.response !== undefined) {
          errMessage = error.response;
          if (typeof error === 'object') {
            if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
              if (typeof error.response.data === 'object') {
                if ('Error' in error.response.data) {
                  errMessage = error.response.data['Error'];
                }
              } else {
                errMessage = error.response.data;
              }
            }
          }
        }
        dispatch(readUserTopicsError(errMessage));
      })
      .finally(() => {
        dispatch(readUserTopicsInProgress(false));
        dispatch(readUserTopicsError(''));
      });
  }
}

export function updateUserSelectedTopics(topicUuids) {
  return (dispatch) => {
    let url = `${process.env.REACT_APP_API_URL}/users/topics`;
    let config = {headers: {Authorization: 'Bearer '+sessionStorage.getItem('jwt')}};
    dispatch(updateUserSelectedTopicsInProgress(true));
    let data = {
      uuids: topicUuids
    }
    axios.patch(url, data, config)
    .then((response) => {
      dispatch(updateUserSelectedTopicsSuccess(true));
      dispatch(readUserSelectedTopics());
    })
    .catch(error => {
      let errMessage = 'Error selecting topic. Please try again later.';
      if (error.response !== undefined) {
        errMessage = error.response;
        if (typeof error === 'object') {
          if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
            if (typeof error.response.data === 'object') {
              if ('Error' in error.response.data) {
                errMessage = error.response.data['Error'];
              }
            } else {
              errMessage = error.response.data;
            }
          }
        }
      }
      dispatch(updateUserSelectedTopicsError(errMessage));
    })
    .finally(() => {
      dispatch(updateUserSelectedTopicsError(''));
      dispatch(updateUserSelectedTopicsSuccess(false));
      dispatch(updateUserSelectedTopicsInProgress(false));
    })
  }
}

export function updateTopic(topic, values) {
  return (dispatch) => {
    let config = {headers: {Authorization: 'Bearer '+sessionStorage.getItem('jwt')}};
    let url = `${process.env.REACT_APP_API_URL}/topics/${topic.uuid}`;
    const data = {
      first_name: values.firstName,
      last_name: values.lastName,
      time_zone: values.time_zone
    };
    dispatch(updateTopicInProgress(true));
    axios.patch(url, data, config)
      .then((response) => {
        dispatch(updateTopicData(response.data.topic));
        dispatch(updateTopicSuccess(true));
        dispatch(updateTopicInProgress(false));
      })
      .catch(error => {
        let errMessage = 'Error updating topic';
        if (error.response !== undefined) {
          errMessage = error.response;
          if (typeof error === 'object') {
            if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
              if (typeof error.response.data === 'object') {
                if ('Error' in error.response.data) {
                  errMessage = error.response.data['Error'];
                }
              } else {
                errMessage = error.response.data;
              }
            }
          }
        }
        dispatch(updateTopicError(errMessage));
        dispatch(updateTopicInProgress(false));
      })
      .finally(() => {
        dispatch(updateTopicError(''));
        dispatch(updateTopicSuccess(false));
        dispatch(updateTopicInProgress(false));
      });
  }
}

export function readCategoriesInTopic(topicUuid) {
  return (dispatch) => {
    let url = `${process.env.REACT_APP_API_URL}/topic/${topicUuid}/categories`;
    let config = {headers: {Authorization: 'Bearer '+sessionStorage.getItem('jwt')}};
    dispatch(readAllCategoriesInTopicInProgress(true));
    axios.get(url, config)
      .then((response) => {
        if (!isEmpty(response)) {
          dispatch(readAllCategoriesInTopicSuccess(response.data));
        } else {
          dispatch(readAllCategoriesInTopicSuccess([]));
        }
      })
      .catch(error => {
        let errMessage = 'Error fetching product data. Please try again later.';
        if (error.response !== undefined) {
          errMessage = error.response;
          if (typeof error === 'object') {
            if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
              if (typeof error.response.data === 'object') {
                if ('Error' in error.response.data) {
                  errMessage = error.response.data['Error'];
                }
              } else {
                errMessage = error.response.data;
              }
            }
          }
        }
        dispatch(readAllCategoriesInTopicError(errMessage));
      })
      .finally(() => {
        dispatch(readAllCategoriesInTopicInProgress(false));
        dispatch(readAllCategoriesInTopicError(''));
      });
  }
}

export function createNewMoment(moment) {
  return (dispatch) => {
    // Implement the logic to create a new moment
    // This might involve an API call and then dispatching an action
    console.log('Creating new moment:', moment);
    // dispatch({ type: types.CREATE_NEW_MOMENT, payload: moment });
  };
}

export function updateTopicProgress(topicId, completedCategories) {
  return (dispatch) => {
    let url = `${process.env.REACT_APP_API_URL}/topics/${topicId}/progress`;
    let config = {headers: {Authorization: 'Bearer '+sessionStorage.getItem('jwt')}};
    dispatch(updateTopicInProgress(true));
    axios.patch(url, { completedCategories }, config)
      .then((response) => {
        dispatch({
          type: types.UPDATE_TOPIC_PROGRESS,
          payload: { topicId, completedCategories }
        });
        dispatch(updateTopicSuccess(true, response.data.topic));
      })
      .catch(error => {
        let errMessage = 'Error updating topic progress';
        if (error.response !== undefined) {
          errMessage = error.response.data.Error || error.response.data;
        }
        dispatch(updateTopicError(errMessage));
      })
      .finally(() => {
        dispatch(updateTopicInProgress(false));
      });
  }
}
