import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import LoginRouter from './routing/LoginRouter';
import AppRouter from './routing/AppRouter';
import { checkLogin, logout } from './redux/authentication/actions';
import { isEmpty } from './helperFunctions';
import '../assets/css/App.css';

const App = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkUserLogin = async () => {
      console.log("Starting login check");
      try {
        await dispatch(checkLogin());
      } catch (error) {
        console.error("Login check failed:", error);
      } finally {
        console.log("Login check completed");
        // Force the spinner to be visible for at least 1 second
        setTimeout(() => {
          setLoading(false);
          console.log("Loading set to false");
        }, 1000);
      }
    };

    checkUserLogin();
  }, [dispatch]);

  useEffect(() => {
    const handleBeforeUnload = (ev) => {
      if (!isEmpty(user)) {
        ev.preventDefault();
        dispatch(logout(user));
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [dispatch, user]);

  console.log("Rendering. Loading:", loading, "User:", user);

  if (loading) {
    return <p>Loading</p>;
  }

  return user ? <AppRouter /> : <LoginRouter />;
};

export default App;
