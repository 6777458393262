import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Row, Steps, Form } from 'antd';
import DueDate from '../components/inputs/DueDate.js';
import Partner from '../components/inputs/Partner.js';
import PrimaryUserName from '../components/inputs/PrimaryUserName.js';
import * as userActions from '../redux/users/actions';

const { Step } = Steps;

class OnboardingFlow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedItem: null,
      stepCount: 0
    }
    this.form = React.createRef();
  }

  onNextButtonClick = (event) => {
    this.setState((state) => ({stepCount: state.stepCount + 1}));
  };

  onFormFinish = (values) => {
    values.due_date = values.due_date.format('MM/DD/YYYY');
    this.props.createMultipleUserAttributes(this.props.user, values);
  }

  render() {
    return (
      <div>
        <Row style={{ textAlign: 'center', marginTop: 100}} >
          <Col xs={{span: 24}} sm={{span: 24}} md={{span: 8}} lg={{span: 8}} />
          <Col xs={{span: 24}} sm={{span: 24}} md={{span: 8}} lg={{span: 8}}>
            <Steps current={this.state.stepCount}>
              <Step />
              <Step />
              <Step />
            </Steps>
          </ Col>
          <Col xs={{span: 24}} sm={{span: 24}} md={{span: 8}} lg={{span: 8}} />
        </ Row>
        <Form ref={this.form} onFinish={this.onFormFinish} preserve={true}>
          <PrimaryUserName stepCount={this.state.stepCount} />
          <DueDate stepCount={this.state.stepCount} />
          <Partner stepCount={this.state.stepCount} />
          <Row style={{ textAlign: 'right' }}>
            <Col xs={{span: 24}} sm={{span: 24}} md={{span: 8}} lg={{span: 8}} />
            <Col xs={{span: 24}} sm={{span: 24}} md={{span: 8}} lg={{span: 8}}>
              {this.state.stepCount <= 1 ?
                <div>
                  <Button style={styles.NextButton} onClick={this.onNextButtonClick}>
                    Next
                  </ Button>
                  <Button ghost style={styles.SkipThisButton} onClick={this.onNextButtonClick}>
                    Skip this
                  </ Button>
                </div>
              :
                <Button type="primary" htmlType="submit" style={styles.NextButton}>Submit</Button>
              }
            </ Col>
            <Col xs={{span: 24}} sm={{span: 24}} md={{span: 8}} lg={{span: 8}} />
          </ Row>
        </Form>
      </ div>
    );
  }
};

const styles = {
  SkipThisButton: {
    color: 'grey'
  },
  NextButton: {
    color: 'white',
    backgroundColor: '#7E3FFF'
  }
}


const mapStateToProps = (state) => {
  return {}
};

const mapDispatchToProps = (dispatch) => {
  return {
    createMultipleUserAttributes: (user, attributes) => dispatch(userActions.createMultipleUserAttributes(user, attributes))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingFlow);
