export const sampleSharedNotes = [
  {
    id: 1,
    author: "Emma Johnson",
    isFromFriend: true,
    content: "I found it helpful to write down all my questions before the appointment. Don't be afraid to ask about anything that's on your mind!",
    relevance: ""
  },
  {
    id: 2,
    author: "Sophia Lee",
    isFromFriend: true,
    content: "My doctor recommended starting folic acid supplements right away. It's never too early to start taking care of your future baby!",
    relevance: ""
  },
  {
    id: 3,
    author: "Olivia Garcia",
    isFromFriend: true,
    content: "Don't forget to discuss any medications you're currently taking. Some might need to be adjusted before trying to conceive.",
    relevance: ""
  },
  {
    id: 4,
    author: "Ava",
    isFromFriend: false,
    content: "I was nervous about my first preconception appointment, but my doctor was so supportive. It really helped ease my anxiety about the whole process.",
    relevance: "Also expressed anxiety about first appointment"
  },
  {
    id: 5,
    author: "Mia",
    isFromFriend: false,
    content: "Make sure to discuss any genetic conditions that run in your family. We did carrier screening and it gave us peace of mind.",
    relevance: "Similar age group and first-time parent"
  },
  {
    id: 6,
    author: "Isabella",
    isFromFriend: false,
    content: "Don't forget to talk about your work environment. I hadn't considered how some jobs might affect pregnancy.",
    relevance: "Similar professional background"
  },
  {
    id: 7,
    author: "Charlotte",
    isFromFriend: false,
    content: "I found it helpful to bring my partner to the appointment. It made us feel like we were truly in this together from the start.",
    relevance: "Also planning pregnancy with partner"
  },
  {
    id: 8,
    author: "Amelia",
    isFromFriend: false,
    content: "Ask about recommended lifestyle changes. I was surprised by some of the suggestions, like avoiding certain types of fish.",
    relevance: "Similar health profile"
  },
  {
    id: 9,
    author: "Harper",
    isFromFriend: false,
    content: "Don't be embarrassed to discuss your menstrual cycle in detail. It's really important for planning conception!",
    relevance: "Similar menstrual health concerns"
  },
  {
    id: 10,
    author: "Evelyn",
    isFromFriend: false,
    content: "I found it helpful to start a preconception journal after my appointment. It helped me keep track of all the advice and changes I needed to make.",
    relevance: "Also using app for pregnancy planning"
  }
];
