export const READ_ALL_FRIENDS_SUCCESS = 'READ_ALL_FRIENDS_SUCCESS';
export const READ_ALL_FRIENDS_ERROR = 'READ_ALL_FRIENDS_ERROR';
export const INVITE_FRIEND_SUCCESS = 'INVITE_FRIEND_SUCCESS';
export const INVITE_FRIEND_ERROR = 'INVITE_FRIEND_ERROR';
export const ADD_FRIEND_ERROR = 'ADD_FRIEND_ERROR';
export const ADD_FRIEND_SUCCESS = 'ADD_FRIEND_SUCCESS';
export const ADD_FRIEND_IN_PROGRESS = 'ADD_FRIEND_IN_PROGRESS';
export const REMOVE_FRIEND_ERROR = 'REMOVE_FRIEND_ERROR';
export const REMOVE_FRIEND_SUCCESS = 'REMOVE_FRIEND_SUCCESS';
export const REMOVE_FRIEND_IN_PROGRESS = 'REMOVE_FRIEND_IN_PROGRESS';
export const READ_RECEIVED_FRIEND_REQUESTS_SUCCESS = 'READ_RECEIVED_FRIEND_REQUESTS_SUCCESS';
export const READ_RECEIVED_FRIEND_REQUESTS_ERROR = 'READ_RECEIVED_FRIEND_REQUESTS_ERROR';
export const READ_SENT_FRIEND_REQUESTS_SUCCESS = 'READ_SENT_FRIEND_REQUESTS_SUCCESS';
export const READ_SENT_FRIEND_REQUESTS_ERROR = 'READ_SENT_FRIEND_REQUESTS_ERROR';
export const REJECT_EMAIL_ERROR = 'REJECT_EMAIL_ERROR';
export const REJECT_EMAIL_SUCCESS = 'REJECT_EMAIL_SUCCESS';
export const REJECT_EMAIL_IN_PROCESS = 'REJECT_EMAIL_IN_PROCESS';
export const SET_SELECTED_FRIENDS = 'SET_SELECTED_FRIENDS';
export const FETCH_FRIENDS_NOTES_SUCCESS = 'FETCH_FRIENDS_NOTES_SUCCESS';
export const FETCH_FRIENDS_NOTES_ERROR = 'FETCH_FRIENDS_NOTES_ERROR';
export const FETCH_FRIEND_LISTS_REQUEST = 'FETCH_FRIEND_LISTS_REQUEST';
export const FETCH_FRIEND_LISTS_SUCCESS = 'FETCH_FRIEND_LISTS_SUCCESS';
export const FETCH_FRIEND_LISTS_FAILURE = 'FETCH_FRIEND_LISTS_FAILURE';
export const SELECT_FRIEND_LIST = 'SELECT_FRIEND_LIST';
