import React from 'react';
import { Table, Button, Popconfirm } from 'antd';
import { connect } from 'react-redux';
import * as friendsFunctions from '../../redux/friends/actions';

const ConsolidatedFriendsTable = ({
  friends = [],
  requestsReceived = [],
  requestsSent = [],
  addNewFriend,
  rejectEmail,
  removeFriend,
  handleViewFriendList
}) => {
  const dataSource = [
    ...(friends || []).map(friend => ({
      ...friend,
      status: 'Friend',
      key: friend.uuid
    })),
    ...(requestsReceived || []).map(request => ({
      ...request,
      status: 'Waiting for your response',
      key: request.uuid
    })),
    ...(requestsSent || []).map(request => ({
      ...request,
      status: 'Invite sent',
      key: request.uuid
    }))
  ];

  const columns = [
    {
      title: 'Name',
      dataIndex: 'first_name',
      render: (text, record) => `${record.first_name} ${record.last_name}`
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render: (text, record) => record.email || record.sender_email || record.recipient_email
    },
    {
      title: 'Status',
      dataIndex: 'status'
    },
    {
      title: 'Actions',
      dataIndex: 'operation',
      render: (_, record) => {
        switch (record.status) {
          case 'Friend':
            return (
              <>
                <Button onClick={() => handleViewFriendList(record.key)}>View List</Button>
                <Popconfirm title="Confirm delete?" onConfirm={() => removeFriend(record.key)}>
                  <Button style={{ color: 'red', borderColor: '#ffcccb' }}>Remove</Button>
                </Popconfirm>
              </>
            );
          case 'Waiting for your response':
            return (
              <>
                <Button onClick={() => addNewFriend(record.sender_email)}>Accept</Button>
                <Popconfirm title="Confirm Cancel?" onConfirm={() => rejectEmail(record)}>
                  <Button>Cancel</Button>
                </Popconfirm>
              </>
            );
          case 'Invite sent':
            return (
              <Popconfirm title="Confirm Cancel?" onConfirm={() => rejectEmail(record)}>
                <Button>Cancel</Button>
              </Popconfirm>
            );
          default:
            return null;
        }
      }
    }
  ];

  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      rowClassName={() => 'editable-row'}
      pagination={{ pageSize: 10 }}
      scroll={{ x: true }}
    />
  );
};

const mapDispatchToProps = (dispatch) => ({
  rejectEmail: (invite) => dispatch(friendsFunctions.rejectEmail(invite)),
  addNewFriend: (email) => dispatch(friendsFunctions.addNewFriend(email)),
  removeFriend: (uuid) => dispatch(friendsFunctions.removeFriend(uuid)),
});

export default connect(null, mapDispatchToProps)(ConsolidatedFriendsTable);
