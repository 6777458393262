import React, { useState } from 'react';
import { DatePicker, Button, Typography } from 'antd';

const { Title, Text } = Typography;

const DueDateQuestion = ({ onSelect }) => {
  const [knowsDueDate, setKnowsDueDate] = useState(null);
  const [dueDate, setDueDate] = useState(null);

  const handleSubmit = () => {
    onSelect(knowsDueDate, dueDate);
  };

  return (
    <div className="due-date-question">
      <Title level={2}>Do you know your due date?</Title>
      <Text>Your checklist will only include tasks that are relevant to your current trimester.</Text>
      <div className="button-group">
        <Button onClick={() => setKnowsDueDate(true)}>Yes</Button>
        <Button onClick={() => {
          setKnowsDueDate(false);
          handleSubmit();
        }}>No</Button>
      </div>
      {knowsDueDate && (
        <>
          <DatePicker onChange={(date) => setDueDate(date)} />
          <Button onClick={handleSubmit}>Submit</Button>
        </>
      )}
    </div>
  );
};

export default DueDateQuestion;
