const milestones = [
  {
    key: 'milestone-1',
    id: 1,
    text: "Confirmed pregnancy with home test",
    date: "2023-01-15",
    achieved: false,
    icon: "CheckCircleOutlined",
    notes: "The moment we saw those two lines, our hearts filled with joy and excitement!",
    type: "medical",
    category: "medical-appointment",
    stage: "Pre-pregnancy",
    details: "Home pregnancy tests detect the hormone hCG in urine. For most accurate results, use first morning urine and follow instructions carefully. If positive, schedule an appointment with your healthcare provider for confirmation and to begin prenatal care.",
    flexibility: "flexible",
    flexibilityReason: "The exact date of confirmation can vary based on when you decide to take the test.",
    frequency: "once",
    group: "preconception-health",
    dependsOn: null,
    additionalInputs: ["brand of test used", "time of day test was taken"],
    resources: ["CDC's Preconception Health and Health Care page"],
    features: ["Interactive quiz on preconception health knowledge", "Customizable questionnaire template with common preconception concerns"],
    relevantUntil: 6,
    notePlaceholders: {
      private: ["Use this space to take your notes. For example: List questions for your doctor about preconception health."],
      friends: ["Use this space to take your notes. For example: Ask friends about their preconception check-up experiences."],
      community: ["Use this space to take your notes. For example: Share tips for finding a good OB-GYN for preconception care."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-2',
    id: 2,
    text: "Found out due date",
    date: "2023-01-20",
    achieved: false,
    icon: "CalendarOutlined",
    notes: "",
    type: "medical",
    category: "medical-appointment",
    stage: "First trimester",
    details: "Your due date is typically calculated as 40 weeks from the first day of your last menstrual period. This is an estimate; only about 5% of babies are born on their exact due date. Your healthcare provider may adjust this date based on early ultrasound results.",
    flexibility: "rigid",
    flexibilityReason: "The due date is usually calculated by a healthcare professional based on specific criteria.",
    frequency: "once",
    group: "pregnancy-milestones",
    dependsOn: 1,
    additionalInputs: ["method of calculation (LMP or ultrasound)", "healthcare provider's name"],
    resources: ["American Pregnancy Association's Due Date Calculator"],
    features: ["Due date calculator", "Pregnancy timeline visualization"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Note your due date and any questions you have about the pregnancy timeline."],
      friends: ["Share your due date and any excitement or concerns with close friends."],
      community: ["Discuss general tips for preparing for your due date with other expectant parents."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-3',
    id: 3,
    text: "Had first ultrasound and heard heartbeat",
    date: "2023-02-10",
    achieved: false,
    icon: "SoundOutlined",
    notes: "",
    type: "medical",
    category: "medical-appointment",
    stage: "First trimester",
    details: "The first ultrasound usually occurs between weeks 6-8. You may see the gestational sac, yolk sac, and possibly the fetal pole. The heartbeat might be detectable from around 6 weeks. This scan confirms the pregnancy's viability and can help date the pregnancy accurately.",
    flexibility: "semi-flexible",
    flexibilityReason: "The timing can vary based on your healthcare provider's recommendations and appointment availability.",
    frequency: "once",
    group: "pregnancy-milestones",
    dependsOn: 2,
    additionalInputs: ["gestational age at time of ultrasound", "heart rate (if detected)"],
    resources: ["What to Expect: First Ultrasound"],
    features: ["Ultrasound image gallery", "Fetal development timeline"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Describe your emotions during the ultrasound. Note any questions for your next appointment."],
      friends: ["Share your experience of hearing the heartbeat with close friends or family."],
      community: ["Discuss general first ultrasound experiences with other expectant parents."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-4',
    id: 4,
    text: "Announced pregnancy to family and friends",
    date: "2023-02-15",
    achieved: false,
    icon: "NotificationOutlined",
    notes: "",
    type: "personal",
    category: "life-event",
    stage: "First trimester",
    details: "Many people wait until after the first trimester (around 12 weeks) to announce their pregnancy, as the risk of miscarriage decreases significantly by then. However, this is a personal decision. Consider how you'd feel about sharing early if complications were to arise.",
    flexibility: "very flexible",
    flexibilityReason: "This is entirely a personal decision and can happen at any point during the pregnancy.",
    frequency: "once",
    group: "pregnancy-milestones",
    dependsOn: null,
    additionalInputs: ["method of announcement", "reactions received"],
    resources: ["Creative Pregnancy Announcement Ideas"],
    features: ["Announcement template creator", "Social media announcement scheduler"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Note down your plans for announcing the pregnancy and any special reactions."],
      friends: ["Share your announcement experience with close friends."],
      community: ["Discuss creative pregnancy announcement ideas with other expectant parents."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-5',
    id: 5,
    text: "Started taking prenatal vitamins",
    date: "2023-02-16",
    achieved: false,
    icon: "MedicineBoxOutlined",
    notes: "",
    type: "medical",
    category: "health-management",
    stage: "First trimester",
    details: "Prenatal vitamins supplement your diet, providing essential nutrients for fetal development. Key components include folic acid, iron, calcium, and DHA. Ideally, start taking them before conception. Consult your healthcare provider about which supplements are right for you.",
    flexibility: "flexible",
    flexibilityReason: "While it's best to start early, you can begin taking prenatal vitamins at any point during pregnancy.",
    frequency: "daily",
    group: "pregnancy-health",
    dependsOn: null,
    additionalInputs: ["brand of vitamins", "any side effects experienced"],
    resources: ["American Pregnancy Association: Prenatal Vitamin Guide"],
    features: ["Vitamin reminder notifications", "Side effect tracker"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Note the brand of prenatal vitamins you're taking and any effects you notice."],
      friends: ["Discuss prenatal vitamin experiences with close friends who are also pregnant."],
      community: ["Share tips for choosing prenatal vitamins or managing side effects."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-6',
    id: 6,
    text: "Completed first trimester screening",
    date: "2023-03-05",
    achieved: false,
    icon: "SafetyOutlined",
    notes: "",
    type: "medical",
    category: "medical-appointment",
    stage: "First trimester",
    details: "First trimester screening typically includes blood tests and an ultrasound, usually performed between weeks 11-13. It assesses the risk of certain chromosomal conditions like Down syndrome. This is optional and can help guide decisions about further diagnostic testing.",
    flexibility: "semi-rigid",
    flexibilityReason: "This screening must be done within a specific timeframe to be accurate, but there's some flexibility within that window.",
    frequency: "once",
    group: "pregnancy-health",
    dependsOn: null,
    additionalInputs: ["results of screening", "follow-up actions if any"],
    resources: ["American Pregnancy Association: First Trimester Screen"],
    features: ["Test result tracker", "Risk assessment calculator"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Note down the results of your screening and any questions for your healthcare provider."],
      friends: ["Share your experience with the screening process with close friends, if you're comfortable."],
      community: ["Discuss general information about first trimester screening with other expectant parents."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-7',
    id: 7,
    text: "Felt baby's first movements",
    date: "2023-04-20",
    achieved: false,
    icon: "ThunderboltOutlined",
    notes: "",
    type: "physical",
    category: "pregnancy-milestone",
    stage: "Second trimester",
    details: "First-time mothers usually feel fetal movements, called 'quickening', between 18-25 weeks. Experienced mothers might feel it earlier. Initially, movements feel like flutters or bubbles. As the pregnancy progresses, movements become stronger and more frequent.",
    flexibility: "very flexible",
    flexibilityReason: "The timing of first felt movements can vary greatly between individuals and pregnancies.",
    frequency: "once",
    group: "pregnancy-milestones",
    dependsOn: null,
    additionalInputs: ["description of the sensation", "location of movement"],
    resources: ["What to Expect: Fetal Movement"],
    features: ["Movement tracker", "Kick counter"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Describe the first movements you felt. When and where did you feel them?"],
      friends: ["Share your experience of feeling the baby move for the first time with close friends."],
      community: ["Discuss experiences of first fetal movements with other expectant parents."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-8',
    id: 8,
    text: "Found out baby's gender",
    date: "2023-04-25",
    achieved: false,
    icon: "GiftOutlined",
    notes: "",
    type: "medical",
    category: "pregnancy-milestone",
    stage: "Second trimester",
    details: "Gender can often be determined via ultrasound around 18-20 weeks, though some tests can reveal it earlier. Remember, ultrasound predictions aren't 100% accurate. Some parents choose not to find out the gender before birth. This is a personal choice.",
    flexibility: "flexible",
    flexibilityReason: "The timing can vary based on when you choose to find out and the method used.",
    frequency: "once",
    group: "pregnancy-milestones",
    dependsOn: null,
    additionalInputs: ["method of finding out (ultrasound, NIPT, etc.)", "any gender reveal plans"],
    resources: ["Gender Prediction Methods"],
    features: ["Gender reveal party planner", "Name suggestion tool"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Note your feelings about learning the baby's gender. Any name ideas?"],
      friends: ["Share your gender reveal experience with close friends and family."],
      community: ["Discuss gender reveal ideas or experiences with other expectant parents."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-9',
    id: 9,
    text: "Registered for childbirth classes",
    date: "2023-05-01",
    achieved: false,
    icon: "BookOutlined",
    notes: "",
    type: "educational",
    category: "preparation",
    stage: "Second trimester",
    details: "Childbirth classes educate parents about pregnancy, labor, delivery, and newborn care. They typically cover breathing techniques, pain management, and what to expect during labor. Classes are often available through hospitals, community centers, or online platforms.",
    flexibility: "very flexible",
    flexibilityReason: "Classes can be taken at various points during pregnancy, based on personal preference and availability.",
    frequency: "varies",
    group: "pregnancy-education",
    dependsOn: null,
    additionalInputs: ["type of class (in-person, online, etc.)", "duration of course"],
    resources: ["Types of Childbirth Classes"],
    features: ["Class scheduler", "Course material organizer"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Note the details of the classes you've registered for and any specific topics you want to learn about."],
      friends: ["Discuss childbirth class experiences with friends who have taken them before."],
      community: ["Share recommendations for good childbirth classes in your area."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-10',
    id: 10,
    text: "Created a baby registry",
    date: "2023-05-15",
    achieved: false,
    icon: "ShoppingOutlined",
    notes: "",
    type: "preparation",
    category: "shopping",
    stage: "Second trimester",
    details: "A baby registry is a list of items you'd like to receive for your baby. Consider essentials like a car seat, crib, stroller, and clothing. Many stores offer registry services, often with perks like completion discounts. Online registries can be convenient for out-of-town family and friends.",
    flexibility: "very flexible",
    flexibilityReason: "A registry can be created at any time during pregnancy, or even after birth.",
    frequency: "once",
    group: "baby-preparation",
    dependsOn: null,
    additionalInputs: ["stores or platforms used", "number of items added"],
    resources: ["Baby Registry Checklist"],
    features: ["Registry item organizer", "Gift tracker"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["List the main items you've added to your registry and any research you want to do on products."],
      friends: ["Ask friends for recommendations on must-have baby items for your registry."],
      community: ["Share tips on creating a registry or must-have items with other expectant parents."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-11',
    id: 11,
    text: "Completed second trimester screening",
    date: "2023-06-10",
    achieved: false,
    icon: "SafetyOutlined",
    notes: "",
    type: "medical",
    category: "medical-appointment",
    stage: "Second trimester",
    details: "Second trimester screening typically occurs between 15-20 weeks. It includes a maternal blood test and a detailed ultrasound (anatomy scan). This screening checks for neural tube defects, genetic abnormalities, and assesses fetal growth and development of major organs.",
    flexibility: "semi-rigid",
    flexibilityReason: "This screening must be done within a specific timeframe to be accurate, but there's some flexibility within that window.",
    frequency: "once",
    group: "pregnancy-health",
    dependsOn: null,
    additionalInputs: ["results of screening", "any abnormalities detected"],
    resources: ["Second Trimester Screening Overview"],
    features: ["Test result tracker", "Fetal development visualizer"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Note down the results of your screening and any questions for your next appointment."],
      friends: ["Share your experience with the anatomy scan, if you're comfortable."],
      community: ["Discuss general information about second trimester screenings with other expectant parents."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-12',
    id: 12,
    text: "Chose a pediatrician",
    date: "2023-06-20",
    achieved: false,
    icon: "UserOutlined",
    notes: "",
    type: "preparation",
    category: "healthcare",
    stage: "Second trimester",
    details: "When selecting a pediatrician, consider factors like their experience, office location, hours, and philosophy on childcare. It's recommended to interview potential pediatricians and ask about their approach to vaccinations, breastfeeding support, and after-hours care.",
    flexibility: "flexible",
    flexibilityReason: "This can be done at any point during pregnancy, but it's good to have a pediatrician chosen before birth.",
    frequency: "once",
    group: "baby-preparation",
    dependsOn: null,
    additionalInputs: ["pediatrician's name", "reasons for choosing"],
    resources: ["How to Choose a Pediatrician"],
    features: ["Pediatrician interview question generator", "Local pediatrician finder"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["List the pediatricians you're considering and your impressions after interviews."],
      friends: ["Ask friends for pediatrician recommendations in your area."],
      community: ["Share tips on choosing a pediatrician with other expectant parents."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-13',
    id: 13,
    text: "Started planning maternity/paternity leave",
    date: "2023-07-01",
    achieved: false,
    icon: "CalendarOutlined",
    notes: "",
    type: "preparation",
    category: "work-life",
    stage: "Second trimester",
    details: "Maternity and paternity leave policies vary by employer and location. Research your rights and options early. Consider factors like length of leave, pay during leave, and how to transition back to work. It's also a good time to start arranging childcare if needed after your leave ends.",
    flexibility: "flexible",
    flexibilityReason: "Planning can start at any time, but it's best to begin early to ensure all arrangements are in place.",
    frequency: "once",
    group: "work-life-balance",
    dependsOn: null,
    additionalInputs: ["planned leave duration", "expected start date"],
    resources: ["Understanding Parental Leave Rights"],
    features: ["Leave planner", "Back-to-work transition checklist"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Outline your leave plan and any questions you need to ask your employer."],
      friends: ["Discuss leave strategies with friends who have gone through this before."],
      community: ["Share tips on planning parental leave or managing the transition back to work."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-14',
    id: 14,
    text: "Attended first childbirth class",
    date: "2023-07-15",
    achieved: false,
    icon: "TeamOutlined",
    notes: "",
    type: "educational",
    category: "preparation",
    stage: "Third trimester",
    details: "Childbirth classes cover topics like stages of labor, pain management techniques, medical interventions, and postpartum care. They often include practical exercises and can help partners learn how to support the birthing person. Classes may be in-person or online, and often span several weeks.",
    flexibility: "flexible",
    flexibilityReason: "Classes can be taken at various points during pregnancy, but are often recommended in the second or third trimester.",
    frequency: "varies",
    group: "pregnancy-education",
    dependsOn: 9,
    additionalInputs: ["type of class (e.g., Lamaze, Bradley)", "partner's involvement"],
    resources: ["Types of Childbirth Classes"],
    features: ["Class note organizer", "Breathing technique guide"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Note key takeaways from the class and any techniques you want to practice."],
      friends: ["Share your experience of the class with friends who are also expecting."],
      community: ["Discuss different types of childbirth classes and their benefits."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-15',
    id: 15,
    text: "Had a baby shower",
    date: "2023-08-01",
    achieved: false,
    icon: "GiftOutlined",
    notes: "",
    type: "personal",
    category: "celebration",
    stage: "Third trimester",
    details: "Baby showers are traditionally held in the third trimester. They're an opportunity to gather essential items for the baby and celebrate the upcoming arrival. Consider creating a registry to guide gift-givers. Modern showers may include games, advice-sharing, or even co-ed celebrations.",
    flexibility: "very flexible",
    flexibilityReason: "Baby showers can be held at any time during pregnancy or even after birth.",
    frequency: "once",
    group: "pregnancy-milestones",
    dependsOn: null,
    additionalInputs: ["number of guests", "favorite gifts received"],
    resources: ["Baby Shower Planning Guide"],
    features: ["Baby shower planner", "Gift tracker"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Record your favorite moments from the shower and any special gifts received."],
      friends: ["Share highlights from your baby shower with close friends."],
      community: ["Discuss baby shower ideas or experiences with other expectant parents."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-16',
    id: 16,
    text: "Completed hospital pre-registration",
    date: "2023-08-15",
    achieved: false,
    icon: "FormOutlined",
    notes: "",
    type: "preparation",
    category: "healthcare",
    stage: "Third trimester",
    details: "Pre-registration allows you to provide necessary information to the hospital before labor begins. This usually includes insurance details, medical history, and your birth plan preferences. It can save time and reduce stress when you arrive at the hospital in labor.",
    flexibility: "semi-flexible",
    flexibilityReason: "This should be done before the due date, but the exact timing can vary based on hospital procedures.",
    frequency: "once",
    group: "birth-preparation",
    dependsOn: null,
    additionalInputs: ["hospital name", "any special requests noted"],
    resources: ["Hospital Pre-Registration Checklist"],
    features: ["Pre-registration form filler", "Hospital tour scheduler"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Note any questions you have about the hospital's procedures or your birth plan."],
      friends: ["Discuss hospital experiences with friends who have given birth there before."],
      community: ["Share tips on hospital pre-registration or choosing a birthing facility."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-17',
    id: 17,
    text: "Packed hospital bag",
    date: "2023-08-20",
    achieved: false,
    icon: "ShoppingOutlined",
    notes: "",
    type: "preparation",
    category: "birth-preparation",
    stage: "Third trimester",
    details: "A well-packed hospital bag typically includes comfortable clothes for labor and postpartum, toiletries, snacks, baby's first outfit, car seat, and important documents. Don't forget items for your partner too. Pack early, around 35-36 weeks, in case of early labor.",
    flexibility: "flexible",
    flexibilityReason: "While it's recommended to pack early, the exact timing can vary based on personal preference and circumstances.",
    frequency: "once",
    group: "birth-preparation",
    dependsOn: null,
    additionalInputs: ["list of packed items", "any last-minute additions"],
    resources: ["Hospital Bag Checklist"],
    features: ["Hospital bag checklist generator", "Last-minute items reminder"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Make a list of items you've packed and anything you still need to add."],
      friends: ["Ask friends what they found most useful in their hospital bags."],
      community: ["Share hospital bag packing tips with other expectant parents."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-18',
    id: 18,
    text: "Installed car seat",
    date: "2023-08-25",
    achieved: false,
    icon: "CarOutlined",
    notes: "",
    type: "preparation",
    category: "safety",
    stage: "Third trimester",
    details: "Choose a rear-facing car seat appropriate for your baby's weight. Install it securely, following both the car seat and vehicle manual instructions. Many fire stations or police departments offer free car seat installation checks to ensure it's correctly installed.",
    flexibility: "semi-flexible",
    flexibilityReason: "This should be done before the baby arrives, but the exact timing can vary.",
    frequency: "once",
    group: "baby-preparation",
    dependsOn: null,
    additionalInputs: ["car seat model", "installation check details"],
    resources: ["Car Seat Safety Guidelines"],
    features: ["Car seat installation guide", "Local installation check locator"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Note the details of your car seat installation and any safety checks performed."],
      friends: ["Ask friends for recommendations on car seat models or installation services."],
      community: ["Share tips on choosing and installing car seats safely."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-19',
    id: 19,
    text: "Assembled nursery furniture",
    date: "2023-09-01",
    achieved: false,
    icon: "HomeOutlined",
    notes: "",
    type: "preparation",
    category: "nesting",
    stage: "Third trimester",
    details: "When setting up the nursery, prioritize safety. Ensure furniture is sturdy and secured to walls if necessary. Keep the crib free of pillows, blankets, and toys to reduce SIDS risk. Consider factors like room darkening capabilities and easy access for nighttime feedings.",
    flexibility: "flexible",
    flexibilityReason: "Nursery setup can be done gradually throughout pregnancy or even after birth.",
    frequency: "once",
    group: "baby-preparation",
    dependsOn: null,
    additionalInputs: ["main furniture items", "nursery theme or color scheme"],
    resources: ["Nursery Safety Checklist"],
    features: ["Nursery layout planner", "Safety checklist"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["List the furniture you've assembled and any final touches needed in the nursery."],
      friends: ["Share your nursery design ideas with friends and ask for suggestions."],
      community: ["Discuss nursery setup tips or safety considerations with other expectant parents."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-20',
    id: 20,
    text: "Washed and organized baby clothes",
    date: "2023-09-05",
    achieved: false,
    icon: "SkinOutlined",
    notes: "",
    type: "preparation",
    category: "nesting",
    stage: "Third trimester",
    details: "Wash baby clothes in hypoallergenic, fragrance-free detergent before first use. Organize clothes by size, as babies grow quickly. Don't overstock newborn sizes. Consider the season when your baby will be wearing each size. Prepare a few outfits in different sizes for the hospital bag.",
    flexibility: "flexible",
    flexibilityReason: "This can be done at any point before the baby arrives, but is often done in the third trimester.",
    frequency: "once",
    group: "baby-preparation",
    dependsOn: null,
    additionalInputs: ["detergent used", "smallest and largest sizes prepared"],
    resources: ["Guide to Washing Baby Clothes"],
    features: ["Baby clothes inventory tracker", "Clothing size calculator by due date"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Make a list of clothing items you have and any additional items you might need."],
      friends: ["Ask friends for advice on essential clothing items for newborns."],
      community: ["Share tips on organizing baby clothes or favorite brands for newborns."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-21',
    id: 21,
    text: "Prepared and froze meals for postpartum",
    date: "2023-09-10",
    achieved: false,
    icon: "CoffeeOutlined",
    notes: "",
    type: "preparation",
    category: "nesting",
    stage: "Third trimester",
    details: "Preparing meals in advance can be a lifesaver in the early postpartum period. Focus on nutritious, easy-to-reheat meals. Label with cooking instructions. Consider including snacks and foods that support lactation if planning to breastfeed. Don't forget to stock up on pantry staples too.",
    flexibility: "flexible",
    flexibilityReason: "Meal prep can be done at any point in late pregnancy, as long as the meals will keep until needed.",
    frequency: "once",
    group: "postpartum-preparation",
    dependsOn: null,
    additionalInputs: ["number of meals prepared", "favorite recipes"],
    resources: ["Postpartum Meal Prep Ideas"],
    features: ["Meal prep planner", "Grocery list generator"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["List the meals you've prepared and any recipes you want to try."],
      friends: ["Exchange postpartum meal prep ideas with friends who have been through this."],
      community: ["Share meal prep tips or favorite freezer-friendly recipes with other expectant parents."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-22',
    id: 22,
    text: "Finished childbirth education classes",
    date: "2023-09-15",
    achieved: false,
    icon: "ReadOutlined",
    notes: "",
    type: "educational",
    category: "preparation",
    stage: "Third trimester",
    details: "Completing childbirth classes equips you with knowledge about labor stages, pain management techniques, potential complications, and immediate postpartum care. It's also an opportunity to connect with other expectant parents. Review and practice the techniques you've learned regularly.",
    flexibility: "semi-flexible",
    flexibilityReason: "Classes should ideally be completed before labor begins, but the exact timing can vary.",
    frequency: "varies",
    group: "pregnancy-education",
    dependsOn: 14,
    additionalInputs: ["most valuable lessons learned", "any remaining questions"],
    resources: ["Childbirth Education Review"],
    features: ["Labor techniques quick reference guide", "Contraction timer"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Summarize the key points you learned in your classes and any techniques you want to practice."],
      friends: ["Share your experience of the childbirth classes with friends who are also expecting."],
      community: ["Discuss different childbirth techniques or class experiences with other expectant parents."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-23',
    id: 23,
    text: "Created a birth plan",
    date: "2023-09-20",
    achieved: false,
    icon: "FileOutlined",
    notes: "",
    type: "preparation",
    category: "birth-preparation",
    stage: "Third trimester",
    details: "A birth plan outlines your preferences for labor, delivery, and postpartum care. Include pain management preferences, desired interventions or those you'd like to avoid, immediate newborn care preferences, and contingencies. Discuss your plan with your healthcare provider and remain flexible, as births often don't go exactly as planned.",
    flexibility: "flexible",
    flexibilityReason: "A birth plan can be created at any point during pregnancy, but is often done in the third trimester.",
    frequency: "once",
    group: "birth-preparation",
    dependsOn: 22,
    additionalInputs: ["key preferences noted", "healthcare provider's feedback"],
    resources: ["Birth Plan Templates"],
    features: ["Interactive birth plan creator", "Birth plan sharing with healthcare team"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Outline your birth plan preferences and any concerns you want to discuss with your provider."],
      friends: ["Discuss birth plan ideas with friends who have given birth before."],
      community: ["Share general tips on creating a birth plan or discussing it with healthcare providers."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-24',
    id: 24,
    text: "Had group B strep test",
    date: "2023-09-25",
    achieved: false,
    icon: "ExperimentOutlined",
    notes: "",
    type: "medical",
    category: "medical-appointment",
    stage: "Third trimester",
    details: "The group B strep test is typically done between 35-37 weeks of pregnancy. It involves a quick swab of the vagina and rectum. If positive, antibiotics during labor can prevent transmission to the baby. Group B strep is common and usually harmless in adults but can cause serious infections in newborns.",
    flexibility: "semi-rigid",
    flexibilityReason: "This test needs to be done within a specific timeframe for accuracy and relevance.",
    frequency: "once",
    group: "pregnancy-health",
    dependsOn: null,
    additionalInputs: ["test results", "any recommended precautions"],
    resources: ["CDC: Group B Strep and Pregnancy"],
    features: ["Test reminder", "Results tracker"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Note your test results and any instructions from your healthcare provider."],
      friends: ["Discuss the importance of the GBS test with expectant friends."],
      community: ["Share general information about GBS testing in pregnancy."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-25',
    id: 25,
    text: "Practiced relaxation and breathing techniques",
    date: "2023-09-30",
    achieved: false,
    icon: "YuqueOutlined",
    notes: "",
    type: "preparation",
    category: "birth-preparation",
    stage: "Third trimester",
    details: "Regular practice of relaxation and breathing techniques can help manage pain and anxiety during labor. Techniques might include deep breathing, visualization, progressive muscle relaxation, or hypnobirthing methods. Practice with your partner so they can guide you through these during labor.",
    flexibility: "very flexible",
    flexibilityReason: "These techniques can be practiced at any time during pregnancy and even during labor.",
    frequency: "daily",
    group: "birth-preparation",
    dependsOn: 22,
    additionalInputs: ["favorite techniques", "frequency of practice"],
    resources: ["Labor Relaxation Techniques Guide"],
    features: ["Guided relaxation audio", "Breathing technique timer"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Note which relaxation techniques work best for you and how often you're practicing."],
      friends: ["Share favorite relaxation techniques with other expectant friends."],
      community: ["Discuss different relaxation and breathing methods for labor."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-26',
    id: 26,
    text: "Sterilized breast pump and bottles",
    date: "2023-10-05",
    achieved: false,
    icon: "ExperimentOutlined",
    notes: "",
    type: "preparation",
    category: "feeding-preparation",
    stage: "Third trimester",
    details: "Before first use, sterilize all feeding equipment by boiling for 5-10 minutes or using a steam sterilizer. After that, thorough washing with hot, soapy water or in a dishwasher is usually sufficient. Always wash hands before handling sterilized equipment. Consider having multiple sets of pump parts for convenience.",
    flexibility: "flexible",
    flexibilityReason: "This can be done at any point before the baby arrives, but is often done close to the due date.",
    frequency: "once",
    group: "baby-preparation",
    dependsOn: null,
    additionalInputs: ["sterilization method used", "types of equipment prepared"],
    resources: ["CDC: How to Clean, Sanitize, and Store Infant Feeding Items"],
    features: ["Sterilization reminder", "Feeding equipment inventory"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["List the feeding equipment you've sterilized and any additional items you need."],
      friends: ["Discuss breast pump recommendations or bottle sterilization methods with other parents."],
      community: ["Share tips on preparing and maintaining feeding equipment."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-27',
    id: 27,
    text: "Installed baby monitor",
    date: "2023-10-10",
    achieved: false,
    icon: "AudioOutlined",
    notes: "",
    type: "preparation",
    category: "safety",
    stage: "Third trimester",
    details: "When installing a baby monitor, ensure the camera has a clear view of the crib but is out of baby's reach. For audio monitors, place them close enough to pick up baby's sounds but far enough to avoid cord hazards. Test the range throughout your home. Consider features like two-way audio, temperature sensors, or movement monitoring based on your needs.",
    flexibility: "flexible",
    flexibilityReason: "This can be done at any point before the baby starts sleeping in their own room.",
    frequency: "once",
    group: "baby-preparation",
    dependsOn: null,
    additionalInputs: ["monitor brand and model", "key features used"],
    resources: ["Baby Monitor Safety Guidelines"],
    features: ["Monitor setup guide", "Range test checker"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Note the location and settings of your baby monitor, and any features you want to test."],
      friends: ["Ask friends for baby monitor recommendations or setup tips."],
      community: ["Discuss different types of baby monitors and their pros and cons."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-28',
    id: 28,
    text: "Packed diaper bag for outings",
    date: "2023-10-15",
    achieved: false,
    icon: "ShoppingOutlined",
    notes: "",
    type: "preparation",
    category: "baby-care",
    stage: "Third trimester",
    details: "A well-packed diaper bag typically includes diapers, wipes, changing pad, diaper rash cream, change of clothes, burp cloths, feeding supplies (breast or bottle), plastic bags for soiled items, and hand sanitizer. Consider the length of your outing and pack accordingly. Don't forget essentials for yourself too!",
    flexibility: "flexible",
    flexibilityReason: "This can be done at any point before you plan to take the baby out, but is often done close to or after the birth.",
    frequency: "once",
    group: "baby-preparation",
    dependsOn: null,
    additionalInputs: ["bag type or brand", "any unique items included"],
    resources: ["Diaper Bag Essentials Checklist"],
    features: ["Diaper bag packing list generator", "Outing duration-based packing guide"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["List the items you've packed in your diaper bag and any extras you might need."],
      friends: ["Ask friends about their diaper bag must-haves for different types of outings."],
      community: ["Share diaper bag packing tips or favorite products for on-the-go baby care."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-29',
    id: 29,
    text: "Arranged for postpartum support",
    date: "2023-10-20",
    achieved: false,
    icon: "TeamOutlined",
    notes: "",
    type: "preparation",
    category: "postpartum-planning",
    stage: "Third trimester",
    details: "Postpartum support can include family members, friends, postpartum doulas, or professional services. Discuss expectations clearly. Support might involve help with household chores, meal preparation, childcare for older children, or simply providing company. Don't hesitate to ask for help with specific tasks.",
    flexibility: "flexible",
    flexibilityReason: "Arrangements can be made at any point during pregnancy, but are often finalized in the third trimester.",
    frequency: "once",
    group: "postpartum-preparation",
    dependsOn: null,
    additionalInputs: ["types of support arranged", "schedule or rotation if applicable"],
    resources: ["Postpartum Support Planning Guide"],
    features: ["Support schedule organizer", "Task delegation tool"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Outline your postpartum support plan, including who will help and with what tasks."],
      friends: ["Discuss postpartum support strategies with friends who have been through it."],
      community: ["Share ideas for organizing postpartum support or self-care during the fourth trimester."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-30',
    id: 30,
    text: "Completed newborn care class",
    date: "2023-10-25",
    achieved: false,
    icon: "ReadOutlined",
    notes: "",
    type: "educational",
    category: "preparation",
    stage: "Third trimester",
    details: "Newborn care classes cover essential topics like diapering, bathing, feeding, safe sleep practices, and recognizing common newborn health issues. They often provide hands-on practice with dolls. These classes can boost confidence for first-time parents and serve as a refresher for experienced ones.",
    flexibility: "flexible",
    flexibilityReason: "Classes can be taken at any point during pregnancy, but are often done in the third trimester.",
    frequency: "once",
    group: "pregnancy-education",
    dependsOn: null,
    additionalInputs: ["key skills learned", "any remaining concerns"],
    resources: ["Newborn Care Basics"],
    features: ["Newborn care quick reference guide", "Baby care technique videos"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Summarize the key points you learned in the newborn care class and any areas you want to research more."],
      friends: ["Share experiences or tips from newborn care classes with expectant friends."],
      community: ["Discuss newborn care techniques or share resources for learning baby care skills."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-31',
    id: 31,
    text: "Attended breastfeeding class",
    date: "2023-10-30",
    achieved: false,
    icon: "ReadOutlined",
    notes: "",
    type: "educational",
    category: "feeding-preparation",
    stage: "Third trimester",
    details: "Breastfeeding classes cover topics like proper latch techniques, positioning, frequency of feeding, pumping and storage of breast milk, and common challenges. They often provide resources for support after birth. Even if you're unsure about breastfeeding, these classes can help you make an informed decision.",
    flexibility: "flexible",
    flexibilityReason: "Classes can be taken at any point during pregnancy, but are often done in the third trimester.",
    frequency: "once",
    group: "pregnancy-education",
    dependsOn: null,
    additionalInputs: ["key takeaways", "any concerns addressed"],
    resources: ["La Leche League: Breastfeeding Information"],
    features: ["Breastfeeding position guide", "Feeding tracker"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Note key breastfeeding techniques you learned and any questions you still have."],
      friends: ["Discuss breastfeeding experiences or concerns with other expectant or new parents."],
      community: ["Share general breastfeeding tips or resources for support."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-32',
    id: 32,
    text: "Prepared siblings or pets for new baby's arrival",
    date: "2023-11-01",
    achieved: false,
    icon: "TeamOutlined",
    notes: "",
    type: "preparation",
    category: "family-adjustment",
    stage: "Third trimester",
    details: "For siblings, read books about new babies, involve them in preparations, and maintain their routines. For pets, gradually introduce baby items and sounds, and plan for their care during your hospital stay. Consider bringing home a blanket with the baby's scent before the actual homecoming.",
    flexibility: "flexible",
    flexibilityReason: "Preparation can be ongoing throughout pregnancy, intensifying as the due date approaches.",
    frequency: "ongoing",
    group: "family-preparation",
    dependsOn: null,
    additionalInputs: ["sibling/pet reactions", "successful strategies used"],
    resources: ["Preparing Siblings for a New Baby", "Introducing Pets to Newborns"],
    features: ["Sibling involvement activity ideas", "Pet adjustment checklist"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Note how siblings or pets are reacting to preparations and any concerns you have."],
      friends: ["Share tips for preparing siblings or pets with friends who have been through this."],
      community: ["Discuss strategies for helping family members adjust to a new baby."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-33',
    id: 33,
    text: "Had maternity/newborn photoshoot",
    date: "2023-11-05",
    achieved: false,
    icon: "CameraOutlined",
    notes: "",
    type: "personal",
    category: "memory-making",
    stage: "Third trimester",
    details: "Schedule a maternity photoshoot between 28-36 weeks when your bump is pronounced but you're still comfortable. For newborn shoots, aim for 5-14 days after birth. Choose a photographer experienced in maternity/newborn photography. Discuss your vision, preferred styles, and any specific shots you want beforehand.",
    flexibility: "semi-flexible",
    flexibilityReason: "Timing depends on the type of shoot, but there's usually some flexibility within the recommended windows.",
    frequency: "once",
    group: "pregnancy-milestones",
    dependsOn: null,
    additionalInputs: ["photographer's name", "favorite photos"],
    resources: ["Maternity and Newborn Photography Ideas"],
    features: ["Photoshoot pose inspiration gallery", "Photographer finder"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Note your favorite photos from the shoot and any special moments you want to remember."],
      friends: ["Share your photoshoot experience and tips with expectant friends."],
      community: ["Discuss maternity/newborn photography ideas or recommend photographers in your area."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-34',
    id: 34,
    text: "Wrote thank you notes for baby shower gifts",
    date: "2023-11-10",
    achieved: false,
    icon: "MailOutlined",
    notes: "",
    type: "personal",
    category: "social",
    stage: "Third trimester",
    details: "Try to send thank you notes within 2-3 weeks of receiving gifts. Personalize each note, mentioning the specific gift and how you plan to use it. If you're behind due to the baby's arrival, a simple note acknowledging the delay is appreciated. Consider including a photo of the baby with the gift for a special touch.",
    flexibility: "flexible",
    flexibilityReason: "While it's polite to send thank you notes promptly, they can be sent at any time.",
    frequency: "once",
    group: "social-etiquette",
    dependsOn: 15,
    additionalInputs: ["number of notes written", "method of delivery (e.g., mail, email)"],
    resources: ["Baby Shower Thank You Note Guide"],
    features: ["Thank you note template generator", "Gift tracker"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Keep track of gifts received and thank you notes sent."],
      friends: ["Ask friends for cute thank you note ideas or wording suggestions."],
      community: ["Share tips for managing thank you notes or creative ideas for showing appreciation."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-35',
    id: 35,
    text: "Established a pediatrician appointment for newborn",
    date: "2023-11-15",
    achieved: false,
    icon: "CalendarOutlined",
    notes: "",
    type: "medical",
    category: "healthcare",
    stage: "Third trimester",
    details: "The first pediatrician visit typically occurs 3-5 days after birth. This visit checks the baby's weight, jaundice levels, and overall health. It's also an opportunity to discuss feeding, sleep, and any concerns. Prepare questions in advance and bring any hospital discharge papers.",
    flexibility: "semi-rigid",
    flexibilityReason: "The timing of this appointment is important for newborn health checks, but there's some flexibility within the recommended window.",
    frequency: "once",
    group: "baby-health",
    dependsOn: 12,
    additionalInputs: ["appointment date and time", "list of questions prepared"],
    resources: ["First Pediatrician Visit: What to Expect"],
    features: ["Appointment reminder", "Question list generator"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["List questions you want to ask the pediatrician and any concerns you have."],
      friends: ["Ask friends about their experiences with first pediatrician visits."],
      community: ["Share tips for preparing for the first pediatrician appointment."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-36',
    id: 36,
    text: "Set up a comfortable nursing/feeding station",
    date: "2023-11-20",
    achieved: false,
    icon: "HomeOutlined",
    notes: "",
    type: "preparation",
    category: "feeding-preparation",
    stage: "Third trimester",
    details: "A good nursing station includes a comfortable chair with arm support, a footstool, a side table for water and snacks, burp cloths, and any breastfeeding aids you plan to use. For bottle feeding, ensure easy access to cleaned bottles, formula if using, and sterilizing equipment. Good lighting and entertainment options (books, TV remote) can be helpful for night feeds.",
    flexibility: "flexible",
    flexibilityReason: "This can be set up at any time before the baby arrives, but is often done in the third trimester.",
    frequency: "once",
    group: "baby-preparation",
    dependsOn: null,
    additionalInputs: ["location in home", "essential items included"],
    resources: ["Setting Up a Nursing Station"],
    features: ["Nursing station checklist", "Feeding tracker"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Describe your nursing station setup and any items you still need to add."],
      friends: ["Ask friends for recommendations on comfortable nursing chairs or essential items for the station."],
      community: ["Share tips for creating a comfortable and efficient nursing/feeding station."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-37',
    id: 37,
    text: "Installed baby gates",
    date: "2023-11-25",
    achieved: false,
    icon: "SafetyOutlined",
    notes: "",
    type: "preparation",
    category: "safety",
    stage: "Third trimester",
    details: "Install baby gates at the top and bottom of stairs and to section off any unsafe areas. Ensure gates are securely attached and meet current safety standards. Pressure-mounted gates are suitable for doorways but not for use at the top of stairs. Consider the gate's height and the ease of opening for adults while remaining secure against children.",
    flexibility: "flexible",
    flexibilityReason: "Gates can be installed at any time, but are often put up before the baby starts crawling.",
    frequency: "once",
    group: "baby-proofing",
    dependsOn: null,
    additionalInputs: ["number of gates installed", "locations in home"],
    resources: ["Baby Gate Safety Guidelines"],
    features: ["Baby-proofing checklist", "Home safety assessment tool"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Note where you've installed baby gates and any areas that still need addressing."],
      friends: ["Ask friends for recommendations on reliable baby gate brands or installation tips."],
      community: ["Share experiences with different types of baby gates or general home safety tips."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-38',
    id: 38,
    text: "Baby-proofed main living areas",
    date: "2023-11-30",
    achieved: false,
    icon: "SafetyOutlined",
    notes: "",
    type: "preparation",
    category: "safety",
    stage: "Third trimester",
    details: "Baby-proofing includes covering electrical outlets, securing furniture to walls, removing or padding sharp corners, and storing hazardous items out of reach. Install locks on cabinets containing dangerous items. Remove or secure window blind cords. Create a safe play area. Remember, baby-proofing is an ongoing process as your child grows and becomes more mobile.",
    flexibility: "flexible",
    flexibilityReason: "Baby-proofing can be done gradually, starting before birth and continuing as the baby grows.",
    frequency: "ongoing",
    group: "baby-proofing",
    dependsOn: null,
    additionalInputs: ["areas of home baby-proofed", "main safety measures implemented"],
    resources: ["Room-by-Room Baby-Proofing Guide"],
    features: ["Baby-proofing checklist", "Safety product recommendations"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["List the baby-proofing measures you've taken and areas that still need attention."],
      friends: ["Exchange baby-proofing tips and product recommendations with other parents."],
      community: ["Share creative baby-proofing solutions or discuss overlooked safety hazards."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-39',
    id: 39,
    text: "Prepared postpartum care kit",
    date: "2023-12-01",
    achieved: false,
    icon: "MedicineBoxOutlined",
    notes: "",
    type: "preparation",
    category: "postpartum-planning",
    stage: "Third trimester",
    details: "A postpartum care kit typically includes maternity pads, comfortable underwear, perineal cold packs, soothing spray or ointment, pain relievers, stool softeners, and nipple cream if breastfeeding. Include comfortable, loose clothing and high-waisted underwear. Don't forget self-care items like dry shampoo, facial wipes, and healthy snacks. Prepare this kit before your due date for easy access post-birth.",
    flexibility: "flexible",
    flexibilityReason: "This can be prepared at any time during pregnancy, but is often done in the third trimester.",
    frequency: "once",
    group: "postpartum-preparation",
    dependsOn: null,
    additionalInputs: ["key items included", "any personalized additions"],
    resources: ["Postpartum Recovery Essentials"],
    features: ["Postpartum care kit checklist", "Self-care reminder notifications"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["List the items in your postpartum care kit and any additional items you want to include."],
      friends: ["Ask friends about their postpartum must-haves or recovery tips."],
      community: ["Share recommendations for postpartum recovery products or self-care strategies."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-40',
    id: 40,
    text: "Arranged for meal train or food delivery service",
    date: "2023-12-05",
    achieved: false,
    icon: "ShoppingOutlined",
    notes: "",
    type: "preparation",
    category: "postpartum-planning",
    stage: "Third trimester",
    details: "Meal trains can be organized through websites or apps where friends and family sign up to provide meals. If using a food delivery service, stock up on gift cards or set up a recurring order. Consider dietary restrictions and easy-to-reheat options. Don't forget to include snacks and drinks. Having meals taken care of can significantly reduce stress in the early postpartum period.",
    flexibility: "flexible",
    flexibilityReason: "This can be arranged at any time, but is often set up close to the due date.",
    frequency: "once",
    group: "postpartum-preparation",
    dependsOn: null,
    additionalInputs: ["duration of meal support", "favorite meals received"],
    resources: ["Setting Up a Meal Train"],
    features: ["Meal train organizer", "Local restaurant finder"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Note the details of your meal train or delivery service plan."],
      friends: ["Coordinate meal train details with friends and family."],
      community: ["Share tips for organizing meal support or recommend local food delivery services."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-41',
    id: 41,
    text: "Established a support network for postpartum period",
    date: "2023-12-10",
    achieved: false,
    icon: "TeamOutlined",
    notes: "",
    type: "preparation",
    category: "postpartum-planning",
    stage: "Third trimester",
    details: "A support network might include family members, close friends, a postpartum doula, a lactation consultant, and mental health professionals. Discuss expectations and boundaries with potential helpers. Consider joining new parent support groups, either in-person or online. Have contact information readily available for professional support services, including your healthcare provider and emergency numbers.",
    flexibility: "flexible",
    flexibilityReason: "Building a support network can happen throughout pregnancy and even after birth.",
    frequency: "ongoing",
    group: "postpartum-preparation",
    dependsOn: null,
    additionalInputs: ["key support people identified", "types of support arranged"],
    resources: ["Building a Postpartum Support Network"],
    features: ["Support contact list organizer", "Local support group finder"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["List your support network members and their roles or contributions."],
      friends: ["Discuss postpartum support strategies with friends who have been through it."],
      community: ["Share ideas for building a strong support network or finding local resources."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-42',
    id: 42,
    text: "Baby's here!",
    date: "2023-12-15",
    achieved: false,
    icon: "HeartOutlined",
    notes: "",
    type: "life event",
    category: "birth",
    stage: "Birth",
    details: "Document the details: time of birth, weight, length, and any special moments. Take photos, but also be present in the moment. Inform close family and friends. Rest as much as possible and accept help. Don't hesitate to ask nurses or doctors any questions. Remember, the first few days are a period of adjustment for everyone - be patient with yourself and your baby.",
    flexibility: "rigid",
    flexibilityReason: "The baby's birth date is not flexible, though it may differ from the expected due date.",
    frequency: "once",
    group: "birth",
    dependsOn: null,
    additionalInputs: ["birth time", "birth weight and length"],
    resources: ["Newborn Care Basics"],
    features: ["Birth announcement creator", "Baby milestone tracker"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Record the details of your baby's birth and your first impressions."],
      friends: ["Share your birth announcement with close friends and family."],
      community: ["Share your birth story or tips for new parents, if you're comfortable."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-43',
    id: 43,
    text: "Successful first diaper change",
    date: "2023-12-16",
    achieved: false,
    icon: "CheckCircleOutlined",
    notes: "",
    type: "parenting",
    category: "baby-care",
    stage: "Newborn",
    details: "Gather all supplies before starting. Always keep one hand on the baby. Clean from front to back, especially for girls. Allow the area to dry before putting on a new diaper to prevent diaper rash. Newborns may need up to 10-12 diaper changes a day. Don't be alarmed by the color of the first few bowel movements (meconium) - it's normal for them to be dark and sticky.",
    flexibility: "semi-flexible",
    flexibilityReason: "This will happen soon after birth, but the exact timing can vary.",
    frequency: "multiple times daily",
    group: "baby-care",
    dependsOn: 42,
    additionalInputs: ["type of diaper used", "any challenges encountered"],
    resources: ["Diaper Changing 101"],
    features: ["Diaper change timer", "Diaper usage tracker"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Note any tips or tricks you've discovered for diaper changes."],
      friends: ["Share diaper changing experiences or product recommendations with other new parents."],
      community: ["Discuss diaper changing techniques or favorite products."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-44',
    id: 44,
    text: "First pediatrician visit completed",
    date: "2023-12-20",
    achieved: false,
    icon: "MedicineBoxOutlined",
    notes: "",
    type: "medical",
    category: "baby-health",
    stage: "Newborn",
    details: "The first visit usually occurs 3-5 days after birth. The pediatrician will check weight, length, head circumference, and overall health. They'll also check the umbilical cord and, if applicable, circumcision site. Come prepared with questions about feeding, sleep, or any concerns. Bring any hospital discharge paperwork and a list of any medications or vitamins you're taking if breastfeeding.",
    flexibility: "semi-rigid",
    flexibilityReason: "This visit should occur within a specific timeframe, but there's some flexibility in scheduling.",
    frequency: "once",
    group: "baby-health",
    dependsOn: 42,
    additionalInputs: ["baby's weight at visit", "key topics discussed"],
    resources: ["First Pediatrician Visit Checklist"],
    features: ["Question list generator", "Growth tracker"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Record the details from the visit, including baby's measurements and doctor's advice."],
      friends: ["Share experiences from the first pediatrician visit with other new parents."],
      community: ["Discuss what to expect at the first pediatrician visit or share tips for preparing."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-45',
    id: 45,
    text: "Survived first night at home with baby",
    date: "2023-12-21",
    achieved: false,
    icon: "HomeOutlined",
    notes: "",
    type: "parenting",
    category: "baby-care",
    stage: "Newborn",
    details: "The first night can be overwhelming. Remember that frequent waking is normal for newborns. Establish a simple bedtime routine, even if it's just a diaper change and feeding. Keep the room dark and quiet for night feedings. Take turns with your partner if possible. Don't hesitate to call your pediatrician if you have concerns. Remember, this phase is temporary - you're doing great!",
    flexibility: "semi-flexible",
    flexibilityReason: "This will happen soon after leaving the hospital, but the exact date can vary.",
    frequency: "once",
    group: "baby-care",
    dependsOn: 42,
    additionalInputs: ["number of wakings", "most challenging aspect"],
    resources: ["Newborn Sleep Patterns"],
    features: ["Sleep tracker", "Feeding log"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Reflect on your first night at home. What worked well? What was challenging?"],
      friends: ["Share your experience of the first night home with close friends or family."],
      community: ["Discuss strategies for managing the first night home with a newborn."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-46',
    id: 46,
    text: "First successful breastfeeding or bottle feeding session",
    date: "2023-12-22",
    achieved: false,
    icon: "HeartOutlined",
    notes: "",
    type: "parenting",
    category: "feeding",
    stage: "Newborn",
    details: "For breastfeeding, ensure a good latch and comfortable positioning. For bottle feeding, hold the baby semi-upright and tilt the bottle to prevent air ingestion. Burp the baby halfway through and after feeding. Watch for hunger cues rather than strictly following a schedule. It's normal for newborns to feed 8-12 times in 24 hours. If you're having difficulties, don't hesitate to seek help from a lactation consultant or your pediatrician.",
    flexibility: "semi-flexible",
    flexibilityReason: "This will happen soon after birth, but the timing and success can vary.",
    frequency: "multiple times daily",
    group: "feeding",
    dependsOn: 42,
    additionalInputs: ["feeding method", "duration of session"],
    resources: ["Breastfeeding and Bottle Feeding Basics"],
    features: ["Feeding tracker", "Breastfeeding position guide"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Note details about the feeding session, including position, duration, and baby's response."],
      friends: ["Share feeding experiences or ask for advice from other new parents."],
      community: ["Discuss feeding techniques or share resources for breastfeeding/bottle feeding support."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-47',
    id: 47,
    text: "Introduced baby to extended family",
    date: "2023-12-25",
    achieved: false,
    icon: "TeamOutlined",
    notes: "",
    type: "personal",
    category: "social",
    stage: "Newborn",
    details: "When introducing your baby to extended family, consider your comfort level and the baby's needs. Limit visits in duration and number of people, especially in the early weeks. Ensure visitors wash their hands before holding the baby. It's okay to set boundaries about holding, feeding, or wake times. Consider virtual introductions if in-person visits aren't feasible.",
    flexibility: "very flexible",
    flexibilityReason: "This can happen at any time based on family circumstances and personal preferences.",
    frequency: "once",
    group: "family-bonding",
    dependsOn: 42,
    additionalInputs: ["number of family members introduced", "method of introduction (in-person, virtual)"],
    resources: ["Introducing Newborns to Family"],
    features: ["Virtual meeting scheduler", "Visitor log"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Record memories from introducing your baby to family members."],
      friends: ["Share experiences of introducing your baby to family with close friends."],
      community: ["Discuss tips for managing family visits with a newborn."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-48',
    id: 48,
    text: "Captured baby's first smile",
    date: "2024-01-15",
    achieved: false,
    icon: "CameraOutlined",
    notes: "",
    type: "developmental",
    category: "baby-milestone",
    stage: "Infant",
    details: "Babies typically start smiling socially between 6-8 weeks old. These early smiles are a crucial developmental milestone, indicating social and emotional growth. Keep your camera ready, as these moments can be fleeting! Remember, what looks like early smiles before 6 weeks are usually reflex actions, not true social smiles.",
    flexibility: "flexible",
    flexibilityReason: "The timing of a baby's first smile can vary, but usually occurs within a certain age range.",
    frequency: "once",
    group: "baby-milestones",
    dependsOn: 42,
    additionalInputs: ["age when first smile occurred", "what prompted the smile"],
    resources: ["Understanding Baby's First Smile"],
    features: ["Milestone photo album", "Development tracker"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Describe the moment of your baby's first smile. What was happening? How did it make you feel?"],
      friends: ["Share the joy of your baby's first smile with close friends and family."],
      community: ["Discuss experiences of baby's first smiles and other early social cues."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-49',
    id: 49,
    text: "Completed postpartum check-up",
    date: "2024-01-25",
    achieved: false,
    icon: "MedicineBoxOutlined",
    notes: "",
    type: "medical",
    category: "postpartum-health",
    stage: "Postpartum",
    details: "The postpartum check-up typically occurs around 6 weeks after birth. It includes a physical exam, mental health screening, and discussions about recovery, contraception, and any concerns. Be honest about your physical and emotional state. This is a good time to ask questions about resuming activities like exercise or intimacy.",
    flexibility: "semi-flexible",
    flexibilityReason: "This check-up should occur around 6 weeks postpartum, but the exact timing can be adjusted if needed.",
    frequency: "once",
    group: "postpartum-health",
    dependsOn: 42,
    additionalInputs: ["physical recovery status", "emotional well-being assessment"],
    resources: ["Postpartum Recovery Guide"],
    features: ["Postpartum symptom tracker", "Self-care reminder"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Note any concerns discussed during the check-up and advice received from your healthcare provider."],
      friends: ["Share postpartum recovery experiences with close friends who are also new parents."],
      community: ["Discuss general postpartum recovery tips or experiences."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-50',
    id: 50,
    text: "Baby's first bath at home",
    date: "2024-01-02",
    achieved: false,
    icon: "DropboxOutlined",
    notes: "",
    type: "parenting",
    category: "baby-care",
    stage: "Newborn",
    details: "For the first bath, stick to a sponge bath until the umbilical cord stump falls off. Gather all supplies before starting. Use warm (not hot) water and mild, baby-safe soap. Support the baby's head and neck at all times. Keep the bath short, around 5 minutes, to prevent chilling. Have a warm towel ready for after the bath.",
    flexibility: "flexible",
    flexibilityReason: "The timing of the first bath can vary based on when the umbilical cord stump falls off and parental readiness.",
    frequency: "once",
    group: "baby-care",
    dependsOn: 42,
    additionalInputs: ["bath duration", "baby's reaction to bath"],
    resources: ["Newborn Bath Time Tips"],
    features: ["Bath time checklist", "Baby care routine planner"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Describe your baby's first bath experience. What worked well? What would you do differently next time?"],
      friends: ["Share tips or experiences from your baby's first bath with other new parents."],
      community: ["Discuss baby bath time strategies or product recommendations."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-51',
    id: 51,
    text: "First outing with baby",
    date: "2024-01-10",
    achieved: false,
    icon: "CompassOutlined",
    notes: "",
    type: "parenting",
    category: "baby-care",
    stage: "Newborn",
    details: "For your first outing, choose a quiet, low-key destination. A short walk or visit to a nearby park can be ideal. Ensure you have a well-packed diaper bag. Time the outing between feedings if possible. Be prepared for unexpected diaper changes or feedings. Remember, it's okay to cut the trip short if needed - this is a learning experience for everyone.",
    flexibility: "very flexible",
    flexibilityReason: "The timing of the first outing depends on parental readiness and baby's health.",
    frequency: "once",
    group: "baby-care",
    dependsOn: 42,
    additionalInputs: ["destination of outing", "duration of outing"],
    resources: ["Tips for First Outings with Baby"],
    features: ["Outing checklist generator", "Local family-friendly destination finder"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Reflect on your first outing with the baby. What went well? What would you do differently next time?"],
      friends: ["Share your experience of the first outing with close friends or family."],
      community: ["Discuss tips for successful outings with a newborn or recommend baby-friendly locations."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-52',
    id: 52,
    text: "Baby slept for 4-hour stretch",
    date: "2024-01-20",
    achieved: false,
    icon: "ClockCircleOutlined",
    notes: "",
    type: "developmental",
    category: "sleep",
    stage: "Infant",
    details: "Babies typically start sleeping for longer stretches between 3-6 months old. A 4-hour sleep stretch is a positive sign of developing sleep patterns. However, sleep patterns can vary greatly between babies and can change frequently. Continue practicing good sleep hygiene and bedtime routines. Remember, it's normal for sleep patterns to regress occasionally.",
    flexibility: "very flexible",
    flexibilityReason: "The timing of longer sleep stretches varies greatly among babies.",
    frequency: "recurring",
    group: "baby-sleep",
    dependsOn: 42,
    additionalInputs: ["time of day/night it occurred", "any sleep training methods used"],
    resources: ["Infant Sleep Patterns and Development"],
    features: ["Sleep tracker", "Sleep training method guide"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Record details about your baby's sleep patterns and any strategies that seem to be working."],
      friends: ["Share sleep victories or challenges with other new parents."],
      community: ["Discuss infant sleep patterns or share tips for encouraging longer sleep stretches."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-53',
    id: 53,
    text: "Started tummy time",
    date: "2024-01-05",
    achieved: false,
    icon: "ExperimentOutlined",
    notes: "",
    type: "developmental",
    category: "physical-development",
    stage: "Newborn",
    details: "Tummy time helps strengthen neck, arm, and shoulder muscles, crucial for later development. Start with short sessions (3-5 minutes) several times a day. Always supervise tummy time and stop if the baby becomes upset. Use toys or a mirror to make it more engaging. As your baby gets stronger, gradually increase the duration of tummy time sessions.",
    flexibility: "flexible",
    flexibilityReason: "Tummy time can start soon after birth, but the exact timing and frequency can vary.",
    frequency: "daily",
    group: "baby-development",
    dependsOn: 42,
    additionalInputs: ["duration of tummy time sessions", "baby's reaction to tummy time"],
    resources: ["Tummy Time Techniques and Benefits"],
    features: ["Tummy time tracker", "Developmental milestone guide"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Record your baby's progress with tummy time, including duration and any new skills observed."],
      friends: ["Share tummy time experiences or tips with other new parents."],
      community: ["Discuss tummy time strategies or share activities to make it more enjoyable for babies."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-54',
    id: 54,
    text: "Baby's first laugh",
    date: "2024-02-10",
    achieved: false,
    icon: "SmileOutlined",
    notes: "",
    type: "developmental",
    category: "social-development",
    stage: "Infant",
    details: "Babies typically start laughing between 3-4 months old. This milestone indicates developing social skills and emotional connections. Encourage laughter through play, funny faces, or gentle tickling. Remember, each baby develops at their own pace, so don't worry if your baby takes a little longer to reach this milestone.",
    flexibility: "flexible",
    flexibilityReason: "The timing of a baby's first laugh can vary widely.",
    frequency: "once",
    group: "baby-milestones",
    dependsOn: 42,
    additionalInputs: ["what prompted the laugh", "age when it occurred"],
    resources: ["Baby's Social and Emotional Development"],
    features: ["Milestone photo/video capture", "Development tracker"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Describe the moment of your baby's first laugh. What caused it? How did it make you feel?"],
      friends: ["Share the joy of your baby's first laugh with close friends and family."],
      community: ["Discuss experiences of baby's first laugh and other early communication milestones."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-55',
    id: 55,
    text: "Started solid foods",
    date: "2024-06-15",
    achieved: false,
    icon: "CoffeeOutlined",
    notes: "",
    type: "developmental",
    category: "feeding",
    stage: "Infant",
    details: "Most babies are ready for solid foods around 6 months old. Start with single-ingredient purees or soft foods. Introduce new foods one at a time, waiting a few days between each new food to watch for allergic reactions. Continue breast milk or formula as the primary source of nutrition. Always supervise eating and avoid choking hazards. Consult your pediatrician before starting solids.",
    flexibility: "semi-flexible",
    flexibilityReason: "The timing can vary based on baby's readiness and pediatrician's advice, but usually occurs around 6 months.",
    frequency: "recurring",
    group: "feeding",
    dependsOn: 42,
    additionalInputs: ["first food introduced", "baby's reaction to solids"],
    resources: ["Introducing Solid Foods to Your Baby"],
    features: ["Food introduction tracker", "Allergy watchlist"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Record your baby's reactions to different foods and any signs of allergies or preferences."],
      friends: ["Share experiences of starting solids with other parents, including favorite first foods."],
      community: ["Discuss strategies for introducing solids or share homemade baby food recipes."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-56',
    id: 56,
    text: "Baby rolled over",
    date: "2024-03-01",
    achieved: false,
    icon: "RedoOutlined",
    notes: "",
    type: "developmental",
    category: "physical-development",
    stage: "Infant",
    details: "Babies typically start rolling over between 4-6 months. This milestone marks improved strength and coordination. Once your baby starts rolling, never leave them unattended on high surfaces. Encourage rolling by placing toys just out of reach during tummy time. Remember to always practice safe sleep guidelines, placing baby on their back to sleep.",
    flexibility: "flexible",
    flexibilityReason: "The timing of when a baby first rolls over can vary widely among infants.",
    frequency: "once",
    group: "baby-milestones",
    dependsOn: 42,
    additionalInputs: ["direction of first roll (back to front or vice versa)", "age when it occurred"],
    resources: ["Baby Movement Milestones"],
    features: ["Milestone tracker", "Safe sleep reminder"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Describe your baby's first roll. When and how did it happen? How did they react?"],
      friends: ["Share the excitement of your baby's new skill with close friends and family."],
      community: ["Discuss experiences with rolling and tips for encouraging physical development."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-57',
    id: 57,
    text: "First tooth appeared",
    date: "2024-07-10",
    achieved: false,
    icon: "StarOutlined",
    notes: "",
    type: "developmental",
    category: "physical-development",
    stage: "Infant",
    details: "The first tooth typically appears between 4-7 months, but it can vary greatly. Signs of teething include increased drooling, irritability, and a desire to chew on things. Soothe discomfort with teething toys, cold washcloths, or gently massaging the gums. Avoid teething gels unless recommended by your pediatrician. Start dental hygiene practices as soon as the first tooth appears.",
    flexibility: "very flexible",
    flexibilityReason: "The timing of the first tooth can vary significantly among babies.",
    frequency: "once",
    group: "baby-milestones",
    dependsOn: 42,
    additionalInputs: ["which tooth appeared first", "any teething symptoms observed"],
    resources: ["Baby Teething Guide"],
    features: ["Teething symptom tracker", "Dental care reminder"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Note when and where the first tooth appeared, and any teething symptoms your baby experienced."],
      friends: ["Share teething experiences and remedies with other parents."],
      community: ["Discuss teething relief strategies or share experiences with early dental care."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-58',
    id: 58,
    text: "Baby sat up unassisted",
    date: "2024-07-25",
    achieved: false,
    icon: "ArrowUpOutlined",
    notes: "",
    type: "developmental",
    category: "physical-development",
    stage: "Infant",
    details: "Babies typically master sitting unassisted between 4-7 months. This milestone indicates strong core muscles and improving balance. Create safe spaces for your baby to practice sitting, using pillows for support initially. Sitting up opens a new world of play and exploration for your baby. Remember, babies may topple over unexpectedly at first, so always supervise and provide a soft surface.",
    flexibility: "flexible",
    flexibilityReason: "The age at which babies can sit unassisted varies, but usually occurs within a certain range.",
    frequency: "once",
    group: "baby-milestones",
    dependsOn: 42,
    additionalInputs: ["duration of independent sitting", "favorite toys when sitting"],
    resources: ["Encouraging Your Baby's Physical Development"],
    features: ["Milestone tracker", "Playtime activity suggestions"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Record when your baby first sat unassisted and how it has changed their play and exploration."],
      friends: ["Share the excitement of this new milestone with close friends and family."],
      community: ["Discuss experiences with baby's sitting milestone and tips for encouraging it safely."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-59',
    id: 59,
    text: "Started babbling",
    date: "2024-04-05",
    achieved: false,
    icon: "AudioOutlined",
    notes: "",
    type: "developmental",
    category: "language-development",
    stage: "Infant",
    details: "Babbling typically starts around 4-6 months. It's an important precursor to speech, as babies experiment with sounds and mouth movements. Encourage babbling by talking to your baby frequently, naming objects, and responding to their vocalizations. This back-and-forth interaction is crucial for language development. Remember, every baby develops at their own pace.",
    flexibility: "flexible",
    flexibilityReason: "The onset of babbling can vary, but usually begins within a certain age range.",
    frequency: "ongoing",
    group: "baby-milestones",
    dependsOn: 42,
    additionalInputs: ["first sounds made", "frequency of babbling"],
    resources: ["Supporting Your Baby's Language Development"],
    features: ["Language milestone tracker", "Baby talk translator"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Record the sounds your baby is making and how often they babble."],
      friends: ["Share cute babbling moments with close friends and family."],
      community: ["Discuss experiences with baby's early vocalizations and tips for encouraging language development."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-60',
    id: 60,
    text: "First word",
    date: "2024-10-20",
    achieved: false,
    icon: "MessageOutlined",
    notes: "",
    type: "developmental",
    category: "language-development",
    stage: "Infant",
    details: "Babies typically say their first word between 9-14 months. Common first words include 'mama', 'dada', or simple objects. Encourage speech by talking to your baby often, reading books, and naming objects in their environment. Remember, gestures and sounds count as communication too. If you have concerns about your baby's language development, consult your pediatrician.",
    flexibility: "very flexible",
    flexibilityReason: "The timing of a baby's first word can vary greatly and is influenced by many factors.",
    frequency: "once",
    group: "baby-milestones",
    dependsOn: 42,
    additionalInputs: ["the word spoken", "context of when it was said"],
    resources: ["Encouraging Your Baby's First Words"],
    features: ["First words log", "Language development activity suggestions"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Record your baby's first word, when it was said, and the context around it."],
      friends: ["Share the excitement of your baby's first word with close friends and family."],
      community: ["Discuss experiences with baby's first words and tips for encouraging language development."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-61',
    id: 61,
    text: "Researched and chose daycare options",
    date: null,
    achieved: false,
    icon: "SearchOutlined",
    notes: "",
    type: "preparation",
    category: "childcare",
    stage: "Postpartum",
    details: "Research local daycare options, considering factors such as location, cost, staff qualifications, and available schedules. Visit potential daycares and ask about their policies, curriculum, and safety measures.",
    flexibility: "flexible",
    flexibilityReason: "This task can be done at various points during pregnancy or early postpartum, depending on when you plan to start daycare.",
    frequency: "once",
    group: "childcare-planning",
    dependsOn: null,
    additionalInputs: ["chosen daycare name", "start date"],
    resources: ["Daycare Evaluation Checklist"],
    features: ["Daycare comparison tool", "Policy review checklist"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["List pros and cons of each daycare option you've considered."],
      friends: ["Ask friends for daycare recommendations or experiences."],
      community: ["Discuss daycare selection criteria and tips with other parents."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-62',
    id: 62,
    text: "Hired a nanny or au pair",
    date: null,
    achieved: false,
    icon: "UserAddOutlined",
    notes: "",
    type: "preparation",
    category: "childcare",
    stage: "Postpartum",
    details: "Research nanny or au pair options, conduct interviews, check references, and complete necessary paperwork. Discuss expectations, schedules, and responsibilities. Prepare your home for the caregiver's arrival if applicable.",
    flexibility: "flexible",
    flexibilityReason: "This process can be started during pregnancy or postpartum, depending on when you need childcare to begin.",
    frequency: "once",
    group: "childcare-planning",
    dependsOn: null,
    additionalInputs: ["caregiver's name", "start date"],
    resources: ["Nanny Interview Questions", "Au Pair Program Guide"],
    features: ["Caregiver background check tool", "Contract template generator"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Note down interview impressions and reference check results."],
      friends: ["Discuss nanny hiring experiences with other parent friends."],
      community: ["Share tips on finding and vetting childcare providers."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-63',
    id: 63,
    text: "Coordinated childcare with family member",
    date: null,
    achieved: false,
    icon: "TeamOutlined",
    notes: "",
    type: "preparation",
    category: "childcare",
    stage: "Postpartum",
    details: "Discuss childcare arrangements with the family member who will be providing care. Agree on schedules, responsibilities, and any compensation. Prepare your home and necessary supplies for childcare.",
    flexibility: "flexible",
    flexibilityReason: "This can be done during pregnancy or early postpartum, depending on when family care will begin.",
    frequency: "once",
    group: "childcare-planning",
    dependsOn: null,
    additionalInputs: ["caregiver's name", "care schedule"],
    resources: ["Family Childcare Agreement Template"],
    features: ["Family care schedule planner", "Supply checklist generator"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Note agreed-upon care details and any concerns to address."],
      friends: ["Discuss experiences with family childcare arrangements."],
      community: ["Share tips on successful family caregiving arrangements."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-64',
    id: 64,
    text: "Prepared for full-time stay-at-home parenting",
    date: null,
    achieved: false,
    icon: "HomeOutlined",
    notes: "",
    type: "preparation",
    category: "parenting",
    stage: "Postpartum",
    details: "Plan your daily routine as a stay-at-home parent. Research local parent groups and activities. Set up a support network and plan for personal time and self-care.",
    flexibility: "flexible",
    flexibilityReason: "This preparation can be done during pregnancy or early postpartum.",
    frequency: "once",
    group: "parenting-preparation",
    dependsOn: null,
    additionalInputs: ["planned daily routine", "local parent groups"],
    resources: ["Stay-at-Home Parent Guide", "Local Parenting Resources"],
    features: ["Daily routine planner", "Local activity finder"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Outline your ideal daily routine and personal goals."],
      friends: ["Discuss stay-at-home parenting experiences with friends."],
      community: ["Share tips and resources for stay-at-home parents."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-65',
    id: 65,
    text: "Planned return to work",
    date: null,
    achieved: false,
    icon: "ScheduleOutlined",
    notes: "",
    type: "preparation",
    category: "work-life-balance",
    stage: "Postpartum",
    details: "Plan your return to work after parental leave. Discuss arrangements with your employer, plan childcare, and prepare for pumping at work if breastfeeding.",
    flexibility: "semi-flexible",
    flexibilityReason: "This should be done before your planned return-to-work date, but timing can vary based on your leave duration.",
    frequency: "once",
    group: "career-planning",
    dependsOn: null,
    additionalInputs: ["return to work date", "childcare arrangements"],
    resources: ["Back-to-Work After Baby Guide", "Pumping at Work Rights"],
    features: ["Return-to-work checklist", "Pumping schedule planner"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["List your concerns about returning to work and strategies to address them."],
      friends: ["Discuss return-to-work experiences with other parent friends."],
      community: ["Share tips for balancing work and new parenthood."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-66',
    id: 66,
    text: "Set up home office for remote work",
    date: null,
    achieved: false,
    icon: "LaptopOutlined",
    notes: "",
    type: "preparation",
    category: "work-life-balance",
    stage: "Postpartum",
    details: "Prepare a dedicated workspace at home for remote work. Ensure you have necessary equipment and a quiet area for video calls. Plan your work schedule around baby's routine.",
    flexibility: "flexible",
    flexibilityReason: "This can be done during pregnancy or early postpartum, before returning to work.",
    frequency: "once",
    group: "career-planning",
    dependsOn: null,
    additionalInputs: ["workspace location", "needed equipment"],
    resources: ["Home Office Setup Guide", "Work-From-Home with Baby Tips"],
    features: ["Home office checklist", "Work-baby schedule planner"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["List your home office needs and potential challenges."],
      friends: ["Discuss work-from-home strategies with other parent friends."],
      community: ["Share tips for balancing remote work and childcare."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-67',
    id: 67,
    text: "Established flexible work arrangements",
    date: null,
    achieved: false,
    icon: "ScheduleOutlined",
    notes: "",
    type: "preparation",
    category: "work-life-balance",
    stage: "Postpartum",
    details: "Negotiate flexible work arrangements with your employer, such as adjusted hours, part-time work, or a combination of remote and in-office work.",
    flexibility: "semi-flexible",
    flexibilityReason: "This should be done before returning to work, but timing can vary based on your employer and leave duration.",
    frequency: "once",
    group: "career-planning",
    dependsOn: null,
    additionalInputs: ["agreed work schedule", "flexibility terms"],
    resources: ["Negotiating Flexible Work Guide", "Sample Flexible Work Proposals"],
    features: ["Flexible schedule planner", "Work arrangement proposal template"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Outline your ideal flexible work arrangement and justification."],
      friends: ["Discuss flexible work experiences with other parent friends."],
      community: ["Share tips for successfully negotiating flexible work arrangements."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-68',
    id: 68,
    text: "Organized local support network",
    date: null,
    achieved: false,
    icon: "TeamOutlined",
    notes: "",
    type: "preparation",
    category: "support-system",
    stage: "Pregnancy",
    details: "Identify and organize your local support network, including family, friends, and neighbors. Create a contact list and discuss how each person can help after the baby arrives.",
    flexibility: "flexible",
    flexibilityReason: "This can be done at any point during pregnancy or early postpartum.",
    frequency: "once",
    group: "support-planning",
    dependsOn: null,
    additionalInputs: ["support network members", "types of help offered"],
    resources: ["Building a Postpartum Support Network", "Postpartum Help Scheduler"],
    features: ["Support network contact list", "Help schedule organizer"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["List your support network members and their offered help."],
      friends: ["Discuss support network strategies with other expectant friends."],
      community: ["Share tips for building and managing a local support network."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-69',
    id: 69,
    text: "Set up virtual support system",
    date: null,
    achieved: false,
    icon: "VideoCameraOutlined",
    notes: "",
    type: "preparation",
    category: "support-system",
    stage: "Pregnancy",
    details: "Establish a virtual support system for long-distance family and friends. Set up video call schedules, join online parenting groups, and explore virtual postpartum support services.",
    flexibility: "flexible",
    flexibilityReason: "This can be set up at any point during pregnancy or early postpartum.",
    frequency: "once",
    group: "support-planning",
    dependsOn: null,
    additionalInputs: ["video call platform", "online support groups joined"],
    resources: ["Virtual Support for New Parents", "Online Parenting Communities"],
    features: ["Video call scheduler", "Virtual support group finder"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["List your virtual support network and planned communication schedules."],
      friends: ["Share virtual support experiences with other expectant friends."],
      community: ["Recommend online parenting communities and virtual support resources."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-70',
    id: 70,
    text: "Researched local parenting resources",
    date: null,
    achieved: false,
    icon: "SearchOutlined",
    notes: "",
    type: "preparation",
    category: "support-system",
    stage: "Pregnancy",
    details: "Research and compile a list of local parenting resources, including support groups, parenting classes, lactation consultants, and pediatricians.",
    flexibility: "flexible",
    flexibilityReason: "This research can be done at any point during pregnancy.",
    frequency: "once",
    group: "support-planning",
    dependsOn: null,
    additionalInputs: ["local resources found", "contact information"],
    resources: ["Local Parenting Resource Directory", "New Parent Support Services"],
    features: ["Local resource map", "Service provider contact list"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["List local resources you've found and their services."],
      friends: ["Ask friends for recommendations on local parenting resources."],
      community: ["Share helpful local resources for new parents in your area."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-71',
    id: 71,
    text: "Created postpartum care plan",
    date: null,
    achieved: false,
    icon: "MedicineBoxOutlined",
    notes: "",
    type: "preparation",
    category: "health-wellness",
    stage: "Pregnancy",
    details: "Develop a comprehensive postpartum care plan, including self-care strategies, mental health support, and physical recovery plans. Discuss this plan with your healthcare provider.",
    flexibility: "semi-flexible",
    flexibilityReason: "This should be done in the third trimester, but can be adjusted as needed.",
    frequency: "once",
    group: "postpartum-planning",
    dependsOn: null,
    additionalInputs: ["self-care strategies", "support resources"],
    resources: ["Postpartum Recovery Guide", "Mental Health Support for New Parents"],
    features: ["Postpartum care checklist", "Self-care reminder app"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Outline your postpartum care plan and concerns to discuss with your doctor."],
      friends: ["Discuss postpartum care experiences with other parent friends."],
      community: ["Share postpartum recovery tips and resources."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-72',
    id: 72,
    text: "Established prenatal fitness routine",
    date: null,
    achieved: false,
    icon: "HeartOutlined",
    notes: "",
    type: "health",
    category: "fitness",
    stage: "Pregnancy",
    details: "Consult with your healthcare provider and establish a safe prenatal fitness routine. This may include prenatal yoga, swimming, or walking.",
    flexibility: "flexible",
    flexibilityReason: "This can be started at any point during pregnancy with doctor's approval.",
    frequency: "ongoing",
    group: "prenatal-health",
    dependsOn: null,
    additionalInputs: ["chosen activities", "exercise frequency"],
    resources: ["Safe Prenatal Exercise Guide", "Prenatal Yoga Videos"],
    features: ["Workout tracker", "Pregnancy-safe exercise finder"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Describe your prenatal fitness routine and how you feel after exercising."],
      friends: ["Share prenatal fitness experiences with other expectant friends."],
      community: ["Recommend pregnancy-safe workouts or fitness classes."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-73',
    id: 73,
    text: "Created postpartum nutrition plan",
    date: null,
    achieved: false,
    icon: "AppleOutlined",
    notes: "",
    type: "health",
    category: "nutrition",
    stage: "Pregnancy",
    details: "Develop a nutritious postpartum meal plan, considering dietary needs for recovery and breastfeeding if applicable. Consider meal prep strategies and easy, nutritious snacks.",
    flexibility: "flexible",
    flexibilityReason: "This can be done in late pregnancy or early postpartum.",
    frequency: "once",
    group: "postpartum-health",
    dependsOn: null,
    additionalInputs: ["favorite recipes", "meal prep ideas"],
    resources: ["Postpartum Nutrition Guide", "Lactation-Boosting Recipes"],
    features: ["Meal planner", "Grocery list generator"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["List your favorite postpartum meals and snacks."],
      friends: ["Share postpartum nutrition tips with other new parents."],
      community: ["Recommend nutritious, easy-to-prepare meals for new parents."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-74',
    id: 74,
    text: "Scheduled regular prenatal check-ups",
    date: null,
    achieved: false,
    icon: "CalendarOutlined",
    notes: "",
    type: "health",
    category: "medical",
    stage: "Pregnancy",
    details: "Schedule and attend regular prenatal check-ups as recommended by your healthcare provider. Keep track of questions to ask during these appointments.",
    flexibility: "semi-rigid",
    flexibilityReason: "Appointments should be scheduled according to medical recommendations, but exact dates may have some flexibility.",
    frequency: "recurring",
    group: "prenatal-health",
    dependsOn: null,
    additionalInputs: ["upcoming appointment dates", "questions for doctor"],
    resources: ["Prenatal Appointment Schedule", "Questions to Ask Your OB-GYN"],
    features: ["Appointment reminder", "Pregnancy health tracker"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Note any concerns or questions for your next check-up."],
      friends: ["Discuss prenatal care experiences with other expectant friends."],
      community: ["Share tips for getting the most out of prenatal appointments."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-75',
    id: 75,
    text: "Established self-care routine",
    date: null,
    achieved: false,
    icon: "HeartOutlined",
    notes: "",
    type: "health",
    category: "wellness",
    stage: "Pregnancy",
    details: "Create and maintain a regular self-care routine, including activities that help you relax and recharge. This could include meditation, prenatal massage, or hobby time.",
    flexibility: "flexible",
    flexibilityReason: "This can be established at any point during pregnancy and adjusted as needed.",
    frequency: "ongoing",
    group: "self-care",
    dependsOn: null,
    additionalInputs: ["favorite self-care activities", "frequency"],
    resources: ["Self-Care Ideas for Expecting Parents", "Stress Relief Techniques"],
    features: ["Self-care activity tracker", "Relaxation technique guide"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Describe your self-care routine and how it makes you feel."],
      friends: ["Share self-care ideas with other expectant friends."],
      community: ["Recommend relaxation techniques or self-care activities for expecting parents."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-76',
    id: 76,
    text: "Created a help request list",
    date: null,
    achieved: false,
    icon: "OrderedListOutlined",
    notes: "",
    type: "preparation",
    category: "support-system",
    stage: "Pregnancy",
    details: "Make a list of specific tasks or types of help you might need after the baby arrives. This can include meal preparation, house cleaning, or childcare for older siblings.",
    flexibility: "flexible",
    flexibilityReason: "This list can be created at any point during pregnancy and updated as needed.",
    frequency: "once",
    group: "support-planning",
    dependsOn: null,
    additionalInputs: ["types of help needed", "potential helpers"],
    resources: ["New Parent Help Checklist", "Delegating Tasks to Helpers"],
    features: ["Task assignment tool", "Help schedule organizer"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["List specific tasks you'd like help with after the baby arrives."],
      friends: ["Discuss postpartum help experiences with other parent friends."],
      community: ["Share tips for effectively asking for and accepting help as a new parent."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-77',
    id: 77,
    text: "Prepared for solo parenting",
    date: null,
    achieved: false,
    icon: "UserOutlined",
    notes: "",
    type: "preparation",
    category: "parenting",
    stage: "Pregnancy",
    details: "If planning to parent solo, prepare strategies for self-care, time management, and building a support network. Research local resources for single parents.",
    flexibility: "flexible",
    flexibilityReason: "This preparation can be done throughout pregnancy and early postpartum.",
    frequency: "ongoing",
    group: "parenting-preparation",
    dependsOn: null,
    additionalInputs: ["self-care strategies", "support resources"],
    resources: ["Single Parent Resources", "Time Management for Solo Parents"],
    features: ["Solo parenting toolkit", "Local support group finder"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["List your concerns about solo parenting and strategies to address them."],
      friends: ["Discuss solo parenting experiences with other single parent friends."],
      community: ["Share resources and tips for successful solo parenting."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-78',
    id: 78,
    text: "Learned to set boundaries",
    date: null,
    achieved: false,
    icon: "BorderOuterOutlined",
    notes: "",
    type: "personal-growth",
    category: "self-care",
    stage: "Pregnancy",
    details: "Practice setting healthy boundaries with family, friends, and work. Learn to say no to extra commitments and prioritize your wellbeing.",
    flexibility: "flexible",
    flexibilityReason: "This is an ongoing process that can start at any time during pregnancy.",
    frequency: "ongoing",
    group: "self-care",
    dependsOn: null,
    additionalInputs: ["boundary setting scenarios", "communication strategies"],
    resources: ["Setting Healthy Boundaries", "Assertive Communication Techniques"],
    features: ["Boundary setting practice scenarios", "Self-advocacy phrase generator"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Reflect on situations where you've successfully set boundaries."],
      friends: ["Discuss boundary-setting strategies with trusted friends."],
      community: ["Share tips for maintaining personal boundaries as a new parent."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-79',
    id: 79,
    text: "Attended hands-on parenting class",
    date: null,
    achieved: false,
    icon: "ExperimentOutlined",
    notes: "",
    type: "education",
    category: "parenting-skills",
    stage: "Pregnancy",
    details: "Participate in a hands-on parenting class that covers essential skills like diapering, bathing, and feeding. Practice with dolls or demo equipment.",
    flexibility: "flexible",
    flexibilityReason: "This class can be taken at any point during pregnancy, ideally in the third trimester.",
    frequency: "once",
    group: "parenting-preparation",
    dependsOn: null,
    additionalInputs: ["class name", "key skills learned"],
    resources: ["Local Parenting Classes", "Hands-On Baby Care Techniques"],
    features: ["Skill practice reminder", "Baby care technique videos"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Note the most useful skills you learned in the class."],
      friends: ["Share experiences from parenting classes with other expectant friends."],
      community: ["Recommend good hands-on parenting classes in your area."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-80',
    id: 80,
    text: "Created parenting book reading list",
    date: null,
    achieved: false,
    icon: "ReadOutlined",
    notes: "",
    type: "education",
    category: "parenting-skills",
    stage: "Pregnancy",
    details: "Compile a list of recommended parenting books and create a reading schedule. Include books on various topics such as baby care, child development, and parenting philosophies.",
    flexibility: "flexible",
    flexibilityReason: "This list can be created and updated throughout pregnancy and beyond.",
    frequency: "once",
    group: "parenting-preparation",
    dependsOn: null,
    additionalInputs: ["book titles", "reading schedule"],
    resources: ["Top Parenting Books for New Parents", "Child Development Reading List"],
    features: ["Reading progress tracker", "Book summary generator"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["List the parenting books you want to read and your thoughts on each."],
      friends: ["Share book recommendations with other expectant friends."],
      community: ["Recommend insightful parenting books you've found helpful."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-81',
    id: 81,
    text: "Joined a parenting support group",
    date: null,
    achieved: false,
    icon: "TeamOutlined",
    notes: "",
    type: "support",
    category: "community",
    stage: "Pregnancy",
    details: "Find and join a local or online parenting support group. This could be for expectant parents or new parents, depending on your preference and stage of pregnancy.",
    flexibility: "flexible",
    flexibilityReason: "You can join a group at any point during pregnancy or postpartum.",
    frequency: "once",
    group: "support-planning",
    dependsOn: null,
    additionalInputs: ["group name", "meeting frequency"],
    resources: ["Local Parenting Groups", "Online Parenting Communities"],
    features: ["Group meeting reminder", "Discussion topic suggestion tool"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Note your impressions of the group and topics you'd like to discuss."],
      friends: ["Share experiences from parenting groups with other expectant friends."],
      community: ["Recommend supportive parenting groups you've found helpful."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-82',
    id: 82,
    text: "Established stress management techniques",
    date: null,
    achieved: false,
    icon: "SmileOutlined",
    notes: "",
    type: "health",
    category: "mental-health",
    stage: "Pregnancy",
    details: "Learn and practice stress management techniques such as deep breathing, progressive muscle relaxation, or mindfulness meditation. Find methods that work best for you.",
    flexibility: "flexible",
    flexibilityReason: "These techniques can be learned and practiced at any time during pregnancy and beyond.",
    frequency: "ongoing",
    group: "self-care",
    dependsOn: null,
    additionalInputs: ["favorite techniques", "practice frequency"],
    resources: ["Stress Management for Expecting Parents", "Pregnancy Meditation Guide"],
    features: ["Guided relaxation audio", "Stress level tracker"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Describe your go-to stress relief techniques and their effectiveness."],
      friends: ["Share stress management tips with other expectant friends."],
      community: ["Recommend effective stress relief methods for pregnant individuals."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-83',
    id: 83,
    text: "Identified postpartum mental health resources",
    date: null,
    achieved: false,
    icon: "MedicineBoxOutlined",
    notes: "",
    type: "health",
    category: "mental-health",
    stage: "Pregnancy",
    details: "Research and compile a list of postpartum mental health resources, including therapists specializing in postpartum issues, support groups, and hotlines.",
    flexibility: "flexible",
    flexibilityReason: "This can be done at any point during pregnancy, ideally before the third trimester.",
    frequency: "once",
    group: "postpartum-planning",
    dependsOn: null,
    additionalInputs: ["local resources", "hotline numbers"],
    resources: ["Postpartum Mental Health Guide", "Finding a Postpartum Therapist"],
    features: ["Mental health resource locator", "Mood tracker app suggestions"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["List mental health resources you've identified and why you chose them."],
      friends: ["Discuss postpartum mental health experiences with trusted friends."],
      community: ["Share reliable postpartum mental health resources and support options."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-84',
    id: 84,
    text: "Learned quick coping strategies",
    date: null,
    achieved: false,
    icon: "ThunderboltOutlined",
    notes: "",
    type: "health",
    category: "mental-health",
    stage: "Pregnancy",
    details: "Learn and practice quick coping strategies for stressful moments, such as grounding techniques, positive affirmations, or brief mindfulness exercises.",
    flexibility: "flexible",
    flexibilityReason: "These strategies can be learned and practiced at any time during pregnancy and postpartum.",
    frequency: "ongoing",
    group: "self-care",
    dependsOn: null,
    additionalInputs: ["favorite strategies", "trigger situations"],
    resources: ["Quick Stress Relief Techniques", "Grounding Exercises for New Parents"],
    features: ["Coping strategy quick-reference", "Personalized affirmation generator"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["List your go-to coping strategies and when you find them most useful."],
      friends: ["Share quick stress relief tips with other expectant friends."],
      community: ["Recommend effective coping strategies for new parent stress."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-85',
    id: 85,
    text: "Created detailed baby budget",
    date: null,
    achieved: false,
    icon: "DollarOutlined",
    notes: "",
    type: "financial",
    category: "budgeting",
    stage: "Pregnancy",
    details: "Develop a comprehensive budget for baby-related expenses, including one-time purchases, recurring costs, and potential changes to income. Consider both immediate and long-term expenses.",
    flexibility: "flexible",
    flexibilityReason: "This can be done at any point during pregnancy, but earlier is better for financial planning.",
    frequency: "once",
    group: "financial-planning",
    dependsOn: null,
    additionalInputs: ["major expenses", "saving goals"],
    resources: ["New Parent Financial Planning Guide", "Baby Cost Calculator"],
    features: ["Interactive budget planner", "Expense tracking tool"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["List your major anticipated expenses and saving strategies."],
      friends: ["Discuss budget planning strategies with other expectant parents."],
      community: ["Share money-saving tips for new parents."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-86',
    id: 86,
    text: "Set up basic family budget",
    date: null,
    achieved: false,
    icon: "AccountBookOutlined",
    notes: "",
    type: "financial",
    category: "budgeting",
    stage: "Pregnancy",
    details: "Create a basic family budget that includes essential baby expenses and adjusts for changes in income. Focus on key categories like diapers, formula (if applicable), and childcare.",
    flexibility: "flexible",
    flexibilityReason: "This can be done at any point during pregnancy, but preferably by the third trimester.",
    frequency: "once",
    group: "financial-planning",
    dependsOn: null,
    additionalInputs: ["key expense categories", "income changes"],
    resources: ["Family Budgeting Basics", "Adjusting Your Budget for a New Baby"],
    features: ["Simple budget template", "Cost comparison tool"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Outline your basic family budget and areas you may need to adjust."],
      friends: ["Share budgeting tips with other expectant friends."],
      community: ["Discuss strategies for managing essential baby expenses on a budget."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-87',
    id: 87,
    text: "Started tracking baby-related expenses",
    date: null,
    achieved: false,
    icon: "FundOutlined",
    notes: "",
    type: "financial",
    category: "budgeting",
    stage: "Pregnancy",
    details: "Begin tracking all baby-related purchases and expenses. This will help you understand your spending patterns and adjust your budget as needed.",
    flexibility: "flexible",
    flexibilityReason: "This can be started as soon as you begin making baby-related purchases.",
    frequency: "ongoing",
    group: "financial-planning",
    dependsOn: null,
    additionalInputs: ["expense categories", "tracking method"],
    resources: ["Expense Tracking Apps for Parents", "Understanding Baby Costs"],
    features: ["Expense categorization tool", "Spending trend analyzer"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Note your biggest expense categories and any surprising costs."],
      friends: ["Discuss typical baby expenses with other expectant parents."],
      community: ["Share tips for managing and tracking baby-related costs."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-88',
    id: 88,
    text: "Created shared parenting responsibility plan",
    date: null,
    achieved: false,
    icon: "SolutionOutlined",
    notes: "",
    type: "preparation",
    category: "parenting",
    stage: "Pregnancy",
    details: "Work with your partner to create a plan for sharing parenting responsibilities. Discuss expectations for tasks like night feedings, diaper changes, and household chores.",
    flexibility: "flexible",
    flexibilityReason: "This can be done at any point during pregnancy, but earlier allows more time for discussion and adjustment.",
    frequency: "once",
    group: "parenting-preparation",
    dependsOn: null,
    additionalInputs: ["agreed responsibilities", "discussion points"],
    resources: ["Co-Parenting Strategies", "Dividing Baby Care Tasks"],
    features: ["Task division planner", "Responsibility rotation scheduler"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Outline your shared parenting plan and any concerns to address."],
      friends: ["Discuss co-parenting strategies with other expectant couples."],
      community: ["Share tips for effectively sharing parenting responsibilities."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-89',
    id: 89,
    text: "Arranged partner support for primary caregiver",
    date: null,
    achieved: false,
    icon: "HeartOutlined",
    notes: "",
    type: "preparation",
    category: "support-system",
    stage: "Pregnancy",
    details: "If one partner will be the primary caregiver, discuss and plan how the other partner can provide support. This may include taking on specific tasks or providing regular breaks.",
    flexibility: "flexible",
    flexibilityReason: "This can be discussed and planned at any point during pregnancy.",
    frequency: "once",
    group: "parenting-preparation",
    dependsOn: null,
    additionalInputs: ["support strategies", "scheduled breaks"],
    resources: ["Supporting a Stay-at-Home Parent", "Balancing Work and Family Support"],
    features: ["Support schedule planner", "Task assignment tool"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["List specific ways your partner can support you as the primary caregiver."],
      friends: ["Discuss support strategies with other couples planning similar arrangements."],
      community: ["Share tips for supporting a primary caregiver partner."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-90',
    id: 90,
    text: "Developed solo parenting strategies",
    date: null,
    achieved: false,
    icon: "UserOutlined",
    notes: "",
    type: "preparation",
    category: "parenting",
    stage: "Pregnancy",
    details: "If you'll be parenting solo, develop strategies for managing daily tasks, self-care, and building a support network. Consider both practical and emotional aspects of solo parenting.",
    flexibility: "flexible",
    flexibilityReason: "These strategies can be developed throughout pregnancy and adjusted as needed.",
    frequency: "ongoing",
    group: "parenting-preparation",
    dependsOn: null,
    additionalInputs: ["key challenges", "coping strategies"],
    resources: ["Solo Parenting Tips", "Building a Support Network for Single Parents"],
    features: ["Daily routine planner", "Local support group finder"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["List your main concerns about solo parenting and strategies to address them."],
      friends: ["Discuss solo parenting experiences with other single parent friends."],
      community: ["Share tips and resources for successful solo parenting."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-91',
    id: 91,
    text: "Researched homemade baby food options",
    date: null,
    achieved: false,
    icon: "ExperimentOutlined",
    notes: "",
    type: "preparation",
    category: "nutrition",
    stage: "Pregnancy",
    details: "Research recipes, techniques, and equipment for making homemade baby food. Consider nutritional needs at different stages and safe food storage practices.",
    flexibility: "flexible",
    flexibilityReason: "This research can be done at any point during pregnancy or early postpartum.",
    frequency: "once",
    group: "feeding-preparation",
    dependsOn: null,
    additionalInputs: ["favorite recipes", "needed equipment"],
    resources: ["Homemade Baby Food Guide", "Baby Nutrition Basics"],
    features: ["Recipe database", "Meal planning tool"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["List baby food recipes you'd like to try and any equipment you need."],
      friends: ["Share homemade baby food experiences with other parent friends."],
      community: ["Recommend reliable resources for making nutritious baby food at home."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-92',
    id: 92,
    text: "Planned quick and nutritious meal options",
    date: null,
    achieved: false,
    icon: "ClockCircleOutlined",
    notes: "",
    type: "preparation",
    category: "nutrition",
    stage: "Pregnancy",
    details: "Compile a list of quick, easy, and nutritious meal options for busy days with a newborn. Consider meals that can be prepared in advance or with minimal effort.",
    flexibility: "flexible",
    flexibilityReason: "This planning can be done at any point during pregnancy.",
    frequency: "once",
    group: "postpartum-preparation",
    dependsOn: null,
    additionalInputs: ["favorite quick meals", "grocery list"],
    resources: ["Quick Meals for New Parents", "Nutritious No-Cook Recipes"],
    features: ["Meal idea generator", "Grocery list creator"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["List your go-to quick and nutritious meal ideas."],
      friends: ["Share easy meal ideas with other expectant friends."],
      community: ["Recommend nutritious, low-effort meals for new parents."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-93',
    id: 93,
    text: "Created balanced meal and snack plan",
    date: null,
    achieved: false,
    icon: "PieChartOutlined",
    notes: "",
    type: "preparation",
    category: "nutrition",
    stage: "Pregnancy",
    details: "Develop a balanced meal and snack plan that includes a mix of homemade and convenient options. Focus on nutrients important for postpartum recovery and breastfeeding if applicable.",
    flexibility: "flexible",
    flexibilityReason: "This plan can be created during pregnancy and adjusted postpartum as needed.",
    frequency: "once",
    group: "postpartum-preparation",
    dependsOn: null,
    additionalInputs: ["meal ideas", "favorite snacks"],
    resources: ["Postpartum Nutrition Guide", "Balanced Meal Planning for New Parents"],
    features: ["Customizable meal planner", "Nutrition tracker"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Outline your balanced meal plan and favorite nutritious snacks."],
      friends: ["Discuss postpartum nutrition strategies with other expectant friends."],
      community: ["Share tips for maintaining a balanced diet as a new parent."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-94',
    id: 94,
    text: "Started baby book or scrapbook",
    date: null,
    achieved: false,
    icon: "BookOutlined",
    notes: "",
    type: "personal",
    category: "memory-keeping",
    stage: "Pregnancy",
    details: "Begin a baby book or scrapbook to document your pregnancy and baby's milestones. Gather supplies and start with pregnancy memories if desired.",
    flexibility: "flexible",
    flexibilityReason: "This can be started at any point during pregnancy or after birth.",
    frequency: "ongoing",
    group: "memory-keeping",
    dependsOn: null,
    additionalInputs: ["book/scrapbook style", "first entries"],
    resources: ["Baby Book Ideas", "Creative Memory Keeping for New Parents"],
    features: ["Digital scrapbook template", "Milestone tracker"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Describe your vision for your baby book or scrapbook."],
      friends: ["Share baby book ideas with other expectant friends."],
      community: ["Recommend creative ways to document baby's milestones."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-95',
    id: 95,
    text: "Set up digital photo organization system",
    date: null,
    achieved: false,
    icon: "PictureOutlined",
    notes: "",
    type: "preparation",
    category: "memory-keeping",
    stage: "Pregnancy",
    details: "Establish a system for organizing and backing up digital photos of your baby. Choose a cloud storage service or local backup method and create a folder structure.",
    flexibility: "flexible",
    flexibilityReason: "This system can be set up at any point during pregnancy or early postpartum.",
    frequency: "once",
    group: "memory-keeping",
    dependsOn: null,
    additionalInputs: ["chosen storage method", "folder structure"],
    resources: ["Digital Photo Organization for Parents", "Best Cloud Storage for Family Photos"],
    features: ["Auto-upload setup guide", "Photo tagging system"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Describe your digital photo organization system and backup method."],
      friends: ["Share photo storage strategies with other expectant friends."],
      community: ["Recommend efficient ways to organize and preserve baby photos."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-96',
    id: 96,
    text: "Planned simple memory-keeping strategy",
    date: null,
    achieved: false,
    icon: "StarOutlined",
    notes: "",
    type: "preparation",
    category: "memory-keeping",
    stage: "Pregnancy",
    details: "Develop a simple, low-effort strategy for capturing and preserving key memories and milestones. This could include a monthly photo tradition, a one-line-a-day journal, or milestone cards.",
    flexibility: "flexible",
    flexibilityReason: "This strategy can be planned during pregnancy and implemented after birth.",
    frequency: "once",
    group: "memory-keeping",
    dependsOn: null,
    additionalInputs: ["chosen method", "frequency of recording"],
    resources: ["Easy Memory Keeping Ideas for Busy Parents", "Digital vs. Physical Memory Keeping"],
    features: ["Reminder system for chosen method", "Quick-entry journal app"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Outline your simple memory-keeping strategy and why you chose it."],
      friends: ["Discuss low-effort memory keeping ideas with other expectant friends."],
      community: ["Share easy ways to capture baby memories without feeling overwhelmed."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-97',
    id: 97,
    text: "Researched baby-friendly social activities",
    date: null,
    achieved: false,
    icon: "TeamOutlined",
    notes: "",
    type: "preparation",
    category: "social-life",
    stage: "Pregnancy",
    details: "Research local baby-friendly activities and venues, such as mom and baby yoga classes, library story times, or parent-child playgroups. Make a list of options to explore after the baby arrives.",
    flexibility: "flexible",
    flexibilityReason: "This research can be done at any point during pregnancy.",
    frequency: "once",
    group: "postpartum-planning",
    dependsOn: null,
    additionalInputs: ["interesting activities", "local venues"],
    resources: ["Baby-Friendly Activities in Your Area", "Socializing with a Newborn"],
    features: ["Local activity finder", "Activity schedule planner"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["List baby-friendly activities you'd like to try and their locations."],
      friends: ["Discuss potential group activities with other new parent friends."],
      community: ["Share recommendations for baby-friendly social activities in your area."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-98',
    id: 98,
    text: "Planned family bonding activities",
    date: null,
    achieved: false,
    icon: "HeartOutlined",
    notes: "",
    type: "preparation",
    category: "family-life",
    stage: "Pregnancy",
    details: "Plan simple activities to help your family bond with the new baby, such as reading time, gentle play sessions, or family walks. Include activities for siblings if applicable.",
    flexibility: "flexible",
    flexibilityReason: "This planning can be done during pregnancy and adjusted after birth.",
    frequency: "once",
    group: "postpartum-planning",
    dependsOn: null,
    additionalInputs: ["planned activities", "sibling involvement ideas"],
    resources: ["Bonding with Your Newborn", "Including Siblings in Baby Care"],
    features: ["Family activity idea generator", "Sibling helper task list"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["List family bonding activities you'd like to try with your new baby."],
      friends: ["Share family bonding ideas with other expectant friends."],
      community: ["Recommend simple ways for families to bond with a new baby."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-99',
    id: 99,
    text: "Identified key friendships to maintain",
    date: null,
    achieved: false,
    icon: "UserOutlined",
    notes: "",
    type: "personal",
    category: "social-life",
    stage: "Pregnancy",
    details: "Identify the key friendships you want to prioritize maintaining after the baby arrives. Consider friends who are supportive, understanding of your new role, and those who may also be parents.",
    flexibility: "flexible",
    flexibilityReason: "This reflection can happen at any point during pregnancy.",
    frequency: "once",
    group: "postpartum-planning",
    dependsOn: null,
    additionalInputs: ["key friends", "connection strategies"],
    resources: ["Maintaining Friendships as a New Parent", "Balancing Social Life and Parenthood"],
    features: ["Friend catch-up reminder", "Virtual hangout scheduler"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["List the friends you want to stay close with and ideas for staying connected."],
      friends: ["Discuss strategies for maintaining friendships with other expectant friends."],
      community: ["Share tips for balancing friendships and new parenthood."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-100',
    id: 100,
    text: "Planned strategies to continue hobbies",
    date: null,
    achieved: false,
    icon: "SmileOutlined",
    notes: "",
    type: "personal",
    category: "self-care",
    stage: "Pregnancy",
    details: "Develop strategies for continuing your hobbies or interests after the baby arrives. This might include modifying how you engage in the hobby, finding baby-friendly versions, or scheduling dedicated time.",
    flexibility: "flexible",
    flexibilityReason: "This planning can be done at any point during pregnancy.",
    frequency: "once",
    group: "postpartum-planning",
    dependsOn: null,
    additionalInputs: ["key hobbies", "adaptation strategies"],
    resources: ["Maintaining Hobbies as a New Parent", "Baby-Friendly Hobby Ideas"],
    features: ["Hobby time scheduler", "Modified hobby idea generator"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["List your important hobbies and ideas for continuing them with a baby."],
      friends: ["Discuss hobby continuation strategies with other expectant friends."],
      community: ["Share creative ways to maintain personal interests as a new parent."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-101',
    id: 101,
    text: "Created future hobby reintroduction plan",
    date: null,
    achieved: false,
    icon: "CalendarOutlined",
    notes: "",
    type: "personal",
    category: "self-care",
    stage: "Pregnancy",
    details: "If you expect to pause some hobbies, create a plan for gradually reintroducing them in the future. Set realistic timelines and consider how your interests might evolve with parenthood.",
    flexibility: "flexible",
    flexibilityReason: "This plan can be created at any point during pregnancy or early postpartum.",
    frequency: "once",
    group: "postpartum-planning",
    dependsOn: null,
    additionalInputs: ["hobbies to reintroduce", "tentative timeline"],
    resources: ["Balancing Personal Interests and Parenting", "Evolving Hobbies in Parenthood"],
    features: ["Hobby reintroduction timeline", "Adapted hobby suggestion tool"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Outline your plan for reintroducing paused hobbies over time."],
      friends: ["Discuss future plans for personal interests with other expectant friends."],
      community: ["Share experiences of rediscovering hobbies after having a baby."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-102',
    id: 102,
    text: "Identified key interests to maintain",
    date: null,
    achieved: false,
    icon: "StarOutlined",
    notes: "",
    type: "personal",
    category: "self-care",
    stage: "Pregnancy",
    details: "Identify the most important personal interests or activities you want to maintain after the baby arrives. Prioritize those that are most fulfilling or essential to your well-being.",
    flexibility: "flexible",
    flexibilityReason: "This reflection can happen at any point during pregnancy.",
    frequency: "once",
    group: "postpartum-planning",
    dependsOn: null,
    additionalInputs: ["key interests", "maintenance strategies"],
    resources: ["Preserving Personal Identity in Parenthood", "Self-Care for New Parents"],
    features: ["Interest priority ranking tool", "Self-care activity scheduler"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["List your most important personal interests and ideas for maintaining them."],
      friends: ["Discuss strategies for preserving personal interests with other expectant friends."],
      community: ["Share tips for maintaining a sense of self while adapting to parenthood."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-103',
    id: 103,
    text: "Researched sleep training methods",
    date: null,
    achieved: false,
    icon: "BulbOutlined",
    notes: "",
    type: "education",
    category: "baby-care",
    stage: "Pregnancy",
    details: "Research various sleep training methods and their pros and cons. Consider which approach aligns best with your parenting philosophy and baby's temperament.",
    flexibility: "flexible",
    flexibilityReason: "This research can be done at any point during pregnancy or early postpartum.",
    frequency: "once",
    group: "parenting-preparation",
    dependsOn: null,
    additionalInputs: ["preferred methods", "concerns to address"],
    resources: ["Sleep Training Methods Overview", "Choosing the Right Sleep Training Approach"],
    features: ["Sleep training method comparison tool", "Custom sleep plan generator"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Summarize sleep training methods you're considering and your thoughts on each."],
      friends: ["Discuss sleep training experiences with other parent friends."],
      community: ["Share balanced information about different sleep training approaches."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-104',
    id: 104,
    text: "Learned about responsive nighttime parenting",
    date: null,
    achieved: false,
    icon: "HeartOutlined",
    notes: "",
    type: "education",
    category: "baby-care",
    stage: "Pregnancy",
    details: "Research responsive nighttime parenting techniques, including safe co-sleeping practices if interested. Learn about normal infant sleep patterns and strategies for supporting your baby's sleep development.",
    flexibility: "flexible",
    flexibilityReason: "This learning can happen at any point during pregnancy or early postpartum.",
    frequency: "once",
    group: "parenting-preparation",
    dependsOn: null,
    additionalInputs: ["key concepts learned", "questions to ask pediatrician"],
    resources: ["Responsive Nighttime Parenting Guide", "Safe Co-Sleeping Practices"],
    features: ["Infant sleep pattern tracker", "Nighttime parenting technique guide"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Note key points about responsive nighttime parenting and your thoughts on the approach."],
      friends: ["Discuss nighttime parenting strategies with other expectant friends."],
      community: ["Share experiences and tips for responsive nighttime care of infants."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-105',
    id: 105,
    text: "Created gradual sleep development plan",
    date: null,
    achieved: false,
    icon: "SolutionOutlined",
    notes: "",
    type: "preparation",
    category: "baby-care",
    stage: "Pregnancy",
    details: "Develop a plan for gradually supporting your baby's natural sleep development. Include strategies for establishing bedtime routines, creating a sleep-friendly environment, and gently encouraging independent sleep over time.",
    flexibility: "flexible",
    flexibilityReason: "This plan can be created during pregnancy and adjusted based on your baby's needs after birth.",
    frequency: "once",
    group: "parenting-preparation",
    dependsOn: null,
    additionalInputs: ["planned strategies", "sleep environment ideas"],
    resources: ["Gradual Sleep Coaching Methods", "Creating a Sleep-Friendly Nursery"],
    features: ["Customizable sleep plan template", "Bedtime routine builder"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Outline your gradual sleep development plan and key strategies you want to try."],
      friends: ["Discuss gentle sleep coaching ideas with other expectant friends."],
      community: ["Share experiences with gradual sleep development approaches for babies."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-106',
    id: 106,
    text: "Learned about various sleep approaches",
    date: null,
    achieved: false,
    icon: "ReadOutlined",
    notes: "",
    type: "education",
    category: "baby-care",
    stage: "Pregnancy",
    details: "Research a variety of sleep approaches, from sleep training to gentle sleep coaching and responsive nighttime parenting. Understand the spectrum of methods to make informed decisions based on your family's needs.",
    flexibility: "flexible",
    flexibilityReason: "This research can be done at any point during pregnancy or early postpartum.",
    frequency: "once",
    group: "parenting-preparation",
    dependsOn: null,
    additionalInputs: ["methods researched", "preferred approaches"],
    resources: ["Comprehensive Guide to Baby Sleep Approaches", "Adapting Sleep Strategies to Your Family"],
    features: ["Sleep approach comparison tool", "Personalized sleep strategy quiz"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Summarize the sleep approaches you've learned about and your initial thoughts."],
      friends: ["Discuss various sleep strategies with other expectant friends."],
      community: ["Share balanced information about different baby sleep approaches."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-107',
    id: 107,
    text: "Developed work-life balance strategies",
    date: null,
    achieved: false,
    icon: "BalanceOutlined",
    notes: "",
    type: "preparation",
    category: "work-life-balance",
    stage: "Pregnancy",
    details: "Develop strategies for balancing work and family life after the baby arrives. Consider time management techniques, boundary-setting at work, and ways to maximize quality time with your baby.",
    flexibility: "flexible",
    flexibilityReason: "This planning can be done at any point during pregnancy.",
    frequency: "once",
    group: "career-planning",
    dependsOn: null,
    additionalInputs: ["key strategies", "potential challenges"],
    resources: ["Work-Life Balance for New Parents", "Time Management Techniques for Working Parents"],
    features: ["Work-life balance assessment tool", "Time blocking planner"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["List your work-life balance strategies and potential challenges to address."],
      friends: ["Discuss work-life balance ideas with other working parent friends."],
      community: ["Share tips for managing career and family life as a new parent."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-108',
    id: 108,
    text: "Planned career transition strategy",
    date: null,
    achieved: false,
    icon: "SwapOutlined",
    notes: "",
    type: "preparation",
    category: "career",
    stage: "Pregnancy",
    details: "If planning a career change, develop a strategy for your transition. Consider timing, necessary skills or education, and how to balance the change with new parenthood.",
    flexibility: "flexible",
    flexibilityReason: "This planning can be done at any point during pregnancy or early postpartum.",
    frequency: "once",
    group: "career-planning",
    dependsOn: null,
    additionalInputs: ["career goals", "transition timeline"],
    resources: ["Career Transitions for New Parents", "Balancing Career Change and Parenthood"],
    features: ["Skill gap analyzer", "Career transition timeline planner"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Outline your career transition plan and key steps to take."],
      friends: ["Discuss career change strategies with other expectant friends."],
      community: ["Share experiences of managing career transitions as a new parent."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-109',
    id: 109,
    text: "Created plan for extended career break",
    date: null,
    achieved: false,
    icon: "PauseCircleOutlined",
    notes: "",
    type: "preparation",
    category: "career",
    stage: "Pregnancy",
    details: "If planning an extended career break, create a comprehensive plan. Consider financial implications, strategies for staying current in your field, and a tentative timeline for returning to work if desired.",
    flexibility: "flexible",
    flexibilityReason: "This plan can be developed at any point during pregnancy or early postpartum.",
    frequency: "once",
    group: "career-planning",
    dependsOn: null,
    additionalInputs: ["break duration", "professional development ideas"],
    resources: ["Managing a Career Break", "Returning to Work After Extended Leave"],
    features: ["Career break budget planner", "Skill maintenance scheduler"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Outline your career break plan, including financial considerations and professional goals."],
      friends: ["Discuss career break experiences with other parent friends."],
      community: ["Share strategies for managing an extended career break and eventual return to work."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-110',
    id: 110,
    text: "Planned regular date nights",
    date: null,
    achieved: false,
    icon: "HeartOutlined",
    notes: "",
    type: "preparation",
    category: "relationship",
    stage: "Pregnancy",
    details: "Plan strategies for maintaining regular date nights after the baby arrives. Consider both at-home and out-of-home options, and discuss childcare arrangements.",
    flexibility: "flexible",
    flexibilityReason: "This planning can be done at any point during pregnancy.",
    frequency: "once",
    group: "relationship-maintenance",
    dependsOn: null,
    additionalInputs: ["date night ideas", "childcare options"],
    resources: ["Maintaining Romance After Baby", "Creative At-Home Date Night Ideas"],
    features: ["Date night idea generator", "Babysitter contact list"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["List date night ideas and potential childcare arrangements."],
      friends: ["Share date night strategies with other expectant couples."],
      community: ["Recommend ways to prioritize couple time as new parents."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-111',
    id: 111,
    text: "Developed co-parenting communication plan",
    date: null,
    achieved: false,
    icon: "MessageOutlined",
    notes: "",
    type: "preparation",
    category: "relationship",
    stage: "Pregnancy",
    details: "Create a plan for effective communication and decision-making as co-parents. Include strategies for discussing parenting choices, sharing information about the baby, and resolving disagreements.",
    flexibility: "flexible",
    flexibilityReason: "This plan can be developed at any point during pregnancy.",
    frequency: "once",
    group: "parenting-preparation",
    dependsOn: null,
    additionalInputs: ["communication methods", "decision-making process"],
    resources: ["Effective Co-Parenting Communication", "Resolving Parenting Disagreements"],
    features: ["Co-parenting agreement template", "Parenting decision tracker"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Outline your co-parenting communication plan and potential challenges to address."],
      friends: ["Discuss co-parenting strategies with other expectant couples."],
      community: ["Share tips for effective communication and teamwork in parenting."]
    },
    notePrivacy: "private",
    sharedNotes: []
  },
  {
    key: 'milestone-112',
    id: 112,
    text: "Created couple and family time balance plan",
    date: null,
    achieved: false,
    icon: "ScheduleOutlined",
    notes: "",
    type: "preparation",
    category: "relationship",
    stage: "Pregnancy",
    details: "Develop a plan to balance couple time, family time, and individual time after the baby arrives. Consider strategies for incorporating the baby into couple activities and ensuring each partner gets personal time.",
    flexibility: "flexible",
    flexibilityReason: "This plan can be created at any point during pregnancy and adjusted after birth.",
    frequency: "once",
    group: "relationship-maintenance",
    dependsOn: null,
    additionalInputs: ["balance strategies", "individual time ideas"],
    resources: ["Balancing Relationships After Baby", "Family Bonding Activities"],
    features: ["Family calendar planner", "Personal time scheduler"],
    relevantUntil: null,
    notePlaceholders: {
      private: ["Outline your plan for balancing couple, family, and individual time."],
      friends: ["Discuss time management strategies with other expectant couples."],
      community: ["Share tips for maintaining a healthy balance of relationships and personal time as new parents."]
    },
    notePrivacy: "private",
    sharedNotes: []
  }
];

export default milestones;
