import React, { useState, useRef, useEffect } from 'react';
import { Button, Menu, Input, message } from 'antd';
import { UpOutlined } from '@ant-design/icons';
import PrivacyToggle from "./PrivacyToggle";
import ProductList from "../lists/ProductList";
import SavedNote from './SavedNote';
import CarouselContainer from './CarouselContainer';
import InviteCard from './InviteCard';

const ExpandedCategoryItem = ({
  category,
  products,
  loading,
  onCollapse,
  onNotesChange,
  onPrivacyChange,
  isLoadingSharedNotes,
  sharedNotes,
  canEdit,
  currentFriendUuid,
  selectedFriends,
  readProductsInCategory,
  currentUser
}) => {
  const [currentNote, setCurrentNote] = useState('');
  const [currentSection, setCurrentSection] = useState('mine');
  const [sharingScope, setSharingScope] = useState('friends');
  const carouselRef = useRef(null);
  const [myNotes, setMyNotes] = useState([]);

  useEffect(() => {
    const existingMyNotes = sharedNotes?.filter(note => !note.isFromFriend && note.author === 'You') || [];
    setMyNotes(existingMyNotes);
  }, [sharedNotes]);

  const friendNotes = sharedNotes?.filter(note => note.isFromFriend).map(note => ({ ...note, type: 'friends' })) || [];
  const communityNotes = sharedNotes?.filter(note => !note.isFromFriend && note.author !== 'You').map(note => ({ ...note, type: 'community' })) || [];

  const allNotes = [
    { type: 'input' },
        ...myNotes.map(note => ({ ...note, type: 'mine' })),
        ...friendNotes,
        ...(canEdit && !currentFriendUuid ? [{ type: 'inviteFriends' }] : []),
        ...communityNotes
      ];

      const handleSave = (e) => {
        e.stopPropagation();
        if (currentNote.trim()) {
          const newNote = {
            content: currentNote,
            date: new Date(),
            privacy: category.notePrivacy,
            sharingScope: sharingScope,
            author: 'You',
            type: 'mine',
            user: {
              first_name: currentUser?.first_name || 'Anonymous',
              last_name: currentUser?.last_name || '',
            }
          };

          const updatedMyNotes = [...myNotes, newNote];
          setMyNotes(updatedMyNotes);

          try {
            onNotesChange(newNote, category.uuid);
            setCurrentNote('');
            message.success('Note saved!');

            setTimeout(() => {
              const newNoteIndex = 1 + updatedMyNotes.length - 1;
              if (carouselRef.current) {
                carouselRef.current.goTo(newNoteIndex);
              }
            }, 100);
          } catch (error) {
            console.error('Error saving note:', error);
            message.error('Failed to save note. Please try again.');
          }
        } else {
          message.warning('Please enter a note before saving.');
        }
      };

      const renderNote = (note, index) => {
        switch (note.type) {
          case 'input':
            return (
              <div className="note-input-container" onClick={e => e.stopPropagation()}>
                <div className="note-input-header">
                  <span>Create a note for </span>
                  <PrivacyToggle
                    value={category.notePrivacy}
                    onChange={(value) => {
                      onPrivacyChange(value);
                      if (value === 'private') {
                        setSharingScope('friends');
                      }
                    }}
                    disabled={!canEdit || !!currentFriendUuid}
                  />
                </div>
                <Input.TextArea
                  placeholder="Add your notes here..."
                  className="sticky-note-input"
                  value={currentNote}
                  onChange={(e) => setCurrentNote(e.target.value)}
                  disabled={!canEdit || !!currentFriendUuid}
                />
                <div className="notes-actions">
                  <Button
                    onClick={handleSave}
                    className="new-moment-button"
                    disabled={!canEdit || !!currentFriendUuid}
                  >
                    Save
                  </Button>
                </div>
              </div>
            );
          case 'mine':
          case 'friends':
          case 'community':
            return (
              <SavedNote
                key={`${note.type}-note-${index}`}
                note={note}
                noteType={note.type}
              />
            );
          case 'inviteFriends':
            return <InviteCard key="invite-friends" type="friends" onInvite={(values) => {}} />;
          default:
            return null;
        }
      };

      const handleTabChange = (key) => {
        setCurrentSection(key);
        const index = allNotes.findIndex(note => note.type === key);
        if (index !== -1 && carouselRef.current) {
          carouselRef.current.goTo(index);
        }
      };

      return (
        <div className="card-expanded-content" onClick={e => e.stopPropagation()}>
          <ProductList
            products={products}
            loading={loading}
            categoryUuid={category.uuid}
            selectedFriends={selectedFriends}
          />
          <div className="shared-notes-carousel">
            <h3 className="notes-header">Notes</h3>
            <Menu
              onClick={(e) => handleTabChange(e.key)}
              selectedKeys={[currentSection]}
              mode="horizontal"
              className="notes-menu"
            >
              <Menu.Item key="input" className="menu-item-mine">My Notes</Menu.Item>
              {!currentFriendUuid && <Menu.Item key="friends" className="menu-item-friends">Friends</Menu.Item>}
              {!currentFriendUuid && <Menu.Item key="community" className="menu-item-others">Community</Menu.Item>}
            </Menu>
            <CarouselContainer
              ref={carouselRef}
              items={allNotes}
              renderItem={(note, index) => renderNote(note, index)}
              onAfterChange={(current) => {
                const currentNote = allNotes[current];
                setCurrentSection(currentNote.type);
              }}
              className="notes-carousel"
            />
          </div>
          <Button
            type="text"
            icon={<UpOutlined />}
            onClick={(e) => {
              e.stopPropagation();
              onCollapse();
            }}
            className="collapse-button"
          >
            Collapse
          </Button>
        </div>
      );
    };

    export default ExpandedCategoryItem;
