export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_ERROR = 'REGISTER_ERROR';
export const RESET_REGISTER_SUCCESS = 'RESET_REGISTER_SUCCESS';
export const REGISTER_IN_PROCESS = 'REGISTER_IN_PROCESS';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const RESET_PASSWORD_IN_PROCESS = 'RESET_PASSWORD_IN_PROCESS';
export const RESET_RESET_PASSWORD_SUCCESS = 'RESET_RESET_PASSWORD_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_IN_PROCESS = 'LOGIN_IN_PROCESS';
export const LOGIN_MISSING = 'LOGIN_MISSING';
export const SEND_REGISTRATION_IN_PROCESS = 'SEND_REGISTRATION_IN_PROCESS';
export const SEND_REGISTRATION_SUCCESS = 'SEND_REGISTRATION_SUCCESS';
export const SEND_REGISTRATION_ERROR = 'SEND_REGISTRATION_ERROR';
export const GET_EMAIL_IN_PROCESS = 'GET_EMAIL_IN_PROCESS';
export const GET_EMAIL_SUCCESS = 'GET_EMAIL_SUCCESS';
export const GET_EMAIL_ERROR = 'GET_EMAIL_ERROR';
export const SEND_PASSWORD_RESET_REQUEST_IN_PROCESS = 'SEND_PASSWORD_RESET_REQUEST_IN_PROCESS';
export const SEND_PASSWORD_RESET_REQUEST_SUCCESS = 'SEND_PASSWORD_RESET_REQUEST_SUCCESS';
export const SEND_PASSWORD_RESET_REQUEST_ERROR = 'SEND_PASSWORD_RESET_REQUEST_ERROR';
export const GET_PASSWORD_RESET_REQUEST_IN_PROCESS = 'GET_PASSWORD_RESET_REQUEST_IN_PROCESS';
export const GET_PASSWORD_RESET_REQUEST_SUCCESS = 'GET_PASSWORD_RESET_REQUEST_SUCCESS';
export const GET_PASSWORD_RESET_REQUEST_ERROR = 'GET_PASSWORD_RESET_REQUEST_ERROR';
