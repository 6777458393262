import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import '../../../assets/css/views/OnboardingCarousel.css';

const OnboardingCarousel = ({ children, onComplete }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const carouselRef = useRef(null);

  useEffect(() => {
    if (carouselRef.current) {
      carouselRef.current.style.transition = 'transform 0.5s ease';
      const transform = `translateX(-${currentStep * 100}%)`;
      carouselRef.current.style.transform = transform;
    }
  }, [currentStep]);

  const handleNextStep = () => {
    setShowConfirmation(false);
    setCurrentStep(prevStep => prevStep + 1);
  };

  const handleBackStep = () => {
    setShowConfirmation(false);
    setCurrentStep(prevStep => prevStep - 1);
  };

  const showConfirmationMessage = (message, duration = 3000) => {
    setConfirmationMessage(message);
    setShowConfirmation(true);
    setTimeout(() => {
      setShowConfirmation(false);
      handleNextStep();
    }, duration);
  };

  const renderStep = (step, index) => {
      const isActive = index === currentStep;
      return (
        <div
          key={index}
          className={`carousel-slide ${isActive ? 'active' : ''}`}
          style={{ pointerEvents: isActive ? 'auto' : 'none' }}
        >
          {React.cloneElement(step, {
            showConfirmationMessage,
            goToNextStep: handleNextStep,
            isActive,
          })}
        </div>
      );
    };

    return (
      <div className="onboarding-carousel">
        {currentStep > 0 && (
          <Button
            className="carousel-back-button"
            icon={<LeftOutlined />}
            onClick={handleBackStep}
          />
        )}
        <div className="carousel-container" ref={carouselRef}>
          {React.Children.map(children, renderStep)}
          <div className="carousel-slide blank-step">
            {showConfirmation && (
              <div className="confirmation-container">
                <div className="confirmation-message">{confirmationMessage}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  export default OnboardingCarousel;
