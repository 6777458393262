import React, { useState } from 'react';
import {
  Progress, Card, Row, Col, Button, Statistic, Timeline, List
} from 'antd';
import {
  HeartOutlined, ClockCircleOutlined, TeamOutlined,
  TrophyOutlined, FireOutlined, BookOutlined,
  CheckCircleOutlined, BulbOutlined, CalendarOutlined,
  MedicineBoxOutlined, SoundOutlined, NotificationOutlined,
  SmileOutlined, HomeOutlined, ShoppingOutlined,
  CarOutlined, SafetyOutlined, ThunderboltOutlined,
  CameraOutlined, MailOutlined, UserOutlined,
  GiftOutlined, FormOutlined, FileOutlined,
  ToolOutlined
} from '@ant-design/icons';
import '../../assets/css/views/Dashboard.css';
import '../../assets/css/components/button.css';

const Dashboard = () => {
  const [analytics, setAnalytics] = useState({
    overallCompletion: 65,
    daysUntilDueDate: 120,
    communitySize: 15,
    categoryProgress: [
      { name: 'Nursery', completionRate: 80 },
      { name: 'Clothing', completionRate: 50 },
      { name: 'Feeding', completionRate: 30 },
      { name: 'Safety', completionRate: 70 },
    ],
    recentAchievements: [
      'Completed Nursery Setup',
      'Chose a Pediatrician',
      'Attended Prenatal Class',
      'Finalized Birth Plan',
    ],
    taskCompletionStreak: 5,
    decisionsCompleted: 10,
    totalDecisions: 20,
    articlesRead: 15,
    suggestedNextSteps: [
      { title: 'Pack Hospital Bag', description: 'Prepare essentials for your hospital stay' },
      { title: 'Install Car Seat', description: 'Ensure safe transportation for your newborn' },
      { title: 'Prepare Freezer Meals', description: 'Stock up on easy meals for after baby arrives' },
    ],
  });

  const [achievements, setAchievements] = useState([
    { icon: <MedicineBoxOutlined />, text: "Confirmed pregnancy with home test", date: "2023-01-15" },
    { icon: <CalendarOutlined />, text: "Scheduled first prenatal appointment", date: "2023-01-20" },
    { icon: <SoundOutlined />, text: "Had first ultrasound and heard heartbeat", date: "2023-02-10" },
    { icon: <NotificationOutlined />, text: "Announced pregnancy to family and friends", date: "2023-02-15" },
    { icon: <MedicineBoxOutlined />, text: "Started taking prenatal vitamins", date: "2023-02-16" },
    { icon: <CheckCircleOutlined />, text: "Completed first trimester screening", date: "2023-03-05" },
    { icon: <ThunderboltOutlined />, text: "Felt baby's first movements", date: "2023-04-20" },
    { icon: <SmileOutlined />, text: "Found out baby's gender (if chosen)", date: "2023-04-25" },
    { icon: <BookOutlined />, text: "Registered for childbirth classes", date: "2023-05-01" },
    { icon: <ShoppingOutlined />, text: "Created a baby registry", date: "2023-05-15" },
    { icon: <CheckCircleOutlined />, text: "Completed second trimester screening", date: "2023-06-10" },
    { icon: <UserOutlined />, text: "Chose a pediatrician", date: "2023-06-20" },
    { icon: <CalendarOutlined />, text: "Started planning maternity/paternity leave", date: "2023-07-01" },
    { icon: <BookOutlined />, text: "Attended first childbirth class", date: "2023-07-15" },
    { icon: <GiftOutlined />, text: "Had a baby shower", date: "2023-08-01" },
    { icon: <FormOutlined />, text: "Completed hospital pre-registration", date: "2023-08-15" },
    { icon: <ShoppingOutlined />, text: "Packed hospital bag", date: "2023-08-20" },
    { icon: <CarOutlined />, text: "Installed car seat", date: "2023-08-25" },
    { icon: <HomeOutlined />, text: "Assembled nursery furniture", date: "2023-09-01" },
    { icon: <ShoppingOutlined />, text: "Washed and organized baby clothes", date: "2023-09-05" },
    { icon: <FireOutlined />, text: "Prepared and froze meals for postpartum", date: "2023-09-10" },
    { icon: <BookOutlined />, text: "Finished childbirth education classes", date: "2023-09-15" },
    { icon: <FileOutlined />, text: "Created a birth plan", date: "2023-09-20" },
    { icon: <MedicineBoxOutlined />, text: "Had group B strep test", date: "2023-09-25" },
    { icon: <ThunderboltOutlined />, text: "Practiced relaxation and breathing techniques", date: "2023-09-30" },
    { icon: <ToolOutlined />, text: "Sterilized breast pump and bottles", date: "2023-10-05" },
    { icon: <SafetyOutlined />, text: "Installed baby monitor", date: "2023-10-10" },
    { icon: <ShoppingOutlined />, text: "Packed diaper bag for outings", date: "2023-10-15" },
    { icon: <TeamOutlined />, text: "Arranged for postpartum support", date: "2023-10-20" },
    { icon: <BookOutlined />, text: "Completed newborn care class", date: "2023-10-25" },
    { icon: <BookOutlined />, text: "Attended breastfeeding class (if planning to breastfeed)", date: "2023-10-30" },
    { icon: <HeartOutlined />, text: "Prepared siblings or pets for new baby's arrival", date: "2023-11-01" },
    { icon: <CameraOutlined />, text: "Had maternity/newborn photoshoot", date: "2023-11-05" },
    { icon: <MailOutlined />, text: "Wrote thank you notes for baby shower gifts", date: "2023-11-10" },
    { icon: <CalendarOutlined />, text: "Established a pediatrician appointment for newborn", date: "2023-11-15" },
    { icon: <HomeOutlined />, text: "Set up a comfortable nursing/feeding station", date: "2023-11-20" },
    { icon: <SafetyOutlined />, text: "Installed baby gates (if needed)", date: "2023-11-25" },
    { icon: <SafetyOutlined />, text: "Baby-proofed main living areas", date: "2023-11-30" },
    { icon: <MedicineBoxOutlined />, text: "Prepared postpartum care kit", date: "2023-12-01" },
    { icon: <TeamOutlined />, text: "Arranged for meal train or food delivery service", date: "2023-12-05" },
    { icon: <TeamOutlined />, text: "Established a support network for postpartum period", date: "2023-12-10" },
    { icon: <HeartOutlined />, text: "Welcomed baby into the world!", date: "2023-12-15" },
    { icon: <CheckCircleOutlined />, text: "Successful first diaper change", date: "2023-12-16" },
    { icon: <MedicineBoxOutlined />, text: "First pediatrician visit completed", date: "2023-12-20" },
    { icon: <HomeOutlined />, text: "Survived first night at home with baby", date: "2023-12-21" },
    { icon: <HeartOutlined />, text: "First successful breastfeeding or bottle feeding session", date: "2023-12-22" },
    { icon: <TeamOutlined />, text: "Introduced baby to extended family (in person or virtually)", date: "2023-12-25" },
    { icon: <CameraOutlined />, text: "Captured baby's first smile", date: "2024-01-15" },
    { icon: <MedicineBoxOutlined />, text: "Completed postpartum check-up", date: "2024-01-25" }
  ]);

  const handleInviteFriend = () => {
    console.log('Invite friend clicked');
  };

  return (
    <div className="analytics-dashboard newCategoriesContainer">
      <h1 className="headerTitleText">Your Baby Prep Journey</h1>
      <p className="subheaderTitleText">Track your progress and next steps</p>

      <div className="progressContainer">
        <div className="progressText">Overall Progress</div>
        <div className="progressBarOuter">
          <div className="progressBarInner" style={{ width: `${analytics.overallCompletion}%` }}></div>
        </div>
        <div className="progressPercentage">{analytics.overallCompletion}% Complete</div>
      </div>

      <div className="scrollableContent">
        {/* Decision Making Group */}
        <div className="dashboardGroup">
          <h2 className="groupTitle">Decision Making</h2>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <Card className="analyticsCard">
                <div className="cardContent">
                  <Statistic
                    title="Decisions Made"
                    value={analytics.decisionsCompleted}
                    suffix={`/ ${analytics.totalDecisions}`}
                    prefix={<CheckCircleOutlined className="dashboardIcon" />}
                  />
                  <Button className="actionButton">
                    Make a Decision
                  </Button>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={12}>
              <Card className="analyticsCard">
                <div className="cardContent">
                  <Statistic
                    title="Knowledge Growth"
                    value={analytics.articlesRead}
                    suffix="articles"
                    prefix={<BookOutlined className="dashboardIcon" />}
                  />
                  <Button className="actionButton">
                    Explore Resources
                  </Button>
                </div>
              </Card>
            </Col>
          </Row>
        </div>

        {/* Milestones and Achievements Group */}
        <div className="dashboardGroup">
          <h2 className="groupTitle">Milestones and Achievements</h2>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <Card className="analyticsCard" title="Recent Achievements">
                <Timeline>
                  {analytics.recentAchievements.map((achievement, index) => (
                    <Timeline.Item
                      key={index}
                      dot={<TrophyOutlined className="dashboardIcon" />}
                    >
                      {achievement}
                    </Timeline.Item>
                  ))}
                </Timeline>
              </Card>
            </Col>
            <Col xs={24} sm={12}>
              <Card className="analyticsCard">
                <div className="cardContent">
                  <Statistic
                    title="Task Completion Streak"
                    value={analytics.taskCompletionStreak}
                    suffix="days"
                    prefix={<FireOutlined className="dashboardIcon" />}
                  />
                  <Button className="actionButton">
                    Complete a Task
                  </Button>
                </div>
              </Card>
            </Col>
          </Row>
        </div>

        {/* Social and Community Group */}
        <div className="dashboardGroup">
          <h2 className="groupTitle">Social and Community</h2>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <Card className="analyticsCard">
                <div className="cardContent">
                  <Statistic
                    title="Community Size"
                    value={analytics.communitySize}
                    prefix={<TeamOutlined className="dashboardIcon" />}
                  />
                  <Button
                    onClick={handleInviteFriend}
                    className="actionButton"
                  >
                    Grow Your Community
                  </Button>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={12}>
              <Card className="analyticsCard" title="Suggested Next Steps">
                <List
                  dataSource={analytics.suggestedNextSteps}
                  renderItem={item => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={<BulbOutlined className="dashboardIcon" />}
                        title={item.title}
                        description={item.description}
                      />
                      <Button className="actionButton">Take Action</Button>
                    </List.Item>
                  )}
                />
              </Card>
            </Col>
          </Row>
        </div>

        {/* Timeline Group */}
        <div className="dashboardGroup">
          <h2 className="groupTitle">Timeline</h2>
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <Card className="analyticsCard">
                <div className="cardContent">
                  <Statistic
                    title="Time to Due Date"
                    value={analytics.daysUntilDueDate}
                    suffix="days"
                    prefix={<ClockCircleOutlined className="dashboardIcon" />}
                  />
                  <Button className="actionButton">
                    Update Due Date
                  </Button>
                </div>
              </Card>
            </Col>
          </Row>
        </div>

        {/* All Achievements */}
        <div className="dashboardGroup">
          <h2 className="groupTitle">All Achievements</h2>
          <Row>
            <Col span={24}>
              <Card className="analyticsCard" title="All Achievements">
                <Timeline>
                  {achievements.map((achievement, index) => (
                    <Timeline.Item
                      key={index}
                      dot={React.cloneElement(achievement.icon, { className: 'dashboardIcon' })}
                    >
                      <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <span>{achievement.text}</span>
                        <span style={{color: '#888'}}>{achievement.date}</span>
                      </div>
                    </Timeline.Item>
                  ))}
                </Timeline>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
