const state = {
  userNotes: [],
  friendNotes: [],
  friendProductNotes: [],
  friendCategoryNotes: [],
  productNotes: [],
  categoryNotes: [],
  listNotes: [],
  saveNoteError: null,
  saveNoteSuccess: false,
  saveNoteInProgress: false,
  readNoteError: "",
  notesentered: {},
  savedMoments: {},
  friendsNotes: {},
  friendsNotesError: null,
};

export default state;
