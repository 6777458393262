import * as types from './actionTypes';

export const setMoments = (moments) => ({ type: types.SET_MOMENTS, payload: moments });
export const setSortedMilestones = (milestones) => ({ type: types.SET_SORTED_MILESTONES, payload: milestones });
export const setGroupedTimelineItems = (items) => ({ type: types.SET_GROUPED_TIMELINE_ITEMS, payload: items });
export const setActiveCardId = (id) => ({ type: types.SET_ACTIVE_CARD_ID, payload: id });
export const setEditingMomentId = (id) => ({ type: types.SET_EDITING_MOMENT_ID, payload: id });
export const setCurrentLifeStage = (stage) => ({ type: types.SET_CURRENT_LIFE_STAGE, payload: stage });
export const setCurrentMilestone = (milestone) => ({ type: types.SET_CURRENT_MILESTONE, payload: milestone });
export const setNewlyCompletedMilestone = (milestone) => ({ type: types.SET_NEWLY_COMPLETED_MILESTONE, payload: milestone });
export const setMomentsWithInput = (moments) => ({ type: types.SET_MOMENTS_WITH_INPUT, payload: moments });
export const setAddedToTimelineText = (text) => ({ type: types.SET_ADDED_TO_TIMELINE_TEXT, payload: text });
export const setRemovedTasks = (tasks) => ({ type: 'SET_REMOVED_TASKS', payload: tasks });
export const setExpandedCardId = (id) => ({ type: 'SET_EXPANDED_CARD_ID', payload: id });
export const setAchievedMoments = (moments) => ({ type: 'SET_ACHIEVED_MOMENTS', payload: moments });
export const setUnachievedMoments = (moments) => ({ type: 'SET_UNACHIEVED_MOMENTS', payload: moments });
export const setAchievedMomentOrder = (order) => ({ type: 'SET_ACHIEVED_MOMENT_ORDER', payload: order });
export const setPreviousVisibleItemId = (id) => ({ type: 'SET_PREVIOUS_VISIBLE_ITEM_ID', payload: id });
