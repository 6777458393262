import React from 'react';
import { Button, Typography } from 'antd';

const { Title, Paragraph } = Typography;

const PrivacyStatement = ({ onAccept, firstName }) => {
  const handleAccept = () => {
    console.log('Privacy statement accepted');
    if (onAccept && typeof onAccept === 'function') {
      onAccept();
    } else {
      console.error('onAccept is not a function or is undefined');
    }
  };

  return (
    <div className="privacy-statement">
      <Title level={2}>
        Welcome {firstName}
      </Title>
      <Title level={3}>
        Your Privacy is Our Priority
      </Title>
      <Paragraph>
        At MeSearch, your information is private by default. We only share what you explicitly choose to share, and there's no way to search for users on our app. Your journey is yours to control.
      </Paragraph>
      <Button type="primary" onClick={handleAccept} className="standardButton">
        I Understand
      </Button>
    </div>
  );
};

export default PrivacyStatement;
