import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Input, Row, Col, Form } from 'antd';

class Partner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stepCount: props.stepCount
    }
  }

  static getDerivedStateFromProps(nextProps, prevState){
    return {
      ...nextProps
    }
  }

  render() {
    return (
      <div hidden={this.state.stepCount !== 2}>
        <Row style={{ textAlign: 'center', marginTop: 100}}>
          <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 24}} style={{ marginBottom: 10}}>
            <span style={styles.headerText}> Do you have a partner? </ span>
            </ Col>
        </ Row >
        <Input.Group>
          <Row style={{ textAlign: 'center', marginBottom: 100}}>
            <Col xs={{span: 24}} sm={{span: 24}} md={{span: 8}} lg={{span: 8}}/>
            <Col xs={{span: 24}} sm={{span: 24}} md={{span: 8}} lg={{span: 8}}>
              <Form.Item
                name='email'
                preserve={true}
                hasFeedback
                hidden={this.state.stepCount !== 2}
                rules={
                  [
                    {
                      type: 'email',
                      required: false,
                      message: 'Please enter valid email'
                    }
                  ]
                }
              >
                <Input placeholder="Email" />
              </Form.Item>
            </ Col>
            <Col xs={{span: 24}} sm={{span: 24}} md={{span: 8}} lg={{span: 8}}/>
          </ Row >
        </Input.Group>
      </div>
    );
  }
};

const styles = {
  headerText: {
    paddingTop: 40,
    paddingBottom: 0,
    fontWeight: 300,
    fontSize: '200%',
    marginBottom: 0
  }
}


const mapStateToProps = (state) => {
  return {

  }
};

const mapDispatchToProps = (dispatch) => {
  return {

  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Partner);
