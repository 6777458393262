import React, { useState } from 'react';
import { Input, Button, Typography } from 'antd';

const { Title, Text } = Typography;

const InvitePartner = ({ onInvite, onSkip }) => {
  const [partnerName, setPartnerName] = useState('');
  const [partnerEmail, setPartnerEmail] = useState('');

  const handleSubmit = () => {
    if (partnerEmail) {
      onInvite(partnerEmail);
    }
  };

  return (
    <div className="invite-partner">
      <Title level={2}>Invite Partner</Title>
      <Text>Invite a partner to share full access to your checklist and notes.</Text>
      <Input
        placeholder="Partner's First Name"
        value={partnerName}
        onChange={(e) => setPartnerName(e.target.value)}
      />
      <Input
        placeholder="Partner's Email"
        value={partnerEmail}
        onChange={(e) => setPartnerEmail(e.target.value)}
      />
      <Button onClick={handleSubmit}>Send Invite</Button>
      <Button onClick={onSkip}>Skip</Button>
    </div>
  );
};

export default InvitePartner;
