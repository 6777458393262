import React, { useState } from 'react';

const TaskDetails = ({ details }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      className={`task-details ${isExpanded ? 'expanded' : ''}`}
      onClick={toggleExpand}
      onKeyPress={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          toggleExpand();
        }
      }}
      role="button"
      tabIndex={0}
      aria-expanded={isExpanded}
    >
      {details}
    </div>
  );
};

export default TaskDetails;
