import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import App from "./app/App";
import store from "./app/redux/store";
import * as serviceWorker from "./serviceWorker";
import App403 from "./app/App403.js";
import "./assets/css/index.css";
import "./assets/css/App.css";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

const container = document.getElementById("root");
const root = createRoot(container);

if (!(window.location.protocol + "//" + window.location.host).includes("localhost:3000")) {
  var credentials = window.prompt("Enter Realm Password");
  if (!credentials || credentials !== "Research4me") {
    render403();
  } else {
    renderApp();
  }
} else {
  renderApp();
}

function renderApp() {
  // console.log("Version 1");
  serviceWorker.register();
  // unregister();
  const ReduxApp = () => (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </Provider>
  );
  root.render(<ReduxApp />);
}

function render403() {
  serviceWorker.register();
  // unregister();
  const ErrorApp = () => (
    <Provider store={store}>
      <App403 />
    </Provider>
  );
  root.render(<ErrorApp />);
}
