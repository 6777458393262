import * as types from './actionTypes';
import initialState from './initialState';

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_PHOTO_UPLOAD_MODAL_VISIBLE:
      return { ...state, photoUploadModalVisible: action.payload };
    case types.SET_TUTORIAL_STEP:
      return { ...state, tutorialStep: action.payload };
    case types.SET_EDITABLE_INPUT_ID:
      return { ...state, editableInputId: action.payload };
    case types.SET_SHOW_REMOVED_TASKS:
      return { ...state, showRemovedTasks: action.payload };
    case types.SET_IS_LOADING_SHARED_NOTES:
      return { ...state, isLoadingSharedNotes: action.payload };
    case types.SET_CURRENT_TAB:
      return { ...state, currentTab: action.payload };
    default:
      return state;
  }
};

export default uiReducer;
