export const UPDATE_TOPIC_SUCCESS = 'UPDATE_TOPIC_SUCCESS';
export const UPDATE_TOPIC_ERROR = 'UPDATE_TOPIC_ERROR';
export const UPDATE_TOPIC_DATA = 'UPDATE_TOPIC_DATA';
export const ADD_TOPIC_ERROR = 'ADD_TOPIC_ERROR';
export const ADD_TOPIC_IN_PROGRESS = 'ADD_TOPIC_IN_PROGRESS';
export const UPDATE_TOPIC_IN_PROGRESS = 'UPDATE_USER_IN_PROGRESS';
export const READ_ALL_TOPICS_IN_PROGRESS = 'READ_ALL_TOPICS_IN_PROGRESS';
export const READ_ALL_TOPICS_SUCCESS = 'READ_ALL_TOPICS_SUCCESS';
export const READ_ALL_TOPICS_ERROR = 'READ_ALL_TOPICS_ERROR';
export const READ_USER_TOPICS_IN_PROGRESS = 'READ_USER_TOPICS_IN_PROGRESS';
export const READ_USER_TOPICS_SUCCESS = 'READ_USER_TOPICS_SUCCESS';
export const READ_USER_TOPICS_ERROR = 'READ_USER_TOPICS_ERROR';
export const SELECT_USER_TOPIC_ERROR = 'SELECT_USER_TOPIC_ERROR';
export const SELECT_USER_TOPIC_SUCCESS = 'SELECT_USER_TOPIC_SUCCESS';
export const SELECT_USER_TOPIC_IN_PROGRESS = 'SELECT_USER_TOPIC_IN_PROGRESS';
export const READ_CATEGORIES_IN_TOPIC_SUCCESS = 'READ_CATEGORIES_IN_TOPIC_SUCCESS';
export const READ_CATEGORIES_IN_TOPIC_IN_PROGRESS = 'READ_CATEGORIES_IN_TOPIC_IN_PROGRESS';
export const READ_CATEGORIES_IN_TOPIC_ERROR = 'READ_CATEGORIES_IN_TOPIC_ERROR';
export const READ_ALL_CATEGORIES_IN_ALL_TOPICS_IN_PROGRESS = 'READ_ALL_CATEGORIES_IN_ALL_TOPICS_IN_PROGRESS';
export const READ_ALL_CATEGORIES_IN_ALL_TOPICS_ERROR = 'READ_ALL_CATEGORIES_IN_ALL_TOPICS_ERROR';
export const READ_ALL_CATEGORIES_IN_ALL_TOPICS_SUCCESS = 'READ_ALL_CATEGORIES_IN_ALL_TOPICS_SUCCESS';
export const UPDATE_TOPIC_PROGRESS = 'UPDATE_TOPIC_PROGRESS';
