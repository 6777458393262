import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DatePicker, Row, Col, Form } from 'antd';

class DueDate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stepCount: props.stepCount
    }
  }

  static getDerivedStateFromProps(nextProps, prevState){
    return {
      ...nextProps
    }
  }

  render() {
    return (
      <div hidden={this.state.stepCount !== 1}>
        <Row style={{ textAlign: 'center', marginTop: 100}}>
          <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 24}} style={{ marginBottom: 10}}>
            <span style={styles.headerText}> When is your baby due? </ span>
            </ Col>
        </ Row >
        <Row style={{ textAlign: 'center', marginBottom: 100}}>
          <Col xs={{span: 24}} sm={{span: 24}} md={{span: 8}} lg={{span: 8}}/>
          <Col xs={{span: 24}} sm={{span: 24}} md={{span: 8}} lg={{span: 8}}>
            <Form.Item name='due_date' preserve={true} hidden={this.state.stepCount !== 1}>
              <DatePicker style={styles.DatePickerStyle}/>
            </Form.Item>
          </ Col>
          <Col xs={{span: 24}} sm={{span: 24}} md={{span: 8}} lg={{span: 8}}/>
        </ Row >
      </div>
    );
  }
};

const styles = {
  headerText: {
    paddingTop: 40,
    paddingBottom: 0,
    fontWeight: 300,
    fontSize: '200%',
    marginBottom: 0
  },
  DatePickerStyle: {
    width: '90%'
  }
}


const mapStateToProps = (state) => {
  return {

  }
};

const mapDispatchToProps = (dispatch) => {
  return {

  }
};

export default connect(mapStateToProps, mapDispatchToProps)(DueDate);
