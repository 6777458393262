const initialState = {
  moments: [],
  sortedMilestones: [],
  groupedTimelineItems: {},
  activeCardId: null,
  editingMomentId: null,
  currentLifeStage: '1st Trimester',
  currentMilestone: null,
  newlyCompletedMilestone: null,
  momentsWithInput: {},
  addedToTimelineText: {},
  achievedMoments: [],
  unachievedMoments: [],
  achievedMomentOrder: [],
  expandedCardId: null,
  previousVisibleItemId: null,
  removedTasks: []
};

export default initialState;
