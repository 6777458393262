import React, { Component } from 'react';

class App403 extends Component {
  render() {
    return(
      <h1 style={{margin: 0, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', fontSize: 36}}>Access Denied</h1>
    )
  }
}

export default App403;
