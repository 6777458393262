import React from 'react';
import { Typography } from 'antd';
import ClickableCard from '../ClickableCard';

const { Title, Text } = Typography;

const PregnancyQuestion = ({ onSelect }) => {
  return (
    <div className="pregnancy-question">
      <Title level={2}>Are you currently pregnant?</Title>
      <Text>This will impact the items that are included in your default checklist</Text>
      <div className="clickable-card-container">
        <ClickableCard onClick={() => onSelect('Yes')}>Yes</ClickableCard>
        <ClickableCard onClick={() => onSelect('No')}>No</ClickableCard>
      </div>
    </div>
  );
};

export default PregnancyQuestion;
